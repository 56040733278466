/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface AdminroleAdminUserData {
  id?: number
  perm_type?: EdbAdminUserPermType
  uid?: number
}

export interface AdminroleListResponse {
  created_at?: string
  description?: string
  id?: number
  name?: string
  status?: EdbAdminRoleStatus
  updated_at?: string
}

export interface AdminroleOriginData {
  name?: string
}

export interface AdminrolePermData {
  id?: number
  is_for_delete?: boolean
  perm?: string
  policy_type?: EdbAdminUserPermPolicyType
}

export interface AdminroleRequest {
  description?: string
  name?: string
  perm?: AdminrolePermData[]
  status?: EdbAdminRoleStatus
}

export interface AdminroleResponse {
  admin_user?: AdminroleAdminUserData[]
  created_at?: string
  description?: string
  id?: number
  name?: string
  origin?: AdminroleOriginData
  perm?: AdminrolePermData[]
  status?: EdbAdminRoleStatus
  updated_at?: string
}

export interface AdminroleUpdStatusReq {
  status?: EdbAdminRoleStatus
}

export interface AdminroleUpdStatusRes {
  id?: number
  name?: string
  origin?: AdminroleOriginData
  perm?: AdminrolePermData[]
  status?: EdbAdminRoleStatus
  updated_at?: string
}

export interface AdminuserListResponse {
  auth?: EschemaMemberAuthBasicRes[]
  created_at?: string
  display_name?: string
  email?: string
  mfa_enabled?: EdbAdminUserMFAEnabled
  perm_type?: number
  role?: AdminuserRoleData[]
  status?: number
  uid?: number
  unhide_uid?: number
  updated_at?: string
}

export interface AdminuserPermData {
  created_at?: string
  id?: number
  perm?: string
  policy_type?: EdbAdminUserPermPolicyType
  uid?: number
  updated_at?: string
}

export interface AdminuserReqPermData {
  perm?: string
  policy_type?: EdbAdminUserPermPolicyType
}

export interface AdminuserReqRoleData {
  role_id?: number
  role_name?: string
}

export interface AdminuserRequest {
  auth?: AuthRequest[]
  display_name?: string
  email?: string
  mfa_enabled?: EdbAdminUserMFAEnabled
  perm?: AdminuserReqPermData[]
  perm_type?: EdbAdminUserPermType
  role?: AdminuserReqRoleData[]
  status?: EdbAdminUserStatus
}

export interface AdminuserResponse {
  auth?: EschemaMemberAuthBasicRes[]
  created_at?: string
  display_name?: string
  email?: string
  metabase_token?: string
  mfa_enabled?: EdbAdminUserMFAEnabled
  perm?: AdminuserPermData[]
  perm_type?: EdbAdminUserPermType
  role?: AdminuserRoleData[]
  status?: EdbAdminUserStatus
  uid?: number
  unhide_uid?: number
  updated_at?: string
}

export type AdminuserRevokeMetabaseTokenReq = object

export interface AdminuserRevokeMetabaseTokenRes {
  metabase_token?: string
  updated_at?: string
}

export interface AdminuserRoleData {
  created_at?: string
  id?: number
  role_id?: number
  role_name?: string
  uid?: number
}

export interface AdminuserRoleRequest {
  role_id?: number
  uid?: number
}

export interface AdminuserRoleResponse {
  created_at?: string
  id?: number
  perm_type?: EdbAdminUserPermType
  role_id?: number
  role_name?: string
  uid?: number
  updated_at?: string
}

export interface AdminuserUpdStatusReq {
  status?: EdbAdminUserStatus
}

export interface AdminuserUpdStatusRes {
  status?: EdbAdminUserStatus
  uid?: number
  updated_at?: string
}

export interface AdminuserUpdatePermData {
  id?: number
  is_for_delete?: boolean
  perm?: string
  policy_type?: EdbAdminUserPermPolicyType
}

export interface AdminuserUpdateRequest {
  display_name?: string
  email?: string
  mfa_enabled?: EdbAdminUserMFAEnabled
  perm?: AdminuserUpdatePermData[]
  perm_type?: EdbAdminUserPermType
  status?: EdbAdminUserStatus
}

export interface AdminuserUpdateResponse {
  created_at?: string
  display_name?: string
  email?: string
  mfa_enabled?: EdbAdminUserMFAEnabled
  perm?: AdminuserPermData[]
  perm_type?: EdbAdminUserPermType
  role?: AdminuserRoleData[]
  status?: EdbAdminUserStatus
  uid?: number
  unhide_uid?: number
  updated_at?: string
}

export interface ApiresBase {
  code?: number
  message?: string
}

export interface ApiresData {
  code?: number
  data?: any
  message?: string
}

export interface ApiresDocCoverImage {
  code?: number
  data?: ApiresDocCoverImageData
  message?: string
}

export interface ApiresDocCoverImageData {
  cover_image?: string
  id?: number
  updated_at?: string
}

export interface ApiresDocImg {
  code?: number
  data?: ApiresDocImgData
  message?: string
}

export interface ApiresDocImgData {
  id?: number
  img?: string
  updated_at?: string
}

export interface ApiresDocMultiData {
  code?: number
  data?: ApiresDocMultiDataRes
  message?: string
}

export interface ApiresDocMultiDataRes {
  err_data?: Record<string, any[]>
  err_id?: number[]
  success_id?: number[]
}

export interface ApiresDocStatus {
  code?: number
  data?: ApiresDocStatusData
  message?: string
}

export interface ApiresDocStatusData {
  id?: number
  status?: number
  updated_at?: string
}

export interface ApiresDocUploadImg {
  code?: number
  data?: ApiresDocUploadImgData
  message?: string
}

export interface ApiresDocUploadImgData {
  img?: string
  updated_at?: string
}

export interface ApiresExportList {
  code?: number
  export_id?: any
  export_path?: string
  message?: string
  total_count?: number
}

export interface ApiresLinkRedirect {
  redirect_url?: string
}

export interface ApiresList {
  code?: number
  data?: any
  message?: string
  total_count?: number
}

export interface ApiresListData {
  list?: any
  total_count?: number
}

export interface ApiresListV2 {
  code?: number
  data?: ApiresListData
  message?: string
}

export interface ApiresMultiDataRes {
  err_data?: any
  err_id?: any[]
  success_id?: any[]
}

export interface ApiresTimezoneList {
  code?: number
  data?: any
  message?: string
  timezone?: string
  total_count?: number
}

export interface ApiresTimezoneListData {
  list?: any
  timezone?: string
  total_count?: number
}

export interface ApiresTimezoneListV2 {
  code?: number
  data?: ApiresTimezoneListData
  message?: string
}

export interface AssistantMessage {
  cards?: AssistantMessageCards
  /** @maxLength 50 */
  content?: string
  content_type?: DomainMessageContentType
  message_id?: string
  rating?: DomainMessageRatingType
  role?: DomainMessageRole
  sender?: AssistantMessageSender
}

export interface AssistantMessageAction {
  payload?: string
  type?: DomainMessageActionType
}

export interface AssistantMessageCardProduct {
  branch_name?: string
  image?: string
  product_id?: number
  product_name?: string
}

export interface AssistantMessageCards {
  products?: AssistantMessageCardProduct[]
  products_search?: AssistantMessageNewAction
  reply?: AssistantMessageReply
}

export interface AssistantMessageNewAction {
  action?: AssistantMessageAction
}

export interface AssistantMessageReply {
  action?: AssistantMessageAction
  /** @maxLength 50 */
  content?: string
  content_type?: DomainMessageContentType
  hint?: string
}

export interface AssistantMessageSender {
  icon?: string
  name?: string
}

export interface AssistantCreateThreadResData {
  greeting_message?: AssistantGreetingMessage
}

export interface AssistantGreetingMessage {
  /** @maxLength 50 */
  content?: string
  content_type?: DomainMessageContentType
  quick_replies?: AssistantGreetingMessageQuickReply[]
  role?: DomainMessageRole
  sender?: AssistantMessageSender
}

export interface AssistantGreetingMessageQuickReply {
  /** @maxLength 50 */
  content?: string
  content_type?: DomainMessageContentType
  icon?: string
}

export interface AssistantInfoResData {
  available_thread?: boolean
  greeting?: string
  latest_message_at?: string
}

export interface AssistantMessagesResData {
  list?: AssistantMessage[]
  next_page_token?: string
}

export interface AuthAnonymousRegister {
  /** App version code */
  appVersion: string
  currency?: number
  /** Device id */
  deviceId: string
  /** Android gaid */
  gaid?: string
  /** OS like ios, android, web */
  os: string
  /** iOS version, android version, web version */
  osVersion: string
  /** Push token from firebase */
  push_token?: string
}

export interface AuthDocListResponse {
  code?: number
  data?: AuthListResponse[]
  message?: string
  total_count?: number
}

export interface AuthDocMemberInfo {
  account?: string
  /** auth type: 0: Email, 1: Google, 2: FB, 3: Guest, 4: LINE */
  auth_type?: number
  bhd_editable?: number
  birthday?: string
  bonus?: number
  bonus_valid_date?: string
  companyName?: string
  currency?: number
  email?: string
  enabled?: number
  gender?: number
  history?: number
  invite_code?: string
  is_vip?: boolean
  nickname?: string
  phone?: string
  point?: number
  region_id?: number
  sum_past?: number
  sum_this_month?: number
  taxId?: string
  vip_end_time?: string
}

export interface AuthDocResponse {
  code?: number
  data?: AuthResponse
  message?: string
}

export interface AuthListResponse {
  auth_id?: string
  auth_type?: number
  created_at?: string
  email?: string
  id?: number
  name?: string
  status?: number
  uid?: number
  updated_at?: string
}

export interface AuthLoginReq {
  password?: string
  username?: string
}

export interface AuthLoginResp {
  code?: number
  data?: AuthDocMemberInfo
  message?: string
}

export interface AuthMigrateSessionData {
  auth_type?: EdbMemberAuthType
}

export interface AuthMigrateSessionReq {
  /** App version code */
  appVersion: string
  /** Device id */
  deviceId: string
  /** Android gaid */
  gaid?: string
  /** OS like ios, android, web */
  os: string
  /** iOS version, android version, web version */
  osVersion: string
  /** Push token from firebase */
  push_token?: string
}

export interface AuthRequest {
  auth_id?: string
  auth_token?: string
  auth_type?: number
}

export interface AuthResponse {
  auth_id?: string
  auth_type?: number
  created_at?: string
  email?: string
  id?: number
  name?: string
  status?: number
  uid?: number
  updated_at?: string
}

export interface AuthUserLogin {
  /** App version code */
  appVersion: string
  /** Device id */
  deviceId: string
  /** Android gaid */
  gaid?: string
  /** OS like ios, android, web */
  os: string
  /** iOS version, android version, web version */
  osVersion: string
  /** Password to login */
  password: string
  /** Push token from firebase */
  push_token?: string
  /** Username to login */
  username: string
  /** reCAPTCHA token */
  verification_token: string
}

export interface AuthUserRegister {
  /** App version code */
  appVersion: string
  /** Apple auth code */
  apple_auth_code?: string
  /** Apple JWT token */
  apple_token?: string
  /** Apple user ID */
  apple_user_id?: string
  /** Birthday format is yyyy-MM-dd */
  birthday?: string
  bonus?: number
  /** Currency ID, 0: TWD, 1: HKD, 2: JPY, 3: MYR ... */
  currency?: number
  /** Device id */
  deviceId: string
  /** Email if register from facebook */
  email?: string
  /** This value must bind if from facebook */
  fbId?: string
  fbJwtNonce?: string
  fbJwtToken?: string
  /** Facebook login token must bind if from Facebook */
  fbToken?: string
  /** Ask to register from Facebook */
  fromFacebook?: boolean
  /** Ask to register from LINE user */
  fromLine?: boolean
  /** Ask to register from Apple */
  from_apple?: boolean
  /** Ask to register from TNG */
  from_tng?: boolean
  /** Android gaid */
  gaid?: string
  /** Gender, 0: Female, 1: Male */
  gender?: number
  /** Invite code or promotion code */
  invitecode?: string
  /** LINE sid */
  lineId?: string
  /** LINE picture */
  linePicture?: string
  /** LINE sub */
  lineSub?: string
  /** LINE access_token */
  lineToken?: string
  /** Member's nick Name */
  name?: string
  /** OS like ios, android, web */
  os: string
  /** iOS version, android version, web version */
  osVersion: string
  /** Password to register */
  password?: string
  /** Push token from firebase */
  push_token?: string
  /** TNG auth code */
  tng_auth_code?: string
  /** Username to register */
  username?: string
  /** utm campaign */
  utm_campaign?: string
  /** utm medium */
  utm_medium?: string
  /** utm source */
  utm_source?: string
  /** reCAPTCHA token */
  verification_token?: string
}

export interface AuthmodChangePasswordReq {
  new_pwd: string
  old_pwd: string
}

export interface AuthmodDocResponse {
  code?: number
  data?: AuthmodResponse
  message?: string
}

export interface AuthmodRecoverPasswordRequest {
  /** user email (username) */
  email: string
  /** reCAPTCHA token */
  verification_token?: string
}

export interface AuthmodResponse {
  uid?: number
  username?: string
}

export interface BannerCoverImgRes {
  cover_image?: string
  id?: number
  updated_at?: string
}

export interface BannerLargeCoverImgRes {
  id?: number
  large_cover_image?: string
  updated_at?: string
}

export interface BannerListCategoryData {
  banner_id?: number
  category_id?: EdbCategoryID
  category_name?: string
  status?: EdbCategoryStatus
}

export interface BannerListRegionData {
  banner_id?: number
  brand_id?: number
  brand_name?: string
  region_id?: number
  region_name?: string
  status?: EdbRegionStatus
}

export interface BannerListResponse {
  category?: BannerListCategoryData[]
  cover_image?: string
  end_at?: string
  id?: number
  link_target?: string
  link_type?: EdbBannerLinkType
  link_url?: string
  platform?: EdbBannerPlatform
  region?: BannerListRegionData[]
  start_at?: string
  status?: EdbBannerStatus
  title?: string
  trans?: BannerListTransData[]
}

export interface BannerListTransData {
  banner_id?: number
  id?: number
  lang_code?: string
  lang_id?: number
  title?: string
  trans_status?: EdbTransStatus
}

export interface BannerRequest {
  app_link_target?: string
  app_link_type?: EdbBannerLinkType
  category?: EschemaCategoryData[]
  cover_image?: string
  description?: string
  end_at?: string
  large_cover_image?: string
  link_target?: string
  link_type?: EdbBannerLinkType
  link_url?: string
  platform?: EdbBannerPlatform
  region?: EschemaRegionData[]
  start_at?: string
  status?: EdbBannerStatus
  title?: string
  trans?: BannerTransData[]
  web_link_target?: string
  web_link_type?: EdbBannerLinkType
}

export interface BannerResponse {
  app_link_target?: string
  app_link_type?: EdbBannerLinkType
  category?: EschemaCategoryData[]
  cover_image?: string
  created_at?: string
  description?: string
  end_at?: string
  id?: number
  large_cover_image?: string
  link_target?: string
  link_type?: EdbBannerLinkType
  link_url?: string
  platform?: EdbBannerPlatform
  region?: EschemaRegionData[]
  start_at?: string
  status?: EdbBannerStatus
  title?: string
  trans?: BannerTransData[]
  updated_at?: string
  web_link_target?: string
  web_link_type?: EdbBannerLinkType
}

export interface BannerTransData {
  description?: string
  description_status?: EdbTransFieldStatus
  id?: number
  is_for_delete?: boolean
  lang_id?: number
  status?: EdbTransStatus
  title?: string
  title_status?: EdbTransFieldStatus
}

export interface BannerTransListRes {
  banner_id?: number
  code?: string
  id?: number
  name?: string
  status?: EdbTransStatus
  trans_id?: number
}

export interface BannerTransReq {
  banner_id?: number
  description?: string
  description_status?: EdbTransFieldStatus
  lang_id?: number
  status?: EdbTransStatus
  title?: string
  title_status?: EdbTransFieldStatus
}

export interface BannerTransRes {
  banner_id?: number
  created_at?: string
  description?: string
  description_status?: EdbTransFieldStatus
  id?: number
  lang_id?: number
  status?: EdbTransStatus
  title?: string
  title_status?: EdbTransFieldStatus
  updated_at?: string
}

export interface BannerUpdStatusReq {
  status?: EdbBannerStatus
}

export interface BannerUpdStatusRes {
  id?: number
  status?: EdbBannerStatus
  updated_at?: string
}

export interface BbistrochatFields {
  venue_id?: string
}

export interface BblockFields {
  id?: number
  is_for_delete?: boolean
  remark?: string
  uid?: number
}

export interface BdunqianFields {
  hotel_code?: string
}

export interface BeatigoFields {
  beverage_avg_cost?: number
  beverage_avg_expense?: number
  is_on_site_payment_discount?: EdbBranchEatigoIsOnSitePaymentDiscount
  meal_avg_cost?: number
  meal_avg_expense?: number
  reservations?: number
  reservations_mode?: EdbBranchEatigoReservationsMode
  restaurant_id?: number
  total_avg_expense?: number
}

export interface BebisolFields {
  ebisol_group?: string
  ebisol_shop?: number
}

export interface BezdingFields {
  combo_adj?: number
  combo_fee?: number
  combo_upgrade_adj?: number
  combo_upgrade_fee?: number
  ez_cinema_id?: string
  fun_coin_fee?: number
  normal_fee?: number
  offline_refund_limit?: number
  online_refund_limit?: number
  package_fee?: number
  prepare_time?: number
  promo_fee?: number
}

export interface BfreecoverFields {
  end_month?: SchemaMonth
  id?: number
  is_for_delete?: boolean
  pax?: number
  start_month?: SchemaMonth
}

export interface BfunbookFields {
  buffer_time?: number
  duration?: number
  own_page_status?: EdbFunBookOwnPageStatus
  prepare_time?: number
  promo_code_type?: EdbFunBookPromoCodeType
  type?: EdbFunBookType
  ver?: EdbFunBookVer
}

export interface BinlineFields {
  branch_id?: string
  company_id?: string
  table_tag?: string
  tag_type?: EdbInlineTagType
}

export interface BlacklistDocListResponse {
  code?: number
  data?: BlacklistListResponse[]
  message?: string
  total_count?: number
}

export interface BlacklistDocResponse {
  code?: number
  data?: BlacklistResponse
  message?: string
}

export interface BlacklistListResponse {
  created_at?: string
  id?: number
  match_type?: EdbBlackListMatchType
  name?: string
  note?: string
  priority?: number
  rule?: string
  rule_type?: EdbBlackListRuleType
  target?: EdbBlackListTarget
  updated_at?: string
}

export interface BlacklistRequest {
  match_type?: EdbBlackListMatchType
  name?: string
  note?: string
  priority?: number
  rule?: string
  rule_type?: EdbBlackListRuleType
  target?: EdbBlackListTarget
}

export interface BlacklistResponse {
  created_at?: string
  id?: number
  match_type?: EdbBlackListMatchType
  name?: string
  note?: string
  priority?: number
  rule?: string
  rule_type?: EdbBlackListRuleType
  target?: EdbBlackListTarget
  updated_at?: string
}

export interface BlogAlbumData {
  id?: number
  image_path?: string
}

export interface BlogAuthor {
  avatar?: string
  description?: string
  email?: string
  id?: number
  name?: string
  website?: string
}

export interface BlogAuthorDetail {
  avatar?: string
  blog_cnt?: number
  description?: string
  email?: string
  id?: number
  name?: string
  website?: string
}

export interface BlogBlogAuthorData {
  avatar?: string
  id?: number
  name?: string
}

export interface BlogBlogCategoryData {
  blog_country_id?: number
  blog_country_name?: string
  id?: number
  name?: string
}

export interface BlogBlogTagData {
  id?: number
  name?: string
}

export interface BlogCategory {
  category_id?: number
  country_id?: number
  country_name?: string
  country_path_name?: string
  name?: string
  path_name?: string
}

export interface BlogCategoryDetail {
  blog_cnt?: number
  country_id?: number
  country_name?: string
  country_path_name?: string
  cover_image?: string
  description?: string
  id?: number
  name?: string
  path_name?: string
  rank?: number
}

export interface BlogCountryDetail {
  id?: number
  name?: string
  path_name?: string
}

export interface BlogDocAlbumResponse {
  code?: number
  data?: BlogUploadAlbumRes
  message?: string
}

export interface BlogDocListResponse {
  code?: number
  data?: AdminBlogListResponse[]
  message?: string
  total_count?: number
}

export interface BlogDocResponse {
  code?: number
  data?: BlogResponse
  message?: string
}

export interface BlogDocStatusRes {
  code?: number
  data?: BlogUpdateStatusResponse
  message?: string
}

export interface BlogGetAuthorRes {
  code?: number
  data?: BlogAuthorDetail
  message?: string
}

export interface BlogGetCategoryRes {
  code?: number
  data?: BlogCategoryDetail
  message?: string
}

export interface BlogGetCountryRes {
  code?: number
  data?: BlogCountryDetail
  message?: string
}

export interface BlogGetData {
  abstract?: string
  authors?: BlogAuthor[]
  categories?: BlogCategory[]
  content?: string
  cover_image?: string
  create_time?: string
  id?: number
  meta_keywords?: string
  path_name?: string
  products?: BlogProduct[]
  static_link?: string
  subject?: string
  tags?: BlogTag[]
}

export interface BlogGetResponse {
  code?: number
  data?: BlogGetData
  message?: string
}

export interface BlogGetTagRes {
  code?: number
  data?: BlogTagDetail
  message?: string
}

export interface BlogListAuthorData {
  author_id?: number
  author_name?: string
  blog_id?: number
  id?: number
}

export interface BlogListAuthorRes {
  code?: number
  data?: BlogAuthorDetail[]
  message?: string
  total_count?: number
}

export interface BlogListCategoryRes {
  code?: number
  data?: BlogCategoryDetail[]
  message?: string
  total_count?: number
}

export interface BlogListCountryRes {
  code?: number
  data?: BlogCountryDetail[]
  message?: string
  total_count?: number
}

export interface BlogListDetail {
  abstract?: string
  authors?: BlogAuthor[]
  categories?: BlogCategory[]
  content?: string
  cover_image?: string
  create_time?: string
  id?: number
  meta_keywords?: string
  path_name?: string
  subject?: string
  tags?: BlogTag[]
}

export interface BlogListTagData {
  blog_id?: number
  id?: number
  tag_id?: number
  tag_name?: string
}

export interface BlogListTagRes {
  code?: number
  data?: BlogTagDetail[]
  message?: string
  total_count?: number
}

export interface BlogProduct {
  area?: string
  bid?: number
  branch_name?: string
  cover_image?: string
  currency_name?: string
  min_price?: number
  product_id?: number
  product_name?: string
  rrp?: number
  srrp?: number
}

export interface BlogRequest {
  abstract?: string
  album_images?: BlogAlbumData[]
  blog_author?: BlogBlogAuthorData[]
  blog_category?: BlogBlogCategoryData[]
  blog_tag?: BlogBlogTagData[]
  content?: string
  cover_image?: string
  end_at?: string
  meta_keywords?: string
  path_name?: string
  post_at?: string
  product?: EschemaProductData[]
  rank?: number
  start_at?: string
  status?: EdbBlogStatus
  subject?: string
  template?: BlogTemplateData[]
}

export interface BlogResponse {
  abstract?: string
  album_images?: BlogAlbumData[]
  blog_author?: BlogBlogAuthorData[]
  blog_category?: BlogBlogCategoryData[]
  blog_tag?: BlogBlogTagData[]
  content?: string
  cover_image?: string
  created_at?: string
  end_at?: string
  id?: number
  meta_keywords?: string
  path_name?: string
  post_at?: string
  product?: EschemaProductData[]
  rank?: number
  start_at?: string
  status?: EdbBlogStatus
  subject?: string
  template?: BlogTemplateData[]
  updated_at?: string
}

export interface BlogTag {
  id?: number
  name?: string
}

export interface BlogTagDetail {
  blog_cnt?: number
  id?: number
  name?: string
}

export interface BlogTemplateData {
  id?: number
  name?: string
}

export interface BlogUpdateStatusResponse {
  id?: number
  status?: EdbBlogStatus
  updated_at?: string
}

export interface BlogUploadAlbumRes {
  album_images?: BlogAlbumData[]
  id?: number
}

export interface BlogalbumDocListResponse {
  code?: number
  data?: BlogalbumListResponse[]
  message?: string
  total_count?: number
}

export interface BlogalbumDocResponse {
  code?: number
  data?: BlogalbumResponse
  message?: string
}

export interface BlogalbumListResponse {
  blog_id?: number
  created_at?: string
  description?: string
  id?: number
  image_path?: string
  title?: string
  updated_at?: string
}

export interface BlogalbumResponse {
  blog_id?: number
  created_at?: string
  description?: string
  id?: number
  image_path?: string
  title?: string
  updated_at?: string
}

export interface BlogauthorDocAvatarResponse {
  code?: number
  data?: BlogauthorUpdateAvatarResponse
  message?: string
}

export interface BlogauthorDocListResponse {
  code?: number
  data?: BlogauthorListResponse[]
  message?: string
  total_count?: number
}

export interface BlogauthorDocResponse {
  code?: number
  data?: BlogauthorResponse
  message?: string
}

export interface BlogauthorListResponse {
  avatar?: string
  created_at?: string
  email?: string
  id?: number
  name?: string
  website?: string
}

export interface BlogauthorRequest {
  avatar?: string
  description?: string
  email?: string
  name?: string
  web_site?: string
}

export interface BlogauthorResponse {
  avatar?: string
  created_at?: string
  description?: string
  email?: string
  id?: number
  name?: string
  updated_at?: string
  web_site?: string
}

export interface BlogauthorUpdateAvatarResponse {
  avatar?: string
  id?: number
  updated_at?: string
}

export interface BlogcountryBlogCategoryData {
  id?: number
}

export interface BlogcountryDocListResponse {
  code?: number
  data?: BlogcountryListResponse[]
  message?: string
  total_count?: number
}

export interface BlogcountryDocResponse {
  code?: number
  data?: BlogcountryResponse
  message?: string
}

export interface BlogcountryDocTransListResponse {
  code?: number
  data?: BlogcountryTransListResponse[]
  message?: string
  total_count?: number
}

export interface BlogcountryDocTransResponse {
  code?: number
  data?: BlogcountryTransResponse
  message?: string
}

export interface BlogcountryListResponse {
  created_at?: string
  id?: number
  name?: string
  path_name?: string
  rank?: number
  status?: EdbRegionStatus
  trans?: BlogcountryListTransData[]
}

export interface BlogcountryListTransData {
  blog_country_id?: number
  id?: number
  lang_code?: string
  lang_id?: number
  name?: string
  trans_status?: EdbTransStatus
}

export interface BlogcountryResponse {
  blog_category?: BlogcountryBlogCategoryData[]
  created_at?: string
  id?: number
  name?: string
  path_name?: string
  rank?: number
  status?: EdbRegionStatus
  trans?: BlogcountryTransData[]
  updated_at?: string
}

export interface BlogcountryTransData {
  id?: number
  is_for_delete?: boolean
  lang_id?: number
  name?: string
  name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
}

export interface BlogcountryTransListResponse {
  blog_country_id?: number
  code?: string
  id?: number
  name?: string
  status?: EdbTransStatus
  trans_id?: number
}

export interface BlogcountryTransRequest {
  blog_country_id?: number
  lang_id?: number
  name?: string
  name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
}

export interface BlogcountryTransResponse {
  blog_country_id?: number
  created_at?: string
  id?: number
  lang_id?: number
  name?: string
  name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  updated_at?: string
}

export interface BlogctgBlogData {
  id?: number
}

export interface BlogctgDocListResponse {
  code?: number
  data?: BlogctgListResponse[]
  message?: string
  total_count?: number
}

export interface BlogctgDocResponse {
  code?: number
  data?: BlogctgResponse
  message?: string
}

export interface BlogctgDocTransListResponse {
  code?: number
  data?: BlogctgTransListResponse[]
  message?: string
  total_count?: number
}

export interface BlogctgDocTransResponse {
  code?: number
  data?: BlogctgTransResponse
  message?: string
}

export interface BlogctgListResponse {
  blog_country_id?: number
  blog_country_name?: string
  blog_country_path_name?: string
  cover_image?: string
  created_at?: string
  id?: number
  name?: string
  path_name?: string
  rank?: number
  trans?: BlogctgListTransData[]
}

export interface BlogctgListTransData {
  blog_category_id?: number
  id?: number
  lang_code?: string
  lang_id?: number
  name?: string
  trans_status?: EdbTransStatus
}

export interface BlogctgRequest {
  blog?: BlogctgBlogData[]
  blog_country_id?: number
  cover_image?: string
  description?: string
  name?: string
  path_name?: string
  rank?: number
  trans?: BlogctgTransData[]
}

export interface BlogctgResponse {
  blog?: BlogctgBlogData[]
  blog_country_id?: number
  blog_country_name?: string
  cover_image?: string
  created_at?: string
  description?: string
  id?: number
  name?: string
  path_name?: string
  rank?: number
  trans?: BlogctgTransData[]
  updated_at?: string
}

export interface BlogctgTransData {
  id?: number
  is_for_delete?: boolean
  lang_id?: number
  name?: string
  name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
}

export interface BlogctgTransListResponse {
  blog_category_id?: number
  code?: string
  id?: number
  name?: string
  status?: EdbTransStatus
  trans_id?: number
}

export interface BlogctgTransRequest {
  blog_category_id?: number
  lang_id?: number
  name?: string
  name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
}

export interface BlogctgTransResponse {
  blog_category_id?: number
  created_at?: string
  id?: number
  lang_id?: number
  name?: string
  name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  updated_at?: string
}

export interface BlogtagDocListResponse {
  code?: number
  data?: BlogtagListResponse[]
  message?: string
  total_count?: number
}

export interface BlogtagDocResponse {
  code?: number
  data?: BlogtagResponse
  message?: string
}

export interface BlogtagListResponse {
  created_at?: string
  id?: number
  name?: string
}

export interface BlogtagRequest {
  name?: string
}

export interface BlogtagResponse {
  created_at?: string
  id?: number
  name?: string
  updated_at?: string
}

export interface BocardFields {
  brand_id?: string
  merchant_id?: string
}

export interface BossnowuserBranchData {
  bid?: number
  name?: string
}

export interface BossnowuserCreateRequest {
  auth?: AuthRequest[]
  branch?: BossnowuserBranchData[]
  display_name?: string
  email?: string
  merchant?: BossnowuserMerchantData[]
  perm_type?: number
  region?: BossnowuserRegionData[]
  role?: EdbBossNowUserRole
  status?: number
}

export interface BossnowuserDocListResponse {
  code?: number
  list?: BossnowuserListResponse[]
  message?: string
  total_count?: number
}

export interface BossnowuserDocResponse {
  code?: number
  data?: BossnowuserResponse
  message?: string
}

export interface BossnowuserDocToBranchResponse {
  code?: number
  data?: BossnowuserUserToBranchData
  message?: string
}

export interface BossnowuserDocToRegionResponse {
  code?: number
  data?: BossnowuserUserToRegionData
  message?: string
}

export interface BossnowuserListResponse {
  auth?: EschemaMemberAuthBasicRes[]
  created_at?: string
  display_name?: string
  email?: string
  perm_type?: number
  role?: EdbBossNowUserRole
  status?: number
  uid?: number
  unhide_uid?: number
  updated_at?: string
}

export interface BossnowuserMerchantData {
  id?: number
  name?: string
}

export interface BossnowuserRegionData {
  id?: number
  name?: string
}

export interface BossnowuserResponse {
  auth?: EschemaMemberAuthBasicRes[]
  branch?: BossnowuserBranchData[]
  created_at?: string
  display_name?: string
  email?: string
  merchant?: BossnowuserMerchantData[]
  perm_type?: number
  region?: BossnowuserRegionData[]
  role?: EdbBossNowUserRole
  status?: number
  uid?: number
  unhide_uid?: number
  updated_at?: string
}

export interface BossnowuserUpdateRequest {
  display_name?: string
  email?: string
  perm_type?: number
  role?: EdbBossNowUserRole
  status?: number
}

export interface BossnowuserUserToBranchData {
  bid?: number
  uid?: number
}

export interface BossnowuserUserToRegionData {
  id?: number
  region_id?: number
  uid?: number
}

export interface BossnowuserUserToRegionRequest {
  region_id?: number
  uid?: number
}

export interface BownpageFields {
  domain?: string
  domain_prefix?: string
  type?: EdbBranchDomainType
}

export interface BpartnerFields {
  branch_id?: string
  id?: number
  is_for_delete?: boolean
  partner_id?: number
  partner_name?: string
}

export interface BranchArrivalTimeInfo {
  end_day?: string
  slots?: EatigoBranchSlot[]
  start_day?: string
  time_mode?: EdbProductTimeMode
  timezone?: string
}

export interface BranchArrivalTimeInfoRes {
  currency_id?: number
  currency_symbol?: string
  end_day?: string
  origin_curr_info?: BranchArrivaltimeOriginCurrInfo
  remain?: FbranchRemain
  sale_start_at?: string
  selldates?: SselldateSelldates
  start_day?: string
  /** time mode (0: 7 days, 1: 30 days, 2: 180 days, 3: 60 days, 4: 90 days, 5: 365 days) */
  time_mode?: EdbProductTimeMode
  timezone?: string
}

export interface BranchArrivaltimeOriginCurrInfo {
  currency_id?: EdbCurrencyID
  currency_symbol?: string
}

export interface BranchAtdExcelReq {
  /** @example "2006-01-02" */
  end: string
  /** @example ["atdFilled-funnow","atdFilled-funbook"] */
  filter_options: MongoFilterOption[]
  /** @example "3022063123911" */
  keyword: string
  /** @example [3022063123911] */
  pids: number[]
  /** @example "2006-01-02" */
  start: string
  /** @example ["15:00","16:00"] */
  time_slots?: string[]
  /** @example "phone, order_id" */
  type: SorderSearchType
}

export interface BranchAtdExcelResp {
  file_id?: number
}

export interface BranchBlockStatusRes {
  is_blocked?: boolean
}

export interface BranchBossNowUserData {
  auth_id?: string
  display_name?: string
  email?: string
  role?: EdbBossNowUserRole
  status?: EdbBossNowUserStatus
  uid?: number
}

export interface BranchBranchDetail {
  address?: string
  area?: string
  bid?: number
  calling_code?: string
  comment_count?: number
  cover_image?: string
  currency?: string
  currency_symbol?: string
  distance?: string
  fb_intro?: string
  fb_need_to_know?: string
  funbook_type?: EdbFunBookType
  funbook_ver?: EdbFunBookVer
  industry_id?: number
  industry_name?: string
  intro?: string
  is_elite?: boolean
  is_favorite?: boolean
  lat?: number
  lng?: number
  merchant_branches?: BranchMerchantBranch[]
  name?: string
  nearby_spot?: string
  need_to_know?: string
  /** data: Branch.NoticePhone -> Branch.ContactPhone */
  noticePhone?: string
  number_of_comments?: number
  opening_hours?: string
  platform?: EdbBranchPlatform
  rating?: number
  region_id?: number
  reserve_mode?: EdbBranchReserveMode
  tags?: FunnowBranchTag[]
  titles?: BranchTitle[]
  transportation?: string
  type?: EdbBranchType
  zip?: string
}

export interface BranchBranchInfoResponse {
  code?: number
  data?: BranchBranchInfoResponseData
  message?: string
}

export interface BranchBranchInfoResponseData {
  address?: string
  allow_create_product?: boolean
  allow_flash_sale?: boolean
  am_email?: string
  area?: string
  /** 0: none, 1: auto */
  assignment_mode?: EdbBranchAssignmentMode
  bd_email?: string
  bid?: number
  brand?: BranchBrand
  /** Sort alphabetically case-insensitive */
  brands?: BranchBrand[]
  buffer_time?: number
  /**
   * The order has been adjusted, and the sequence is as follows if exists:
   * eatigo -> FunNow -> niceday -> FunBook -> RwG -> Phone -> walk in -> Third Party
   *
   * Enum meaning:
   * 0: FunNow, 1: FunBook, 2: eatigo, 3: niceday, 4: RwG, 5: Phone, 6: walk in, 7: Third Party
   */
  channels?: DomainManagerOrderChannel[]
  cover_image?: string
  cs_channel?: string
  currency?: BranchCurrency
  deny_edit_resource_text?: string
  duration?: number
  fb_intro?: string
  fb_need_to_know?: string
  fc_restore_id?: string
  funbook_link?: string
  funbook_ver?: EdbFunBookVer
  funnow_link?: string
  has_flash_sale_product?: boolean
  has_funbook_contract?: boolean
  has_seat_only_product?: boolean
  industry_name?: string
  industry_type?: number
  intro?: string
  is_enabled_rwg?: boolean
  name?: string
  need_to_know?: string
  /** data: Branch.NoticePhone -> Branch.ContactPhone */
  notice_phone?: string
  opening_hours?: string
  platform?: string
  prepare_time?: number
  print_status?: EdbBranchPrintStatus
  region?: BranchRegion
  /** deprecated, use region.name instead */
  region_name?: string
  resource_groups?: ResourceResourceGroup[]
  /** 0: normal, 1: table management */
  resource_mode?: EdbBranchResourceMode
  rwg_type?: EdbRwGType
  set_mode?: EdbBranchSetMode
  sms_enabled?: boolean
  type?: EdbBranchType
  weekly_sets?: Record<string, BranchWeeklySet[]>
  zip?: string
}

export interface BranchBranchListData {
  address?: string
  area?: string
  bid?: number
  cover_image?: string
  funbook_ver?: EdbFunBookVer
  name?: string
  type?: EdbBranchType
}

export interface BranchBranchListResponse {
  code?: number
  data?: BranchBranchListData[]
  message?: string
}

export interface BranchBranchResource {
  expired_resources?: BranchTblmgmtResource[]
  not_expired_resources?: BranchTblmgmtResource[]
  rg_id?: number
  rg_name?: string
  rg_rank?: number
  rg_status?: EdbResourceGroupStatus
}

export interface BranchBranchResourceResponse {
  code?: number
  data?: BranchResourceGroup[]
  message?: string
}

export interface BranchBrand {
  branch_link?: string
  name?: string
}

export interface BranchBrandData {
  id?: number
  name?: string
}

export interface BranchCalendarBadgeResponse {
  code?: number
  data?: BranchCalendarBadgeResponseData
  message?: string
}

export interface BranchCalendarBadgeResponseData {
  day_off_dates?: string[]
  flash_dates?: string[]
  orders_dates?: string[]
}

export interface BranchCallingCodeResponse {
  code?: number
  data?: string[]
  message?: string
}

export interface BranchChannelData {
  channel_id?: number
  id?: number
  name?: string
  orders_referer_name?: string
  pax_fee?: number
}

export interface BranchContactData {
  email?: string
  id?: number
  mobile?: string
  name?: string
  phone?: string
}

export interface BranchContractData {
  act_month?: SchemaMonth
  cancel_month?: SchemaMonth
  contract_num?: string
  exp_month?: SchemaMonth
  id?: number
  refund_amount?: number
  send_sms?: EdbSendSMS
  signing_date?: string
}

export interface BranchCountDetail {
  total_count?: number
}

export interface BranchCoverFeeData {
  def_fee?: number
  effective_date?: string
  fee_setting?: string
  id?: number
  type?: EdbCoverFeeType
}

export interface BranchCreateDayOffRequest {
  dates?: string[]
}

export interface BranchCreateResourceGroupTblMgmtReq {
  /**
   * shouldn't be empty string
   * @example "resource group name"
   */
  name: string
  /**
   * use 0 if create first resource group
   * otherwise use "current max rank + 1" of the resource groups
   * @example 3
   */
  rank: number
  /**
   * * 0: online
   * * 1: offline
   * @min 0
   * @max 1
   * @example 0
   */
  status: 0 | 1
}

export interface BranchCreateResourceReq {
  name?: string
}

export interface BranchCreateResourceRes {
  code?: number
  data?: BranchResourceData[]
  message?: string
}

export interface BranchCreateTblResourceReq {
  /**
   * use 1 when copying a table
   * @min 1
   * @max 100
   * @example 1
   */
  batch_count: number
  /**
   * turn time (minute)
   * @min 0
   * @max 720
   * @example 120
   */
  duration: number
  /**
   * @min 1
   * @max 100
   * @example 4
   */
  max: number
  /**
   * @min 1
   * @max 100
   * @example 2
   */
  min: number
  /**
   * only use when copying a table
   * must have " copy" suffix
   * @example "T1 copy"
   */
  name?: string
  /** null means permanent time */
  period?: BranchPeriod
  /**
   * can only use 0 or 2
   * * 0: online
   * * 2: offline
   * @example 0
   */
  status: 0 | 2
}

export interface BranchCurrency {
  code?: string
  decimal_precision?: number
  symbol?: string
}

export interface BranchData {
  address?: string
  area?: string
  atmospheres?: EatigoBranchTag[]
  avg_price_range?: number
  bid?: number
  cancel_rules?: string[]
  country_code?: string
  cover_image?: string
  cuisine_tags?: EatigoBranchTag[]
  /** Cuisine will deprecated, according to the new design, we will use CuisineTags instead */
  cuisines?: EatigoBranchTag[]
  customer_notice?: string
  distance?: string
  facilities?: EatigoBranchTag[]
  google_place_id?: string
  images?: string[]
  intro?: string
  is_favorite?: boolean
  lat?: number
  lng?: number
  location_detail?: string
  name?: string
  nearby_spot?: string
  onboard?: number
  opening_hours?: string
  payment_methods?: EatigoBranchTag[]
  pid?: number
  ratings?: string
  region_id?: number
  region_name?: string
  region_tag?: EatigoBranchTag
  reschedule_rules?: string[]
  reservations?: string
  sale_region_ids?: number[]
  service_languages?: EatigoBranchTag[]
  special_condition?: string
  special_diets?: EatigoBranchTag[]
  tags?: EatigoBranchTag[]
  transportation?: string
}

export interface BranchDayOffSet {
  date?: string
  id?: number
}

export interface BranchDeliveryAreaData {
  area?: string
  id?: number
}

export interface BranchDetailData {
  /** address except city and township */
  address?: string
  /** city and township address */
  area?: string
  bid?: number
  cover_image?: string
  /** distance in km */
  distance?: string
  /** introduction */
  intro?: string
  /** is favorite by current user */
  is_favorite?: boolean
  /** latitude */
  lat?: number
  /** longitude */
  lng?: number
  name?: string
  /** number of comments */
  number_of_comments?: number
  /** description of business hours */
  opening_hours?: string
  /** rating score */
  rating?: number
}

export interface BranchEatigoTagData {
  id?: number
  name?: string
  type?: EdbProductTagType
}

export interface BranchEditResource {
  /**
   * turn time (minute)
   * @min 0
   * @max 720
   * @example 120
   */
  duration: number
  /**
   * @min 1
   * @max 100
   * @example 4
   */
  max: number
  /**
   * @min 1
   * @max 100
   * @example 2
   */
  min: number
  /**
   * empty string is not allowed
   * @example "resource name"
   */
  name: string
  /** null means permanent time */
  period?: BranchPeriod
  /** @example 32866 */
  rg_id: number
  /** @example 30251606423561 */
  rid: number
  /**
   * can only use 0 or 2
   * * 0: online
   * * 2: offline
   * @example 0
   */
  status: 0 | 2
}

export interface BranchEditResourceGroupTblMgmtReq {
  /**
   * shouldn't be empty string
   * @example "resource group name"
   */
  name: string
  /**
   * * 0: online
   * * 1: offline
   * @min 0
   * @max 1
   * @example 0
   */
  status: 0 | 1
}

export interface BranchEditTblResourceReq {
  /** @minItems 1 */
  resources: BranchEditResource[]
}

export interface BranchEvent {
  area?: string
  bid?: number
  branch_name?: string
  category_id?: number
  category_name?: string
  currency_id?: number
  pid?: number
  product_name?: string
  region_id?: number
  region_name?: string
  rrp?: number
  srrp?: number
  tags?: FunnowBranchTag[]
}

export interface BranchFAQ {
  answer?: string
  question?: string
}

export interface BranchFAQResp {
  code?: number
  list?: BranchFAQ[]
  message?: string
}

export interface BranchFlashSet {
  /** @example "2024-11-28 15:00:00" */
  end?: string
  /** @example "2024-11-28 12:00:00" */
  exposure_start?: string
  id?: number
  price?: number
  /**
   * Deprecated: reserve for backward compatibility.
   * Please use `price`, both `price` and `ref_price` are the same value.
   * Related ticket: https://zoeknow.atlassian.net/browse/ZOEKIT-15061
   */
  ref_price?: number
  sell_limit?: number
  sold_count?: number
  /** @example "2024-11-28 12:00:00" */
  start?: string
}

export interface BranchFlashSetCountData {
  product_count?: number
}

export interface BranchFlashSetCountResponse {
  code?: number
  data?: BranchFlashSetCountData
  message?: string
}

export interface BranchGetAvailableResourcesTblMgmtRes {
  /** turn time (in minutes) */
  duration?: number
  /**
   * When creating a resource merge, always false.
   * When editing a resource merge, true if the resource is already merged by the resource merge, otherwise false
   */
  is_merged?: boolean
  max?: number
  min?: number
  name?: string
  rid?: number
}

export interface BranchGetDayOffListResponse {
  code?: number
  data?: BranchMonthDayOffs[]
  message?: string
}

export interface BranchGetMovieSessionsResp {
  code?: number
  data?: BranchSessionData
  message?: string
  timezone?: string
}

export interface BranchGetOccupationRes {
  code?: number
  data?: BranchOccuResource[]
  message?: string
  next_page_token?: string
}

export interface BranchGetOperationLogsResponse {
  code?: number
  data?: BranchOperationLog[]
  message?: string
  next_page_token?: string
}

export interface BranchGetResourceGroupsData {
  name?: string
  rg_id?: number
  status?: EdbResourceGroupStatus
}

export interface BranchGetResourceMergesTblMgmtRes {
  resource_merges?: BranchResourceMergeInfo[]
  rg_id?: number
  rg_name?: string
  /** 0: online, 1: offline */
  rg_status?: EdbResourceGroupStatus
}

export interface BranchGetResponse {
  code?: number
  data?: BranchBranchDetail
  message?: string
}

export interface BranchGetUnavailableResponse {
  code?: number
  data?: BranchGetUnavailableResponseData
  message?: string
}

export interface BranchGetUnavailableResponseData {
  days?: number
  end_date?: string
  resources?: BranchResourceSlots[]
}

export interface BranchGetUnavailableSlotsData {
  name?: string
  resources?: BranchResourceSlots[]
  rg_id?: number
}

export interface BranchGetUnavailableSlotsRes {
  code?: number
  data?: BranchGetUnavailableSlotsData[]
  message?: string
}

export interface BranchHistoryBranch {
  bid?: number
  branch_name?: string
  cover_image?: string
}

export interface BranchHistoryBranchRes {
  list?: BranchHistoryBranch[]
}

export interface BranchIndTypeData {
  id?: number
  name?: string
}

export interface BranchInlineData {
  is_matched?: boolean
  resource_data?: Record<string, EredisInlineResource>
}

export interface BranchInlineResponse {
  code?: number
  data?: BranchInlineData
  message?: string
}

export interface BranchLanguage {
  /**
   * zh-TW, en-US, zh-CN, ja-JP, ms-MY, th-TH
   * @example "zh-TW"
   */
  code?: string
  /**
   * 0: 繁體中文, 1: English, 2: 简体中文, 3: 日本語, 4: 馬來語, 5: 泰文
   * @example 0
   */
  id?: LangLangID
  /** @example "中文" */
  name?: string
}

export interface BranchListBranchData {
  address?: string
  area?: string
  bid?: number
  category_id?: number
  comment_count?: number
  cover_image?: string
  currency_id?: number
  distance?: string
  flash_time?: string
  flash_time_type?: SerializerSellTimeType
  intro?: string
  is_elite?: boolean
  is_favorite?: boolean
  is_hot?: boolean
  is_limited?: boolean
  lat?: number
  lng?: number
  min_price?: number
  movie_groups?: BranchMovieGroup[]
  name?: string
  number_of_comments?: number
  on_board?: EdbProductOnBoard
  rating?: number
  sell_time?: string
  sell_time_type?: SerializerSellTimeType
  symbol?: string
  symbol_code?: string
  zip?: string
}

export interface BranchListBranchRes {
  code?: number
  data?: BranchListBranchData[]
  message?: string
  timezone?: string
  total_count?: number
}

export interface BranchListData {
  address?: string
  area?: string
  bid?: number
  category_id?: number
  comment_count?: number
  cover_image?: string
  currency_id?: number
  discount_info?: FproductListDataDiscountInfo
  distance?: string
  flash_time?: string
  flash_time_type?: SerializerSellTimeType
  intro?: string
  is_elite?: boolean
  is_favorite?: boolean
  is_hot?: boolean
  is_limited?: boolean
  is_now?: boolean
  lat?: number
  lng?: number
  min_price?: number
  movie_groups?: BranchMovieGroup[]
  name?: string
  number_of_comments?: number
  on_board?: EdbProductOnBoard
  rating?: number
  sell_time?: string
  sell_time_type?: SerializerSellTimeType
  symbol?: string
  symbol_code?: string
  zip?: string
}

export interface BranchListResponse {
  bid?: number
  brand_id?: number
  brand_name?: string
  cover_image?: string
  created_at?: string
  default_name?: string
  merchant_id?: number
  name?: string
  platform?: EdbBranchPlatform
  pms_type?: EdbBranchPMSType
  region_id?: number
  region_name?: string
  status?: EdbBranchStatus
  trans?: BranchListTransData[]
  type?: EdbBranchType
  unhide_bid?: number
  updated_at?: string
  vat_number?: string
}

export interface BranchListTransData {
  bid?: number
  branch_name?: string
  id?: number
  lang_code?: string
  lang_id?: number
  trans_status?: EdbTransStatus
}

export interface BranchLogResource {
  name?: string
  rid?: number
}

export interface BranchLogResourceGroup {
  name?: string
  resources?: BranchLogResource[]
  rg_id?: number
}

export interface BranchMenuItemsRes {
  code?: number
  data?: Record<string, UsecaseMenuItem[]>
  message?: string
}

export interface BranchMerchantBranch {
  bid?: number
  cover_image?: string
  name?: string
  type?: EdbBranchType
}

export interface BranchMerchantData {
  id?: number
  name?: string
}

export interface BranchMonthDayOffs {
  month?: string
  sets?: BranchDayOffSet[]
}

export interface BranchMovieGroup {
  chinese_name?: string
  duration?: number
  english_name?: string
  grade?: EdbMovieGrade
  group_id?: number
  poster?: string
  rel_date?: string
}

export interface BranchOccuResource {
  max?: number
  min?: number
  name?: string
  rid?: number
  slots?: BranchOccupationSlot[]
  status?: EdbResourceStatus
}

export interface BranchOccupationSlot {
  /** 0: None, 1: Assigned */
  assign_status?: EdbOccupationAssignStatus
  booking_end_time?: string
  /** @example "2025-01-28 00:00:00" */
  booking_start_time?: string
  /** @example "+886" */
  calling_code?: string
  cust_name?: string
  cust_num?: number
  cust_title?: string
  /** @example "2025-01-28 00:30:00" */
  end?: string
  id?: number
  /** @example "2025-01-21 20:45:55" */
  occu_created_at?: string
  /** 0: Unavailable, 1: Sold */
  occu_type?: EdbOccupationType
  /** @example "2025-01-21 20:45:55" */
  order_created_at?: string
  order_display_status?: number
  order_id?: number
  /** 0: FunNow, 1: FunBook, 2: FunBookV2 */
  order_type?: EmongoOrderType
  /** @example "912345678" */
  phone?: string
  /** @example "2025-01-28 00:00:00" */
  start?: string
}

export interface BranchOperationLog {
  action?: SoccupationAction
  auth_id?: string
  created_at?: string
  end?: string
  resource_groups?: BranchLogResourceGroup[]
  start?: string
  trans_id?: string
}

export interface BranchOriginData {
  reservations_mode?: EdbBranchEatigoReservationsMode
  type?: EdbBranchType
}

/** null means permanent time */
export interface BranchPeriod {
  /** @example "2023-12-25 23:59:59" */
  end_time: string
  /** @example "2023-12-25 00:00:00" */
  start_time: string
}

export interface BranchPeriodTime {
  end_time?: string
  start_time?: string
}

export interface BranchProductFlashSets {
  /** 0: none, 1: manager not allow */
  edit_mode?: EdbProductEditMode
  flash_sets?: BranchFlashSet[]
  product_id?: number
  product_name?: string
}

export interface BranchProductsData {
  products?: SproductOnBoard[]
}

export interface BranchProductsRes {
  code?: number
  data?: BranchProductsData
  message?: string
}

export interface BranchRankResourceGroupRes {
  code?: number
  data?: number[]
  message?: string
}

export interface BranchRankResourceMergeReq {
  /**
   * according to the order of rm_ids on ui
   * @minItems 2
   * @uniqueItems true
   * @example [32866,3028121252188,3028175918762]
   */
  rm_ids: number[]
}

export interface BranchRankTblResourceGroupsReq {
  /**
   * according to the order of rg_ids on ui
   * @minItems 2
   * @uniqueItems true
   * @example [32866,3028121252188,3028175918762]
   */
  rg_ids: number[]
}

export interface BranchRankTblResourcesReq {
  /**
   * according to the order of rids on ui
   * @minItems 2
   * @uniqueItems true
   * @example [9930054945325,9930081533290,9930068022057]
   */
  rids: number[]
}

export interface BranchRegion {
  /**
   * 1: FunNow, 2: Niceday, 3: Eatigo
   * @example 1
   */
  brand_id?: EdbBrandID
  languages?: BranchLanguage[]
  name?: string
}

export interface BranchRegionData {
  brand_id?: number
  brand_name?: string
  country_code?: string
  currency_code?: string
  currency_id?: EdbCurrencyID
  decimal_precision?: number
  id?: number
  name?: string
  status?: EdbRegionStatus
  stmt_decimal_precision?: number
  tax_adj_rate?: number
  tz_location?: string
}

export interface BranchRemarkData {
  id?: number
  title?: string
}

export interface BranchRenameResourcesReq {
  resources?: BranchResourceData[]
}

export interface BranchRenameResourcesRes {
  code?: number
  data?: BranchResourceData[]
  message?: string
}

export interface BranchResource {
  name?: string
  rid?: number
}

export interface BranchResourceData {
  name?: string
  rid?: number
}

export interface BranchResourceGroup {
  name?: string
  resources?: BranchResource[]
  rg_id?: number
}

export interface BranchResourceGroupTblMgmt {
  online_pax?: number
  online_resource_count?: number
  online_resource_merge_count?: number
  rg_id?: number
  rg_name?: string
  rg_rank?: number
  /** 0: online, 1: offline */
  rg_status?: EdbResourceGroupStatus
  total_pax?: number
  total_resource_count?: number
  total_resource_merge_count?: number
}

export interface BranchResourceMergeInfo {
  /** turn time */
  duration?: number
  id?: number
  max?: number
  min?: number
  name?: string
  /** 0: online, 1: offline */
  status?: EdbResourceMergeStatus
}

export interface BranchResourceMergeReq {
  /**
   * turn time (minute)
   * @min 0
   * @max 720
   * @example 120
   */
  duration: number
  /**
   * @min 1
   * @max 100
   * @example 4
   */
  max: number
  /**
   * @min 1
   * @max 100
   * @example 2
   */
  min: number
  /** @example "T1+T2" */
  name: string
  /**
   * @minItems 2
   * @uniqueItems true
   * @example [9930054945325,9930081533290,9930068022057]
   */
  rids: number[]
  /**
   * 0: online, 1: offline
   * @example 0
   */
  status: 0 | 1
}

export interface BranchResourceOperationLogsPageRes {
  code?: number
  data?: BranchOperationLog[]
  message?: string
  total?: number
}

export interface BranchResourceSlots {
  name?: string
  rid?: number
  slots?: ManagerBranchSlot[]
}

export interface BranchResponse {
  address?: string
  area?: string
  bid?: number
  bistrochat?: BbistrochatFields
  block?: BblockFields[]
  bossnow_user?: BranchBossNowUserData[]
  brand?: BranchBrandData[]
  channel?: BranchChannelData[]
  contact?: BranchContactData[]
  contact_email?: string
  contact_phone?: string
  contract?: BranchContractData[]
  cover_fee?: BranchCoverFeeData[]
  cover_image?: string
  created_at?: string
  crm_type?: EdbBranchCRMType
  currency_filter_amount_setting?: SsyscfgCurrencyIDtoFilterAmountSetting
  cust_keywords?: string
  delivery_area?: BranchDeliveryAreaData[]
  dunqian?: BdunqianFields
  eatigo?: BeatigoFields
  eatigo_tag?: BranchEatigoTagData[]
  ebisol?: BebisolFields
  ezding?: BezdingFields
  fb_intro?: string
  fb_need_to_know?: string
  free_cover?: BfreecoverFields[]
  funbook?: BfunbookFields
  google_place_id?: string
  ind_type?: BranchIndTypeData[]
  info?: string
  inline?: BinlineFields
  intro?: string
  lat?: number
  lng?: number
  location_detail?: string
  merchant?: BranchMerchantData[]
  name?: string
  nearby_spot?: string
  need_to_know?: string
  notice_note?: string
  notice_phone?: string
  ocard?: BocardFields
  opening_hours?: string
  origin?: BranchOriginData
  own_page?: BownpageFields
  partner?: BpartnerFields[]
  payment?: BranchpaymentFields
  platform?: EdbBranchPlatform
  pms_type?: EdbBranchPMSType
  prop?: BranchpropFields
  rating?: number
  region?: BranchRegionData[]
  remark?: BranchRemarkData[]
  rwg?: BrwgFields
  setting?: BsettingFields
  shalom?: BshalomFields
  status?: EdbBranchStatus
  tableapp?: BtableappFields
  trans?: BranchTransData[]
  transportation?: string
  type?: EdbBranchType
  unhide_bid?: number
  updated_at?: string
  wise?: BwiseFields
  zip?: string
}

export interface BranchSession {
  hall?: string
  hall_id?: number
  id?: number
  time?: string
}

export interface BranchSessionData {
  event?: BranchEvent
  offline_refund_limit?: number
  online_refund_limit?: number
  product_id?: number
  session_dates?: BranchSessionDate[]
}

export interface BranchSessionDate {
  date?: string
  sessions_of_version?: BranchSessionsOfVersion[]
}

export interface BranchSessionsOfVersion {
  sessions?: BranchSession[]
  version?: string
}

export interface BranchTblmgmtResource {
  duration?: number
  max?: number
  min?: number
  name?: string
  /** null means permanent time */
  period?: BranchPeriodTime
  rank?: number
  rid?: number
  status?: EdbResourceStatus
}

export interface BranchTitle {
  id?: number
  name?: string
}

export interface BranchToChannelReq {
  bid?: number
  channel_id?: number
}

export interface BranchToChannelRes {
  bid?: number
  channel_id?: number
  id?: number
}

export interface BranchTransData {
  address?: string
  address_status?: EdbTransFieldStatus
  area?: string
  area_status?: EdbTransFieldStatus
  cust_keywords?: string
  cust_keywords_status?: EdbTransFieldStatus
  fb_intro?: string
  fb_intro_status?: EdbTransFieldStatus
  fb_need_to_know?: string
  fb_need_to_know_status?: EdbTransFieldStatus
  id?: number
  intro?: string
  intro_status?: EdbTransFieldStatus
  is_for_delete?: boolean
  lang_id?: number
  location_detail?: string
  location_detail_status?: EdbTransFieldStatus
  name?: string
  name_status?: EdbTransFieldStatus
  nearby_spot?: string
  nearby_spot_status?: EdbTransFieldStatus
  need_to_know?: string
  need_to_know_status?: EdbTransFieldStatus
  opening_hours?: string
  opening_hours_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  transportation?: string
  transportation_status?: EdbTransFieldStatus
}

export interface BranchTransListRes {
  bid?: number
  code?: string
  id?: number
  name?: string
  status?: EdbTransStatus
  trans_id?: number
}

export interface BranchTransReq {
  address?: string
  address_status?: EdbTransFieldStatus
  area?: string
  area_status?: EdbTransFieldStatus
  bid?: number
  cust_keywords?: string
  cust_keywords_status?: EdbTransFieldStatus
  fb_intro?: string
  fb_intro_status?: EdbTransFieldStatus
  fb_need_to_know?: string
  fb_need_to_know_status?: EdbTransFieldStatus
  intro?: string
  intro_status?: EdbTransFieldStatus
  lang_id?: number
  location_detail?: string
  location_detail_status?: EdbTransFieldStatus
  name?: string
  name_status?: EdbTransFieldStatus
  nearby_spot?: string
  nearby_spot_status?: EdbTransFieldStatus
  need_to_know?: string
  need_to_know_status?: EdbTransFieldStatus
  opening_hours?: string
  opening_hours_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  transportation?: string
  transportation_status?: EdbTransFieldStatus
}

export interface BranchTransRes {
  address?: string
  address_status?: EdbTransFieldStatus
  area?: string
  area_status?: EdbTransFieldStatus
  bid?: number
  created_at?: string
  cust_keywords?: string
  cust_keywords_status?: EdbTransFieldStatus
  fb_intro?: string
  fb_intro_status?: EdbTransFieldStatus
  fb_need_to_know?: string
  fb_need_to_know_status?: EdbTransFieldStatus
  id?: number
  intro?: string
  intro_status?: EdbTransFieldStatus
  lang_id?: number
  location_detail?: string
  location_detail_status?: EdbTransFieldStatus
  name?: string
  name_status?: EdbTransFieldStatus
  nearby_spot?: string
  nearby_spot_status?: EdbTransFieldStatus
  need_to_know?: string
  need_to_know_status?: EdbTransFieldStatus
  opening_hours?: string
  opening_hours_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  transportation?: string
  transportation_status?: EdbTransFieldStatus
  updated_at?: string
}

export interface BranchUpdStatusReq {
  status?: EdbBranchStatus
}

export interface BranchUpdStatusRes {
  bid?: number
  status?: EdbBranchStatus
  updated_at?: string
}

export interface BranchUpdateBranchRequest {
  bid?: number
  buffer_time?: number
  duration?: number
  fc_restore_id?: string
  prepare_time?: number
  sms_enabled?: boolean
}

export interface BranchUpdateBranchResourceRequest {
  resource_groups?: ResourceResourceGroup[]
}

export interface BranchUpdateBranchResourceResponse {
  code?: number
  data?: BranchUpdateBranchResourceResponseData
  message?: string
}

export interface BranchUpdateBranchResourceResponseData {
  resource_groups?: ResourceResourceGroup[]
}

export interface BranchUpdateBranchResponse {
  code?: number
  data?: BranchUpdateBranchResponseData
  message?: string
}

export interface BranchUpdateBranchResponseData {
  bid?: number
  buffer_time?: number
  duration?: number
  fc_restore_id?: string
  prepare_time?: number
  sms_enabled?: boolean
}

export interface BranchUpdateResourceCountRequest {
  count?: number
}

export interface BranchUpdateUnavailableRequest {
  action?: SoccupationAction
  end_time?: string
  remark?: string
  rids?: number[]
  start_time?: string
}

export interface BranchUpdateUnavailableSlotData {
  end?: string
  id?: number
  remark?: string
  start?: string
}

export interface BranchUpdateUnavailableSlotReq {
  end: string
  remark?: string
  start: string
}

export interface BranchUpdateUnavailableSlotRes {
  code?: number
  data?: BranchUpdateUnavailableSlotData
  message?: string
}

export interface BranchWeeklySet {
  end?: string
  id?: number
  start?: string
}

export interface BranchProductListRes {
  code?: number
  data?: any
  message?: string
  timezone?: string
  total_count?: number
}

export interface BranchpaymentFields {
  advise_email?: string
  bank_account_name?: string
  bank_account_number?: string
  bank_branch?: string
  bank_code?: string
  bank_name?: string
  bank_vat_number?: string
  bill_to_address?: string
  bill_to_contact_name?: string
  bill_to_contact_phone?: string
  bill_to_zip?: string
  contract_id?: string
  contract_name?: string
  cooling_off_day?: number
  cover_contract_id?: string
  cover_contract_name?: string
  def_pax_fee?: number
  fb_fee?: number
  fee?: number
  fee_be_borne_by?: EdbBranchFeeBeBorneBy
  has_bill_to?: EdbBranchHasBillTo
  has_commission?: EdbBranchHasCommission
  has_cover_fee?: EdbBranchHasCoverFee
  has_ship_to?: EdbBranchHasShipTo
  invoice_type?: EdbBranchInvoiceType
  legal_entity_name?: string
  net_day?: number
  oa?: EdbBranchOA
  rwg_fee?: number
  rwg_fee_type?: EdbBranchRwGFeeType
  ship_to_address?: string
  ship_to_contact_name?: string
  ship_to_contact_phone?: string
  ship_to_zip?: string
  statement_email?: string
  tier?: EdbBranchTier
  vat_number?: string
  vat_type?: EdbBranchVATType
}

export interface BranchpropFields {
  am_admin_email?: string
  am_admin_name?: string
  am_admin_uid?: number
  bd_admin_email?: string
  bd_admin_name?: string
  bd_admin_uid?: number
  event_date?: string
  grading?: string
}

export interface BrandCurrencyData {
  code?: string
  id?: number
}

export interface BrandDocListResponse {
  code?: number
  data?: BrandListResponse[]
  message?: string
  total_count?: number
}

export interface BrandDocResponse {
  code?: number
  data?: BrandResponse
  message?: string
}

export interface BrandListResponse {
  created_at?: string
  id?: number
  name?: string
  updated_at?: string
}

export interface BrandRequest {
  currency?: BrandCurrencyData[]
  name?: string
}

export interface BrandResponse {
  created_at?: string
  currency?: BrandCurrencyData[]
  id?: number
  name?: string
  updated_at?: string
}

export interface BrandfieldDocListResponse {
  code?: number
  data?: BrandfieldListResponse[]
  message?: string
  total_count?: number
}

export interface BrandfieldDocResponse {
  code?: number
  data?: BrandfieldResponse
  message?: string
}

export interface BrandfieldListResponse {
  brand_id?: number
  brand_name?: string
  created_at?: string
  field_name?: string
  field_value?: number
  id?: number
  map_content?: string
  updated_at?: string
}

export interface BrandfieldRequest {
  brand_id?: number
  field_name?: string
  field_value?: number
  map_content?: string
}

export interface BrandfieldResponse {
  brand_id?: number
  brand_name?: string
  created_at?: string
  field_name?: string
  field_value?: number
  id?: number
  map_content?: string
  updated_at?: string
}

export interface BrwgFields {
  brand_id?: EdbBrandID
  type?: EdbRwGType
}

export interface BsettingFields {
  assignment_mode?: EdbBranchAssignmentMode
  capacity?: number
  delivery_fee_type?: EdbBranchDeliveryFeeType
  delivery_fee_x?: number
  delivery_fee_y?: number
  fb_issue_invoice?: EdbBranchIssueInvoice
  flash_sale?: EdbBranchFlashSale
  issue_invoice?: EdbBranchIssueInvoice
  print_status?: EdbBranchPrintStatus
  rating?: number
  reserve_mode?: EdbBranchReserveMode
  resource_mode?: EdbBranchResourceMode
  rwg?: EdbRwG
  send_sms?: EdbSendSMS
  set_mode?: EdbBranchSetMode
}

export interface BshalomFields {
  hotel_code?: string
}

export interface BtableappFields {
  business_id?: number
  redirect_status?: EdbBranchTableappRedirectStatus
}

export interface BwiseFields {
  hotel_code?: string
}

export interface CampaignListResponse {
  created_at?: string
  end_at?: string
  id?: number
  name?: string
  product_cover_image?: string
  start_at?: string
  updated_at?: string
}

export interface CampaignRequest {
  end_at?: string
  name?: string
  product_album_image?: string
  product_cover_image?: string
  start_at?: string
  tag?: CampaignTagData[]
}

export interface CampaignResponse {
  created_at?: string
  end_at?: string
  id?: number
  name?: string
  product_album_image?: string
  product_cover_image?: string
  start_at?: string
  tag?: CampaignTagData[]
  updated_at?: string
}

export interface CampaignTagData {
  id?: number
  name?: string
}

export interface CampaignUpdateProductAlbumImgResponse {
  id?: number
  product_album_image?: string
  updated_at?: string
}

export interface CampaignUpdateProductCoverImgResponse {
  id?: number
  product_cover_image?: string
  updated_at?: string
}

export interface CasbinResReload {
  code?: number
  err_res?: string[]
  message?: string
}

export interface CasbinResRoutes {
  code?: number
  data?: ScasbinRoutePath[]
  message?: string
  total_count?: number
}

export interface CategoryBanner {
  cover_image?: string
  description?: string
  id?: number
  large_cover_image?: string
  link_target?: string
  link_type?: EdbBannerLinkType
  title?: string
}

export interface CategoryBannerData {
  end_at?: string
  id?: number
  start_at?: string
  status?: EdbBannerStatus
  title?: string
}

export interface CategoryBannerImgRes {
  banner_image?: string
  id?: number
  updated_at?: string
}

export interface CategoryCategoryBanner {
  cover_image?: string
  description?: string
  id?: number
  large_cover_image?: string
  link_target?: string
  link_type?: EdbBannerLinkType
  title?: string
}

export interface CategoryCategoryInfo {
  banner?: CategoryCategoryBanner[]
  banner_image?: string
  blog_articles?: string
  category_tags?: ScategoryTag[]
  day_mode?: number
  has_cus_num?: number
  has_time_filter?: number
  icon_image?: string
  id?: number
  image?: string
  intro?: string
  intro_app?: string
  list_mode?: number
  meta_desc?: string
  meta_keywords?: string
  name?: string
  onboard?: number
  other_category_tags?: ScategoryTag[]
  page_title?: string
  pin?: CategoryPin
  short_name?: string
  sub_categories?: ScategorySubCategory[]
  tags?: ScategoryTag[]
  type?: EdbCategoryType
  web_onboard?: number
}

export interface CategoryCategoryInfoResponse {
  code?: number
  data?: CategoryCategoryInfo
  message?: string
}

export interface CategoryCoverImgRes {
  cover_image?: string
  id?: number
  updated_at?: string
}

export interface CategoryDetailData {
  faq_list?: ScategorySERP[]
  id?: number
  intro?: string
  meta_desc?: string
  meta_keywords?: string
  name?: string
  page_title?: string
  short_name?: string
  sub_categories?: ScategorySubCategory[]
}

export interface CategoryFilterData {
  id?: number
  name?: string
  title?: string
}

export interface CategoryGroupListResponse {
  category_type?: number
  cover_image?: string
  group_region_name?: string
  id?: number
  name?: string
  onboard?: EdbCategoryOnBoard
  rank?: number
  region_id?: number
  region_name?: string
  status?: EdbCategoryStatus
}

export interface CategoryHotAreaData {
  product_tag_name?: string
  tag_id?: number
  type?: number
}

export interface CategoryHotSearchData {
  id?: number
  keyword?: string
}

export interface CategoryIconImgRes {
  icon_image?: string
  id?: number
  updated_at?: string
}

export interface CategoryListRegionData {
  brand_id?: number
  brand_name?: string
  category_id?: number
  region_id?: number
  region_name?: string
  status?: EdbRegionStatus
}

export interface CategoryListResponse {
  category_type?: number
  cover_image?: string
  id?: number
  name?: string
  onboard?: EdbCategoryOnBoard
  rank?: number
  region?: CategoryListRegionData[]
  status?: EdbCategoryStatus
  trans?: CategoryListTransData[]
}

export interface CategoryListTransData {
  category_id?: number
  category_name?: string
  id?: number
  lang_code?: string
  lang_id?: number
  trans_status?: EdbTransStatus
}

export interface CategoryMapIconImgRes {
  id?: number
  map_icon_image?: string
  updated_at?: string
}

export interface CategoryMapIconRes {
  code?: number
  data?: ScategoryCategoryMapIcon[]
  message?: string
}

export interface CategoryPin {
  /** Include Indicates whether to include the "pin" param in the product list API. */
  include?: boolean
  /** Name only used for restaurant category. Indicates whether the wording name appears in the pinned section. */
  name?: string
}

export interface CategoryRequest {
  average_price?: number
  banner?: CategoryBannerData[]
  banner_image?: string
  blog_articles?: string
  category_type?: EdbCategoryType
  cover_image?: string
  ctag?: CategoryTagData[]
  currency_id?: number
  day_mode?: EdbCategoryDayMode
  filter?: CategoryFilterData[]
  has_cross_day?: EdbCategoryHasCrossDay
  has_cust_num?: EdbCategoryHasCustNum
  has_time_filter?: EdbCategoryHasTimeFilter
  hot_area?: CategoryHotAreaData[]
  hot_search?: CategoryHotSearchData[]
  icon_image?: string
  intro?: string
  intro_app?: string
  map_icon_color?: string
  map_icon_image?: string
  meta_description?: string
  meta_keywords?: string
  name?: string
  onboard?: EdbCategoryOnBoard
  page_title?: string
  rank?: number
  region?: EschemaRegionData[]
  short_name?: string
  showall?: EdbCategoryShowAll
  status?: EdbCategoryStatus
  sub_category?: CategorySubCategoryData[]
  trans?: CategoryTransData[]
}

export interface CategoryResponse {
  average_price?: number
  banner?: CategoryBannerData[]
  banner_image?: string
  blog_articles?: string
  category_type?: EdbCategoryType
  cover_image?: string
  created_at?: string
  ctag?: CategoryTagData[]
  currency_id?: number
  day_mode?: EdbCategoryDayMode
  filter?: CategoryFilterData[]
  has_cross_day?: EdbCategoryHasCrossDay
  has_cust_num?: EdbCategoryHasCustNum
  has_time_filter?: EdbCategoryHasTimeFilter
  hot_area?: CategoryHotAreaData[]
  hot_search?: CategoryHotSearchData[]
  icon_image?: string
  id?: number
  intro?: string
  intro_app?: string
  map_icon_color?: string
  map_icon_image?: string
  meta_description?: string
  meta_keywords?: string
  name?: string
  onboard?: EdbCategoryOnBoard
  page_title?: string
  rank?: number
  region?: EschemaRegionData[]
  short_name?: string
  showall?: EdbCategoryShowAll
  status?: EdbCategoryStatus
  sub_category?: CategorySubCategoryData[]
  trans?: CategoryTransData[]
  updated_at?: string
}

export interface CategoryResponseInfo {
  banners?: CategoryBanner[]
  cuisine_tags?: CategoryTag[]
  icon_image?: string
  id?: EdbCategoryID
  intro?: string
  intro_app?: string
  meta_desc?: string
  meta_keywords?: string
  name?: string
  page_title?: string
  short_name?: string
  sub_categories?: ScategorySubCategory[]
  tags?: CategoryTag[]
  /** 0: Industry, 1: Theme, 2: Filter, 3: Cuisine, 4: Brand, 5: Location */
  type?: EdbCategoryType
}

export interface CategorySubCategoryData {
  group_region_name?: string
  id?: number
  name?: string
  region_name?: string
}

export interface CategoryTag {
  id?: number
  name?: string
}

export interface CategoryTagData {
  group_id?: number
  group_name?: string
  id?: number
  name?: string
  type?: number
}

export interface CategoryTransData {
  blog_articles?: string
  blog_articles_status?: EdbTransFieldStatus
  id?: number
  intro?: string
  intro_app?: string
  intro_app_status?: EdbTransFieldStatus
  intro_status?: EdbTransFieldStatus
  is_for_delete?: boolean
  lang_id?: number
  meta_description?: string
  meta_description_status?: EdbTransFieldStatus
  meta_keywords?: string
  meta_keywords_status?: EdbTransFieldStatus
  name?: string
  name_status?: EdbTransFieldStatus
  page_title?: string
  page_title_status?: EdbTransFieldStatus
  short_name?: string
  short_name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
}

export interface CategoryTransListRes {
  category_id?: number
  code?: string
  id?: number
  name?: string
  status?: EdbTransStatus
  trans_id?: number
}

export interface CategoryTransReq {
  blog_articles?: string
  blog_articles_status?: EdbTransFieldStatus
  category_id?: number
  intro?: string
  intro_app?: string
  intro_app_status?: EdbTransFieldStatus
  intro_status?: EdbTransFieldStatus
  lang_id?: number
  meta_description?: string
  meta_description_status?: EdbTransFieldStatus
  meta_keywords?: string
  meta_keywords_status?: EdbTransFieldStatus
  name?: string
  name_status?: EdbTransFieldStatus
  page_title?: string
  page_title_status?: EdbTransFieldStatus
  short_name?: string
  short_name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
}

export interface CategoryTransRes {
  blog_articles?: string
  blog_articles_status?: EdbTransFieldStatus
  category_id?: number
  created_at?: string
  id?: number
  intro?: string
  intro_app?: string
  intro_app_status?: EdbTransFieldStatus
  intro_status?: EdbTransFieldStatus
  lang_id?: number
  meta_description?: string
  meta_description_status?: EdbTransFieldStatus
  meta_keywords?: string
  meta_keywords_status?: EdbTransFieldStatus
  name?: string
  name_status?: EdbTransFieldStatus
  page_title?: string
  page_title_status?: EdbTransFieldStatus
  short_name?: string
  short_name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  updated_at?: string
}

export interface CategoryUpdStatusReq {
  status?: EdbCategoryStatus
}

export interface CategoryUpdStatusRes {
  id?: number
  status?: EdbCategoryStatus
  updated_at?: string
}

export interface ChannelListBranchData {
  bid?: number
  branch_name?: string
  channel_id?: number
  id?: number
}

export interface ChannelListProductData {
  channel_id?: number
  id?: number
  product_id?: number
  product_name?: string
}

export interface ChannelListResponse {
  branch?: ChannelListBranchData[]
  created_at?: string
  id?: number
  name?: string
  orders_referer_name?: string
  pax_fee?: number
  product?: ChannelListProductData[]
  updated_at?: string
}

export interface ChannelRequest {
  branch?: EschemaBranchData[]
  name?: string
  orders_referer_name?: string
  pax_fee?: number
  product?: EschemaProductData[]
}

export interface ChannelResponse {
  branch?: EschemaBranchData[]
  created_at?: string
  id?: number
  name?: string
  orders_referer_name?: string
  pax_fee?: number
  product?: EschemaProductData[]
  updated_at?: string
}

export interface ContactBranchData {
  bid?: number
  name?: string
}

export interface ContactDocListResponse {
  code?: number
  data?: ContactListResponse[]
  message?: string
  total_count?: number
}

export interface ContactDocResponse {
  code?: number
  data?: ContactResponse
  message?: string
}

export interface ContactListResponse {
  email?: string
  id?: number
  mobile?: string
  name?: string
  phone?: string
}

export interface ContactMerchantData {
  id?: number
  name?: string
}

export interface ContactRequest {
  address?: string
  branch?: ContactBranchData[]
  email?: string
  merchant?: ContactMerchantData[]
  mobile?: string
  name?: string
  note?: string
  phone?: string
  zip?: string
}

export interface ContactResponse {
  address?: string
  branch?: ContactBranchData[]
  created_at?: string
  email?: string
  id?: number
  merchant?: ContactMerchantData[]
  mobile?: string
  name?: string
  note?: string
  phone?: string
  updated_at?: string
  zip?: string
}

export interface ContractListBranchData {
  bid?: number
  branch_name?: string
  brand_id?: number
  brand_name?: string
  contract_id?: number
  id?: number
  region_id?: number
  region_name?: string
  unhide_bid?: number
}

export interface ContractListResponse {
  act_month?: SchemaMonth
  branch?: ContractListBranchData[]
  cancel_month?: SchemaMonth
  contract_num?: string
  created_at?: string
  exp_month?: SchemaMonth
  id?: number
  send_sms?: EdbSendSMS
  signing_date?: string
  updated_at?: string
}

export interface ContractRequest {
  act_month?: SchemaMonth
  branch?: EschemaBranchData[]
  cancel_month?: SchemaMonth
  contract_num?: string
  contract_remarks?: string
  exp_month?: SchemaMonth
  gifted_months?: number
  monthly_fee?: number
  monthly_rent_amount?: number
  prepaid_months?: number
  prepaid_sms_amount?: number
  prepaid_sms_quantity?: number
  prepaid_statement_id?: number
  refund_amount?: number
  saas_plan?: string
  send_sms?: EdbSendSMS
  setup_fee?: number
  signing_date?: string
  sms_fee?: number
}

export interface ContractResponse {
  act_month?: SchemaMonth
  branch?: EschemaBranchData[]
  cancel_month?: SchemaMonth
  contract_num?: string
  contract_remarks?: string
  created_at?: string
  exp_month?: SchemaMonth
  gifted_months?: number
  id?: number
  monthly_fee?: number
  monthly_rent_amount?: number
  prepaid_months?: number
  prepaid_sms_amount?: number
  prepaid_sms_quantity?: number
  prepaid_statement_id?: number
  refund_amount?: number
  saas_plan?: string
  send_sms?: EdbSendSMS
  setup_fee?: number
  signing_date?: string
  sms_fee?: number
  updated_at?: string
}

export interface ContractUpdExpRequest {
  cancel_month?: SchemaMonth
  exp_month?: SchemaMonth
  monthly_fee?: number
  refund_amount?: number
  send_sms?: EdbSendSMS
  sms_fee?: number
}

export interface ContractUpdExpResponse {
  act_month?: SchemaMonth
  branch?: EschemaBranchData[]
  cancel_month?: SchemaMonth
  contract_num?: string
  contract_remarks?: string
  created_at?: string
  exp_month?: SchemaMonth
  gifted_months?: number
  id?: number
  monthly_fee?: number
  monthly_rent_amount?: number
  prepaid_months?: number
  prepaid_sms_amount?: number
  prepaid_sms_quantity?: number
  prepaid_statement_id?: number
  refund_amount?: number
  saas_plan?: string
  send_sms?: EdbSendSMS
  setup_fee?: number
  signing_date?: string
  sms_fee?: number
  updated_at?: string
}

export interface CoverfeeBranchData {
  bid?: number
}

export interface CoverfeeOriginData {
  effective_date?: string
}

export interface CoverfeeRequest {
  branch?: CoverfeeBranchData[]
  def_fee?: number
  effective_date?: string
  fee_setting?: string
  type?: EdbCoverFeeType
}

export interface CoverfeeResponse {
  branch?: CoverfeeBranchData[]
  created_at?: string
  def_fee?: number
  effective_date?: string
  fee_setting?: string
  id?: number
  origin?: CoverfeeOriginData
  type?: EdbCoverFeeType
  updated_at?: string
}

export interface CreateAdditionRequest {
  count?: number
  pid?: string
}

export interface CreateMainProduct {
  count?: number
  pid: string
  sub_items?: CreateSubItemReqData[][]
}

export enum CreateNotifyType {
  NotifyTypeDefault = 0,
  NotifyTypeExtraEmailSMS = 1,
}

export interface CreateRemark {
  answers?: CreateRemarkAnswer[]
  content?: string
  remark_id?: number
  sub_id?: number
  type?: EdbProductRemarkType
}

export interface CreateRemarkAnswer {
  content?: string
  sub_id?: number
}

export interface CreateRequest {
  addition?: CreateAdditionRequest[]
  bookingtime: string
  branch_mkt?: EdbBranchMKTEnabled
  check_duplicate?: boolean
  checkoutday?: string
  delivery_address_id?: number
  email?: string
  funnow_mkt?: EdbFunNowMKTEnabled
  group_code?: string
  has_delivery_fee?: boolean
  memberNote?: string
  notify_type?: CreateNotifyType
  product?: CreateMainProduct
  referer?: string
  remark?: CreateRemark[]
  resource_count?: number
  /**
   * RwGToken is a random token generated by Google and used for RwG redirect.
   * If RwGToken is not empty, it means this order is from [edb.RwGTypeRedirect],
   * and should be also inserted into [edb.OrdersRwG].
   */
  rwg_token?: string
  source?: EdbOrdersSource
  tax_info?: ForderCompanyTax
  /** Deprecated */
  upgradeID?: number
  upgrades?: CreateUpgrade[]
}

export interface CreateSubItemReqData {
  description?: string
  freeQty?: number
  id?: number
  limitMax?: number
  limitMin?: number
  name?: string
  price?: number
  qty?: number
  showMode?: number
}

export interface CreateUpgrade {
  quantity?: number
  upgrade_id?: number
}

export interface CreditBinCodeRespData {
  code?: string
  id?: number
  name?: string
}

export interface CrudDocLogListResponse {
  code?: number
  data?: CrudLogListResponse[]
  message?: string
  total_count?: number
}

export interface CrudDocLogResponse {
  code?: number
  data?: CrudLogResponse
  message?: string
}

export interface CrudFieldsRes {
  code?: number
  data?: CrudQueryField[]
  message?: string
}

export interface CrudLogListRes {
  action?: EdbActionType
  content?: string
  created_at?: string
  display_name?: string
  email?: string
  id?: number
  platform?: EdbPlatformType
  uid?: number
}

export interface CrudLogListResponse {
  action?: EdbActionType
  created_at?: string
  display_name?: string
  email?: string
  id?: number
  platform?: EdbPlatformType
  uid?: number
}

export interface CrudLogResponse {
  action?: EdbActionType
  content?: string
  created_at?: string
  data_name?: string
  id?: number
  platform?: EdbPlatformType
  uid?: number
}

export interface CrudQueryField {
  name?: string
  type?: string
}

export interface CurrencyListResponse {
  code?: string
  created_at?: string
  decimal_precision?: number
  default_name?: string
  ex_rate_buy?: number
  ex_rate_sell?: number
  fnc_rate?: number
  id?: number
  name?: string
  stmt_decimal_precision?: number
  symbol?: string
  trans?: CurrencyListTransData[]
  updated_at?: string
}

export interface CurrencyListTransData {
  currency_id?: number
  currency_name?: string
  id?: number
  lang_code?: string
  lang_id?: number
  trans_status?: EdbTransStatus
}

export interface CurrencyRequest {
  code?: string
  decimal_precision?: number
  ex_rate_buy?: number
  ex_rate_sell?: number
  filter_max_amount?: number
  filter_min_amount?: number
  fnc_rate?: number
  min_amount?: number
  name?: string
  stmt_decimal_precision?: number
  symbol?: string
  trans?: CurrencyTransData[]
}

export interface CurrencyResponse {
  code?: string
  created_at?: string
  decimal_precision?: number
  ex_rate_buy?: number
  ex_rate_sell?: number
  filter_max_amount?: number
  filter_min_amount?: number
  fnc_rate?: number
  id?: number
  min_amount?: number
  name?: string
  stmt_decimal_precision?: number
  symbol?: string
  trans?: CurrencyTransData[]
  updated_at?: string
}

export interface CurrencyTransData {
  id?: number
  lang_id?: number
  name?: string
  name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
}

export interface CurrencyTransListRes {
  code?: string
  currency_id?: number
  id?: number
  name?: string
  status?: EdbTransStatus
  trans_id?: number
}

export interface CurrencyTransRequest {
  currency_id?: number
  lang_id?: number
  name?: string
  name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
}

export interface CurrencyTransResponse {
  created_at?: string
  currency_id?: number
  id?: number
  lang_id?: number
  name?: string
  name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  updated_at?: string
}

export interface DataconvPriceAmount {
  amount?: string
  symbol_code?: string
}

export interface DeliveryAddrAvailableData {
  available?: boolean
  failed_reason?: string
}

export interface DeliveryAddrAvailableResp {
  code?: number
  data?: DeliveryAddrAvailableData
  message?: string
}

export interface DeliveryAddrData {
  address?: string
  area?: string
  establishment?: string
  floor_building_number?: string
  google_place_id?: string
  id?: number
  lat?: number
  lng?: number
  note?: string
  pickup_type?: EdbPickupType
}

export interface DeliveryAddrListResp {
  code?: number
  data?: DeliveryAddrData[]
  message?: string
}

export interface DeliveryCreateAddrResp {
  code?: number
  data?: DeliveryAddrData
  message?: string
}

export interface DeliveryUpdateAddrResp {
  code?: number
  data?: DeliveryAddrData
  message?: string
}

export interface DeviceCreatePushTokenReq {
  enable_sound?: boolean
  push_token: string
}

export interface DeviceGetPushTokenResp {
  code?: number
  data?: DevicePushTokenData
  message?: string
}

export interface DevicePushTokenData {
  enable_sound?: boolean
  push_status?: EdbPushStatus
}

export interface DeviceUpdDeviceReq {
  enable_sound?: boolean
  push_status?: EdbPushStatus
  push_token: string
}

export interface DlvaddressDocListResponse {
  code?: number
  data?: DlvaddressListResponse[]
  message?: string
  total_count?: number
}

export interface DlvaddressDocResponse {
  code?: number
  data?: DlvaddressResponse
  message?: string
}

export interface DlvaddressListResponse {
  address?: string
  area?: string
  created_at?: string
  email?: string
  establishment?: string
  floor_building_number?: string
  id?: number
  member_status?: EdbMemberStatus
  nickname?: string
  phone?: string
  pickup_type?: EdbPickupType
  uid?: number
  unhide_uid?: number
  updated_at?: string
}

export interface DlvaddressRequest {
  address?: string
  area?: string
  establishment?: string
  floor_building_number?: string
  google_place_id?: string
  lat?: number
  lng?: number
  note?: string
  pickup_type?: EdbPickupType
  uid?: number
}

export interface DlvaddressResponse {
  address?: string
  area?: string
  created_at?: string
  email?: string
  establishment?: string
  floor_building_number?: string
  google_place_id?: string
  id?: number
  lat?: number
  lng?: number
  member_status?: EdbMemberStatus
  nickname?: string
  note?: string
  phone?: string
  pickup_type?: EdbPickupType
  uid?: number
  unhide_uid?: number
  updated_at?: string
}

export interface DlvareaBranch {
  bid?: number
  name?: string
}

export interface DlvareaDocListResponse {
  code?: number
  data?: DlvareaListResponse[]
  message?: string
  total_count?: number
}

export interface DlvareaDocResponse {
  code?: number
  data?: DlvareaResponse
  message?: string
}

export interface DlvareaKeyword {
  id?: number
  is_for_delete?: boolean
  keyword?: string
}

export interface DlvareaListKeyword {
  area_id?: number
  id?: number
  keyword?: string
}

export interface DlvareaListResponse {
  area?: string
  id?: number
  keyword?: DlvareaListKeyword[]
  status?: EdbDeliveryAreaStatus
}

export interface DlvareaRequest {
  area?: string
  branch?: DlvareaBranch[]
  keyword?: DlvareaKeyword[]
  status?: EdbDeliveryAreaStatus
  trans?: DlvareaTransData[]
}

export interface DlvareaResponse {
  area?: string
  branch?: DlvareaBranch[]
  created_at?: string
  id?: number
  keyword?: DlvareaKeyword[]
  status?: EdbDeliveryAreaStatus
  trans?: DlvareaTransData[]
  updated_at?: string
}

export interface DlvareaTransData {
  area?: string
  area_status?: EdbTransFieldStatus
  id?: number
  is_for_delete?: boolean
  lang_id?: number
  status?: EdbTransStatus
}

export interface DocattachFields {
  attach_type?: EdbAttachType
  created_at?: string
  doc_id?: number
  file_name?: string
  file_path?: string
  id?: number
  updated_at?: string
}

export interface DocitemFields {
  amount?: number
  created_at?: string
  description?: string
  doc_id?: number
  id?: number
  product_type?: EdbItemProductType
  quantity?: number
  service_type?: EdbItemServiceType
  unit_price?: number
  updated_at?: string
}

export interface DomainDailyTierDiscount {
  discount_percent?: number
  min?: number
}

export interface DomainDocVoidRelatedData {
  document_id?: number
  document_number?: string
  statement_id?: number
}

export enum DomainManagerOrderChannel {
  ManagerOrderChannelFunNow = 0,
  ManagerOrderChannelFunBook = 1,
  ManagerOrderChannelEatigo = 2,
  ManagerOrderChannelNiceday = 3,
  ManagerOrderChannelRwG = 4,
  ManagerOrderChannelPhone = 5,
  ManagerOrderChannelWalkIn = 6,
  ManagerOrderChannelThirdParty = 7,
}

export enum DomainMemberLevelDisplay {
  Level1 = 0,
  Level2 = 1,
  Level3 = 2,
}

export enum DomainMessageActionType {
  MessageActionTypeSearchAgain = 0,
  MessageActionTypeHintLocation = 1,
  MessageActionTypeHintTag = 2,
}

export enum DomainMessageContentType {
  MessageContentTypeText = 0,
}

export enum DomainMessageRatingType {
  MessageRatingTypeDown = 0,
  MessageRatingTypeUp = 1,
}

export enum DomainMessageRole {
  MessageRoleAssistant = 0,
  MessageRoleUser = 1,
}

export interface DomainSettingRes {
  code?: number
  data?: DomainSettingResData
  message?: string
}

export interface DomainSettingResData {
  branch_cover_image?: string
  branch_id?: number
  branch_logo?: string
  branch_name?: string
  branch_status?: EdbBranchStatus
  branch_support_types?: EdbBranchType[]
  domain?: string
  funbook_ver?: EdbFunBookVer
  merchant_name?: string
}

export interface DomainTimeSlotPax {
  min?: number
}

export interface DomainYCRule {
  daily_tier_discount?: DomainDailyTierDiscount
  time_slot_pax?: DomainTimeSlotPax
}

export interface EatigomemberAttendanceHistoryScoreRes {
  score?: number
}

export enum EdbActType {
  ActNull = 0,
  ActURL = 1,
  ActApp = 2,
  ActReward = 3,
}

export enum EdbActionType {
  ActionTypeAdd = 0,
  ActionTypeUpdate = 1,
  ActionTypeDelete = 2,
}

export enum EdbAdminRoleStatus {
  AdminRoleStatusOK = 0,
  AdminRoleStatusDisabled = 1,
}

export enum EdbAdminUserMFAEnabled {
  AdminUserMFAEnabledFalse = 0,
  AdminUserMFAEnabledTrue = 1,
}

export enum EdbAdminUserPermPolicyType {
  AdminUserPermPolicyTypeAllow = 0,
  AdminUserPermPolicyTypeDeny = 1,
}

export enum EdbAdminUserPermType {
  AdminUserPermTypeNone = 0,
  AdminUserPermTypeRole = 1,
  AdminUserPermTypeAll = 2,
}

export enum EdbAdminUserStatus {
  AdminUserStatusOK = 0,
  AdminUserStatusDisabled = 1,
}

export enum EdbAllowanceNotify {
  AllowanceNotifySMS = 'S',
  AllowanceNotifyEmail = 'E',
  AllowanceNotifyAll = 'A',
  AllowanceNotifyNone = 'N',
}

export enum EdbAnnManagerType {
  AnnManagerTypeNew = 0,
  AnnManagerTypeReschedule = 1,
  AnnManagerTypeGroupChange = 2,
  AnnManagerTypeCustomerCancel = 3,
  AnnManagerTypeManagerCancel = 4,
  AnnManagerTypeFullRefund = 5,
  AnnManagerTypeSeventyPercentRefund = 6,
  AnnManagerTypeOrderChange = 7,
  AnnManagerTypeDepositPayment = 8,
}

export enum EdbAttachType {
  AttachTypePDF = 0,
  AttachTypeXLSX = 1,
  AttachTypeZIP = 2,
}

export enum EdbAttendanceHistoryActionFromType {
  ActionFromTypeSystem = 0,
  ActionFromTypeAdmin = 1,
  ActionFromTypeManager = 2,
  ActionFromTypePartner = 3,
}

export enum EdbAttendanceHistoryIsWrittenOff {
  AttendanceHistoryIsWrittenOffFalse = 0,
  AttendanceHistoryIsWrittenOffTrue = 1,
}

export enum EdbAttendanceHistoryType {
  AttendanceHistoryTypeNoShow = 0,
  AttendanceHistoryTypeRevokeNoShow = 1,
  AttendanceHistoryTypeSuspended = 2,
  AttendanceHistoryTypeReactivated = 3,
}

export enum EdbBannerLinkType {
  BannerLinkTypeNone = 0,
  BannerLinkTypeURL = 1,
  BannerLinkTypeAbsolutePath = 2,
  BannerLinkTypeRelativePath = 3,
}

export enum EdbBannerPlatform {
  BannerPlatformApp = 0,
  BannerPlatformWeb = 1,
  BannerPlatformAppWeb = 2,
}

export enum EdbBannerStatus {
  BannerStatusShow = 0,
  BannerStatusHidden = 1,
}

export enum EdbBatchJobStatus {
  BatchJobStatusNone = 0,
  BatchJobStatusBeforeInit = 1,
  BatchJobStatusInited = 2,
  BatchJobStatusRunning = 3,
  BatchJobStatusStopped = 4,
  BatchJobStatusFinished = 5,
}

export enum EdbBatchJobType {
  BatchJobTypeCreateStatement = 0,
}

export enum EdbBlackListMatchType {
  BlackListMatchTypeExact = 0,
  BlackListMatchTypeRegEx = 1,
}

export enum EdbBlackListRuleType {
  BlackListRuleTypeBlack = 0,
  BlackListRuleTypeWhite = 1,
}

export enum EdbBlackListTarget {
  BlackListTargetDeviceID = 0,
  BlackListTargetEmailDomain = 1,
  BlackListTargetEmailAddr = 2,
  BlackListTargetPhoneCCode = 3,
  BlackListTargetPhoneNum = 4,
}

export enum EdbBlogStatus {
  BlogStatusDraft = 0,
  BlogStatusOK = 1,
  BlogStatusHidden = 2,
}

export enum EdbBonusType {
  BonusInvited = 0,
  BonusBeInvited = 1,
  BonusSystem = 2,
  BonusTransactionUse = 3,
  BonusTransactionRefund = 4,
  BonusVIPAccount = 5,
  BonusCommentV1 = 6,
  BonusActivity = 7,
  BonusMission = 8,
  BonusToAsiaMiles = 9,
  BonusCommentV2 = 10,
  BonusFunCoinAssign = 11,
  BonusMemberMission = 12,
  BonusOffset = 13,
}

export enum EdbBossNowUserRole {
  BossNowUserRoleDefault = 0,
  BossNowUserRoleFunBookManager = 1,
}

export enum EdbBossNowUserStatus {
  BossNowUserStatusOK = 0,
  BossNowUserStatusDisabled = 1,
}

export enum EdbBranchAssignmentMode {
  BranchAssignmentModeNone = 0,
  BranchAssignmentModeAuto = 1,
}

export enum EdbBranchCRMType {
  BranchCRMNone = 0,
  BranchCRMOcard = 1,
}

export enum EdbBranchDeliveryFeeType {
  BranchDeliveryFeeTypeNone = 0,
  BranchDeliveryFeeTypeLessXFeeY = 1,
  BranchDeliveryFeeTypeFixedFeeY = 2,
}

export enum EdbBranchDomainType {
  BranchDomainTypeSub = 0,
  BranchDomainTypeCustom = 1,
}

export enum EdbBranchEatigoIsOnSitePaymentDiscount {
  BranchEatigoIsOnSitePaymentDiscountNo = 0,
  BranchEatigoIsOnSitePaymentDiscountYes = 1,
}

export enum EdbBranchEatigoReservationsMode {
  BranchEatigoReservationsModeAuto = 0,
  BranchEatigoReservationsModeManual = 1,
}

export enum EdbBranchFeeBeBorneBy {
  BranchFeeBeBorneByMerchant = 0,
  BranchFeeBeBorneByFunNowGroup = 1,
}

export enum EdbBranchFlashSale {
  BranchFlashSaleDisabled = 0,
  BranchFlashSaleEnabled = 1,
}

export enum EdbBranchHasBillTo {
  BranchHasBillToFalse = 0,
  BranchHasBillToTrue = 1,
}

export enum EdbBranchHasCommission {
  BranchHasCommissionFalse = 0,
  BranchHasCommissionTrue = 1,
}

export enum EdbBranchHasCoverFee {
  BranchHasCoverFeeFalse = 0,
  BranchHasCoverFeeTrue = 1,
}

export enum EdbBranchHasShipTo {
  BranchHasShipToFalse = 0,
  BranchHasShipToTrue = 1,
}

export enum EdbBranchInvoiceType {
  BranchInvoiceTypeUnknown = -1,
  BranchInvoiceTypeInvoice = 0,
  BranchInvoiceTypeMerchant = 1,
  BranchInvoiceTypeReceipt = 2,
  BranchInvoiceTypeMutual = 3,
  BranchInvoiceTypePersonal = 4,
}

export enum EdbBranchIssueInvoice {
  BranchIssueInvoiceDisabled = 0,
  BranchIssueInvoiceEnabled = 1,
}

export enum EdbBranchMKTEnabled {
  BranchMKTEnabledFalse = 0,
  BranchMKTEnabledTrue = 1,
}

export enum EdbBranchOA {
  BranchOAMonthly = 0,
  BranchOAHalfMonthly = 1,
  BranchOAWeekly = 2,
  BranchOADaily = 3,
}

export enum EdbBranchPMSType {
  BranchPMSNone = 0,
  BranchPMSShalom = 1,
  BranchPMSWise = 2,
  BranchPMSDunqian = 3,
  BranchPMSBistrochat = 4,
}

export enum EdbBranchPlatform {
  BranchPlatformFunNow = 0,
  BranchPlatformInline = 1,
  BranchPlatformEbisol = 2,
  BranchPlatformTableapp = 3,
  BranchPlatformEZDing = 4,
  BranchPlatformShalom = 5,
}

export enum EdbBranchPrintStatus {
  BranchPrintStatusDisabled = 0,
  BranchPrintStatusEnabled = 1,
}

export enum EdbBranchReserveMode {
  BranchReserveModeProductFirst = 0,
  BranchReserveModeHeadcountFirst = 1,
  BranchReserveModeSelltimeFirst = 2,
}

export enum EdbBranchResourceMode {
  BranchResourceModeNormal = 0,
  BranchResourceModeTblMgmt = 1,
}

export enum EdbBranchRwGFeeType {
  BranchRwGFeeTypeFalse = 0,
  BranchRwGFeeTypeSilverPlan = 1,
  BranchRwGFeeTypeGoldenPlan = 2,
  BranchRwGFeeTypeBasicPlan = 3,
}

export enum EdbBranchSetMode {
  BranchSetModeInfinity = 0,
  BranchSetModeBoth = 1,
}

export enum EdbBranchStatus {
  BranchEnabled = 0,
  BranchDisabled = 1,
}

export enum EdbBranchTableappRedirectStatus {
  BranchTableappRedirectStatusDisabled = 0,
  BranchTableappRedirectStatusEnabled = 1,
}

export enum EdbBranchTier {
  BranchTierFirst = 1,
  BranchTierSecond = 2,
  BranchTierThird = 3,
}

export enum EdbBranchType {
  BranchTypeFunNow = 0,
  BranchTypeFunBook = 1,
  BranchTypeFunNowFunBook = 2,
}

export enum EdbBranchVATType {
  BranchVATTypeRegistered = 0,
  BranchVATTypeZeroRate = 1,
  BranchVATTypeExempt = 2,
}

export enum EdbBrandID {
  BrandIDNone = 0,
  BrandIDFunNow = 1,
  BrandIDNiceday = 2,
  BrandIDEatigo = 3,
}

export enum EdbCardFunding {
  CardFundingCreditCard = 'Credit Card',
  CardFundingDebitCard = 'Debit Card',
  CardFundingPrepaidCard = 'Prepaid Card',
}

export enum EdbCardLevel {
  CardLevelClassic = 'CLASSIC',
  CardLevelGold = 'GOLD',
  CardLevelPlatinum = 'PLATINUM',
  CardLevelSignature = 'SIGNATURE',
  CardLevelInfinite = 'INFINITE',
  CardLevelBusiness = 'BUSINESS',
  CardLevelBusinessSignature = 'BUSINESS SIGNATURE',
  CardLevelBusinessInfinite = 'BUSINESS INFINITE',
}

export enum EdbCardType {
  CardTypeUnknown = 0,
  CardTypeVISA = 1,
  CardTypeMasterCard = 2,
  CardTypeJCB = 3,
  CardTypeUnionPay = 4,
  CardTypeAMEX = 5,
  CardTypeDiscover = 6,
  CardTypeDiners = 7,
}

export enum EdbCategoryDayMode {
  CategoryDayModeDefault = 0,
  CategoryDayModeCalendar = 1,
  CategoryDayModeCalendar180Days = 2,
  CategoryDayModeCalendar60Days = 3,
  CategoryDayModeCalendar90Days = 4,
  CategoryDayModeCalendar365Days = 5,
}

export enum EdbCategoryHasCrossDay {
  CategoryHasCrossDayFalse = 0,
  CategoryHasCrossDayTrue = 1,
}

export enum EdbCategoryHasCustNum {
  CategoryHasCustNumFalse = 0,
  CategoryHasCustNumTrue = 1,
}

export enum EdbCategoryHasTimeFilter {
  CategoryHasTimeFilterFalse = 0,
  CategoryHasTimeFilterTrue = 1,
}

export enum EdbCategoryID {
  CategoryIDRestaurant = 8,
  CategoryIDFlashSale = 10782,
  CategoryIDRecentSale = 11569,
  CategoryIDDiscountSale = 11571,
  CategoryIDWantToEatLater = 11570,
}

export enum EdbCategoryOnBoard {
  CategoryOnBoardOpen = 0,
  CategoryOnBoardClose = 1,
}

export enum EdbCategoryShowAll {
  CategoryShowAllFalse = 0,
  CategoryShowAllTrue = 1,
}

export enum EdbCategoryStatus {
  CategoryStatusOpen = 0,
  CategoryStatusHidden = 1,
  CategoryStatusDisabled = 2,
}

export enum EdbCategoryType {
  CategoryTypeIndustry = 0,
  CategoryTypeTheme = 1,
  CategoryTypeFilter = 2,
  CategoryTypeCuisine = 3,
  CategoryTypeBrand = 4,
  CategoryTypeLocation = 5,
}

export enum EdbCertStatus {
  CertPending = 0,
  CertDone = 1,
}

export enum EdbCourierType {
  CourierTypeGOGOX = 0,
  CourierTypeBranchSelf = 1,
  CourierTypeZeek = 2,
}

export enum EdbCoverFeeType {
  CoverFeeTypeStandard = 0,
  CoverFeeTypeTimeSlot = 1,
}

export enum EdbCurrencyCode {
  CodeTWD = 'TWD',
  CodeHKD = 'HKD',
  CodeMYR = 'MYR',
  CodeJPY = 'JPY',
  CodeTHB = 'THB',
}

export enum EdbCurrencyID {
  CurrencyIDTWD = 0,
  CurrencyIDHKD = 1,
  CurrencyIDJPY = 2,
  CurrencyIDMYR = 3,
  CurrencyIDTHB = 4,
}

export enum EdbDeliveryAreaStatus {
  DeliveryAreaStatusOK = 0,
  DeliveryAreaStatusDisabled = 1,
}

export enum EdbDeliveryStatus {
  DeliveryStatusCreated = 0,
  DeliveryStatusCourierMatched = 1,
  DeliveryStatusCourierPickup = 2,
  DeliveryStatusArrived = 3,
  DeliveryStatusCanceled = 4,
  DeliveryStatusCourierNearBranch = 5,
  DeliveryStatusCourierDelivering = 6,
  DeliveryStatusCourierNearReceiver = 7,
  DeliveryStatusCanceledAfterDelivering = 8,
  DeliveryStatusAssignedAnotherCourier = 9,
  DeliveryStatusLeftBranch = 10,
}

export enum EdbEventNewsStatus {
  EventNewsStatusShow = 0,
  EventNewsStatusHidden = 1,
}

export enum EdbFunBookOwnPageStatus {
  OwnPageStatusDisabled = 0,
  OwnPageStatusEnabled = 1,
}

export enum EdbFunBookPromoCodeType {
  FunBookPromoCodeTypeDeny = 0,
  FunBookPromoCodeTypeAllow = 1,
}

export enum EdbFunBookType {
  FunBookTypeNoProduct = 0,
  FunBookTypeWithProduct = 1,
}

export enum EdbFunBookVer {
  FunBookVerNone = 0,
  FunBookVerV1 = 1,
  FunBookVerV2 = 2,
}

export enum EdbFunNowMKTEnabled {
  FunNowMKTEnabledFalse = 0,
  FunNowMKTEnabledTrue = 1,
}

export enum EdbGatewayType {
  GatewayTypeTapPay = 0,
  GatewayTypeStripe = 1,
}

export enum EdbGiftCardStatus {
  GiftCardStatusOpen = 0,
  GiftCardStatusHidden = 1,
}

export enum EdbGiftToMemberStatus {
  GiftToMemberStatusCreated = 0,
  GiftToMemberStatusReceived = 1,
  GiftToMemberStatusReserved = 2,
  GiftToMemberStatusCanceled = 3,
  GiftToMemberStatusExpired = 4,
}

export enum EdbGiftToMemberType {
  GiftToMemberTypeSender = 0,
  GiftToMemberTypeReceiver = 1,
}

export enum EdbHelpStatus {
  HelpStatusHidden = 0,
  HelpStatusPublished = 1,
}

export enum EdbHotSearchType {
  HotSearchTypeDefault = 0,
  HotSearchTypeSearchBar = 1,
}

export enum EdbInlineTagType {
  InlineTagTypeNone = 0,
  InlineTagTypeProduct = 1,
  InlineTagTypeBranch = 2,
}

export enum EdbInvoiceAllowStatus {
  InvoiceAllowStatusOK = 0,
  InvoiceAllowStatusFail = 1,
  InvoiceAllowStatusInvalid = 2,
}

export enum EdbInvoiceStatus {
  InvoiceStatusUnknown = 0,
  InvoiceStatusAuto = 1,
  InvoiceStatusSuccess = 2,
  InvoiceStatusFail = 3,
  InvoiceStatusAllowed = 4,
  InvoiceStatusInvalid = 5,
  InvoiceStatusInvalidFail = 6,
  InvoiceStatusNoNeed = 7,
}

export enum EdbIsDelivery {
  IsDeliveryFalse = 0,
  IsDeliveryTrue = 1,
}

export enum EdbItemProductType {
  ItemFunNow = 0,
  ItemFunNowManager = 1,
  ItemEatigo = 2,
  ItemNone = 99,
}

export enum EdbItemServiceType {
  ItemPlatformServiceFee = 0,
  ItemProcessingFee = 1,
  ItemSubscriptionFee = 2,
  ItemCoverFee = 3,
  ItemSMSServiceFree = 4,
  ItemSMSService = 5,
  ItemCollectionAdj = 6,
  ItemChargeAdj = 7,
  ItemTotal = 8,
  ItemSMSTableApp = 9,
  ItemSystemSetupFee = 10,
  ItemPromoCode = 11,
  ItemDeposit = 12,
  ItemFreeCovers = 13,
  ItemOther = 99,
}

export enum EdbLabelID {
  Label1stVisit = 1,
  LabelRepeat = 2,
  LabelPrivilege = 3,
  LabelElite = 4,
  LabelMemberReward = 5,
}

export enum EdbLabelStyleID {
  LabelStylePurchaseRate = 1,
  LabelStyleGift = 2,
  LabelStyleMemberLevel = 3,
  LabelStyleMemberReward = 4,
}

export enum EdbMemberAuthType {
  MemberAuthTypeEmail = 0,
  MemberAuthTypeGoogle = 1,
  MemberAuthTypeFB = 2,
  MemberAuthTypeGuest = 3,
  MemberAuthTypeLINE = 4,
  MemberAuthTypeCathay = 5,
  MemberAuthTypeApple = 6,
  MemberAuthTypeTNG = 7,
  MemberAuthTypeRwG = 8,
  MemberAuthTypePartner = 9,
  MemberAuthTypeAnonymous = 10,
}

export enum EdbMemberMissionType {
  MemberMissionTypeFunCoin = 0,
  MemberMissionTypeMile = 1,
}

export enum EdbMemberPaymentInfoType {
  MemberPaymentInfoTypeOther = 0,
  MemberPaymentInfoTypeTWD = 1,
  MemberPaymentInfoTypeHKD = 2,
  MemberPaymentInfoTypeMYR = 3,
  MemberPaymentInfoTypeStripeHKD = 4,
  MemberPaymentInfoTypeStripeJPY = 5,
  MemberPaymentInfoTypeStripeTHB = 6,
  MemberPaymentInfoTypeStripeMYR = 7,
}

export enum EdbMemberPropertyHistory {
  MemberPropertyHistOn = 0,
  MemberPropertyHistOff = 1,
}

export enum EdbMemberStatus {
  MemberEnabled = 0,
  MemberDisabled = 1,
  MemberDeleting = 2,
  MemberDeleted = 3,
  MemberSuspended = 4,
}

export enum EdbMenuItemStatus {
  MenuItemStatusPending = 0,
  MenuItemStatusLive = 1,
}

export enum EdbMovieGrade {
  MovieGradePG15 = 0,
  MovieGradeG = 1,
  MovieGradeP = 2,
  MovieGradePG12 = 3,
  MovieGradeR = 4,
}

export enum EdbMovieTicketType {
  MovieTicketTypeAdult = 0,
  MovieTicketTypeEarly = 1,
  MovieTicketTypeEZPromo = 2,
  MovieTicketTypeCombo = 3,
}

export enum EdbNoticeType {
  NoticeSystem = 0,
  NoticeTransaction = 1,
  NoticeAd = 2,
  NoticeActivity = 3,
  NoticeFunCoin = 4,
  NoticeAttention = 5,
}

export enum EdbOccupationAssignStatus {
  OccupationAssignStatusNone = 0,
  OccupationAssignStatusAssigned = 1,
}

export enum EdbOccupationType {
  OccupationTypeUnavailable = 0,
  OccupationTypeSold = 1,
}

export enum EdbOrdersBookingType {
  OrdersBookingTypeOnline = 0,
  OrdersBookingTypeBranchPhoneBook = 1,
  OrdersBookingTypeBranchOnSiteCustomer = 2,
}

export enum EdbOrdersCancelBy {
  OrdersCancelByUnknown = 0,
  OrdersCancelBySystem = 1,
  OrdersCancelByAdmin = 2,
  OrdersCancelByUser = 3,
  OrdersCancelByBranch = 4,
}

export enum EdbOrdersGroupRole {
  OrdersGroupNone = 0,
  OrdersGroupHead = 1,
  OrdersGroupMember = 2,
  OrdersGroupCancel = 3,
}

export enum EdbOrdersHistoryStatus {
  OrdersHistoryShow = 0,
  OrdersHistoryHidden = 1,
}

export enum EdbOrdersMovieTicketIsUseSeat {
  OrdersMovieTicketIsUseSeatFalse = 0,
  OrdersMovieTicketIsUseSeatTrue = 1,
}

export enum EdbOrdersNotifyMethod {
  OrdersNotifyMethodEmail = 0,
  OrdersNotifyMethodSMS = 1,
}

export enum EdbOrdersNotifyType {
  OrdersNotifyTypeBeforeBookingTime = 0,
  OrdersNotifyTypeExpAttnModify = 1,
  OrdersNotifyTypeExpAttnCron = 2,
  OrdersNotifyTypeOrderCreated = 3,
  OrdersNotifyTypeOrderRescheduled = 4,
  OrdersNotifyTypeEliteOrderCreated = 5,
  OrdersNotifyTypeAtdPeriodicWrite = 6,
  OrdersNotifyTypeAtdRemarkModified = 7,
  OrdersNotifyTypeAtdToFix = 8,
  OrdersNotifyTypeAtdWrite = 9,
  OrdersNotifyTypeAtdLastDay = 10,
  OrdersNotifyTypeAtdUserFixed = 11,
  OrdersNotifyTypeNicedayOrderCreated = 12,
  OrdersNotifyTypeNicedayOrderRescheduled = 13,
  OrdersNotifyTypeMileIssued = 14,
  OrdersNotifyTypeOrderCancelled = 15,
  OrdersNotifyTypeDepositPayment = 16,
  OrdersNotifyTypeManagerOrderCreated = 17,
  OrdersNotifyTypeManagerOrderRescheduled = 18,
  OrdersNotifyTypeManagerOrderCancelled = 19,
  OrdersNotifyTypeOrderNoShow = 20,
  OrdersNotifyTypeEatigoOrderCreated = 21,
  OrdersNotifyTypeBeforeBookingTimeForNewUsers = 22,
  OrdersNotifyTypeEatigoOrderRescheduled = 23,
  OrdersNotifyTypeEatigoOrderCancelled = 24,
}

export enum EdbOrdersReceiptStatus {
  OrdersReceiptStatusCreated = 0,
  OrdersReceiptStatusEmailSuccess = 1,
  OrdersReceiptStatusEmailFailed = 2,
}

export enum EdbOrdersReceiptType {
  OrdersReceiptTypeSuccess = 0,
  OrdersReceiptTypeRefund = 1,
}

export enum EdbOrdersSource {
  OrdersSourceFunNow = 0,
  OrdersSourceLineCoupon = 1,
  OrdersSourceLineSpot = 2,
  OrdersSourceEzbook = 3,
  OrdersSourceBookingEngine = 4,
  OrdersSourcePartner = 5,
  OrdersSourceFunBookV2 = 6,
  OrdersSourceNiceday = 7,
  OrdersSourceEatigo = 8,
  OrdersSourceTableapp = 9,
}

export enum EdbOrdersStatus {
  OrdersStatusCreated = 0,
  OrdersStatusInTransaction = 1,
  OrdersStatusComplete = 2,
  OrdersStatusFailed = 3,
  OrdersStatusCancel = 4,
  OrdersStatusRelease = 5,
  OrdersStatusFullRefund = 6,
  OrdersStatusPartialRefund = 7,
  OrdersStatusTransferred = 8,
  OrdersStatusAppFullRefund = 9,
  OrdersStatusAppPartialRefund = 10,
  OrdersStatusPaymentTimeout = 11,
}

export enum EdbPartnerID {
  PartnerIDUnknown = 0,
  PartnerIDPChome = 1,
  PartnerIDKKday = 2,
  PartnerIDEatigo = 3,
  PartnerIDDianping = 4,
  PartnerIDInline = 5,
}

export enum EdbPartnerProductMappingType {
  PartnerProductMappingTypeByFunNow = 0,
  PartnerProductMappingTypeByPartner = 1,
}

export enum EdbPartnerStatus {
  PartnerStatusDisable = 0,
  PartnerStatusEnable = 1,
}

export enum EdbPartnerType {
  PartnerTypeClient = 0,
  PartnerTypeSupplier = 1,
}

export enum EdbPaymentAttendStatus {
  PaymentAttendStatusAttended = 0,
  PaymentAttendStatusNoShow = 1,
  PaymentAttendStatusCancel = 2,
}

export enum EdbPaymentExternalType {
  PaymentExternalTypeNone = 0,
  PaymentExternalTypeTableapp = 1,
}

export enum EdbPaymentIsFreeOfCharge {
  PaymentIsFreeOfChargeFalse = 0,
  PaymentIsFreeOfChargeTrue = 1,
}

export enum EdbPaymentMethodProviderType {
  PaymentMethodProviderTypeNone = 0,
  PaymentMethodProviderTypeTapPay = 1,
  PaymentMethodProviderTypeStripe = 2,
  PaymentMethodProviderTypeIPay88 = 3,
  PaymentMethodProviderTypeAsiaPay = 4,
  PaymentMethodProviderTypeGrabPay = 5,
  PaymentMethodProviderTypeTNG = 6,
}

export enum EdbPaymentMethodType {
  PaymentMethodTypeCreditCard = 0,
  PaymentMethodTypeGooglePay = 1,
  PaymentMethodTypeApplePay = 2,
  PaymentMethodTypeLINEPay = 3,
  PaymentMethodTypeFPX = 4,
  PaymentMethodTypePiWallet = 5,
  PaymentMethodTypeOctopus = 6,
  PaymentMethodTypeGrabPay = 7,
  PaymentMethodTypeATMToMerchant = 8,
  PaymentMethodTypePromptPay = 9,
  PaymentMethodTypeTNG = 10,
  PaymentMethodType3Installments = 11,
}

export enum EdbPaymentRefundDiffStatus {
  PaymentRefundDiffStatusFalse = 0,
  PaymentRefundDiffStatusTrue = 1,
}

export enum EdbPaymentStatus {
  PaymentStatusOK = 0,
  PaymentStatusDisputes = 1,
}

export enum EdbPickupType {
  PickupTypeMeetOutSide = 0,
  PickupTypeAtTheDoor = 1,
  PickupTypeMeetAtTheDoor = 2,
}

export enum EdbPlatformType {
  PlatformTypeAdmin = 0,
  PlatformTypeManager = 1,
  PlatformTypeFunNow = 2,
  PlatformTypeSystem = 3,
  PlatformTypePartner = 4,
  PlatformTypeRwG = 5,
}

export enum EdbProductAddType {
  ProductAddTypeNotAdd = 0,
  ProductAddTypeAllowAll = 1,
  ProductAddTypeAllowCustom = 2,
}

export enum EdbProductAllowMulti {
  ProductAllowMultiDeny = 0,
  ProductAllowMultiAllow = 1,
}

export enum EdbProductAttendeeType {
  ProductAttendeeTypeNone = 0,
  ProductAttendeeTypeRequired = 1,
}

export enum EdbProductBookingMode {
  ProductBookingModeNormal = 0,
  ProductBookingModeSeatOnly = 1,
}

export enum EdbProductCancelType {
  ProductCancelTypeFunNow = 0,
  ProductCancelTypeEzbook = 1,
  ProductCancelTypeCustom = 2,
}

export enum EdbProductCancelable {
  ProductCancelableFalse = 0,
  ProductCancelableTrue = 1,
}

export enum EdbProductChannel {
  ProductChannelFunNow = 0,
  ProductChannelFunBook = 1,
  ProductChannelEatigo = 2,
  ProductChannelNiceday = 3,
  ProductChannelRwG = 4,
  ProductChannelPhone = 5,
  ProductChannelWalkIn = 6,
  ProductChannelPChome = 7,
  ProductChannelKKday = 8,
  ProductChannelDianping = 9,
}

export enum EdbProductCoolingOff {
  ProductCoolingOffDisabled = 0,
  ProductCoolingOffEnabled = 1,
}

export enum EdbProductDetailMode {
  ProductDetailModeHidden = 0,
  ProductDetailModeDisplay = 1,
}

export enum EdbProductEditMode {
  ProductEditModeNone = 0,
  ProductEditModeManagerNotAllow = 1,
}

export enum EdbProductFBCancelType {
  ProductFBCancelTypeSameAsFunNow = 0,
  ProductFBCancelTypeCustom = 1,
}

export enum EdbProductGiftable {
  ProductGiftableFalse = 0,
  ProductGiftableTrue = 1,
}

export enum EdbProductIsCrossDay {
  ProductIsCrossDayFalse = 0,
  ProductIsCrossDayTrue = 1,
}

export enum EdbProductIsCustNum {
  ProductIsCustNumFalse = 0,
  ProductIsCustNumTrue = 1,
}

export enum EdbProductIsOccuResource {
  ProductIsOccuResourceFalse = 0,
  ProductIsOccuResourceTrue = 1,
}

export enum EdbProductIsShowAddress {
  ProductIsShowAddressFalse = 0,
  ProductIsShowAddressTrue = 1,
}

export enum EdbProductLimitMinType {
  ProductLimitMinTypeNone = 0,
  ProductLimitMinTypeSameAsCustNum = 1,
}

export enum EdbProductLimitType {
  ProductLimitTypeNone = 0,
  ProductLimitTypeSameAsCustNum = 1,
}

export enum EdbProductListType {
  ProductListTypeNormal = 0,
  ProductListTypeAddition = 1,
  ProductListTypeAbove = 2,
}

export enum EdbProductNetPriceMode {
  ProductNetPriceModeNone = 0,
  ProductNetPriceModeByRule = 1,
}

export enum EdbProductNewReleaseMode {
  ProductNewReleaseModeAuto = 0,
  ProductNewReleaseModeManual = 1,
}

export enum EdbProductOnBoard {
  ProductOnBoardFalse = 0,
  ProductOnBoardTrue = 1,
}

export enum EdbProductPartnerStatus {
  ProductPartnerStatusDisable = 0,
  ProductPartnerStatusEnable = 1,
}

export enum EdbProductPartnerUploadStatus {
  ProductPartnerUploadStatusFinish = 1,
}

export enum EdbProductPaymentMode {
  ProductPaymentModeOnline = 0,
  ProductPaymentModeOnsite = 1,
}

export enum EdbProductPopularMode {
  ProductPopularModeAuto = 0,
  ProductPopularModeManual = 1,
}

export enum EdbProductRefPriceMode {
  ProductRefPriceModeAuto = 0,
  ProductRefPriceModeManual = 1,
}

export enum EdbProductRemainMode {
  ProductRemainModeDisabled = 0,
  ProductRemainModeEnabled = 1,
}

export enum EdbProductRemarkRegexType {
  ProductRemarkRegexTypeNone = 0,
  ProductRemarkRegexTypeTWMobile = 1,
  ProductRemarkRegexTypeGlobalMobile = 2,
}

export enum EdbProductRemarkType {
  ProductRemarkInputBox = 0,
  ProductRemarkDatepicker = 1,
  ProductRemarkDateTimepicker = 2,
  ProductRemarkSelections = 3,
  ProductRemarkNumbers = 4,
  ProductRemarkMobileWithCountryCode = 5,
  ProductRemarkMultiSelections = 6,
  ProductRemarkTextDescription = 7,
  ProductRemarkImage = 8,
}

export enum EdbProductReschedulable {
  ProductReschedulableFalse = 0,
  ProductReschedulableTrue = 1,
}

export enum EdbProductSetDiscountMode {
  ProductSetDiscountModeNone = 0,
  ProductSetDiscountModeLastMinute = 1,
  ProductSetDiscountModeTier = 2,
}

export enum EdbProductSetDiscountType {
  ProductSetDiscountTypePercent = 0,
  ProductSetDiscountTypeAmount = 1,
}

export enum EdbProductSetMode {
  NoSettingMode = 0,
  WeekdayMode = 1,
  DateMode = 2,
  HolidayMode = 3,
  BlockMode = 4,
  FlashMode = 5,
  NotEligibleFlashMode = 6,
  DayOffMode = 7,
  LastMinuteMode = 8,
}

export enum EdbProductSetPriceType {
  ProductSetPriceTypeNormal = 0,
  ProductSetPriceTypeSession = 1,
}

export enum EdbProductShowOn {
  ProductShowOnFunNow = 0,
  ProductShowOnFunBook = 1,
  ProductShowOnFunNowFunBook = 2,
}

export enum EdbProductStatus {
  ProductStatusOpen = 0,
  ProductStatusBranchImmutable = 1,
  ProductStatusDisabled = 2,
  ProductStatusBlock = 3,
  ProductStatusHidden = 4,
}

export enum EdbProductSubItemType {
  ProductSubItemTypeNone = 0,
  ProductSubItemTypeAllowCustom = 1,
}

export enum EdbProductTagType {
  ProductTagTypeFacility = 0,
  ProductTagTypeCategory = 1,
  ProductTagTypeNormal = 2,
  ProductTagTypeEvent = 3,
  ProductTagTypeFlashSale = 4,
  ProductTagTypeUniq = 5,
  ProductTagTypeReview = 6,
  ProductTagTypeUserRights = 7,
  ProductTagTypeRegionLabel = 8,
  ProductTagTypeCuisine = 9,
  ProductTagTypeServiceLanguage = 10,
  ProductTagTypePaymentMethod = 11,
  ProductTagTypeAtmosphere = 12,
  ProductTagTypeSpecialDiet = 13,
  ProductTagTypeCardVerification = 14,
}

export enum EdbProductTimeMode {
  ProductTimeModeDefault = 0,
  ProductTimeModeCalendar = 1,
  ProductTimeModeCalendar180Days = 2,
  ProductTimeModeCalendar60Days = 3,
  ProductTimeModeCalendar90Days = 4,
  ProductTimeModeCalendar365Days = 5,
}

export enum EdbProductUpgradeMode {
  ProductUpgradeModeSingle = 0,
  ProductUpgradeModeMultiple = 1,
}

export enum EdbProductUpgradeType {
  ProductUpgradeTypeNone = 0,
  ProductUpgradeTypeRequired = 1,
}

export enum EdbProductYCMode {
  ProductYCModeNone = 0,
  ProductYCModeEnabled = 1,
}

export enum EdbPromoApplicablePlatform {
  PromoApplicablePlatformFunNow = 0,
  PromoApplicablePlatformFunBook = 1,
}

export enum EdbPromoCodeID {
  PromoCodeIDNewUserMovie = 891527,
}

export enum EdbPromoCostAllocation {
  PromoCostAllocationFunNow = 0,
  PromoCostAllocationMerchant = 1,
}

export enum EdbPromoIsDiscountMovieFee {
  PromoIsDiscountMovieFeeFalse = 0,
  PromoIsDiscountMovieFeeTrue = 1,
}

export enum EdbPromoType {
  PromoTypeNone = 0,
  PromoTypeFunCoin = 1,
  PromoTypeGreaterYGiveX = 2,
  PromoTypeXOffLimitYThresholdZ = 3,
  PromoTypeXFunCoinLimitY = 4,
  PromoTypeXPercentFunCoinLimitY = 5,
  PromoTypeLessYAllX = 6,
  PromoTypeVIP = 7,
  PromoTypeElite = 8,
  PromoTypeOnSiteX = 9,
}

export enum EdbPushStatus {
  PushStatusSuccess = 1,
  PushStatusFailed = 2,
  PushStatusOnlyDataMsg = 3,
}

export enum EdbRefundStatus {
  RefundStatusNone = 0,
  RefundStatusSuccess = 1,
  RefundStatusFailed = 2,
}

export enum EdbRefundType {
  RefundTypeFull = 0,
  RefundTypeSeventyPercent = 1,
  RefundTypeSeventyPercentRelease = 2,
  RefundTypeSeventyPercentRefund = 3,
  RefundTypeAllowance = 4,
}

export enum EdbRegionIsReceiptInStmt {
  RegionIsReceiptInStmtNo = 0,
  RegionIsReceiptInStmtYes = 1,
}

export enum EdbRegionLINESpotSync {
  RegionLINESpotSyncOff = 0,
  RegionLINESpotSyncOn = 1,
}

export enum EdbRegionOnBoard {
  RegionOnBoardOpen = 0,
  RegionOnBoardClose = 1,
}

export enum EdbRegionSendReceipt {
  RegionSendReceiptDisabled = 0,
  RegionSendReceiptEnabled = 1,
}

export enum EdbRegionStatus {
  RegionStatusOpen = 0,
  RegionStatusHidden = 1,
}

export enum EdbRegionToBannerType {
  RegionToBannerTypeNormal = 0,
  RegionToBannerTypeEatigoBrand = 1,
}

export enum EdbRemarkGroupType {
  RemarkGroupTypeProductRemark = 0,
  RemarkGroupTypeAttendeeRemark = 1,
}

export enum EdbRemarkIsRequired {
  RemarkIsRequiredFalse = 0,
  RemarkIsRequiredTrue = 1,
}

export enum EdbReservationLinkStatus {
  ReservationLinkStatusNone = 0,
  ReservationLinkStatusPhone = 1,
}

export enum EdbReservationSource {
  ReservationSourceCustomer = 0,
  ReservationSourceBranchFromPhone = 1,
  ReservationSourceRealContact = 2,
  ReservationSourceRealContactPrivacy = 3,
  ReservationSourceRwG = 4,
  ReservationSourceBranchWalkIn = 5,
}

export enum EdbReservationStatus {
  ReservationStatusBook = 0,
  ReservationStatusArrived = 1,
  ReservationStatusCustomerCancel = 2,
  ReservationStatusBranchCancel = 3,
  ReservationStatusNoShow = 4,
}

export enum EdbResourceGroupStatus {
  ResourceGroupStatusNormal = 0,
  ResourceGroupStatusOffline = 1,
}

export enum EdbResourceMergeStatus {
  ResourceMergeStatusNormal = 0,
  ResourceMergeStatusOffline = 1,
}

export enum EdbResourceStatus {
  ResourceStatusNormal = 0,
  ResourceStatusDisabled = 1,
  ResourceStatusOffline = 2,
}

export enum EdbRwG {
  RwGDisabled = 0,
  RwGEnabled = 1,
}

export enum EdbRwGType {
  RwGTypeRedirect = 0,
  RwGTypeDining = 1,
}

export enum EdbSOADocumentDueDateType {
  SOADocumentDueDateTypeNone = 0,
  SOADocumentDueDateTypeImmediate = 1,
  SOADocumentDueDateTypeNetDays = 2,
}

export enum EdbSOADocumentMode {
  SOADocumentModeManual = 0,
  SOADocumentModeAuto = 1,
}

export enum EdbSOADocumentStatus {
  SOADocumentStatusNormal = 0,
  SOADocumentStatusVoid = 1,
}

export enum EdbSOADocumentType {
  SOADocumentTypeInvoice = 0,
  SOADocumentTypeDepositInvoice = 1,
  SOADocumentTypeCreditNote = 2,
  SOADocumentTypeDebitNote = 3,
  SOADocumentTypeReceipt = 4,
  SOADocumentTypeUnknown = 99,
}

export enum EdbSOADocumentVATApplyType {
  SOADocumentVATApplyTypeSameAsBranch = 0,
  SOADocumentVATApplyTypeExempt = 1,
}

export enum EdbSOAInvoiceType {
  SOAInvoiceTypeGeneral = 0,
  SOAInvoiceTypeCustom = 1,
  SOAInvoiceTypeDeposit = 2,
}

export enum EdbSOANoteIsECV {
  SOANoteIsECVFalse = 0,
  SOANoteIsECVTrue = 1,
}

export enum EdbSOANoteType {
  SOANoteTypeDebit = 0,
  SOANoteTypeCredit = 1,
}

export enum EdbSOAReceiptPaymentMode {
  SOAReceiptPaymentModeAuto = 0,
  SOAReceiptPaymentModeCheque = 1,
  SOAReceiptPaymentModeBankTrans = 2,
}

export enum EdbSalesMode {
  NormalSales = 0,
  WeekendSales = 1,
  CertificationSales = 2,
  DeliverySales = 3,
  WineDeliverySales = 4,
  MovieSales = 5,
  CampingSales = 6,
}

export enum EdbSendSMS {
  SendSMSDisabled = 0,
  SendSMSEnabled = 1,
}

export enum EdbStatementInvoiceStatus {
  StatementInvoiceStatusNotReceived = 0,
  StatementInvoiceStatusReceived = 1,
  StatementInvoiceStatusReplied = 2,
}

export enum EdbStatementSource {
  StatementSourceDefault = 0,
  StatementSourceBrand = 1,
  StatementSourceSet = 2,
}

export enum EdbStatementTransferStatus {
  StatementTransferStatusNotYet = 0,
  StatementTransferStatusDone = 1,
}

export enum EdbTagID {
  TagIDRest = 1001,
  TagIDAccommodation = 1002,
  TagIDFemale = 1003,
  TagIDRestaurant = 1005,
  TagIDMassage = 1100,
  TagIDNewRelease = 10001,
  TagIDProductNewRelease = 10002,
  TagIDProductNewReleaseForPromo = 10003,
  TagIDVIP = 10262,
  TagIDHair = 10322,
  TagIDBar = 10357,
  TagIDTakeout = 10392,
  TagIDLimitRelease = 10694,
  TagIDHot = 10703,
  TagIDGift = 11267,
  TagID12H = 10576,
  TagIDMovie = 11403,
  TagIDGHA12H = 11575,
  TagIDHotSpring = 10265,
  TagIDExclusiveGift = 12149,
  TagIDHKHotelTax = 16116,
  TagIDInternet = 1,
  TagIDPool = 19,
  TagIDParking = 26,
  TagIDHotTub = 34,
  TagIDFitness = 10730,
  TagIDMovieGradePG15 = 11427,
  TagIDMovieGradeG = 11428,
  TagIDMovieGradeP = 11429,
  TagIDMovieGradePG12 = 11430,
  TagIDMovieGradeR = 11431,
  TagIDMovieComingSoon = 11433,
  TagIDMovieShowing = 11434,
  TagIDExperienceCourse = 11445,
  TagIDCamping = 11518,
  TagIDGame = 11557,
  TagIDOutdoorRecreation = 11558,
  TagIDOutdoorFamilyTime = 12200,
  TagIDArtisticCreations = 12201,
  TagIDScienceAndIntelligence = 12202,
  TagIDAnimalsAndInsects = 12203,
  TagIDChildrenSports = 12204,
  TagIDSensoryExploration = 12205,
  TagIDFamilyCooking = 12206,
  TagIDEmotionalEducation = 12207,
  TagIDFamilyExhibitions = 12208,
  TagIDVerbalExpression = 12243,
  TagIDElite = 11909,
}

export enum EdbTaskParticipationType {
  TaskParticipationTypeNoRequirement = 0,
  TaskParticipationTypeHasRequirement = 1,
}

export enum EdbTaskTimeType {
  TaskTimeTypeRange = 0,
  TaskTimeTypeDay = 1,
}

export enum EdbTemplateType {
  TemplateTypeBlog = 0,
  TemplateTypeStatementEmail = 1,
  TemplateTypeSlackMessage = 2,
  TemplateTypeConsumerReceiptEmail = 3,
  TemplateTypeNewReservationMail = 4,
  TemplateTypeCancelReservationMail = 5,
  TemplateTypeDefaultNotifyMail = 6,
  TemplateTypeDefaultCancelMail = 7,
  TemplateTypeManagerOrderMail = 8,
  TemplateTypeSOADocument = 9,
  TemplateTypeNicedayNotifyMail = 10,
  TemplateTypeNicedayCancelMail = 11,
  TemplateTypeDepositPaymentMail = 12,
  TemplateTypeNoShowConfirmMail = 13,
  TemplateTypeEatigoZohoTicket = 14,
  TemplateTypeEatigoNotifyMail = 15,
  TemplateTypeEatigoCancelMail = 16,
  TemplateTypeEatigoMemberStatusChanged = 17,
  TemplateTypeSOAEmail = 18,
}

export enum EdbTransFieldStatus {
  TransFieldStatusUnchecked = 0,
  TransFieldStatusChecked = 1,
}

export enum EdbTransStatus {
  TransStatusUnchecked = 0,
  TransStatusChecked = 1,
}

export enum EdbTxnType {
  TxnTypeAllPay = 0,
  TxnTypeBraintree = 1,
  TxnTypeCTBC = 2,
  TxnTypeTapPay = 3,
  TxnTypeApplePay = 4,
  TxnTypeGooglePay = 5,
  TxnTypeNone = 6,
  TxnTypeLINEPay = 7,
  TxnTypeMOLPay = 8,
  TxnTypeGrabPay = 9,
  TxnTypeIPay88 = 10,
  TxnTypeStripeHK = 11,
  TxnTypeStripeJP = 12,
  TxnTypeStripeGooglePayHK = 13,
  TxnTypeStripeGooglePayJP = 14,
  TxnTypeStripeApplePayHK = 15,
  TxnTypeStripeApplePayJP = 16,
  TxnTypeTNGWallet = 17,
  TxnTypeATMToMerchant = 18,
  TxnTypePiWallet = 19,
  TxnTypeOctopus = 20,
  TxnTypePartner = 21,
  TxnTypePayAtStoreOrFBNoPayment = 22,
  TxnTypeStripeTH = 23,
  TxnTypeStripeMY = 24,
  TxnTypeStripeGooglePayTH = 25,
  TxnTypeStripeGooglePayMY = 26,
  TxnTypeStripeApplePayTH = 27,
  TxnTypeStripeApplePayMY = 28,
  TxnTypeStripePromptPay = 29,
  TxnTypeTapPay3Installments = 30,
  TxnTypeTableappDeposit = 31,
}

export interface ElasticsearchCompleteKeyword {
  context?: string
  icon?: string
  identity?: string
  text?: string
  type?: number
}

export interface EliteCategory {
  id?: number
  type?: number
}

export interface EliteEliteDetail {
  category?: EliteCategory
  end_time?: string
  exclusives?: EliteEliteExclusiveData[]
  is_credit?: boolean
  path?: string
  start_time?: string
  total_savings?: EliteTotalSavings
}

export interface EliteEliteExclusiveData {
  action_endpoint?: string
  action_text?: string
  action_type?: number
  content?: string
  img_url?: string
  is_action_active?: boolean
  sub_content?: string
  title?: string
}

export interface EliteTotalSavings {
  amount?: number
  currency_id?: number
  dish_redeemed?: number
}

export interface EliteRequestReq {
  card_holder?: string
  prime?: string
  region_id?: number
}

export interface EliteRequestResData {
  payment_url?: string
}

export interface EmailListResponse {
  created_at?: string
  doc_id?: number
  doc_type?: EdbSOADocumentType
  from_email?: string
  from_name?: string
  id?: number
  status?: number
  subject?: string
  to_email?: string
  updated_at?: string
}

export interface EmailRequest {
  bcc?: string
  cc?: string
  content?: string
  doc_id?: number
  from_email?: string
  from_name?: string
  status?: number
  subject?: string
  to_email?: string
}

export interface EmailResponse {
  bcc?: string
  cc?: string
  content?: string
  created_at?: string
  doc_id?: number
  from_email?: string
  from_name?: string
  id?: number
  status?: number
  subject?: string
  to_email?: string
  updated_at?: string
}

export interface EmailSendRequest {
  /** @minItems 1 */
  id: number[]
  is_ref_key?: boolean
}

export enum EmongoOrderType {
  OrderTypeFunNow = 0,
  OrderTypeFunBook = 1,
  OrderTypeFunBookV2 = 2,
}

export enum EmongoPaymentStatus {
  PaymentStatusUnpaid = 1,
  PaymentStatusPaid = 2,
  PaymentStatusRefund = 3,
}

export interface EredisInlineResource {
  bid?: number
  capacityTime?: string
  maximum?: number
  minimum?: number
}

export interface EschemaAttendanceHistoryData {
  action_from_type?: EdbAttendanceHistoryActionFromType
  action_uid?: number
  created_at?: string
  display_name?: string
  email?: string
  frozen_at?: string
  id?: number
  is_written_off?: EdbAttendanceHistoryIsWrittenOff
  orders_id?: number
  score?: number
  score_expired_at?: string
  type?: EdbAttendanceHistoryType
}

export interface EschemaBranchData {
  bid?: number
  name?: string
}

export interface EschemaCategoryData {
  id?: EdbCategoryID
  name?: string
  status?: EdbCategoryStatus
}

export interface EschemaDeliveryAddressData {
  address?: string
  area?: string
  establishment?: string
  floor_building_number?: string
  google_place_id?: string
  id?: number
  lat?: number
  lng?: number
  note?: string
  pickup_type?: EdbPickupType
  uid?: number
}

export interface EschemaMemberAuthBasicRes {
  auth_id?: string
  auth_type?: number
  created_at?: string
  id?: number
  uid?: number
  updated_at?: string
}

export interface EschemaMemberLevelHistoryData {
  end_time?: string
  id?: number
  level?: number
  level_name?: string
}

export interface EschemaMemberListRes {
  auth?: EschemaMemberAuthBasicRes[]
  created_at?: string
  currency_id?: number
  email?: string
  phone?: string
  reg_type?: number
  region_id?: number
  status?: EdbMemberStatus
  uid?: number
  unhide_uid?: number
  updated_at?: string
}

export interface EschemaMemberRes {
  auth?: EschemaMemberAuthBasicRes[]
  block?: MblockFields[]
  brand_id?: EdbBrandID
  created_at?: string
  currency_id?: number
  delivery_address?: EschemaDeliveryAddressData[]
  member_deletion?: MdeletionFields[]
  member_level_history?: EschemaMemberLevelHistoryData[]
  member_tag?: EschemaMemberTagData[]
  property?: MpropPropertyRes
  property_niceday?: MpropNicedayPropertyRes
  reg_type?: number
  region_id?: number
  status?: EdbMemberStatus
  uid?: number
  unhide_uid?: number
  updated_at?: string
}

export interface EschemaMemberTagData {
  id?: number
  name?: string
}

export interface EschemaMemberUpdateReq {
  member_tag?: EschemaMemberTagData[]
  property?: MpropProperty
  property_niceday?: MpropNicedayProperty
  reg_type?: number
  status?: EdbMemberStatus
}

export interface EschemaMemberUpdateStatusRes {
  status?: EdbMemberStatus
  uid?: number
  updated_at?: string
}

export interface EschemaOrdersCompBonus {
  amount?: number
  bonus_id?: number
  created_at?: string
  diff?: number
  id?: number
  orders_id?: number
  type?: EdbBonusType
  uid?: number
}

export interface EschemaOrdersCompPayment {
  adjustment?: number
  all_amount?: number
  compensation?: number
  note?: string
  statement_note?: string
}

export interface EschemaOrdersCompPromo {
  code?: string
  created_at?: string
  id?: number
  name?: string
  orders_id?: number
  promo_code_id?: number
  promo_code_list_code?: string
  promo_code_list_id?: number
  promo_history_id?: number
  promo_title?: string
  uid?: number
}

export interface EschemaOrdersProduct {
  created_at?: string
  end_time?: string
  id?: number
  orders_id?: number
  price?: number
  product_dscp?: string
  product_id?: number
  product_name?: string
  product_sales_mode?: EdbSalesMode
  quantity?: number
  start_time?: string
  unhide_pid?: number
  updated_at?: string
}

export interface EschemaOrdersUpgrade {
  created_at?: string
  id?: number
  orders_id?: number
  price?: number
  quantity?: number
  updated_at?: string
  upgrade_dscp?: string
  upgrade_id?: number
  upgrade_name?: string
}

export interface EschemaProductData {
  bid?: number
  branch_name?: string
  id?: number
  name?: string
}

export interface EschemaRegionData {
  brand_id?: number
  brand_name?: string
  id?: number
  name?: string
  status?: EdbRegionStatus
}

export interface EschemaSOADocVoidRes {
  id?: number
  status?: EdbSOADocumentStatus
  updated_at?: string
}

export interface EsearchDiscountTier {
  ratio?: number
  stock?: number
}

export interface EventnewsCoverImgRes {
  cover_image?: string
  id?: number
  updated_at?: string
}

export interface EventnewsListRegionData {
  brand_id?: number
  brand_name?: string
  eventnews_id?: number
  region_id?: number
  region_name?: string
  status?: EdbRegionStatus
}

export interface EventnewsListResponse {
  cover_image?: string
  end_at?: string
  id?: number
  link_url?: string
  region?: EventnewsListRegionData[]
  start_at?: string
  status?: EdbEventNewsStatus
  title?: string
  trans?: EventnewsListTransData[]
}

export interface EventnewsListTransData {
  eventnews_id?: number
  id?: number
  lang_code?: string
  lang_id?: number
  title?: string
  trans_status?: EdbTransStatus
}

export interface EventnewsRequest {
  cover_image?: string
  end_at?: string
  link_url?: string
  region?: EschemaRegionData[]
  start_at?: string
  status?: EdbEventNewsStatus
  title?: string
  trans?: EventnewsTransData[]
}

export interface EventnewsResponse {
  cover_image?: string
  created_at?: string
  end_at?: string
  id?: number
  link_url?: string
  region?: EschemaRegionData[]
  start_at?: string
  status?: EdbEventNewsStatus
  title?: string
  trans?: EventnewsTransData[]
  updated_at?: string
}

export interface EventnewsTransData {
  id?: number
  is_for_delete?: boolean
  lang_id?: number
  status?: EdbTransStatus
  title?: string
  title_status?: EdbTransFieldStatus
}

export interface EventnewsTransListRes {
  code?: string
  event_news_id?: number
  id?: number
  name?: string
  status?: EdbTransStatus
  trans_id?: number
}

export interface EventnewsTransReq {
  event_news_id?: number
  lang_id?: number
  status?: EdbTransStatus
  title?: string
  title_status?: EdbTransFieldStatus
}

export interface EventnewsTransRes {
  created_at?: string
  event_news_id?: number
  id?: number
  lang_id?: number
  status?: EdbTransStatus
  title?: string
  title_status?: EdbTransFieldStatus
  updated_at?: string
}

export interface EventnewsUpdStatusReq {
  status?: EdbEventNewsStatus
}

export interface EventnewsUpdStatusRes {
  id?: number
  status?: EdbEventNewsStatus
  updated_at?: string
}

export interface ExportDocListResponse {
  code?: number
  data?: ExportmodelListResponse[]
  message?: string
  total_count?: number
}

export interface ExportDocResponse {
  code?: number
  data?: ExportmodelResponse
  message?: string
}

export interface ExportmodelListResponse {
  created_at?: string
  data_count?: number
  data_name?: string
  display_name?: string
  email?: string
  filter?: string
  id?: number
  path?: string
  status?: number
  uid?: number
  updated_at?: string
}

export interface ExportmodelResponse {
  created_at?: string
  data_count?: number
  data_name?: string
  display_name?: string
  email?: string
  filter?: string
  id?: number
  path?: string
  status?: number
  uid?: number
  updated_at?: string
}

export interface EzdingCheckTicketsReq {
  booking_num?: string
  campaign_code?: string
  channel_code?: string
  channel_trans_id?: string
  cinema_id?: string
  session_id?: string
  /** ThirdpartyOrderID is FunNow's string of hide.Uint64(DB.MovieSeat.ID) */
  thirdparty_order_id?: string
  /** Tickets contains all of the offline-refunded tickets for a movie transaction */
  tickets?: EzdingTicket[]
}

export interface EzdingTicket {
  barcode?: string
  quantity?: number
}

export interface FbranchArrivaltimeInfo {
  currency_id?: number
  currency_symbol?: string
  end_day?: string
  remain?: FbranchRemain
  sale_start_at?: string
  selldates?: SselldateSelldates
  start_day?: string
  /** time mode (0: 7 days, 1: 30 days, 2: 180 days, 3: 60 days, 4: 90 days, 5: 365 days) */
  time_mode?: EdbProductTimeMode
  timezone?: string
}

export interface FbranchDiscountSlot {
  discount?: number
  slot?: string
}

export interface FbranchEatigoListData {
  area?: string
  avg_price_range?: number
  bid?: number
  category_id?: number
  cover_image?: string
  cuisine_tags?: FbranchTag[]
  distance?: string
  /**
   * True if the number of available discount_tiers is greater than the number for the search,
   * false otherwise
   */
  has_more_discount?: boolean
  is_favorite?: boolean
  is_hot?: boolean
  is_new?: boolean
  lat?: number
  lng?: number
  max_discount_tiers_ratio?: number
  name?: string
  ratings?: string
  /** All past reservations for the branch */
  reservations?: string
  slots?: FbranchDiscountSlot[]
  tags?: FbranchTag[]
}

export interface FbranchEatigoListWithPinned {
  list?: FbranchEatigoListData[]
  recent?: FbranchEatigoListData[]
  timezone?: string
  total_count?: number
}

export interface FbranchRemain {
  /** remain mode (0: Disabled, 1: Enabled) */
  mode?: EdbProductRemainMode
  /** When the remaining quantity is greater than this value, it will be shown as sufficient */
  reference?: number
}

export interface FbranchTag {
  id?: EdbTagID
  name?: string
}

export interface FileGetFileResponse {
  event?: string
  message?: FileGetFileResponseMessage
}

export interface FileGetFileResponseMessage {
  code?: number
  data?: any
  message?: string
}

export interface FilterCategoryData {
  id?: number
  name?: string
}

export interface FilterDocListResponse {
  code?: number
  data?: FilterListResponse[]
  message?: string
  total_count?: number
}

export interface FilterDocResponse {
  code?: number
  data?: FilterResponse
  message?: string
}

export interface FilterDocTransListResponse {
  code?: number
  data?: FilterTransListResponse[]
  message?: string
  total_count?: number
}

export interface FilterDocTransResponse {
  code?: number
  data?: FilterTransResponse
  message?: string
}

export interface FilterFilterTag {
  id?: number
  tags?: FilterTag[]
  title?: string
}

export interface FilterListResponse {
  id?: number
  name?: string
  title?: string
}

export interface FilterRequest {
  category?: FilterCategoryData[]
  name?: string
  tag?: FilterTagData[]
  title?: string
}

export interface FilterResponse {
  category?: FilterCategoryData[]
  created_at?: string
  id?: number
  name?: string
  tag?: FilterTagData[]
  title?: string
  updated_at?: string
}

export interface FilterTag {
  id?: number
  name?: string
}

export interface FilterTagData {
  group_id?: number
  group_name?: string
  id?: number
  name?: string
}

export interface FilterTransListResponse {
  code?: string
  filter_id?: number
  id?: number
  name?: string
  status?: EdbTransStatus
  trans_id?: number
}

export interface FilterTransRequest {
  filter_id?: number
  lang_id?: number
  name?: string
  name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  title?: string
  title_status?: EdbTransFieldStatus
}

export interface FilterTransResponse {
  created_at?: string
  filter_id?: number
  id?: number
  lang_id?: number
  name?: string
  name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  title?: string
  title_status?: EdbTransFieldStatus
  updated_at?: string
}

export interface FmemberAccountDetail {
  auth_type?: EdbMemberAuthType
  email?: string
}

export interface FmemberApplyDeletionData {
  email?: string
  expired_at?: string
}

export interface FmemberApplyDeletionRes {
  code?: number
  data?: FmemberApplyDeletionData
  message?: string
}

export interface FmemberBenefit {
  achieve?: string
  expired_at?: string
  level?: number
  level_name?: string
  mile?: number
  mile_expired_at?: string
  next?: FmemberNextBenefit
}

export interface FmemberExclusivesDetail {
  amount?: number
  exclusives?: FmemberExclusive[]
  symbol_code?: string
}

export interface FmemberFreshChatRestoreIDData {
  restore_id?: string
}

export interface FmemberFreshChatRestoreIDReq {
  restore_id: string
}

export interface FmemberFunCoinDetail {
  bonus_amount?: number
  exchange_rates?: string[]
  expired_at?: string
  symbol_code?: string
}

export interface FmemberGetDeletionData {
  is_allow_deletion?: boolean
}

export interface FmemberGetDeletionRes {
  code?: number
  data?: FmemberGetDeletionData
  message?: string
}

export interface FmemberGiftExpiredTime {
  /** @example "2006-01-02 15:04:05" */
  time?: string
  /** @example "Asia/Taipei" */
  timezone?: string
}

export interface FmemberListCollectionData {
  bid?: number
  branch_name?: string
  cover_image?: string
  /** 0:TWD, 1:HKD, 2:JPY, 3:MYR, 4:THB */
  currency_id?: number
  /** Whether the product is disabled or suspended */
  disabled?: number
  price?: number
  product_id?: number
  product_name?: string
}

export interface FmemberListCollectionRes {
  code?: number
  data?: FmemberListCollectionData[]
  message?: string
  next_page_token?: string
  total_count?: number
}

export interface FmemberMemberInfo {
  account?: string
  /** 0: Email, 1: Google, 2: FB, 3: GUEST, 4: LINE, 5: CathayMR, 6: Apple, 7: TNG, 8: RwG, 9: Partner, 10: Anonymous */
  auth_type?: EdbMemberAuthType
  benefit?: FmemberBenefit
  /** 0: birthday is not editable, 1: birthday is editable */
  bhd_editable?: number
  /** @example "2006-01-02" */
  birthday?: string
  /** FunCoin balance */
  bonus?: number
  /** expired date of FunCoin */
  bonus_valid_date?: string
  company_name?: string
  /** Currency ID, 0: TWD, 1: HKD, 2: JPY, 3: MYR ... */
  currency?: number
  email?: string
  /** 0:enable push, 1:disable push */
  enabled?: number
  favorite_count?: number
  /** FreshChat restore ID */
  fc_restore_id?: string
  /** 0: Female, 1: Male, 2: Unknown */
  gender?: 0 | 1 | 2
  gift_expired_time?: FmemberGiftExpiredTime
  /** 0:show order history, 1:hide order history */
  history?: 0 | 1
  invite_code?: string
  is_elite?: boolean
  is_vip?: boolean
  member_invite_cnt?: number
  name?: string
  nickname?: string
  phone?: string
  /**
   * Deprecated: Point remove after frontend forced update
   * membership points
   */
  point?: number
  promo_receivable?: boolean
  promo_usable_cnt?: number
  receipt_company?: string
  /** member default region */
  region_id?: number
  /**
   * Deprecated: PastMonth remove after frontend forced update
   * all membership points earned before this month
   */
  sum_past?: number
  /**
   * Deprecated: ThisMonth remove after frontend forced update
   * membership points earned this month
   */
  sum_this_month?: number
  tax_id?: string
  uid?: number
  unexpired_gift_cnt?: number
  unread_msg_cnt?: number
  /** @example "2006-02-02 15:04:05" */
  vip_end_time?: string
  /** @example "2006-01-02 15:04:05" */
  vip_start_time?: string
}

export interface FmemberNextBenefit {
  amount?: number
  level?: DomainMemberLevelDisplay
  level_name?: string
  mile?: number
  symbol_code?: string
}

export interface FmemberReqSMSRequest {
  /** @example "+886" */
  country_code: string
  phone_number: string
  /** reCAPTCHA token */
  verification_token: string
}

export interface FmemberReqSMSVerify {
  /** @example "+886" */
  country_code: string
  phone_number: string
  verification_code: string
}

export interface FmemberRewardDetail {
  level?: DomainMemberLevelDisplay
  link?: string
  name?: string
  rewards?: FmemberReward[]
}

export interface FmemberExclusive {
  action_endpoint?: string
  action_text?: string
  action_type?: number
  content?: string
  id?: number
  img_url?: string
  rewards?: FmemberExclusiveReward[]
  title?: string
}

export interface FmemberExclusiveReward {
  action_type?: number
  active?: boolean
  claim_action_disable_text?: string
  claim_action_endpoint?: string
  claim_action_text?: string
  claim_text?: string
  is_claimed?: boolean
  level?: number
  level_name?: string
}

export interface FmemberReward {
  icon?: string
  text?: string
}

export interface ForderAddition {
  attn?: string
  dscp?: string
  id?: number
  name?: string
  price?: number
  qty?: number
  total_price?: number
}

export interface ForderCancelReq {
  reason?: string
}

export interface ForderCancelRuleRespData {
  cancel_rule?: string
  cancelable?: boolean
  notice?: string
}

export interface ForderCert {
  cert_path?: string
  cert_status?: EdbCertStatus
}

export interface ForderCompanyTax {
  company_name?: string
  receipt_company?: string
  tax_id?: string
}

export interface ForderCrossDayPrice {
  date?: string
  discount?: number
}

export interface ForderDeferPayment {
  email_count?: number
  sms_count?: number
}

export interface ForderDelivery {
  delivery_fee?: number
  delivery_time_buffer?: number
  estimated_delivery_time?: string
  info?: ForderDeliveryInfo
  status?: EdbDeliveryStatus
}

export interface ForderDeliveryInfo {
  address?: string
  establishment?: string
  floor_building_number?: string
  lat?: number
  lng?: number
  note?: string
  pickup_type?: EdbPickupType
}

export interface ForderGroupDetail {
  cust_num?: number
  head_order_id?: number
  member_note?: string
  member_order_id?: number[]
  member_rewards?: string[]
  order_count?: number
  orders?: ForderGroupOrder[]
  products?: ForderOrdersProduct[]
  remarks?: ForderRemark[]
  revenue_amount?: number
  role?: EdbOrdersGroupRole
  total_amount?: number
  vip_rewards?: string[]
}

export interface ForderGroupOrder {
  display_status?: number
  id?: number
  member_note?: string
  products?: ForderOrdersProduct[]
  promos?: ForderPromo[]
  remarks?: ForderRemark[]
  status?: EdbOrdersStatus
  vip_reward?: string
}

export interface ForderLabel {
  id?: EdbLabelID
  name?: string
  style_id?: EdbLabelStyleID
}

export interface ForderLeaveReviewReq {
  desc?: string
  rating?: number
  tag_id?: number[]
}

export interface ForderListOrderInfo {
  attendee_status?: SattendeeDisplayStatus
  booking_time?: string
  branch_name?: string
  checkout_time?: string
  cover_image?: string
  delivery?: ForderDelivery
  display_status?: number
  end_time_text?: string
  gift_status?: number
  has_cert?: boolean
  id?: number
  movie?: ForderMovieForList
  product_name?: string
  reschedule?: boolean
  reviewed?: number
  sales_mode?: number
  status?: EdbOrdersStatus
  timezone?: string
  type?: SorderUserOrderType
  used_time?: string
}

export interface ForderManagerDetail {
  booking_time?: string
  booking_type?: EdbOrdersBookingType
  branch_note?: string
  calling_code?: string
  cancel_at?: string
  cancel_rules?: string[]
  cancel_type?: EdbProductCancelType
  channel?: DomainManagerOrderChannel
  country_code?: string
  created_at?: string
  cust_num?: number
  cust_title?: string
  defer_payment?: ForderDeferPayment
  display_status?: number
  email?: string
  end_time_text?: string
  gender?: number
  group_detail?: ForderGroupDetail
  id?: number
  is_occu_resource?: boolean
  is_redeem?: boolean
  is_vip?: boolean
  member_nickname?: string
  member_note?: string
  member_reward?: string
  nickname?: string
  notify_methods?: EdbOrdersNotifyMethod[]
  order_type?: EmongoOrderType
  ori_cust_num?: number
  ori_member_note?: string
  ori_tier_discount?: number
  original_booking_time?: string
  original_end_time_text?: string
  partner_logo_img?: string
  partner_name?: string
  payment_mode?: EdbProductPaymentMode
  payment_status?: EmongoPaymentStatus
  phone?: string
  products?: ForderOrdersProduct[]
  promos?: ForderPromo[]
  remark_cust_title?: string
  remark_name?: string
  remarks?: ForderRemark[]
  resource_groups?: ForderRscGroups[]
  resource_use?: number
  sales_mode?: EdbSalesMode
  status?: EdbOrdersStatus
  tags?: ForderLabel[]
  tier_discount?: number
  total_amount?: number
  /** total_resource_use = Product.ResourceUse + ProductUpgrade.ExtraResourceUse */
  total_resource_use?: number
  uid?: number
  used_time?: string
  vip_reward?: string
}

export interface ForderMovieForList {
  name?: string
  poster?: string
  status?: ForderMovieOrderStatus
}

export enum ForderMovieOrderStatus {
  MovieOrderStatusSucc = 0,
  MovieOrderStatusShowing = 1,
  MovieOrderStatusReviewed = 2,
  MovieOrderStatusCanceled = 3,
}

export interface ForderOrdersDetailPromo {
  discount?: number
  has_refund?: boolean
  is_multiple?: boolean
  name?: string
  promo_code_list_id?: number
  promo_id?: number
  refund_quantity?: number
  usage_quantity?: number
}

export interface ForderOrdersProduct {
  id?: number
  is_main?: boolean
  is_upgrade?: boolean
  name?: string
  quantity?: number
}

export interface ForderOrdersUpgrade {
  price?: number
  qty?: number
  upgrade_desc?: string
  upgrade_id?: number
  upgrade_name?: string
}

export interface ForderProductInfos {
  attn?: string
  dscp?: string
  id?: number
  name?: string
  price?: number
  qty?: number
  sub_item_prices?: number[]
  sub_items?: ForderSubItem[][]
  total_price?: number
}

export interface ForderPromo {
  discount?: number
  name?: string
  promo_code_list_id?: number
  promo_id?: EdbPromoCodeID
}

export interface ForderRemark {
  content?: string
  hide?: boolean
  id?: number
  sub_id?: number
  title?: string
}

export interface ForderResource {
  max?: number
  min?: number
  name?: string
  rid?: number
  slot?: ForderSlot
}

export interface ForderRscGroups {
  resources?: ForderResource[]
  rg_id?: number
  rg_name?: string
}

export interface ForderSlot {
  /** 0: None, 1: Assigned */
  assign_status?: EdbOccupationAssignStatus
  booking_end_time?: string
  /** @example "2025-01-28 00:00:00" */
  booking_start_time?: string
  cust_name?: string
  cust_num?: number
  cust_title?: string
  /** @example "2025-01-28 00:30:00" */
  end?: string
  id?: number
  /** @example "2025-01-21 20:45:55" */
  occu_created_at?: string
  /** 0: Unavailable, 1: Sold */
  occu_type?: EdbOccupationType
  /** @example "2025-01-21 20:45:55" */
  order_created_at?: string
  order_display_status?: number
  order_id?: number
  /** 0: FunNow, 1: FunBook, 2: FunBookV2 */
  order_type?: EmongoOrderType
  /** @example "2025-01-28 00:00:00" */
  start?: string
}

export interface ForderSubItem {
  desc?: string
  free_qty?: number
  id?: number
  name?: string
  price?: number
  qty?: number
  show_mode?: number
}

export interface ForderTxnInfo {
  amount?: number
  currency_id?: EdbCurrencyID
  refund?: number
  symbol?: string
  symbol_code?: EdbCurrencyCode
  txn_time?: string
}

export interface FormAnswer {
  content?: string
  sub_id?: number
}

export interface FormAttendeeData {
  answers?: FormRemarkAnswer[]
  id?: number
  type?: EdbProductRemarkType
}

export interface FormAttendeeDetailData {
  additions?: ForderAddition[]
  attendee?: FormAttendeeData[][]
  cross_day_prices?: ForderCrossDayPrice[]
  expiry_time?: string
  is_unlimited?: boolean
  last_modified_time?: string
  order?: FormOrderData
  product?: ForderProductInfos
  sheets?: number
  upgrades?: ForderOrdersUpgrade[]
}

export interface FormAttendeeDetailResp {
  code?: number
  data?: FormAttendeeDetailData
  message?: string
}

export interface FormAttendeeRemarksData {
  branch_name?: string
  cover_image?: string
  product_name?: string
  remarks?: FproductRemarkV2Data[]
}

export interface FormAttendeeRemarksResp {
  code?: number
  data?: FormAttendeeRemarksData
  message?: string
}

export interface FormCreateAttendeeReq {
  remarks: FormRemark[][]
  token: string
}

export interface FormCreateAttendeeResp {
  form_modified_at?: string
}

export interface FormOrderData {
  booking_time?: string
  checkout_time?: string
  contact_email?: string
  contact_phone?: string
  cs_channel?: string
  display_status?: number
  display_type?: FormDisplayType
  duration?: number
  end_time_text?: string
  id?: number
  occupation_count?: number
  source?: EdbOrdersSource
  symbol_code?: string
}

export interface FormRemark {
  answers: FormAnswer[]
  remark_id: number
  type: EdbProductRemarkType
}

export interface FormRemarkAnswer {
  content?: string
  sub_id?: number
}

export enum FormDisplayType {
  DisplayTypeNormal = 0,
  DisplayTypeCrossDay = 1,
  DisplayTypeGlamping = 2,
}

export interface FpaymentAsiaPayWebhookReq {
  Amt?: string
  Cur?: string
  PayRef?: string
  Ref?: string
  payerAuth?: string
  prc?: string
  secureHash?: string
  src?: string
  successcode?: string
}

export interface FpaymentConfirmIPay88Req {
  Amount: string
  AuthCode?: string
  BankMID?: string
  Currency: string
  ErrDesc?: string
  RefNo: string
  S_bankname?: string
  Signature?: string
  Status: string
  TranDate?: string
  TransId?: string
  paymentId: string
}

export interface FpaymentCreditCardInfo {
  /** 1: VISA, 2: MasterCard, 3: JCB, 4: Union Pay, 5: AMEX, 6: discover, 7: diners */
  card_type?: EdbCardType
  currency?: FpaymentCurrency
  id?: number
  /** issuer name */
  issuer?: string
  /** last 4 digits of card number */
  last?: string
  /**
   * card issuing country and corresponding currency
   * 0: Others, 1: Taiwan-TWD(TapPay), 2: HongKong-HKD(Braintree) (deprecated)
   * 3: Malaysia-MYR(TapPay), 4: HongKong-HKD(Stripe), 5: Japan-JPY(Stripe),
   * 6: Thailand-THB(Stripe), 7: Malaysia-MYR(Stripe)
   */
  type?: EdbMemberPaymentInfoType
  /** credit card is usable or not with promotion or product tag restriction */
  usable?: boolean
}

export interface FpaymentCreditCardListResp {
  code?: number
  data?: FpaymentCreditCardInfo[]
  limit_notice?: string
  message?: string
  promo_limit_notice?: string
  total_count?: number
}

export interface FpaymentCurrency {
  /**
   * currency code following ISO 4217 such as TWD, USD
   * @example "TWD"
   */
  code?: string
  decimal_precision?: number
  /** 0:TWD, 1:HKD, 2:JPY, 3:MYR, 4:THB */
  id?: EdbCurrencyID
  /**
   * currency symbol such as $, €, £
   * @example "$"
   */
  symbol?: string
}

export interface FpaymentDocPaymentIntentResp {
  code?: number
  data?: FpaymentStripeClientSecret
  message?: string
}

export interface FpaymentDocPaymentURLResp {
  /** Payment URL for 3D secure check (for MYR cards) */
  payment_url?: string
}

export interface FpaymentDocRespURL {
  code?: number
  data?: FpaymentDocPaymentURLResp
  message?: string
}

export interface FpaymentGrabPayWebhookReq {
  amount?: number
  completedAt?: number
  createdAt?: number
  currency?: string
  origTxID?: string
  partnerID?: string
  partnerTxID?: string
  payload?: FpaymentWebhookPayload
  txID?: string
  txStatus?: string
  txType?: string
}

export interface FpaymentGrabPayWebhookRes {
  description?: string
  status?: string
  txID?: string
}

export interface FpaymentIPay88WebhookReq {
  ActionType?: string
  Amount?: string
  AuthCode?: string
  BankMID?: string
  BindCardErrDesc?: string
  CCName?: string
  CCNo?: string
  Country?: string
  Currency?: string
  Discount?: string
  ErrDesc?: string
  ExpiryDate?: string
  MerchantCode?: string
  OldTokenId?: string
  PANTrackNo?: string
  PayerEmail?: string
  PaymentId?: string
  RefNo?: string
  Remark?: string
  S_bankname?: string
  Signature?: string
  Status?: string
  TokenId?: string
  TranDate?: string
  TransId?: string
}

export interface FpaymentMovieAmountRequest {
  bonus?: SmovieBonus
  combos?: SmovieMovieTicket[]
  currency_id: number
  promos?: SmoviePromo[]
  session_id: number
  tickets?: SmovieMovieTicket[]
}

export interface FpaymentMovieAmountResp {
  code?: number
  data?: FpaymentMovieAmountRespData
  message?: string
}

export interface FpaymentMovieAmountRespBonus {
  amount?: string
  discount?: string
  qty?: number
  total_amount?: string
  total_discount?: string
  total_use_bonus?: number
  use_bonus?: number
}

export interface FpaymentMovieAmountRespData {
  bonus?: FpaymentMovieAmountRespBonus
  combos?: FpaymentMovieAmountRespTicket[]
  comment_reward?: number
  comment_reward_amount?: string
  currency_code?: string
  currency_id?: number
  currency_symbol?: string
  fee?: FpaymentMovieAmountRespFee
  fee_discount?: FpaymentMovieAmountRespFee
  fun_rewards_per?: number
  member_level?: number
  member_level_name?: string
  multi_currency_info?: FpaymentMultiCurrencyInfo
  multi_txn_amount?: Record<string, string>
  promos?: FpaymentMovieAmountRespPromo[]
  tickets?: FpaymentMovieAmountRespTicket[]
}

export interface FpaymentMovieAmountRespFee {
  amount?: string
  qty?: number
  total_amount?: string
}

export interface FpaymentMovieAmountRespPromo {
  amount?: string
  discount?: string
  promo_code_list_id?: number
  qty?: number
  total_amount?: string
  total_discount?: string
}

export interface FpaymentMovieAmountRespTicket {
  amount?: string
  id?: number
  name?: string
  qty?: number
  total_amount?: string
}

export interface FpaymentMultiCurrencyInfo {
  delivery_fee?: Record<string, string>
  delivery_free_amount?: Record<string, string>
  txn_amount?: Record<string, string>
  txn_amount_for_octopus?: Record<string, string>
}

export interface FpaymentPaymentAmountData {
  comment_reward?: number
  comment_reward_amount?: string
  currency_code?: string
  currency_symbol?: string
  delivery_fee?: string
  delivery_free_amount?: string
  fun_coin_discount?: string
  fun_rewards_per?: number
  member_level?: number
  member_level_name?: string
  multi_currency_info?: FpaymentMultiCurrencyInfo
  multi_txn_amount?: Record<string, string>
  promo_discount?: string
  remaining_fun_coin?: number
  txn_amount?: string
  used_fun_coin?: number
}

export interface FpaymentPaymentReqV2 {
  amount?: number
  bin?: string
  bin_code_id?: number
  bonus?: number
  /** use tappay, stripe credit to pay */
  cardid?: number
  code_id?: number
  currency_id?: number
  /** need CVV for MYR (MOLPay) */
  cvv?: string
  from?: string
  installment?: number
  is_new_card?: boolean
  orderid: string
  /** use tappay apple, google, line to pay */
  prime?: string
  promo?: number
  promo_usage?: number
  token?: string
}

export interface FpaymentStripeClientSecret {
  client_secret?: string
  payment_method_id?: string
  publishable_key?: string
}

export interface FpaymentTNGWebhookReq {
  customerId?: string
  paymentAmount?: TngclientPaymentAmount
  paymentID: string
  /** @example "0" */
  paymentRequestId: string
  paymentResult: TngclientResultResp
  paymentTime?: string
}

export interface FpaymentWebhookPayload {
  partnerGroupTxID?: string
  paymentMethod?: string
  reason?: string
}

export interface FproductAddUpgAdditionData {
  count?: number
  desc?: string
  discount?: number
  name?: string
  /** Based on the sales region, and is not empty if the currencyID differs from the branch region */
  origin_price?: number
  product_id?: number
}

export interface FproductAddUpgSubItemData {
  desc?: string
  /** Start calculating the price when the quantity is greater than FreeQty. */
  free_qty?: number
  id?: number
  limit_max?: number
  limit_min?: number
  name?: string
  /** Based on the sales region, and is not empty if the currencyID differs from the branch region */
  origin_price?: number
  price?: number
  /**
   * 0: default. When the quantity of subitem is 0, do not display the option.
   * 1: fixed. Always display the option.
   */
  show_mode?: number
}

export interface FproductAddUpgUpgradeData {
  bid?: number
  description?: string
  duration?: number
  is_required?: boolean
  is_sold_out?: boolean
  limit_min?: number
  name?: string
  /** Based on the sales region, and is not empty if the currencyID differs from the branch region */
  origin_price?: number
  price?: number
  resource_use?: number
  upgrade_id?: number
}

export interface FproductBlogResponse {
  abstract?: string
  author?: string
  avatar?: string
  cover_image?: string
  id?: number
  post_at?: string
  subject?: string
}

export interface FproductCurrency {
  code?: string
  decimal_precision?: number
  id?: EdbCurrencyID
  symbol?: string
}

export interface FproductDayPrice {
  date?: string
  discount?: number
  /** Based on the sales region, and is not empty if the currencyID differs from the branch region */
  origin_price?: number
}

export interface FproductFacilitiesResponse {
  icon?: string
  id?: number
  name?: string
}

export interface FproductHeadcountInfo {
  default_count?: number
  headcount?: number[]
  max_headcount?: number
  min_headcount?: number
}

export interface FproductHeadcountResp {
  code?: number
  data?: FproductHeadcountInfo
  message?: string
}

export interface FproductLastInput {
  content?: string
  id?: number
}

export enum FproductLimitFlag {
  LimitFlagNone = 0,
  LimitFlagMin = 1,
  LimitFlagMax = 2,
  LimitFlagBothMinAndMax = 3,
}

export interface FproductListDataDiscountInfo {
  has_types?: number[]
  rate?: number
  /** @example "2023-05-04 17:15:00" */
  time?: string
  time_type?: 'hidden' | 'now' | 'hour' | 'day'
  type?: number
}

export interface FproductMaxCrossDaysRespData {
  code?: string
  currency_id?: number
  day_prices?: FproductDayPrice[]
  symbol_code?: string
  timezone?: string
}

export interface FproductPaymentMethodData {
  currency?: FproductCurrency
  id?: number
  name?: string
  /**
   * 0: None
   * 1: TapPay
   * 2: Stripe
   * 3: IPay88
   * 4: AsiaPay
   * 5: Grab Pay
   * 6: TNG
   */
  provider_type?: EdbPaymentMethodProviderType
  pub_key?: string
  setting?: any
  /**
   * 0: Credit Card
   * 1: Google Pay
   * 2: Apple Pay
   * 3: LINE Pay
   * 4: FPX
   * 5: Pi Wallet
   * 6: Octopus
   * 7: Grab Pay
   * 8: ATM To Merchant
   * 9: Prompt Pay
   * 10: TNG
   */
  type?: EdbPaymentMethodType
}

export type FproductPlaceholders = Record<string, string>

export interface FproductPriceResponse {
  description?: string
  name?: string
  origin_price?: number
  total_price?: number
  upgrade_id?: number
}

export interface FproductRemarkOption {
  actions?: string[]
  id?: number
  regex?: string
  value?: string
}

export interface FproductRemarkV2Data {
  actions?: string[]
  content?: string
  error_msg?: string
  id?: number
  is_required?: boolean
  last_input?: FproductLastInput
  opt?: FproductRemarkOption[]
  opt_default_idx?: number
  placeholder?: string
  regex?: string
  subtitle?: string
  title?: string
  type?: EdbProductRemarkType
}

export interface FproductReviewTagData {
  placeholder?: FproductPlaceholders
  tag?: FproductTags
}

export type FproductTags = Record<string, SreviewReviewTag[]>

export interface FproductTagsResponse {
  id?: number
  name?: string
}

export interface FpromoCodeData {
  /** 0: None (not Blogger invitecode), 1: Bonus (Fun Coin), 2: Promo Discount */
  award_type?: number
  /** 0: InviteCode, 1: PromoCode */
  code_type?: number
  invite_code?: InviteInviter
  promo_code?: FpromoPromoCodeData
}

export interface FpromoCurrency {
  code?: EdbCurrencyCode
  decimal_precision?: number
  id?: EdbCurrencyID
  symbol?: string
}

export interface FpromoInputPromoReq {
  bid?: number
  /** form tag for Android */
  promo_code: string
}

export interface FpromoListByProductData {
  code?: EdbCurrencyCode
  code_id?: number
  condition?: string
  currency_id?: EdbCurrencyID
  discount?: number
  endat?: string
  id?: EdbPromoCodeID
  is_credit_limit?: boolean
  is_multiple?: boolean
  promo_title?: string
  specified_txn_currency?: FpromoCurrency
  status?: FpromoStatus
  symbol_code?: string
  usable?: boolean
  usage_limit?: number
  usage_rate?: string
}

export interface FpromoListData {
  active?: FpromoMemberPromoCode[]
  inactive?: FpromoMemberPromoCode[]
}

export enum FpromoListStatus {
  ListStatusActive = 0,
  ListStatusUsed = 1,
  ListStatusExpired = 2,
  ListStatusNotApplicable = 3,
  ListStatusFullyUsed = 4,
}

export interface FpromoMemberPromoCode {
  code?: string
  code_id?: number
  /** condition description of using the promo code */
  condition?: string
  /** content of the promo code for showing in the front-end */
  content?: string
  /**
   * expired time of the promo code
   * @example "2006-01-02 15:04:05"
   */
  end_at?: string
  id?: number
  name?: string
  /** path of the CTA button */
  path?: string
  /** remain times of using the promo code */
  remain_times?: number
  /** 0:active, 1:used, 2:expired, 3:not applicable, 4: fully used */
  status?: FpromoListStatus
  /** limit times of using the promo code */
  times?: number
  /** title of the promo code for showing in the front-end */
  title?: string
  /**
   * 1: GiveFunCoinX, 2: GreaterYDiscountX, 3: DiscountXPercentLimitY
   * 4: GiveXFunCoinLimitY, 5: GiveXPercentFunCoinLimitY
   * 6: LessYSetToX, 7:VIP, 8:Elite
   */
  type?: EdbPromoType
  /** usage rate of the promo code, string format, e.g. 50% Used */
  usage_rate?: string
}

export interface FpromoPromoCodeData {
  /** get bonus FunCoins when type is 1 */
  bonus?: number
  code_id?: number
  /** content of the promo code for showing in the front-end */
  content?: string
  /**
   * expired time of the promo code
   * @example "2006-01-02 15:04:05"
   */
  end_at?: string
  id?: number
  name?: string
  /** path of the CTA button */
  path?: string
  /**
   * start time of the promo code
   * @example "2006-01-02 15:04:05"
   */
  start_at?: string
  /** limit times of using the promo code */
  times?: number
  /** title of the promo code for showing in the front-end */
  title?: string
  /**
   * 1: GiveFunCoinX, 2: GreaterYDiscountX, 3: DiscountXPercentLimitY
   * 4: GiveXFunCoinLimitY, 5: GiveXPercentFunCoinLimitY
   * 6: LessYSetToX, 7:VIP, 8:Elite
   */
  type?: number
}

export interface FpromoRecommendedPromo {
  title?: string
  usage_rate?: string
}

export interface FpromoRecommendedResponse {
  end_at?: string
  promo_code_id?: EdbPromoCodeID
  title?: string
  usage_rate?: string
}

export interface FpromoRecommendedTagsResponse {
  list?: FpromoRecommendedPromo[]
}

export interface FpromoRewardPromoInfo {
  content?: string
  link_target?: string
  title?: string
}

export enum FpromoStatus {
  StatusNotApplicable = 0,
  StatusActive = 1,
  StatusFullyUsed = 2,
}

export interface FregionBanner {
  cover_image?: string
  description?: string
  id?: number
  link_target?: string
  /** LinkType 0: None, 1: URL, 2: Page link (from homepage) 3: Page link (from current page) */
  link_type?: EdbBannerLinkType
  link_url?: string
  title?: string
}

export interface FregionRegionDetectData {
  greetings?: string
  id?: number
  name?: string
  onboard?: EdbRegionOnBoard
}

export interface FregionTagResData {
  id?: number
  name?: string
}

export interface FreviewReviewData {
  id?: number
  is_vip?: boolean
  like_count?: number
  liked?: boolean
  member_level?: number
  rate_time?: string
  rated_by?: string
  rating?: number
  rating_description?: string
  tags?: FreviewReviewTagData[]
}

export interface FreviewReviewResponse {
  avg_rating?: number
  comment?: FreviewReviewData[]
  /** @example [0,1,3,7,8] */
  score?: number[]
  total_count?: number
}

export interface FreviewReviewTagData {
  tag_id?: number
  tag_name?: string
}

export interface FsearchSuggestion {
  context?: string
  matches?: FsearchSuggestionMatch[]
  path?: string
  terms?: FsearchSuggestionTerm[]
  text?: string
}

export interface FsearchSuggestionMatch {
  input?: string
  text?: string
  type?: string
}

export interface FsearchSuggestionTerm {
  length?: number
  offset?: number
  value?: string
}

export interface FsearchSuggestionsRes {
  code?: number
  data?: ElasticsearchCompleteKeyword[]
  message?: string
}

export interface FsearchSuggestionsResV2Data {
  completes?: ElasticsearchCompleteKeyword[]
  keyword?: string
  sentence?: string
  suggestions?: FsearchSuggestion[]
}

export interface FunbookArrivalTimeData {
  earliest_selltime?: string
  selldates?: Record<string, FunbookSellTimeInfo[]>
}

export interface FunbookArrivalTimeRes {
  code?: number
  data?: FunbookArrivalTimeData
  message?: string
}

export interface FunbookReservationBranch {
  address?: string
  area?: string
  bid?: number
  cover_image?: string
  lat?: number
  lng?: number
  name?: string
  need_to_know?: string
  phone?: string
}

export interface FunbookReservationProduct {
  fbrrp?: number
  name?: string
  pid?: number
}

export interface FunbookSellTime {
  available?: boolean
  is_block?: boolean
  price?: number
  resource?: number
  selltime?: string
}

export interface FunbookSellTimeInfo {
  selltimes?: FunbookSellTime[]
  time_range_name?: string
}

export interface FuncoinQueryBalanceResponse {
  code?: number
  data?: FuncoinQueryBalanceResponseData
  message?: string
}

export interface FuncoinQueryBalanceResponseData {
  amount?: number
  status?: FuncoinStatus
}

export interface FuncoinSendSmsRequest {
  phone: string
  user_id: string
}

export interface FuncoinSmsVerifyRequest {
  phone: string
  user_id: string
  vcode: string
}

export interface FuncoinSmsVerifyResponse {
  code?: number
  data?: FuncoinSmsVerifyResponseData
  message?: string
}

export interface FuncoinSmsVerifyResponseData {
  token?: string
}

export enum FuncoinStatus {
  StatusUnbindFunNowMember = 0,
  StatusBindFunNowMember = 1,
}

export interface FuncoinUpdateBonusRequest {
  branch_id: string
  /**
   * @min -20000
   * @max 20000
   */
  diff: number
  note?: string
  order_id?: string
  user_id: string
}

export interface FuncoinUpdateBonusResponse {
  code?: number
  data?: FuncoinUpdateBonusResponseData
  message?: string
}

export interface FuncoinUpdateBonusResponseData {
  amount?: number
}

export interface FversionCheckRes {
  app_link?: string
}

export interface FversionCheckResWithSuggestVer {
  app_link?: string
  suggest_version?: string
}

export interface GeoPoint {
  lat?: number
  lng?: number
  valid?: boolean
}

export interface GiftCreateGiftRequest {
  email?: string
  gift_card_id: number
  gift_card_note?: string
  product_id: number
  tax_info?: ForderCompanyTax
  uid?: number
  upgrade_id?: number
}

export interface GiftCreateGiftResponseData {
  gift_id?: number
  order_id?: number
}

export interface GiftDetailRes {
  code?: number
  data?: GiftGiftDetail
  message?: string
}

export interface GiftDocListResponse {
  code?: number
  data?: GiftListResponse[]
  message?: string
  total_count?: number
}

export interface GiftDocResponse {
  code?: number
  data?: GiftResponse
  message?: string
}

export interface GiftGiftArrivalTimeSellData {
  end_day?: string
  selldates?: SselldateSelldates
  start_day?: string
  timezone?: string
}

export interface GiftGiftArrivalTimesResponse {
  code?: number
  data?: GiftGiftArrivalTimeSellData
  message?: string
}

export interface GiftGiftDetail {
  address?: string
  area?: string
  branch_id?: number
  branch_name?: string
  branch_phone?: string
  cancel_date?: string
  checkout?: string
  comment_reward?: number
  comment_reward_amount?: string
  cover_image?: string
  cs_channel?: string
  duration?: number
  duration_text?: string
  expired_time?: string
  fun_rewards_per?: number
  gift_card?: GiftGiftDetailCard
  gift_id?: number
  is_sender?: boolean
  is_vip?: boolean
  lat?: number
  lng?: number
  member_level?: number
  member_level_name?: string
  member_reward?: string
  order_id?: number
  product_id?: number
  product_name?: string
  region_id?: number
  sender_name?: string
  status?: EdbGiftToMemberStatus
  timezone?: string
  txn_info?: GiftGiftDetailTxn
  upgrades?: GiftGiftDetailUpgrade[]
  vip_reward?: string
  zip?: string
}

export interface GiftGiftDetailCard {
  card_image?: string
  link_hash?: string
  name?: string
  note?: string
}

export interface GiftGiftDetailTxn {
  amount?: DataconvPriceAmount
  bonus?: string
  bonus_discount?: DataconvPriceAmount
  product_amount?: DataconvPriceAmount
  promo?: GiftGiftDetailTxnPromo
  refund?: DataconvPriceAmount
  total_amount?: DataconvPriceAmount
  txn_time?: string
}

export interface GiftGiftDetailTxnPromo {
  discount?: DataconvPriceAmount
  name?: string
  refund?: boolean
}

export interface GiftGiftDetailUpgrade {
  quantity?: number
  total_price?: DataconvPriceAmount
  upgrade_id?: number
  upgrade_name?: string
}

export interface GiftListGift {
  branch_name?: string
  card_image?: string
  cover_image?: string
  expired_time?: string
  gift_id?: number
  product_id?: number
  product_name?: string
  sender_name?: string
  status?: EdbGiftToMemberStatus
  timezone?: string
}

export interface GiftListRes {
  code?: number
  data?: GiftListGift[]
  message?: string
}

export interface GiftListResponse {
  auth_id?: string
  auth_type?: number
  bid?: number
  bonus?: number
  booking_time?: string
  branch_name?: string
  contact_phone?: string
  created_at?: string
  currency_code?: string
  currency_id?: number
  discount?: number
  email?: string
  fnc_rate?: number
  gift_id?: number
  gift_status?: EdbGiftToMemberStatus
  gift_type?: EdbGiftToMemberType
  id?: number
  member_level?: number
  member_level_name?: string
  member_status?: EdbMemberStatus
  nickname?: string
  orders_id?: number
  orders_status?: EdbOrdersStatus
  product_id?: number
  product_name?: string
  region_id?: number
  region_name?: string
  relative_gift_status?: EdbGiftToMemberStatus
  total_amount?: number
  txn_acquirer?: string
  txn_amount?: number
  txn_auth_code?: string
  txn_card_bin_code?: string
  txn_card_country?: string
  txn_card_issuer?: string
  txn_card_lastfour?: string
  txn_cost_milli_sec?: number
  txn_currency_code?: string
  txn_currency_id?: number
  txn_ex_trade_id?: string
  txn_id?: number
  txn_pay_amount?: number
  txn_status?: number
  txn_trade_time?: string
  txn_type?: number
  tz_location?: string
  uid?: number
  unhide_bid?: number
  unhide_gift_id?: number
  unhide_orders_id?: number
  unhide_product_id?: number
  unhide_uid?: number
  vip_id?: number
}

export interface GiftResponse {
  auth_id?: string
  auth_type?: number
  bid?: number
  bonus?: number
  booking_time?: string
  branch_name?: string
  contact_phone?: string
  created_at?: string
  currency_code?: string
  currency_id?: number
  discount?: number
  email?: string
  fnc_rate?: number
  gift_card_name?: string
  gift_id?: number
  gift_note?: string
  gift_status?: EdbGiftToMemberStatus
  gift_type?: EdbGiftToMemberType
  id?: number
  lang_code?: string
  lang_id?: number
  lang_name?: string
  member_level?: number
  member_level_name?: string
  nickname?: string
  orders_id?: number
  orders_status?: EdbOrdersStatus
  product_id?: number
  product_name?: string
  received_time?: string
  receiver_phone?: string
  receiver_uid?: number
  region_id?: number
  region_name?: string
  sender_phone?: string
  sender_uid?: number
  total_amount?: number
  txn_acquirer?: string
  txn_amount?: number
  txn_auth_code?: string
  txn_card_bin_code?: string
  txn_card_country?: string
  txn_card_issuer?: string
  txn_card_lastfour?: string
  txn_cost_milli_sec?: number
  txn_currency_code?: string
  txn_currency_id?: number
  txn_ex_trade_id?: string
  txn_id?: number
  txn_pay_amount?: number
  txn_status?: number
  txn_trade_time?: string
  txn_type?: number
  uid?: number
  unhide_bid?: number
  unhide_gift_id?: number
  unhide_orders_id?: number
  unhide_uid?: number
  updated_at?: string
  vip_id?: number
  vip_reward?: string
}

export interface GiftUpdateVisibleRequest {
  visible?: boolean
}

export interface GiftcardDocListResponse {
  code?: number
  data?: GiftcardListResponse[]
  message?: string
  total_count?: number
}

export interface GiftcardDocResponse {
  code?: number
  data?: GiftcardResponse
  message?: string
}

export interface GiftcardListResponse {
  card_image?: string
  id?: number
  name?: string
  status?: EdbGiftCardStatus
}

export interface GiftcardRegionData {
  id?: number
  name?: string
}

export interface GiftcardRequest {
  card_image?: string
  name?: string
  placeholder?: string
  region?: GiftcardRegionData[]
  status?: EdbGiftCardStatus
  trans?: GiftcardTransData[]
}

export interface GiftcardResponse {
  card_image?: string
  created_at?: string
  id?: number
  name?: string
  placeholder?: string
  region?: GiftcardRegionData[]
  status?: EdbGiftCardStatus
  trans?: GiftcardTransData[]
  updated_at?: string
}

export interface GiftcardTransData {
  id?: number
  is_for_delete?: boolean
  lang_id?: number
  name?: string
  name_status?: EdbTransFieldStatus
  placeholder?: string
  placeholder_status?: EdbTransFieldStatus
  status?: EdbTransStatus
}

export interface AdminBlogListResponse {
  author?: BlogListAuthorData[]
  category_id?: number
  category_name?: string
  country_id?: number
  country_name?: string
  cover_image?: string
  created_at?: string
  end_at?: string
  id?: number
  path_name?: string
  post_at?: string
  rank?: number
  start_at?: string
  status?: EdbBlogStatus
  subject?: string
  tag?: BlogListTagData[]
  updated_at?: string
}

export interface AdminBranchRequest {
  address?: string
  area?: string
  bistrochat?: BbistrochatFields
  block?: BblockFields[]
  bossnow_user?: BranchBossNowUserData[]
  brand?: BranchBrandData[]
  channel?: BranchChannelData[]
  contact?: BranchContactData[]
  contact_email?: string
  contact_phone?: string
  contract?: BranchContractData[]
  cover_fee?: BranchCoverFeeData[]
  cover_image?: string
  crm_type?: EdbBranchCRMType
  cust_keywords?: string
  delivery_area?: BranchDeliveryAreaData[]
  dunqian?: BdunqianFields
  eatigo?: BeatigoFields
  eatigo_tag?: BranchEatigoTagData[]
  ebisol?: BebisolFields
  ezding?: BezdingFields
  fb_intro?: string
  fb_need_to_know?: string
  free_cover?: BfreecoverFields[]
  funbook?: BfunbookFields
  google_place_id?: string
  ind_type?: BranchIndTypeData[]
  info?: string
  inline?: BinlineFields
  intro?: string
  lat?: number
  lng?: number
  location_detail?: string
  merchant?: BranchMerchantData[]
  name?: string
  nearby_spot?: string
  need_to_know?: string
  notice_note?: string
  notice_phone?: string
  ocard?: BocardFields
  opening_hours?: string
  own_page?: BownpageFields
  partner?: BpartnerFields[]
  payment?: BranchpaymentFields
  platform?: EdbBranchPlatform
  pms_type?: EdbBranchPMSType
  prop?: BranchpropFields
  rating?: number
  region?: BranchRegionData[]
  remark?: BranchRemarkData[]
  rwg?: BrwgFields
  setting?: BsettingFields
  shalom?: BshalomFields
  status?: EdbBranchStatus
  tableapp?: BtableappFields
  trans?: BranchTransData[]
  transportation?: string
  type?: EdbBranchType
  wise?: BwiseFields
  zip?: string
}

export interface AdminInvoiceListResponse {
  auth_id?: string
  auth_type?: number
  bid?: number
  bonus?: number
  booking_time?: string
  branch_name?: string
  contact_phone?: string
  created_at?: string
  currency_code?: string
  customer_email?: string
  discount?: number
  email?: string
  fnc_rate?: number
  id?: number
  invoice_date?: string
  invoice_number?: string
  nickname?: string
  orders_amount?: number
  orders_created_at?: string
  orders_id?: number
  product_id?: number
  product_name?: string
  redeem_code?: string
  region_id?: number
  region_name?: string
  rtn_code?: string
  rtn_msg?: string
  sales_amount?: number
  status?: number
  trade_time?: string
  txn_amount?: number
  txn_currency_code?: string
  txn_pay_amount?: number
  tz_location?: string
  uid?: number
  unhide_bid?: number
  unhide_orders_id?: number
  unhide_pid?: number
  unhide_uid?: number
  updated_at?: string
}

export interface AdminInvoiceResponse {
  auth_id?: string
  auth_type?: number
  bid?: number
  bonus?: number
  booking_time?: string
  branch_name?: string
  carruer_num?: string
  carruer_type?: string
  clearance_mark?: string
  contact_phone?: string
  created_at?: string
  currency_code?: string
  customer_addr?: string
  customer_email?: string
  customer_id?: string
  customer_identifier?: string
  customer_name?: string
  customer_phone?: string
  discount?: number
  donation?: string
  email?: string
  fnc_rate?: number
  id?: number
  inv_create_date?: string
  inv_type?: string
  invalid_reason?: string
  invoice_date?: string
  invoice_number?: string
  invoice_remark?: string
  item_amount?: string
  item_count?: string
  item_name?: string
  item_price?: string
  item_tax_type?: string
  item_word?: string
  love_code?: string
  merchant_id?: string
  nickname?: string
  orders_amount?: number
  orders_created_at?: string
  orders_id?: number
  print?: string
  product_id?: number
  product_name?: string
  random_number?: string
  redeem_code?: string
  region_id?: number
  region_name?: string
  relate_number?: string
  rtn_code?: string
  rtn_msg?: string
  sales_amount?: number
  status?: EdbInvoiceStatus
  tax_type?: string
  timestamp?: number
  trade_time?: string
  txn_amount?: number
  txn_currency_code?: string
  txn_pay_amount?: number
  tz_location?: string
  uid?: number
  unhide_bid?: number
  unhide_orders_id?: number
  unhide_pid?: number
  unhide_uid?: number
  updated_at?: string
  vat?: string
}

export interface AdminProductRemarkData {
  id?: number
  is_required?: EdbRemarkIsRequired
  name?: string
  remark_type?: EdbProductRemarkType
  title?: string
}

export interface AdminProductTagData {
  group_id?: number
  group_name?: string
  id?: number
  name?: string
  /** For setting ProductToTag.TagType */
  tag_type?: number
  /** For getting ProductTagGroup.Type */
  type?: EdbProductTagType
}

export interface AdminSoaInvoiceListResponse {
  balance?: number
  bid?: number
  branch_name?: string
  brand_id?: number
  created_at?: string
  date?: string
  doc_id?: number
  invoice_id?: number
  invoice_type?: EdbSOAInvoiceType
  is_email_sent?: number
  number?: string
  region_id?: number
  region_name?: string
  statement_id?: number
  status?: EdbSOADocumentStatus
  total_amount?: number
  type?: EdbSOADocumentType
  unhide_bid?: number
  updated_at?: string
}

export interface AdminSoaInvoiceResponse {
  amount?: number
  attach?: DocattachFields[]
  balance?: number
  bid?: number
  branch_name?: string
  created_at?: string
  date?: string
  doc_id?: number
  due_date?: string
  due_date_type?: EdbSOADocumentDueDateType
  invoice_id?: number
  invoice_type?: EdbSOAInvoiceType
  item?: DocitemFields[]
  legal_entity_name?: string
  memo?: string
  number?: string
  region_id?: number
  region_name?: string
  remark?: string
  statement_id?: number
  status?: EdbSOADocumentStatus
  subject?: string
  total_amount?: number
  type?: EdbSOADocumentType
  updated_at?: string
  vat_amount?: number
  vat_apply_type?: EdbSOADocumentVATApplyType
  vat_number?: string
  vat_type?: EdbBranchVATType
}

export interface CommonResourceResource {
  is_binding?: boolean
  name?: string
  rid?: number
}

export interface EatigoBranchHeadcountInfo {
  default?: number
  headcount?: number[]
}

export interface EatigoBranchSlot {
  discount?: number
  slot?: string
}

export interface EatigoBranchTag {
  id?: number
  name?: string
}

export interface EatigoCurrencyCurrencyData {
  code?: string
  decimal_precision?: number
  ex_rate_buy?: number
  ex_rate_sell?: number
  fnc_rate?: number
  id?: EdbCurrencyID
  member_setting_name?: string
  min_txn_amount?: number
  symbol?: string
}

export interface EatigoFilterFilterRes {
  components?: SfilterComponent[]
  currency_id?: number
  /** day mode (0: 7 days, 1: 30 days, 2: 180 days, 3: 60 days, 4: 90 days, 5: 365 days) */
  day_mode?: EdbCategoryDayMode
  filter_tags?: FilterFilterTag[]
  /** max count of show more button */
  max_count?: number
}

export interface EatigoMemberInfoData {
  /** Account */
  account?: string
  account_list?: FmemberAccountDetail[]
  /** 0: Email, 1: Google, 2: FB, 3: Guest, 6: Apple */
  auth_type?: EdbMemberAuthType
  /** member is able to edit Birthday or not (0: not editable, 1: editable) */
  bhd_editable?: 0 | 1
  /**
   * birthday date
   * @example "2006-01-02"
   */
  birthday?: string | null
  currency_id?: number | null
  email?: string | null
  first_name?: string
  /** null: Unknown, 0: Female, 1: Male, 2: Other, 3: Not specified */
  gender?: 0 | 1 | 2 | 3 | null
  /**
   * code for invite
   * @example "YMT9UA"
   */
  invite_code?: string | null
  /** 0:enable push, 1:disable push */
  is_disabled_push?: boolean
  is_phone_verified?: boolean
  last_name?: string
  phone?: string | null
  region_id?: number | null
  uid?: number
  unread_msg_cnt?: number
}

export interface EatigoOrderAnswer {
  content?: string
  sub_id?: number
}

export type EatigoOrderCancelReq = object

export interface EatigoOrderCreateOrderReq {
  booking_time: string
  check_duplicate?: boolean
  discount: number
  headcount: number
  member_note?: string
  pid: number
  promo_code_list_id?: number
  referer?: string
  remarks?: EatigoOrderRemark[]
}

export interface EatigoOrderCreateOrderResData {
  id?: number
}

export interface EatigoOrderEditReq {
  /** @min 0 */
  discount?: number
  /** @min 0 */
  headcount?: number
  slot?: string
}

export interface EatigoOrderListData {
  bid?: number
  booking_time?: string
  branch_name?: string
  cover_image?: string
  discount?: number
  display_status?: number
  headcount?: number
  id?: number
  product_id?: number
  region_id?: number
}

export interface EatigoOrderOrderData {
  address?: string
  area?: string
  avg_price_range?: number
  bid?: number
  booking_time?: string
  branch_name?: string
  branch_phone?: string
  branch_region_name?: string
  cancelable?: boolean
  comment_count?: number
  comment_reward?: number
  comment_reward_amount?: string
  cover_image?: string
  created_at?: string
  cuisine_tags?: OrderTag[]
  currency_id?: EdbCurrencyID
  discount?: number
  display_status?: number
  google_place_id?: string
  headcount?: number
  id?: number
  is_rescheduled?: boolean
  lat?: number
  lng?: number
  location_detail?: string
  member_note?: string
  need_to_know?: string
  no_show_reply_token?: string
  notices?: string
  ori_booking_time?: string
  ori_discount?: number
  ori_headcount?: number
  product_id?: number
  promos?: OrderOrderEatigoPromo[]
  rating?: number
  redeem_code?: string
  region_id?: number
  remarks?: GithubComZoeknowFunnowGoModuleOrderDomainRemark[]
  reschedule_status?: ServicesRescheduleStatus
  review_content?: string
  review_create_time?: string
  review_rating?: number
  review_tags?: OrderReviewTag[]
  reviewer_name?: string
  symbol?: string
  symbol_code?: string
  tags?: OrderTag[]
  timezone?: string
}

export interface EatigoOrderOrderDetailResponse {
  code?: number
  data?: EatigoOrderOrderData
  message?: string
}

export interface EatigoOrderRemark {
  answers?: EatigoOrderAnswer[]
  remark_id?: number
}

export interface EatigoProductTagData {
  id?: number
  name?: string
}

export interface EatigoReviewReviewData {
  comment_time?: string
  description?: string
  id?: number
  is_like?: boolean
  like_count?: number
  rated_by?: string
  rating?: number
  tags?: ReviewReviewTag[]
}

export interface EatigoSearchKeywordsResp {
  code?: number
  data?: SearchKeywordData
  message?: string
}

export interface FunbookReservationReservationData {
  booking_time?: string
  branch?: FunbookReservationBranch
  cust_num?: number
  cust_title?: string
  duration?: number
  email?: string
  gender?: number
  id?: number
  link?: string
  link_status?: EdbReservationLinkStatus
  nickname?: string
  note?: string
  phone?: string
  product?: FunbookReservationProduct
  source?: EdbReservationSource
  status?: EdbReservationStatus
}

export interface FunbookReservationReservationDetailRes {
  code?: number
  data?: FunbookReservationReservationData
  message?: string
}

export interface FunnowBlogListResponse {
  code?: number
  data?: BlogListDetail[]
  message?: string
  total_count?: number
}

export interface FunnowBranchHeadcountInfo {
  default?: number
  headcount?: number[]
}

export interface FunnowBranchTag {
  id?: number
  name?: string
}

export interface FunnowCurrencyCurrencyData {
  code?: string
  decimal_precision?: number
  ex_rate_buy?: number
  ex_rate_sell?: number
  fnc_rate?: number
  id?: number
  member_setting_name?: string
  min_txn_amount?: number
  symbol?: string
}

export interface FunnowFilterFilterRes {
  category_filter?: SfilterCategoryFilter
  components?: SfilterComponent[]
  currency_id?: number
  /** day mode (0: 7 days, 1: 30 days, 2: 180 days, 3: 60 days, 4: 90 days, 5: 365 days) */
  day_mode?: EdbCategoryDayMode
  filter_tags?: SfilterFilterTag[]
  /** max count of show more button */
  max_count?: number
}

export interface FunnowMemberListFavoriteRes {
  code?: number
  data?: FunnowMemberListFavoriteData[]
  message?: string
  next_page_token?: string
  total_count?: number
}

export interface FunnowMemberListFavoriteData {
  /** address except city and township */
  address?: string
  /** city and township address */
  area?: string
  bid?: number
  cover_image?: string
  /** branch status (0: enabled, 1: disabled) */
  disabled?: 0 | 1
  /** distance in km */
  distance?: string
  /** compatible for old image field */
  image?: string
  name?: string
  number_of_comments?: number
  /**
   * @min 0
   * @max 5
   * @example 4.5
   */
  rating?: number
}

export interface FunnowOrderOrderData {
  additions?: ForderAddition[]
  address?: string
  area?: string
  attendee_expiry_time?: string
  attendee_status?: SattendeeDisplayStatus
  bonus?: number
  bonus_discount?: number
  booking_time?: string
  branch_cover_image?: string
  branch_id?: number
  branch_link?: string
  branch_name?: string
  branch_phone?: string
  can_group?: boolean
  cancel_date?: string
  cancel_reschedule_text?: string
  cancel_rules?: string[]
  cancelable?: boolean
  cert?: ForderCert
  checkout_time?: string
  comment_count?: number
  comment_reward?: number
  comment_reward_amount?: string
  /** customer phone */
  contact_phone?: string
  cover_image?: string
  created_at?: string
  cross_day_prices?: ForderCrossDayPrice[]
  cs_channel?: string
  currency_id?: number
  delivery?: OrderDelivery
  display_status?: number
  domain_prefix?: string
  duration?: number
  duration_text?: string
  end_time_text?: string
  form_link?: string
  fun_rewards_per?: number
  gift_status?: number
  has_paid?: boolean
  head_order_cust_num?: number
  head_order_member_note?: string
  head_order_remarks?: GithubComZoeknowFunnowGoModuleOrderDomainRemark[]
  headcount_changeable?: boolean
  id?: number
  is_cust_num?: boolean
  is_defer_payment?: boolean
  is_gift?: boolean
  is_paid_onsite?: boolean
  is_payment_expired?: boolean
  is_vip?: boolean
  lat?: number
  lng?: number
  member_level?: number
  member_level_name?: string
  member_note?: string
  member_reward?: string
  movie?: OrderMovie
  need_to_know?: string
  occupation_count?: number
  orders_group_detail?: OrderOrdersGroupDetail
  orders_group_role?: EdbOrdersGroupRole
  ori_headcount?: number
  original_checkout?: string
  original_end_time_text?: string
  original_time?: string
  owner_id?: number
  product?: ForderProductInfos
  product_album_image?: string
  product_detail_link?: string
  /** Deprecated */
  promo?: SpromoPromo
  promos?: ForderOrdersDetailPromo[]
  rating?: number
  redeem_code?: string
  region_id?: number
  remarks?: GithubComZoeknowFunnowGoModuleOrderDomainRemark[]
  reschedulable?: boolean
  reschedule_rules?: string[]
  reschedule_status?: ServicesRescheduleStatus
  return_bonus?: number
  review_content?: string
  review_create_time?: string
  review_rating?: number
  review_tags?: SreviewReviewTag[]
  sales_mode?: EdbSalesMode
  short_link?: string
  show_refund?: boolean
  source?: EdbOrdersSource
  status?: EdbOrdersStatus
  symbol?: string
  symbol_code?: string
  timezone?: string
  total_amount?: number
  txn_amount?: number
  txn_info?: ForderTxnInfo
  type?: SorderUserOrderType
  /** Deprecated */
  upgrade?: ForderOrdersUpgrade
  upgrades?: ForderOrdersUpgrade[]
  used_time?: string
  vip_reward?: string
  zip?: string
}

export interface FunnowOrderOrderDetailResponse {
  code?: number
  data?: FunnowOrderOrderData
  message?: string
}

export interface FunnowOrderOrderStatusData {
  orderId?: number
  orderStatus?: EdbOrdersStatus
}

export interface FunnowProductAddUpgData {
  additions?: ProductAddUpgAdditionData[]
  /** if true, allow selecting more than or equal to one quantity for a single option. */
  allow_multi?: boolean
  currency_id?: EdbCurrencyID
  is_cust_num?: boolean
  /**
   * 0: None
   * 1: limit_min exists
   * 2: limit_max exists
   * 3: limit_min + limit_max exists
   */
  limit_flag?: FproductLimitFlag
  limit_max?: number
  limit_min?: number
  /**
   * When is_cust_num is false, ensure sufficient inventory while satisfying formulas A and B:
   *
   * Case: "No upgrade options available":
   * Formula A: Sum of quantities for the main product <= stock
   * Formula B: data.resource_use * quantity of main product <= max_sum
   *
   * Case: "Upgrade options available":
   * Formula A: Sum of quantities for all upgrades <= stock
   * Formula B: (data.upgrades[0].resource_use * quantity of upgrade[0]) + (data.upgrades[1].resource_use * quantity of upgrade[1]) … <= max_sum
   */
  max_sum?: number
  resource_use?: number
  /**
   * When is_cust_num is true, ensure sufficient inventory through the following formulas:
   *
   * Case "No upgrade options available":
   * Formula: Sum of quantities for the main product <= stock
   *
   * Case: "Upgrade options available":
   * Formula: Sum of quantities for all upgrades <= stock
   */
  stock?: number
  sub_item_max_sum?: number
  sub_item_min_sum?: number
  sub_items?: FproductAddUpgSubItemData[]
  symbol?: string
  symbol_code?: string
  /**
   * 0: Single.
   * 1: Multiple. Allow using checkboxes for multi-select operations.
   */
  upgrade_mode?: EdbProductUpgradeMode
  upgrades?: FproductAddUpgUpgradeData[]
}

export interface FunnowProductArrivalTimeInfo {
  checkout_time?: string
  currency_id?: number
  data?: FunnowProductSellTime[]
  end_day?: string
  max_price?: number
  min_price?: number
  remain_mode?: EdbProductRemainMode
  remain_reference?: number
  resource_use?: number
  rrp?: number
  selldates?: SselldateSelldates
  srrp?: number
  start_day?: string
  symbol?: string
  symbol_code?: string
  time_mode?: EdbProductTimeMode
  timezone?: string
}

export interface FunnowProductDelivery {
  delivery_fee?: number
  delivery_fee_type?: EdbBranchDeliveryFeeType
  delivery_free_amount?: number
}

export interface FunnowProductGatewayData {
  apple_pay_merchant_id?: string
  fb_promo_code_type?: EdbFunBookPromoCodeType
  gateway_type?: EdbGatewayType
  google_pay_merchant_id?: string
  issue_invoice?: boolean
  methods?: string[]
  payment_methods?: FproductPaymentMethodData[]
  publishable_key?: string
}

export interface FunnowProductListData {
  address?: string
  /** city and township address */
  area?: string
  bid?: number
  branch_name?: string
  /** ProductTag ID when TagType is Unique(5) */
  category_id?: number
  /** ProductTag name when TagType is Unique(5) */
  category_name?: string
  cover_image?: string
  /**
   * currency code following ISO 4217 such as TWD, USD
   * @example "TWD"
   */
  currency_code?: string
  /** currency serial number in DB */
  currency_id?: number
  /**
   * currency symbol such as $, €, £
   * @example "$"
   */
  currency_symbol?: string
  /** discount price */
  discount?: number
  discount_info?: FproductListDataDiscountInfo
  /** discount ratio = (SRRP/RRP)*100 */
  discount_ratio?: number
  /** distance in km */
  distance?: string
  /**
   * flash time of product
   * @example "2023-05-04 17:15:00"
   */
  flash_time?: string
  /** sell time type depend on flash time */
  flash_time_type?: 'hidden' | 'now' | 'hour' | 'day'
  /** product has Elite right tag or not */
  is_elite?: boolean
  /** product is favorite or not */
  is_favorite?: boolean
  /** product contains hot tag or not */
  is_hot?: boolean
  /** product is a limited time release or not */
  is_limited?: boolean
  /** product is created within 45 days or not */
  is_new_product?: boolean
  /** is bookable or not within 1 hour */
  is_now?: boolean
  /** product has vip tag or not */
  is_vip_tag?: boolean
  lat?: number
  lng?: number
  /** number of comments */
  number_of_comments?: number
  /** is bookable or not within a selectable time range */
  onboard?: 0 | 1
  product_id?: number
  product_name?: string
  /**
   * rating score (product or branch)
   * @min 0
   * @max 5
   * @example 4.7
   */
  rating?: number
  /** recommended retail price */
  rrp?: number
  /** sales mode of product */
  sales_mode?: EdbSalesMode
  /**
   * sell time of product
   * @example "2023-05-04 17:15:00"
   */
  sell_time?: string
  /** sell time type depend on sell time */
  sell_time_type?: 'hidden' | 'now' | 'hour' | 'day'
  /**
   * recent 3 sell times of product
   * @example ["2023-05-04 17:15:00"]
   */
  sell_times?: string[]
  /** special recommended retail price */
  srrp?: number
  /**
   * currency symbol such as $, €, £
   * @example "$"
   */
  symbol?: string
  /**
   * currency code following ISO 4217 such as TWD, USD
   * @example "TWD"
   */
  symbol_code?: string
  /** normal tags */
  tags?: FproductTagsResponse[]
}

export interface FunnowProductProductDetailResponse {
  code?: number
  data?: FunnowProductProductResponse
  message?: string
}

export interface FunnowProductProductResponse {
  address?: string
  allow_multi?: EdbProductAllowMulti
  area?: string
  bid?: number
  blogs?: FproductBlogResponse[]
  branch_link?: string
  branch_name?: string
  buffer_time?: number
  can_group?: boolean
  cancel_rules?: string[]
  category_id?: number
  category_name?: string
  checkout?: string
  cover_image?: string
  currency_id?: number
  delivery?: FunnowProductDelivery
  description?: string
  discount?: number
  discount_plan?: ProductDiscountPlan
  distance?: string
  duration?: number
  duration_text?: string
  facilities?: FproductFacilitiesResponse[]
  fbrrp?: number
  flash_time?: string
  flash_time_type?: SerializerSellTimeType
  funbook_ver?: EdbFunBookVer
  giftable?: boolean
  has_detail?: boolean
  images?: string[]
  intro?: string
  is_book_seat_only?: boolean
  is_crossday?: EdbProductIsCrossDay
  is_cust_num?: EdbProductIsCustNum
  is_elite?: boolean
  is_favorite?: boolean
  is_favorite_branch?: boolean
  is_limited?: boolean
  is_paid_onsite?: boolean
  lat?: number
  limit_max?: number
  limit_min?: number
  list_type?: EdbProductListType
  lng?: number
  member_reward?: string
  nearby_spot?: string
  need_to_know?: string
  notices?: string
  onboard?: number
  opening_hours?: string
  partner_cancel_rules?: string[]
  pid?: number
  prepare_time?: number
  price_list?: FproductPriceResponse[]
  product_name?: string
  rating?: number
  region_id?: number
  reschedule_rules?: string[]
  resource_use?: number
  rrp?: number
  sales_mode?: EdbSalesMode
  srrp?: number
  static_link?: string
  sub_item_type?: EdbProductSubItemType
  support_types?: EdbProductShowOn[]
  symbol?: string
  symbol_code?: string
  tags?: FproductTagsResponse[]
  time_mode?: EdbProductTimeMode
  transportation?: string
  vip_reward?: string
}

export interface FunnowProductSellTime {
  discount?: number
  promostart?: string
  remain_count?: number
}

export interface FunnowRegionPopularData {
  /** city and township address */
  area?: string
  bid?: number
  branch_name?: string
  /** ProductTag ID when TagType is Unique(5) */
  category_id?: number
  /** ProductTag name when TagType is Unique(5) */
  category_name?: string
  cover_image?: string
  /**
   * currency code following ISO 4217 such as TWD, USD
   * @example "TWD"
   */
  currency_code?: string
  /** currency serial number in DB */
  currency_id?: number
  /**
   * currency symbol such as $, €, £
   * @example "$"
   */
  currency_symbol?: string
  /** discount price */
  discount?: number
  /** distance in km */
  distance?: number
  /** product has Elite right tag or not */
  is_elite?: boolean
  /** product is favorite or not */
  is_favorite?: boolean
  /** product contains hot tag or not */
  is_hot?: boolean
  /** product is a limited time release or not */
  is_limited?: boolean
  /** product is created within 45 days or not */
  is_new_product?: boolean
  /** is bookable or not within 1 hour */
  is_now?: boolean
  /** product has vip tag or not */
  is_vip_tag?: boolean
  /** number of comments */
  number_of_comments?: number
  /** is bookable or not within a selectable time range */
  onboard?: 0 | 1
  /** score of popular */
  popular?: number
  product_id?: number
  product_name?: string
  /**
   * rating score (product or branch)
   * @min 0
   * @max 5
   */
  rating?: number
  /** recommended retail price */
  rrp?: number
  /** sell time of product */
  sell_time?: string
  /** sell time type depend on sell time */
  sell_time_type?: 'hidden' | 'now' | 'hour' | 'day'
  /** normal tags */
  tags?: FregionTagResData[]
}

export interface FunnowRegionPopularRes {
  code?: number
  data?: FunnowRegionPopularData[]
  message?: string
  timezone?: string
  total_count?: number
}

export interface FunnowRegionRegionInfoRes {
  banner?: FregionBanner[]
  category?: ScategoryRegionCategory[]
  currency_id?: number
  discount?: RegionSpecificCategoryRes
  event_news?: SeventnewsRegionEventNews[]
  greetings?: string
  id?: number
  keywords?: string[]
  /** description for mate-data */
  meta_description?: string
  name?: string
  onboard?: EdbRegionOnBoard
  recent?: RegionSpecificCategoryRes
  /** Obsolete, keep for web search */
  tag?: any[]
  theme?: ScategoryRegionCategory[]
  web_greetings?: string
  web_image?: string
  web_subtitle?: string
}

export interface FunnowReservationReservationData {
  booking_time?: string
  branch?: FunbookReservationBranch
  branch_link?: string
  cancel_time?: string
  created_at?: string
  cust_num?: number
  email?: string
  end_time_text?: string
  gender?: number
  id?: number
  nickname?: string
  note?: string
  phone?: string
  product?: FunbookReservationProduct
  reservation_link?: string
  source?: EdbReservationSource
  status?: EdbReservationStatus
  timezone?: string
}

export interface FunnowReservationReservationDetailRes {
  code?: number
  data?: FunnowReservationReservationData
  message?: string
}

export interface FunnowReviewReviewData {
  id?: number
  is_vip?: boolean
  like_count?: number
  liked?: boolean
  member_level?: number
  rate_time?: string
  rated_by?: string
  rating?: number
  rating_description?: string
  tags?: ReviewReviewTagData[]
}

export interface FunnowSearchKeywordsResp {
  code?: number
  data?: string[]
  message?: string
  total_count?: number
}

export interface ManagerBranchSlot {
  end?: string
  id?: number
  order_type?: EmongoOrderType
  remark?: string
  start?: string
  type?: SoccupationOccupationType
}

export interface ManagerOrderMainProduct {
  /** @example 3122936102775 */
  pid: number
  /** @example 1 */
  quantity?: number
}

export interface ManagerOrderOrdersRemark {
  /** @example "i am content" */
  content: string
  /** @example 1 */
  remark_id: number
  /** @example 1 */
  sub_id?: number
}

export interface ManagerProductRemarkData {
  error_msg?: string
  id?: number
  opt?: ProductRemarkOption[]
  opt_default_idx?: number
  regex?: string
  title?: string
  type?: EdbProductRemarkType
}

export interface NicedayFilterFilterRes {
  category_filter?: SfilterCategoryFilter
  components?: SfilterComponent[]
  currency_id?: number
  /** day mode (0: 7 days, 1: 30 days, 2: 180 days, 3: 60 days, 4: 90 days, 5: 365 days) */
  day_mode?: EdbCategoryDayMode
  filter_tags?: SfilterFilterTag[]
  /** max count of show more button */
  max_count?: number
}

export interface NicedayMemberInfoData {
  /** Account */
  account?: string
  /** 0: Email, 1: Google, 2: FB, 3: Guest, 4: LINE */
  auth_type?: EdbMemberAuthType
  benefit?: FmemberBenefit
  /** member is able to edit Birthday or not (0: not editable, 1: editable) */
  bhd_editable?: 0 | 1
  /**
   * birthday date
   * @example "2006-01-02"
   */
  birthday?: string
  /** amount of FunCoin */
  bonus?: number
  /** expired date of FunCoin */
  bonus_valid_date?: string
  /** company name for tax */
  company_name?: string
  currency?: number
  email?: string
  /** FreshChat restore ID */
  fc_restore_id?: string
  /** null: Unknown, 0: Female, 1: Male, 2: Other */
  gender?: 0 | 1 | 2
  /**
   * code for invite
   * @example "YMT9UA"
   */
  invite_code?: string
  /** Name */
  name?: string
  /** Nickname */
  nickname?: string
  phone?: string
  /** receipt company name for tax */
  receipt_company?: string
  tax_id?: string
  uid?: number
}

export interface NicedayMemberListFavoriteRes {
  code?: number
  data?: NicedayMemberListFavoriteData[]
  message?: string
  next_page_token?: string
  total_count?: number
}

export interface NicedayMemberListFavoriteData {
  bid?: number
  cover_image?: string
  /** branch status (0: enabled, 1: disabled) */
  disabled?: 0 | 1
  name?: string
  sale_region_id?: number
}

export interface NicedayOrderAnswer {
  content?: string
  sub_id?: number
}

export interface NicedayOrderCreateOrderReq {
  additions?: OrderAddition[]
  bookingtime: string
  check_duplicate?: boolean
  checkoutday?: string
  email?: string
  member_note?: string
  product?: NicedayOrderMainProduct
  referer?: string
  remarks?: NicedayOrderRemark[]
  sale_region_id?: number
  tax_info?: ForderCompanyTax
  upgrades?: NicedayOrderUpgrade[]
}

export interface NicedayOrderCreateOrderResData {
  id?: number
}

export interface NicedayOrderListData {
  /**
   * 0: None
   * 1: Unfinished
   * 2: Finished
   */
  attendee_status?: SattendeeDisplayStatus
  booking_time?: string
  branch_name?: string
  cover_image?: string
  /**
   * 0: GeneralSuccess
   * 2: Redeemed
   * 5: CertificationRedeemed
   * 9: Reviewed
   * 10: GeneralCancelled
   */
  display_status?: number
  end_time_text?: string
  has_cert?: boolean
  id?: number
  product_name?: string
  review_bonus?: number
  reviewed?: boolean
  sales_mode?: number
  /**
   * 0: Created
   * 1: Transaction
   * 2: Complete
   * 3: Failed
   * 4: Cancel
   * 5: Release
   * 6: FullRefund
   * 7: PartialRefund
   * 8: Transferred
   * 9: AppFullRefund
   * 10: AppPartialRefund
   * 11: PaymentTimeout
   */
  status?: EdbOrdersStatus
  timezone?: string
  used_time?: string
}

export interface NicedayOrderMainProduct {
  count?: number
  pid: number
  sub_items?: OrderSubItem[][]
}

export interface NicedayOrderOrderStatusData {
  status?: EdbOrdersStatus
}

export interface NicedayOrderRemark {
  answers?: NicedayOrderAnswer[]
  remark_id?: number
}

export interface NicedayOrderUpgrade {
  quantity?: number
  upgrade_id?: number
}

export interface NicedayProductAddUpgData {
  additions?: FproductAddUpgAdditionData[]
  /** if true, allow selecting more than or equal to one quantity for a single option. */
  allow_multi?: boolean
  currency_id?: EdbCurrencyID
  is_cust_num?: boolean
  /**
   * 0: None
   * 1: limit_min exists
   * 2: limit_max exists
   * 3: limit_min + limit_max exists
   */
  limit_flag?: FproductLimitFlag
  limit_max?: number
  limit_min?: number
  /**
   * When is_cust_num is false, ensure sufficient inventory while satisfying formulas A and B:
   *
   * Case: "No upgrade options available":
   * Formula A: Sum of quantities for the main product <= stock
   * Formula B: data.resource_use * quantity of main product <= max_sum
   *
   * Case: "Upgrade options available":
   * Formula A: Sum of quantities for all upgrades <= stock
   * Formula B: (data.upgrades[0].resource_use * quantity of upgrade[0]) + (data.upgrades[1].resource_use * quantity of upgrade[1]) … <= max_sum
   */
  max_sum?: number
  origin_curr_info?: ProductAddUpgOriginCurrInfo
  resource_use?: number
  /**
   * When is_cust_num is true, ensure sufficient inventory through the following formulas:
   *
   * Case "No upgrade options available":
   * Formula: Sum of quantities for the main product <= stock
   *
   * Case: "Upgrade options available":
   * Formula: Sum of quantities for all upgrades <= stock
   */
  stock?: number
  sub_item_max_sum?: number
  sub_item_min_sum?: number
  sub_items?: FproductAddUpgSubItemData[]
  symbol?: string
  symbol_code?: string
  /**
   * 0: Single.
   * 1: Multiple. Allow using checkboxes for multi-select operations.
   */
  upgrade_mode?: EdbProductUpgradeMode
  upgrades?: FproductAddUpgUpgradeData[]
}

export interface NicedayProductGatewayData {
  issue_invoice?: boolean
  payment_methods?: FproductPaymentMethodData[]
}

export interface NicedayProductListData {
  bid?: number
  branch_name?: string
  /** ProductTag ID when TagType is Unique(5) */
  category_id?: number
  /** ProductTag name when TagType is Unique(5) */
  category_name?: string
  cover_image?: string
  /**
   * currency code following ISO 4217 such as TWD, USD
   * @example "TWD"
   */
  currency_code?: string
  /** currency serial number in DB */
  currency_id?: number
  /**
   * currency symbol such as $, €, £
   * @example "$"
   */
  currency_symbol?: string
  /** discount price */
  discount?: number
  /** product is favorite or not */
  is_favorite?: boolean
  /** product contains hot tag or not */
  is_hot?: boolean
  /** product is created within 45 days or not */
  is_new_product?: boolean
  /** is bookable or not within a selectable time range */
  onboard?: 0 | 1
  product_id?: number
  product_name?: string
  /** region name to use for product extra info */
  region_tag?: string
  /** normal tags */
  tags?: FproductTagsResponse[]
}

export interface NicedayRegionPopularData {
  bid?: number
  branch_name?: string
  /** ProductTag ID when TagType is Unique(5) */
  category_id?: number
  /** ProductTag name when TagType is Unique(5) */
  category_name?: string
  cover_image?: string
  /**
   * currency code following ISO 4217 such as TWD, USD
   * @example "TWD"
   */
  currency_code?: string
  /** currency serial number in DB */
  currency_id?: number
  /**
   * currency symbol such as $, €, £
   * @example "$"
   */
  currency_symbol?: string
  /** discount price */
  discount?: number
  /** product is favorite or not */
  is_favorite?: boolean
  /** product contains hot tag or not */
  is_hot?: boolean
  /** product is created within 45 days or not */
  is_new_product?: boolean
  /** is bookable or not within a selectable time range */
  onboard?: 0 | 1
  /** score of popular */
  popular?: number
  product_id?: number
  product_name?: string
  /** region name to use for product extra info */
  region_tag?: string
  /** normal tags */
  tags?: FregionTagResData[]
}

export interface NicedayRegionPopularRes {
  code?: number
  data?: NicedayRegionPopularData[]
  message?: string
  timezone?: string
  total_count?: number
}

export interface NicedayRegionRegionInfoRes {
  banner?: FregionBanner[]
  category?: ScategoryRegionCategory[] | null
  currency_id?: number
  event_news?: SeventnewsRegionEventNews[]
  greetings?: string
  id?: number
  keywords?: string[]
  /** description for mate-data */
  meta_description?: string
  name?: string
  onboard?: EdbRegionOnBoard
  theme?: ScategoryRegionCategory[] | null
  web_greetings?: string
  web_image?: string
  web_subtitle?: string
}

export interface NicedaySearchKeywordsResp {
  code?: number
  data?: string[]
  message?: string
  total_count?: number
}

export interface PartnerMessageNoticeData {
  /** @example "0" */
  Act?: string
  ActID?: string
  /** @example "0" */
  BonusType?: string
  BtnText?: string
  Content?: string
  Img?: string
  Subject?: string
  /** @example "0" */
  Type?: string
}

export interface PartnerOrderCancelReq {
  reason?: string
}

export interface PartnerOrderCreateOrderReq {
  additions?: SorderAdditionForCreate[]
  amount?: number
  bookingtime?: string
  bookingtime_tz?: string
  checkoutday?: string
  cust_num?: number
  discount?: number
  email?: string
  member_note?: string
  name?: string
  phone?: string
  product?: PartnerOrderMainProduct
  remarks?: PartnerOrderRemark[]
  third_party_order_id: string
  third_party_user_id: string
  upgrades?: PartnerOrderUpgrade[]
}

export interface PartnerOrderEditReq {
  booking_time?: string
  cust_num?: number
  discount?: number
  member_note?: string
}

export interface PartnerOrderMainProduct {
  count: number
  pid?: number
  restaurant_id?: number
}

export interface PartnerOrderOrderData {
  additions?: OrderOrderProduct[]
  address?: string
  area?: string
  booking_time?: string
  branch_name?: string
  branch_phone?: string
  cancel_date?: string
  cancel_reschedule_text?: string
  cancel_rules?: string[]
  cancelable?: boolean
  cover_image?: string
  created_at?: string
  currency_id?: EdbCurrencyID
  end_time_text?: string
  headcount?: number
  id?: number
  is_vip?: boolean
  lat?: number
  lng?: number
  main_status?: number
  member_note?: string
  onsite_discount?: number
  original_end_time_text?: string
  original_time?: string
  product?: OrderOrderProduct
  redeem_code?: string
  remarks?: OrderOrderRemark[]
  reschedulable?: boolean
  reschedule_rules?: string[]
  reschedule_status?: ServicesRescheduleStatus
  status?: number
  symbol?: string
  symbol_code?: string
  third_party_order_id?: string
  timezone?: string
  total_amount?: number
  txn_amount?: number
  upgrades?: OrderOrderProduct[]
  used_time?: string
  vip_reward?: string
  zip?: string
}

export interface PartnerOrderRemark {
  content?: string
  remark_id?: number
}

export interface PartnerOrderUpgrade {
  quantity?: number
  upgrade_id?: number
}

export interface PartnerProductArrivalTimeInfo {
  checkout_time?: string
  currency_id?: number
  data?: PartnerProductSellTime[]
  end_day?: string
  max_price?: number
  min_price?: number
  remain_mode?: EdbProductRemainMode
  remain_reference?: number
  resource_use?: number
  rrp?: number
  srrp?: number
  start_day?: string
  symbol?: string
  symbol_code?: string
  time_mode?: EdbProductTimeMode
  timezone?: string
}

export interface PartnerProductDelivery {
  delivery_fee?: number
  delivery_fee_type?: EdbBranchDeliveryFeeType
  delivery_free_amount?: number
}

export interface PartnerProductListData {
  address?: string
  /** city and township address */
  area?: string
  bid?: number
  branch_name?: string
  /** ProductTag ID when TagType is Unique(5) */
  category_id?: number
  /** ProductTag name when TagType is Unique(5) */
  category_name?: string
  cover_image?: string
  /**
   * currency code following ISO 4217 such as TWD, USD
   * @example "TWD"
   */
  currency_code?: string
  /** currency serial number in DB */
  currency_id?: number
  /**
   * currency symbol such as $, €, £
   * @example "$"
   */
  currency_symbol?: string
  /** discount price */
  discount?: number
  discount_info?: FproductListDataDiscountInfo
  /** discount ratio = (SRRP/RRP)*100 */
  discount_ratio?: number
  /** distance in km */
  distance?: string
  /**
   * flash time of product
   * @example "2023-05-04 17:15:00"
   */
  flash_time?: string
  /** sell time type depend on flash time */
  flash_time_type?: 'hidden' | 'now' | 'hour' | 'day'
  /** product has Elite right tag or not */
  is_elite?: boolean
  /** product is favorite or not */
  is_favorite?: boolean
  /** product contains hot tag or not */
  is_hot?: boolean
  /** product is a limited time release or not */
  is_limited?: boolean
  /** product is created within 45 days or not */
  is_new_product?: boolean
  /** is bookable or not within 1 hour */
  is_now?: boolean
  /** product has vip tag or not */
  is_vip_tag?: boolean
  lat?: number
  lng?: number
  /** number of comments */
  number_of_comments?: number
  /** is bookable or not within a selectable time range */
  onboard?: 0 | 1
  product_id?: number
  product_name?: string
  /**
   * rating score (product or branch)
   * @min 0
   * @max 5
   * @example 4.7
   */
  rating?: number
  /** recommended retail price */
  rrp?: number
  /** sales mode of product */
  sales_mode?: EdbSalesMode
  /**
   * sell time of product
   * @example "2023-05-04 17:15:00"
   */
  sell_time?: string
  /** sell time type depend on sell time */
  sell_time_type?: 'hidden' | 'now' | 'hour' | 'day'
  /**
   * recent 3 sell times of product
   * @example ["2023-05-04 17:15:00"]
   */
  sell_times?: string[]
  /** special recommended retail price */
  srrp?: number
  /**
   * currency symbol such as $, €, £
   * @example "$"
   */
  symbol?: string
  /**
   * currency code following ISO 4217 such as TWD, USD
   * @example "TWD"
   */
  symbol_code?: string
  /** normal tags */
  tags?: FproductTagsResponse[]
}

export interface PartnerProductProductDetailResponse {
  code?: number
  data?: PartnerProductProductResponse
  message?: string
}

export interface PartnerProductProductResponse {
  address?: string
  allow_multi?: EdbProductAllowMulti
  area?: string
  bid?: number
  blogs?: FproductBlogResponse[]
  branch_link?: string
  branch_name?: string
  buffer_time?: number
  cancel_rules?: string[]
  category_id?: number
  category_name?: string
  checkout?: string
  cover_image?: string
  currency_id?: number
  delivery?: PartnerProductDelivery
  description?: string
  discount?: number
  distance?: string
  duration?: number
  duration_text?: string
  facilities?: FproductFacilitiesResponse[]
  fbrrp?: number
  flash_time?: string
  flash_time_type?: SerializerSellTimeType
  funbook_ver?: EdbFunBookVer
  giftable?: boolean
  has_detail?: boolean
  images?: string[]
  intro?: string
  is_book_seat_only?: boolean
  is_crossday?: EdbProductIsCrossDay
  is_cust_num?: EdbProductIsCustNum
  is_elite?: boolean
  is_favorite?: boolean
  is_favorite_branch?: boolean
  is_limited?: boolean
  is_paid_onsite?: boolean
  lat?: number
  limit_max?: number
  limit_min?: number
  list_type?: EdbProductListType
  lng?: number
  max_cust_num?: number
  member_reward?: string
  nearby_spot?: string
  need_to_know?: string
  notices?: string
  onboard?: number
  opening_hours?: string
  partner_cancel_rules?: string[]
  pid?: number
  prepare_time?: number
  price_list?: FproductPriceResponse[]
  product_name?: string
  rating?: number
  region_id?: number
  reschedule_rules?: string[]
  resource_use?: number
  rrp?: number
  sales_mode?: EdbSalesMode
  srrp?: number
  static_link?: string
  sub_item_type?: EdbProductSubItemType
  support_types?: EdbProductShowOn[]
  symbol?: string
  symbol_code?: string
  tags?: FproductTagsResponse[]
  time_mode?: EdbProductTimeMode
  transportation?: string
  vip_reward?: string
}

export interface PartnerProductSellTime {
  discount?: number
  promostart?: string
  remain_count?: number
}

export interface PartnerSupplierOrderCancelReq {
  branchID?: number
  order_id: number
  ordersID?: number
  reason?: string
}

export interface GithubComZoeknowFunnowGoModuleOrderDomainRemark {
  content?: string
  id?: number
  sub_id?: number
  title?: string
  type?: EdbProductRemarkType
}

export interface GormDeletedAt {
  time?: string
  /** Valid is true if Time is not NULL */
  valid?: boolean
}

export interface HelpAllHelpsRes {
  code?: number
  data?: ShelpHelp[]
  message?: string
}

export interface HelpDocListResponse {
  code?: number
  data?: HelpListResponse[]
  message?: string
  total_count?: number
}

export interface HelpDocResponse {
  code?: number
  data?: HelpResponse
  message?: string
}

export interface HelpDocUpdateStatusResponse {
  code?: number
  data?: HelpUpdateStatusResponse
  message?: string
}

export interface HelpHelpInfo {
  content?: string
  hid?: number
  parent_id?: number
  title?: string
}

export interface HelpHelpSerializer {
  code?: number
  data?: HelpHelpInfo[]
  message?: string
  total_count?: number
}

export interface HelpListResponse {
  id?: number
  lang_id?: number
  parent_id?: number
  rank?: number
  status?: EdbHelpStatus
  title?: string
}

export interface HelpRequest {
  content?: string
  lang_id?: number
  parent_id?: number
  rank?: number
  status?: EdbHelpStatus
  title?: string
}

export interface HelpResponse {
  content?: string
  created_at?: string
  id?: number
  lang_id?: number
  parent_id?: number
  rank?: number
  status?: EdbHelpStatus
  title?: string
  updated_at?: string
}

export interface HelpUpdateStatusResponse {
  id?: number
  status?: EdbHelpStatus
  updated_at?: string
}

export interface HotareaListResponse {
  id?: number
  name?: string
}

export interface HotsearchCategoryData {
  id?: number
  name?: string
}

export interface HotsearchListRegionData {
  brand_id?: number
  brand_name?: string
  hotsearch_id?: number
  region_id?: number
  region_name?: string
  status?: EdbRegionStatus
}

export interface HotsearchListResponse {
  id?: number
  keyword?: string
  region?: HotsearchListRegionData[]
  trans?: HotsearchListTransData[]
}

export interface HotsearchListTransData {
  hotsearch_id?: number
  id?: number
  keyword?: string
  lang_code?: string
  lang_id?: number
  trans_status?: EdbTransStatus
}

export interface HotsearchRegionData {
  brand_id?: number
  brand_name?: string
  hot_search_type?: EdbHotSearchType
  id?: number
  name?: string
  status?: EdbRegionStatus
}

export interface HotsearchRequest {
  category?: HotsearchCategoryData[]
  keyword?: string
  region?: HotsearchRegionData[]
  search_bar_region?: HotsearchRegionData[]
  trans?: HotsearchTransData[]
}

export interface HotsearchResponse {
  category?: HotsearchCategoryData[]
  created_at?: string
  id?: number
  keyword?: string
  region?: HotsearchRegionData[]
  search_bar_region?: HotsearchRegionData[]
  trans?: HotsearchTransData[]
  updated_at?: string
}

export interface HotsearchTransData {
  id?: number
  is_for_delete?: boolean
  keyword?: string
  keyword_status?: EdbTransFieldStatus
  lang_id?: number
  status?: EdbTransStatus
}

export interface HotsearchTransListRes {
  code?: string
  hot_search_id?: number
  id?: number
  name?: string
  status?: EdbTransStatus
  trans_id?: number
}

export interface HotsearchTransReq {
  hot_search_id?: number
  keyword?: string
  keyword_status?: EdbTransFieldStatus
  lang_id?: number
  status?: EdbTransStatus
}

export interface HotsearchTransRes {
  created_at?: string
  hot_search_id?: number
  id?: number
  keyword?: string
  keyword_status?: EdbTransFieldStatus
  lang_id?: number
  status?: EdbTransStatus
  updated_at?: string
}

export interface IndustryDocListResponse {
  code?: number
  data?: IndustryListResponse[]
  message?: string
  total_count?: number
}

export interface IndustryDocResponse {
  code?: number
  data?: IndustryResponse
  message?: string
}

export interface IndustryListResponse {
  id?: number
  line_spot_category?: string
  name?: string
}

export interface IndustryRequest {
  line_spot_category?: string
  name?: string
}

export interface IndustryResponse {
  created_at?: string
  id?: number
  line_spot_category?: string
  name?: string
  updated_at?: string
}

export interface InviteInviter {
  blogger?: number
  nickname?: string
  uid?: number
}

export interface InvoiceAllowGetResponse {
  allowance_amount?: number
  allowance_notify?: string
  auth_id?: string
  auth_type?: number
  bid?: number
  bonus?: number
  branch_name?: string
  created_at?: string
  currency_code?: string
  customer_name?: string
  discount?: number
  email?: string
  fnc_rate?: number
  ia_allow_no?: string
  ia_date?: string
  ia_invoice_no?: string
  ia_remain_allowance_amt?: number
  id?: number
  invalid_reason?: string
  invoice_id?: number
  invoice_no?: string
  item_amount?: string
  item_count?: string
  item_name?: string
  item_price?: string
  item_tax_type?: string
  item_word?: string
  merchant_id?: string
  nickname?: string
  notify_mail?: string
  notify_phone?: string
  orders_amount?: number
  orders_id?: number
  product_id?: number
  product_name?: string
  redeem_code?: string
  region_id?: number
  region_name?: string
  rtn_code?: string
  rtn_msg?: string
  status?: EdbInvoiceAllowStatus
  timestamp?: number
  txn_amount?: number
  txn_currency_code?: string
  txn_pay_amount?: number
  uid?: number
  updated_at?: string
}

export interface InvoiceAllowListResponse {
  auth_id?: string
  auth_type?: number
  bid?: number
  bonus?: number
  branch_name?: string
  created_at?: string
  currency_code?: string
  discount?: number
  email?: string
  fnc_rate?: number
  id?: number
  invoice_id?: number
  invoice_no?: string
  nickname?: string
  orders_amount?: number
  orders_id?: number
  product_id?: number
  product_name?: string
  redeem_code?: string
  region_id?: number
  region_name?: string
  rtn_code?: string
  rtn_msg?: string
  status?: number
  txn_amount?: number
  txn_currency_code?: string
  txn_pay_amount?: number
  uid?: number
  updated_at?: string
}

export interface InvoiceAllowRequest {
  allowance_amount?: number
}

export interface InvoiceCreateReq {
  bid: number
  date: string
  due_date_type?: EdbSOADocumentDueDateType
  invoice?: InvoiceInvoiceData
  /** @minItems 1 */
  item: InvoiceItemData[]
  memo?: string
  remark: string
  subject: string
  vat_apply_type?: EdbSOADocumentVATApplyType
}

export interface InvoiceCreateRes {
  amount?: number
  balance?: number
  bid: number
  country_code?: string
  created_at?: string
  date: string
  due_date?: string
  due_date_type?: EdbSOADocumentDueDateType
  id?: number
  invoice?: InvoiceInvoiceData
  /** @minItems 1 */
  item: InvoiceItemData[]
  legal_entity_name?: string
  memo?: string
  mode?: EdbSOADocumentMode
  number?: string
  remark: string
  status?: EdbSOADocumentStatus
  subject: string
  total_amount?: number
  type?: EdbSOADocumentType
  updated_at?: string
  vat_amount?: number
  vat_apply_type?: EdbSOADocumentVATApplyType
  vat_number?: string
  vat_type?: EdbBranchVATType
}

export interface InvoiceDocAllowGetResponse {
  code?: number
  data?: InvoiceAllowGetResponse
  message?: string
}

export interface InvoiceDocAllowListResponse {
  code?: number
  data?: InvoiceAllowListResponse[]
  message?: string
  total_count?: number
}

export interface InvoiceDocAllowResponse {
  code?: number
  data?: SinvoiceAllowRes
  message?: string
}

export interface InvoiceDocInvalidAllowResponse {
  code?: number
  data?: InvoiceInvalidAllowResponse
  message?: string
}

export interface InvoiceDocInvalidResponse {
  code?: number
  data?: SinvoiceInvalidResponse
  message?: string
}

export interface InvoiceDocResponse {
  code?: number
  data?: AdminInvoiceResponse
  message?: string
}

export interface InvoiceInvalidAllowRequest {
  reason?: string
}

export interface InvoiceInvalidAllowResponse {
  ia_allow_no?: string
  ia_invoice_no?: string
  id?: number
  invalid_reason?: string
  rtn_code?: string
  rtn_msg?: string
  status?: EdbInvoiceAllowStatus
  updated_at?: string
}

export interface InvoiceInvoiceData {
  type?: EdbSOAInvoiceType
}

export interface InvoiceItemData {
  amount?: number
  description?: string
  id?: number
  product_type?: EdbItemProductType
  quantity?: number
  service_type?: EdbItemServiceType
  unit_price?: number
}

export interface InvoiceRequest {
  orders_id?: number
}

export interface InvoiceUpdateReq {
  memo?: string
}

export interface InvoiceUpdateRes {
  id?: number
  memo?: string
  updated_at?: string
}

export interface Ipay88BankData {
  bank_name?: string
  payment_id?: number
}

export enum LangLangID {
  LANG_ID_ZH_TW = 0,
  LANG_ID_EN_US = 1,
  LANG_ID_ZH_CN = 2,
  LANG_ID_JA_JP = 3,
  LANG_ID_MS_MY = 4,
  LANG_ID_TH_TH = 5,
  LANG_ID_ZH_HK = 6,
}

export interface LanguageDocListResponse {
  code?: number
  data?: LanguageListResponse[]
  message?: string
  total_count?: number
}

export interface LanguageDocResponse {
  code?: number
  data?: LanguageResponse
  message?: string
}

export interface LanguageListResponse {
  code?: string
  id?: number
  name?: string
  web_code?: string
}

export interface LanguageRequest {
  code?: string
  name?: string
  web_code?: string
}

export interface LanguageResponse {
  code?: string
  created_at?: string
  id?: number
  name?: string
  updated_at?: string
  web_code?: string
}

export enum ManagementAttendanceStatus {
  AttendanceStatusReserved = 'reserved',
  AttendanceStatusConfirmed = 'confirmed',
  AttendanceStatusAttended = 'attended',
  AttendanceStatusFinished = 'finished',
  AttendanceStatusNoShow = 'no-show',
}

export interface MblockFields {
  bid?: number
  branch_name?: string
  remark?: string
}

export interface MbrtagDocListResponse {
  code?: number
  data?: MbrtagListResponse[]
  message?: string
  total_count?: number
}

export interface MbrtagDocResponse {
  code?: number
  data?: MbrtagResponse
  message?: string
}

export interface MbrtagListResponse {
  id?: number
  name?: string
}

export interface MbrtagRequest {
  name?: string
}

export interface MbrtagResponse {
  created_at?: string
  id?: number
  name?: string
  updated_at?: string
}

export interface MdeletionFields {
  created_at?: string
  data_deleted_at?: string
  expired_at?: string
  id?: number
  status?: EdbMemberStatus
  verified_at?: string
}

export interface MePermData {
  routes?: ScasbinRoutePath[]
  total_count?: number
}

export interface MeResPerm {
  code?: number
  data?: MePermData
  message?: string
}

export interface MemberExistRes {
  email?: string
  valid?: boolean
}

export interface MemberFavoriteList {
  code?: number
  data?: MemberFavoriteListData
  message?: string
}

export interface MemberFavoriteListData {
  list?: any
  next_page_token?: string
  total_count?: number
}

export interface MemberFavoriteListRes {
  avg_price_range?: number
  bid?: number
  cover_image?: string
  distance?: string
  has_more_discount?: boolean
  is_favorite?: boolean
  is_hot?: boolean
  is_new?: boolean
  lat?: number
  lng?: number
  name?: string
  ratings?: string
  /** All past reservations for the branch */
  reservations?: string
}

export interface MemberListCollectionData {
  bid?: number
  branch_name?: string
  cover_image?: string
  /** 0:TWD, 1:HKD, 2:JPY, 3:MYR, 4:THB */
  currency_id?: number
  /** Whether the product is disabled or suspended */
  disabled?: number
  /** product contains hot tag or not */
  is_hot?: boolean
  /** product is created within 45 days or not */
  is_new_product?: boolean
  price?: number
  product_id?: number
  product_name?: string
  /** region name to use for product extra info */
  region_tag?: string
  sale_region_id?: number
}

export interface MemberListCollectionRes {
  code?: number
  data?: MemberListCollectionData[]
  message?: string
  next_page_token?: string
  total_count?: number
}

export interface MemberMemberBonus {
  bonus?: number
}

export interface MemberPushSetting {
  pushenabled: number
}

export interface MemberPushSettingReq {
  enable: boolean
}

export interface MemberReqSMSRes {
  expired_at?: number
}

export interface MemberSuspendedRes {
  is_suspended?: boolean
}

export interface MemberToggleHistoryReq {
  /**
   * @min 0
   * @max 1
   */
  showhistory: EdbMemberPropertyHistory
}

export interface MemberUpdateDeviceReq {
  /** App version code */
  app_version: string
  /** Device id */
  device_id: string
  /** Android gaid */
  ga_id?: string
  /** OS like ios, android, web */
  os: string
  /** iOS version, android version, web version */
  os_version: string
  /** Push token from firebase */
  push_token?: string
}

export interface MemberUpdateMemberReq {
  email: string
  first_name: string
  /** null: Unknown, 0: Female, 1: Male, 2: Other, 3: Not specified */
  gender?: 0 | 1 | 2 | 3 | null
  last_name?: string
}

export interface MemberBeInvitedData {
  invite_cnt?: number
}

export interface MemberMissionListResponse {
  brand_id?: EdbBrandID
  brand_name?: string
  id?: number
  title?: string
  type?: EdbMemberMissionType
}

export interface MemberMissionPeriodData {
  end_at?: string
  id?: number
  is_for_delete?: boolean
  start_at?: string
}

export interface MemberMissionRegionData {
  brand_id?: number
  brand_name?: string
  id?: number
  name?: string
  status?: EdbRegionStatus
}

export interface MemberMissionRequest {
  booking_times?: MemberMissionPeriodData[]
  brand_id?: EdbBrandID
  max_quantity?: number
  multiplier?: number
  region?: MemberMissionRegionData[]
  tags?: MemberMissionTagData[]
  title?: string
  trade_times?: MemberMissionPeriodData[]
  type?: EdbMemberMissionType
}

export interface MemberMissionResponse {
  booking_times?: MemberMissionPeriodData[]
  brand_id?: EdbBrandID
  created_at?: string
  id?: number
  max_quantity?: number
  multiplier?: number
  region?: MemberMissionRegionData[]
  tags?: MemberMissionTagData[]
  title?: string
  trade_times?: MemberMissionPeriodData[]
  type?: EdbMemberMissionType
  updated_at?: string
}

export interface MemberMissionTagData {
  id?: number
  name?: string
}

export interface MemberVipReceivableResp {
  code?: number
  data?: MemberVipReceivableRespData
  message?: string
}

export interface MemberVipReceivableRespData {
  receivable?: boolean
}

export interface MemberVipVIPAddResp {
  code?: number
  data?: MemberVipVIPAddRespData
  message?: string
}

export interface MemberVipVIPAddRespData {
  is_vip?: boolean
  /** @example "2006-01-02 15:04:05" */
  vip_end_time?: string
}

export interface MemberVipVIPExclusive {
  action_endpoint?: string
  action_text?: string
  action_type?: number
  content?: string
  id?: number
  img_url?: string
  is_action_active?: boolean
  sub_content?: string
  title?: string
}

export interface MemberVipVIPExclusiveListSerializer {
  code?: number
  data?: MemberVipVIPExclusive[]
  message?: string
  total_count?: number
}

export interface MenuCreateMenuItemRequest {
  /**
   * @maxItems 1
   * @minItems 0
   * @uniqueItems true
   * @example [2]
   */
  channels?: EdbProductChannel[]
  /**
   * @minLength 1
   * @example "name"
   */
  name: string
  /** @example 100 */
  price: number
  /**
   * 0: Pending, 1: Live
   * @min 0
   * @max 1
   * @default 0
   * @example 0
   */
  status?: EdbMenuItemStatus
  trans?: MenuTran[]
}

export interface MenuCreateMenuItemResponse {
  /** @example 3063362350370 */
  id: number
}

export interface MenuEditItemRankRequest {
  /**
   * menu item id
   * @uniqueItems true
   * @example [1,2,3]
   */
  mi_ids: number[]
}

export interface MenuEditMenuItemRequest {
  /**
   * @maxItems 2
   * @minItems 0
   * @uniqueItems true
   * @example [2]
   */
  channels?: EdbProductChannel[]
  /**
   * @minLength 1
   * @example "name"
   */
  name?: string
  /** @example 100 */
  price?: number
  /**
   * 0: Pending, 1: Live
   * @min 0
   * @max 1
   * @default 0
   * @example 0
   */
  status?: EdbMenuItemStatus
  trans?: MenuTran[]
}

export interface MenuItemDetailResp {
  channels?: MenuChannel[]
  id?: number
  name?: string
  /** @example 100 */
  price?: number
  /**
   * 0: Pending, 1: Live
   * @example 0
   */
  status?: EdbMenuItemStatus
  trans?: MenuTran[]
}

export interface MenuItemListResponse {
  channels?: MenuChannel[]
  id?: number
  languages?: MenuLanguage[]
  /** @example "Menu Item Name" */
  name?: string
  /** @example 100 */
  price?: number
  /**
   * 0: Pending, 1: Live
   * @example 0
   */
  status?: EdbMenuItemStatus
}

export interface MenuTran {
  /**
   * Language ID: 0: zh-TW, 1: en-US, 2: zh-CN, 3: ja-JP, 4: ms-MY, 5: th-TH
   * @example 0
   */
  id?: LangLangID
  name?: string
}

export interface MenuChannel {
  /**
   * Channel icon path
   * @example "/path/to/icon"
   */
  icon?: string
  /**
   * Channel ID
   * @example 2
   */
  id?: EdbProductChannel
  /** @example "Eatigo" */
  name?: string
}

export interface MenuLanguage {
  /**
   * Language icon path
   * @example "/path/to/icon"
   */
  icon?: string
  /**
   * Language ID: 0: zh-TW, 1: en-US, 2: zh-CN, 3: ja-JP, 4: ms-MY, 5: th-TH
   * @example 0
   */
  id?: LangLangID
}

export interface MerchantContactData {
  email?: string
  id?: number
  mobile?: string
  name?: string
  phone?: string
}

export interface MerchantDocListResponse {
  code?: number
  data?: MerchantListResponse[]
  message?: string
  total_count?: number
}

export interface MerchantDocResponse {
  code?: number
  data?: MerchantResponse
  message?: string
}

export interface MerchantListResponse {
  contact_mobile?: string
  contact_name?: string
  contact_phone?: string
  id?: number
  name?: string
  principal_name?: string
  status?: number
  unhide_id?: number
  vat_number?: string
}

export interface MerchantRequest {
  address?: string
  contact?: MerchantContactData[]
  name?: string
  note?: string
  principal_id?: string
  principal_name?: string
  status?: number
  vat_number?: string
  zip?: string
}

export interface MerchantResponse {
  address?: string
  contact?: MerchantContactData[]
  created_at?: string
  id?: number
  name?: string
  note?: string
  principal_id?: string
  principal_name?: string
  status?: number
  unhide_id?: number
  updated_at?: string
  vat_number?: string
  zip?: string
}

export interface MessageCreateNoticeRequest {
  profiles?: MessageProfile[]
}

export interface MessageCreateNoticeResponse {
  code?: number
  data?: MessageCreatedData[]
  message?: string
}

export interface MessageCreatedData {
  act?: EdbActType
  actID?: string
  bonusType?: EdbBonusType
  btnText?: string
  content?: string
  id?: number
  img?: string
  sendTime?: string
  subject?: string
  type?: EdbNoticeType
  uid?: number
}

export interface MessageDocMessageResp {
  code?: number
  data?: MessageDocNoticeData[]
  message?: string
  total_count?: number
}

export interface MessageDocNoticeData {
  act?: number
  act_id?: string
  btn_text?: string
  content?: string
  img?: string
  is_clicked?: number
  nlid?: number
  read_mark?: number
  read_time?: string
  send_time?: string
  subject?: string
}

export interface MessageDocUnreadResp {
  code?: number
  data?: MessageUnread
  message?: string
}

export interface MessageNoticeDetail {
  action_path?: string
  /** 0:none, 1:open url, 2:open app link */
  action_type?: 0 | 1 | 2
  btn_text?: string
  content?: string
  is_clickable?: boolean
  notice_list_id?: number
  read_mark?: boolean
  send_time?: string
  subject?: string
}

export interface MessageNoticeRes {
  list?: MessageNoticeDetail[]
  total_count?: number
}

export interface MessageProfile {
  email?: string
  /** @example "0" */
  identity?: string
  key_values?: PartnerMessageNoticeData
  name?: string
  objectId?: string
}

export interface MessageUnread {
  count?: number
}

export interface MissionDocMissionListResp {
  code?: number
  list?: MissionMissionData[]
  message?: string
  total_count?: number
}

export interface MissionMissionData {
  btn_text?: string
  desc?: string
  id?: number
  image?: string
  level?: number
  name?: string
  points?: number
  redeemable?: boolean
}

export interface ModAppleAuthReq {
  /** App version code */
  appVersion: string
  apple_auth_code: string
  apple_token: string
  /** Device id */
  deviceId: string
  /** Android gaid */
  gaid?: string
  /** OS like ios, android, web */
  os: string
  /** iOS version, android version, web version */
  osVersion: string
  /** Push token from firebase */
  push_token?: string
}

export interface ModEmailLoginReq {
  /** App version code */
  appVersion: string
  /** Device id */
  deviceId: string
  /** Android gaid */
  gaid?: string
  /** OS like ios, android, web */
  os: string
  /** iOS version, android version, web version */
  osVersion: string
  /** Password to login */
  password: string
  /** Push token from firebase */
  push_token?: string
  /** Username to login */
  username: string
  /** reCAPTCHA token */
  verification_token: string
}

export interface ModEmailRegisterReq {
  /** App version code */
  appVersion: string
  /** Device id */
  deviceId: string
  /** Android gaid */
  gaid?: string
  /** OS like ios, android, web */
  os: string
  /** iOS version, android version, web version */
  osVersion: string
  /** Password to login */
  password: string
  /** Push token from firebase */
  push_token?: string
  /** Username to login */
  username: string
  /** reCAPTCHA token */
  verification_token: string
}

export interface ModFacebookLoginReq {
  /** App version code */
  appVersion: string
  /** Device id */
  deviceId: string
  fbId?: string
  fbJwtNonce?: string
  fbJwtToken?: string
  fbToken?: string
  /** Android gaid */
  gaid?: string
  /** OS like ios, android, web */
  os: string
  /** iOS version, android version, web version */
  osVersion: string
  /** Push token from firebase */
  push_token?: string
}

export interface ModFacebookRegisterReq {
  /** App version code */
  appVersion: string
  birthday?: string
  /** Device id */
  deviceId: string
  email?: string
  fbId?: string
  fbJwtNonce?: string
  fbJwtToken?: string
  fbToken?: string
  firstName?: string
  /** Android gaid */
  gaid?: string
  /** null: Unknown, 0: Female, 1: Male, 2: Other, 3: Not specified */
  gender?: 0 | 1 | 2 | 3 | null
  lastName?: string
  name?: string
  /** OS like ios, android, web */
  os: string
  /** iOS version, android version, web version */
  osVersion: string
  /** Push token from firebase */
  push_token?: string
}

export interface ModGoogleLoginReq {
  access_token: string
  /** App version code */
  appVersion: string
  /** Device id */
  deviceId: string
  /** Android gaid */
  gaid?: string
  /** OS like ios, android, web */
  os: string
  /** iOS version, android version, web version */
  osVersion: string
  /** Push token from firebase */
  push_token?: string
}

export interface ModGoogleRegisterReq {
  access_token: string
  /** App version code */
  appVersion: string
  /** Device id */
  deviceId: string
  /** Android gaid */
  gaid?: string
  /** OS like ios, android, web */
  os: string
  /** iOS version, android version, web version */
  osVersion: string
  /** Push token from firebase */
  push_token?: string
}

export interface ModGuestAuthReq {
  /** App version code */
  appVersion: string
  currency?: number
  /** Device id */
  deviceId: string
  /** Android gaid */
  gaid?: string
  /** OS like ios, android, web */
  os: string
  /** iOS version, android version, web version */
  osVersion: string
  /** Push token from firebase */
  push_token?: string
  /** utm campaign */
  utm_campaign?: string
  /** utm medium */
  utm_medium?: string
  /** utm source */
  utm_source?: string
}

export enum MongoFilterOption {
  FilterOptionBookingFunNow = 'booking-funnow',
  FilterOptionBookingFunBook = 'booking-funbook',
  FilterOptionBookingFunBookPhone = 'booking-funbook-phone',
  FilterOptionBookingFunBookWalkin = 'booking-funbook-walkin',
  FilterOptionBookingEatigo = 'booking-eatigo',
  FilterOptionBookingNiceday = 'booking-niceday',
  FilterOptionBookingRwG = 'booking-rwg',
  FilterOptionBookingThirdParty = 'booking-thirdparty',
  FilterOptionConfirmedFunNow = 'confirmed-funnow',
  FilterOptionConfirmedFunBook = 'confirmed-funbook',
  FilterOptionConfirmedFunBookPhone = 'confirmed-funbook-phone',
  FilterOptionConfirmedFunBookWalkin = 'confirmed-funbook-walkin',
  FilterOptionConfirmedEatigo = 'confirmed-eatigo',
  FilterOptionConfirmedNiceday = 'confirmed-niceday',
  FilterOptionConfirmedRwG = 'confirmed-rwg',
  FilterOptionConfirmedThirdParty = 'confirmed-thirdparty',
  FilterOptionArrivedFunNow = 'arrived-funnow',
  FilterOptionArrivedFunBook = 'arrived-funbook',
  FilterOptionArrivedFunBookPhone = 'arrived-funbook-phone',
  FilterOptionArrivedFunBookWalkin = 'arrived-funbook-walkin',
  FilterOptionArrivedEatigo = 'arrived-eatigo',
  FilterOptionArrivedNiceday = 'arrived-niceday',
  FilterOptionArrivedRwG = 'arrived-rwg',
  FilterOptionArrivedThirdParty = 'arrived-thirdparty',
  FilterOptionFinishedFunNow = 'finished-funnow',
  FilterOptionFinishedFunBook = 'finished-funbook',
  FilterOptionFinishedFunBookPhone = 'finished-funbook-phone',
  FilterOptionFinishedFunBookWalkin = 'finished-funbook-walkin',
  FilterOptionFinishedEatigo = 'finished-eatigo',
  FilterOptionFinishedNiceday = 'finished-niceday',
  FilterOptionFinishedRwG = 'finished-rwg',
  FilterOptionFinishedThirdParty = 'finished-thirdparty',
  FilterOptionCanceledFunNow = 'canceled-funnow',
  FilterOptionCanceledFunBook = 'canceled-funbook',
  FilterOptionCanceledFunBookPhone = 'canceled-funbook-phone',
  FilterOptionCanceledFunBookWalkin = 'canceled-funbook-walkin',
  FilterOptionCanceledEatigo = 'canceled-eatigo',
  FilterOptionCanceledNiceday = 'canceled-niceday',
  FilterOptionCanceledRwG = 'canceled-rwg',
  FilterOptionCanceledThirdParty = 'canceled-thirdparty',
  FilterOptionNoShowFunNow = 'noshow-funnow',
  FilterOptionNoShowFunBook = 'noshow-funbook',
  FilterOptionNoShowFunBookPhone = 'noshow-funbook-phone',
  FilterOptionNoShowFunBookWalkin = 'noshow-funbook-walkin',
  FilterOptionNoShowEatigo = 'noshow-eatigo',
  FilterOptionNoShowNiceday = 'noshow-niceday',
  FilterOptionNoShowRwG = 'noshow-rwg',
  FilterOptionNoShowThirdParty = 'noshow-thirdparty',
  FilterOptionAtdFilledFunNow = 'atdFilled-funnow',
  FilterOptionAtdFilledFunBook = 'atdFilled-funbook',
  FilterOptionAtdFilledFunBookPhone = 'atdFilled-funbook-phone',
  FilterOptionAtdFilledFunBookWalkin = 'atdFilled-funbook-walkin',
  FilterOptionAtdFilledEatigo = 'atdFilled-eatigo',
  FilterOptionAtdFilledNiceday = 'atdFilled-niceday',
  FilterOptionAtdFilledRwG = 'atdFilled-rwg',
  FilterOptionAtdFilledThirdParty = 'atdFilled-thirdparty',
  FilterOptionAtdUnfilledFunNow = 'atdUnfilled-funnow',
  FilterOptionAtdUnfilledFunBook = 'atdUnfilled-funbook',
  FilterOptionAtdUnfilledFunBookPhone = 'atdUnfilled-funbook-phone',
  FilterOptionAtdUnfilledFunBookWalkin = 'atdUnfilled-funbook-walkin',
  FilterOptionAtdUnfilledEatigo = 'atdUnfilled-eatigo',
  FilterOptionAtdUnfilledNiceday = 'atdUnfilled-niceday',
  FilterOptionAtdUnfilledRwG = 'atdUnfilled-rwg',
  FilterOptionAtdUnfilledThirdParty = 'atdUnfilled-thirdparty',
}

export interface MovieBonusInfo {
  amount?: number
  discount?: number
  expired_date?: string
  max?: number
  selected?: number
  total?: number
  usable?: boolean
  use_bonus?: number
}

export interface MovieCreditPromoInfo {
  amount?: number
  card_type?: EdbCardType
  description?: string
  discount?: number
  expired_date?: string
  is_discount_movie_fee?: boolean
  max?: number
  name?: string
  notice?: string
  promo_code_list_id?: number
  remain_qty?: number
  selected?: number
  specified_txn_currency?: MovieCurrency
  usable?: boolean
}

export interface MovieCurrency {
  code?: EdbCurrencyCode
  decimal_precision?: number
  id?: EdbCurrencyID
  symbol?: string
}

export interface MovieGetGroupShowDatesResp {
  code?: number
  data?: string[]
  message?: string
  timezone?: string
}

export interface MovieGetGroupsRes {
  code?: number
  data?: MovieGroupData[]
  message?: string
  next_page_token?: string
  timezone?: string
}

export interface MovieGetMovieGroupBranchesResp {
  code?: number
  data?: MovieGroupBranchData[]
  message?: string
  timezone?: string
}

export interface MovieGetPromoData {
  bonus?: MovieBonusInfo
  credit_promos?: MovieCreditPromoInfo[]
  currency_code?: EdbCurrencyCode
  currency_id?: EdbCurrencyID
  currency_symbol?: string
  decimal_precision?: number
  normal_promos?: MovieNormalPromoInfo[]
  promos?: MovieOldPromoInfo[]
}

export interface MovieGetPromoResp {
  code?: number
  data?: MovieGetPromoData
  message?: string
  timezone?: string
}

export interface MovieGetSeatsResp {
  code?: number
  data?: SmovieSeatData
  message?: string
}

export interface MovieGetTicketsData {
  combos?: MovieTicket[]
  currency_code?: string
  currency_id?: number
  currency_symbol?: string
  decimal_precision?: number
  tickets?: MovieTicket[]
}

export interface MovieGetTicketsResp {
  code?: number
  data?: MovieGetTicketsData
  message?: string
}

export interface MovieGroupBranchData {
  bid?: number
  distance?: string
  is_pinned?: boolean
  name?: string
  sessions_of_version?: MovieSessionsOfVersion[]
}

export interface MovieGroupData {
  chinese_name?: string
  coming_soon?: boolean
  duration?: number
  english_name?: string
  grade?: EdbMovieGrade
  group_id?: number
  poster?: string
  rel_date?: string
  sessions_of_version?: MovieSessionsOfVersion[]
}

export interface MovieLockSeatReq {
  cinema_trans_id: string
  seat_tags: string[]
  session_id: number
}

export interface MovieLockSeatResp {
  code?: number
  data?: MovieSeatData
  message?: string
}

export interface MovieNormalPromoInfo {
  amount?: number
  description?: string
  discount?: number
  expired_date?: string
  is_discount_movie_fee?: boolean
  max?: number
  name?: string
  notice?: string
  promo_code_list_id?: number
  remain_qty?: number
  selected?: number
  specified_txn_currency?: MovieCurrency
  usable?: boolean
}

export interface MovieOldPromoInfo {
  amount?: number
  discount?: number
  expired_date?: string
  is_credit_limit?: boolean
  max?: number
  name?: string
  promo_code_list_id?: number
  remain_qty?: number
  selected?: number
  usable?: boolean
}

export interface MovieReleasePreLockSeatsReq {
  cinema_id: string
  cinema_trans_id: string
}

export interface MovieSeatData {
  expired_timestamp?: number
  seat_id?: number
}

export interface MovieSession {
  hall?: string
  hall_id?: number
  id?: number
  time?: string
}

export interface MovieSessionsOfVersion {
  sessions?: MovieSession[]
  version?: string
}

export interface MovieTicket {
  description?: string
  id?: number
  name?: string
  price?: number
  type?: EdbMovieTicketType
}

export interface MpropNicedayProperty {
  legacy_system_uid?: number
  name?: string
}

export interface MpropNicedayPropertyRes {
  legacy_system_uid?: number
  name?: string
}

export interface MpropProperty {
  be_invited_code?: string
  bhd_editable?: number
  birthday?: string
  blogger?: number
  bonus?: number
  bonus_date?: string
  email?: string
  first_name?: string
  gender?: number
  history?: number
  invite_code?: string
  last_name?: string
  mile?: number
  mile_date?: string
  nickname?: string
  note?: string
  phone?: string
  push?: number
}

export interface MpropPropertyRes {
  be_invited_code?: string
  bhd_editable?: number
  birthday?: string
  blogger?: number
  bonus?: number
  bonus_date?: string
  created_at?: string
  email?: string
  first_name?: string
  gender?: number
  history?: number
  invite_code?: string
  last_name?: string
  mile?: number
  mile_date?: string
  nickname?: string
  note?: string
  phone?: string
  push?: number
  updated_at?: string
}

export interface MultipartFileHeader {
  filename?: string
  header?: TextprotoMIMEHeader
  size?: number
}

export interface NewsDocListResponse {
  code?: number
  data?: NewsListResponse[]
  message?: string
  total_count?: number
}

export interface NewsDocResponse {
  code?: number
  data?: NewsResponse
  message?: string
}

export interface NewsDocTransListResponse {
  code?: number
  data?: NewsTransListResponse[]
  message?: string
  total_count?: number
}

export interface NewsDocTransResponse {
  code?: number
  data?: NewsTransResponse
  message?: string
}

export interface NewsListResponse {
  act?: number
  act_id?: string
  btn_text?: string
  end_at?: string
  id?: number
  img?: string
  start_at?: string
  subject?: string
  trans?: NewsListTransData[]
  type?: number
}

export interface NewsListTransData {
  id?: number
  lang_code?: string
  lang_id?: number
  news_id?: number
  subject?: string
  trans_status?: EdbTransStatus
}

export interface NewsNewsInfo {
  act?: number
  act_id?: string
  btn_text?: string
  content?: string
  end_at?: string
  id?: number
  img?: string
  regions?: NewsTranslatedRegion[]
  start_at?: string
  sub?: string
  type?: number
}

export interface NewsNewsListResp {
  code?: number
  data?: NewsNewsInfo[]
  message?: string
  total_count?: number
}

export interface NewsRegionData {
  id?: number
  name?: string
}

export interface NewsRequest {
  act?: number
  act_id?: string
  btn_text?: string
  content?: string
  end_at?: string
  img?: string
  region?: NewsRegionData[]
  start_at?: string
  subject?: string
  trans?: NewsTransData[]
  type?: number
}

export interface NewsResponse {
  act?: number
  act_id?: string
  btn_text?: string
  content?: string
  created_at?: string
  end_at?: string
  id?: number
  img?: string
  region?: NewsRegionData[]
  start_at?: string
  subject?: string
  trans?: NewsTransData[]
  type?: number
  updated_at?: string
}

export interface NewsTransData {
  btn_text?: string
  btn_text_status?: EdbTransFieldStatus
  content?: string
  content_status?: EdbTransFieldStatus
  id?: number
  is_for_delete?: boolean
  lang_id?: number
  status?: EdbTransStatus
  subject?: string
  subject_status?: EdbTransFieldStatus
}

export interface NewsTransListResponse {
  code?: string
  id?: number
  name?: string
  news_id?: number
  status?: EdbTransStatus
  trans_id?: number
}

export interface NewsTransRequest {
  btn_text?: string
  btn_text_status?: EdbTransFieldStatus
  content?: string
  content_status?: EdbTransFieldStatus
  lang_id?: number
  news_id?: number
  status?: EdbTransStatus
  subject?: string
  subject_status?: EdbTransFieldStatus
}

export interface NewsTransResponse {
  btn_text?: string
  btn_text_status?: EdbTransFieldStatus
  content?: string
  content_status?: EdbTransFieldStatus
  created_at?: string
  id?: number
  lang_id?: number
  news_id?: number
  status?: EdbTransStatus
  subject?: string
  subject_status?: EdbTransFieldStatus
  updated_at?: string
}

export interface NewsTranslatedRegion {
  region_id?: number
  region_name?: string
}

export interface NoteCreateByEditBookingsReq {
  bid: number
  date: string
  due_date_type?: EdbSOADocumentDueDateType
  /** @minItems 1 */
  edit_list: NoteEditBooking[]
  invoice_doc_id: number
}

export interface NoteCreateReq {
  bid: number
  date: string
  due_date_type?: EdbSOADocumentDueDateType
  invoice_doc_id?: number
  /** @minItems 1 */
  item: NoteItem[]
  memo?: string
  remark: string
  subject: string
  type?: EdbSOANoteType
  vat_apply_type?: EdbSOADocumentVATApplyType
}

export interface NoteEditBooking {
  attended?: number
  id?: number
}

export interface NoteItem {
  amount?: number
  description?: string
  product_type?: EdbItemProductType
  service_type?: EdbItemServiceType
}

export interface NoteListResponse {
  balance?: number
  bid?: number
  branch_name?: string
  brand_id?: number
  created_at?: string
  date?: string
  doc_id?: number
  invoice_doc_id?: number
  invoice_number?: string
  is_email_sent?: number
  number?: string
  region_id?: number
  region_name?: string
  status?: EdbSOADocumentStatus
  total_amount?: number
  type?: EdbSOADocumentType
  unhide_bid?: number
  updated_at?: string
}

export interface NoteResponse {
  amount?: number
  attach?: DocattachFields[]
  balance?: number
  bid?: number
  branch_name?: string
  created_at?: string
  date?: string
  doc_id?: number
  due_date?: string
  due_date_type?: EdbSOADocumentDueDateType
  invoice_doc_id?: number
  invoice_number?: string
  is_ecv?: EdbSOANoteIsECV
  item?: DocitemFields[]
  legal_entity_name?: string
  memo?: string
  number?: string
  region_id?: number
  region_name?: string
  remark?: string
  status?: EdbSOADocumentStatus
  subject?: string
  total_amount?: number
  type?: EdbSOADocumentType
  updated_at?: string
  vat_amount?: number
  vat_apply_type?: EdbSOADocumentVATApplyType
  vat_number?: string
  vat_type?: EdbBranchVATType
}

export interface NoteUpdateReq {
  memo?: string
}

export interface NoteUpdateRes {
  id?: number
  memo?: string
  updated_at?: string
}

export interface NotificationCreateAtdOrdersNotifyReq {
  note?: string
  orders_id: number
  type: 8 | 9
}

export interface NotificationGetAtdNotiCheckData {
  last_notified_time?: string
  legal_notify_time?: string
  note?: string
  type?: EdbOrdersNotifyType
}

export interface NotificationGetNotificationsData {
  notifications?: NotificationNotification[]
}

export interface NotificationGetNotificationsResp {
  code?: number
  data?: NotificationGetNotificationsData
  message?: string
}

export interface NotificationGetUnreadCountResp {
  code?: number
  data?: NotificationGetUnreadCountRespData
  message?: string
}

export interface NotificationGetUnreadCountRespData {
  count?: number
}

export interface NotificationMarkReadReq {
  ids?: number[]
}

export interface NotificationNotification {
  booking_time?: string
  booking_type?: EdbOrdersBookingType
  calling_code?: string
  cancel_type?: EdbProductCancelType
  channel?: DomainManagerOrderChannel
  country_code?: string
  created_at?: string
  cust_num?: number
  cust_title?: string
  email?: string
  end_time_text?: string
  gender?: number
  id?: number
  is_group_order?: boolean
  is_read?: boolean
  member_nickname?: string
  /** deprecated */
  nickname?: string
  notification_type?: EdbAnnManagerType
  order_id?: number
  order_type?: EmongoOrderType
  ori_booking_time?: string
  partner_logo_img?: string
  partner_name?: string
  phone?: string
  products?: NotificationProduct[]
  remark_cust_title?: string
  remark_name?: string
  source?: EdbReservationSource
}

export interface NotificationProduct {
  name?: string
  quantity?: number
}

export interface NotificationODeferPaymentNotifyReq {
  /**
   * @maxItems 2
   * @minItems 0
   * @example [0,1]
   */
  notify_methods: EdbOrdersNotifyMethod[]
  /** @example 123456789 */
  order_id: number
}

export interface OpenccConvertReq {
  /** Support s2t, t2s, s2tw, tw2s, s2hk, hk2s, s2twp, tw2sp, t2tw, t2hk (s: Simplified, t: Traditional, tw: Taiwan, hk: HongKong) */
  method: string
  /** Input text to convert */
  text?: string
}

export interface OpenccConvertRes {
  code?: number
  message?: string
  result?: string
}

export interface OrderAddition {
  count?: number
  pid?: number
}

export interface OrderAtdAnswer {
  content?: string
  id?: number
}

export interface OrderAtdCountRespData {
  time_slots?: OrderAtdTimeSlot[]
}

export interface OrderAtdDetailData {
  answers?: OrderAtdAnswer[][]
  remarks?: OrderAtdRemark[]
}

export interface OrderAtdOrder {
  attendee_status?: SattendeeDisplayStatus
  booking_time?: string
  booking_type?: EdbOrdersBookingType
  calling_code?: string
  channel?: DomainManagerOrderChannel
  cust_title?: string
  form_created_at?: string
  form_expired_time?: string
  form_link?: string
  gender?: number
  id?: number
  member_nickname?: string
  order_created_at?: string
  order_end_time?: string
  order_type?: EmongoOrderType
  partner_logo_img?: string
  phone?: string
  remark_name?: string
  sheets?: number
}

export interface OrderAtdOrdersRespData {
  has_more?: boolean
  orders?: OrderAtdOrder[]
}

export interface OrderAtdRemark {
  id?: number
  title?: string
  type?: EdbProductRemarkType
}

export interface OrderAtdTimeSlot {
  orders_count?: number
  sheets_count?: number
  time_slot?: string
}

export interface OrderAttendanceReason {
  allows_reason_input?: boolean
  id?: number
  title?: string
}

export interface OrderAttendanceReasonRes {
  list?: OrderAttendanceReason[]
}

export interface OrderAttendanceStatusRes {
  is_no_show?: boolean
  is_reply?: boolean
}

export interface OrderCancelByTokenReq {
  reason?: string
  token: string
}

export interface OrderCancelRespData {
  cancel_rule?: string
  cancelable?: boolean
}

export interface OrderCancelRuleRespData {
  cancel_rules?: string[]
  cancelable?: boolean
}

export interface OrderCompleteOrderReq {
  note?: string
}

export interface OrderCreateAttendanceReq {
  '-'?: string
  attachment?: MultipartFileHeader
  custom_reason?: string
  is_attended?: boolean
  reason_id?: number
  token: string
}

export interface OrderCreateByGiftRequest {
  authID?: string
  authType?: EdbMemberAuthType
  bookingTimeLoc?: string
  bookingtime: string
  clientOS?: string
  email?: string
  gift_id: number
  member_note?: string
  remarks?: CreateRemark[]
  resource_count?: number
  uid?: number
  unhideGiftID?: number
}

export interface OrderCreateMovieRequest {
  bonus?: SmovieBonus
  combos?: SmovieMovieTicket[]
  email?: string
  phone: string
  promos?: SmoviePromo[]
  seat_id: number
  session_id: number
  tax_info?: ForderCompanyTax
  tickets?: SmovieMovieTicket[]
}

export interface OrderCreateMovieResponse {
  order_id?: number
}

export interface OrderCreateOrderRequest {
  /** @example "2022-12-15 17:44:05" */
  booking_time: string
  /** @example 1 */
  booking_type: 1 | 2
  /** @example "店家備註" */
  branch_note?: string
  /** @example 1 */
  cust_num?: number
  /** @example false */
  is_force: boolean
  /**
   * @maxItems 2
   * @minItems 0
   * @example [0]
   */
  notify_methods?: EdbOrdersNotifyMethod[]
  product: ManagerOrderMainProduct
  remarks: ManagerOrderOrdersRemark[]
}

export interface OrderCreateOrderResp {
  order_id?: number
  redeem_code?: string
}

export interface OrderCreateOrderResponseData {
  /**
   * the same as CreateOrderRequest.BookingTime (more convenient for frontend)
   * @example "2022-12-15 17:44:05"
   */
  booking_time: string
  /** @example 3063362350370 */
  order_id: number
}

export interface OrderDailyOrdersCountResponse {
  code?: number
  data?: Record<string, number>
  message?: string
}

export interface OrderDateInfo {
  /** @example 2 */
  bookings?: number
  /** @example "2023-10-03" */
  date?: string
  /**
   * if this field is `null`, then frontend should not show pax
   * @example 3
   */
  pax?: number
  sessions?: OrderSessionInfo[]
}

export interface OrderDeferPaymentReq {
  /** @example 100 */
  customized_amount?: number
}

export interface OrderDeferPaymentRes {
  url?: string
}

export interface OrderDelivery {
  delivery_fee?: number
  delivery_time_buffer?: number
  estimated_delivery_time?: string
  info?: OrderDeliveryInfo
  status?: EdbDeliveryStatus
}

export interface OrderDeliveryInfo {
  address?: string
  establishment?: string
  floor_building_number?: string
  lat?: number
  lng?: number
  note?: string
  pickup_type?: EdbPickupType
}

export interface OrderDetailData {
  additions?: ForderAddition[]
  address?: string
  area?: string
  attendee_expiry_time?: string
  /**
   * 0: None
   * 1: Unfinished
   * 2: Finished
   */
  attendee_status?: SattendeeDisplayStatus
  bonus?: number
  bonus_discount?: number
  booking_time?: string
  branch_id?: number
  branch_name?: string
  branch_phone?: string
  cancel_date?: string
  cancel_reschedule_text?: string
  cancel_rules?: string[]
  cancelable?: boolean
  cert?: ForderCert
  checkout_time?: string
  comment_reward?: number
  comment_reward_amount?: string
  contact_phone?: string
  cover_image?: string
  created_at?: string
  cs_channel?: string
  currency_id?: number
  /**
   * 0: GeneralUnredeemed
   * 1: CertificationUnredeemed
   * 5: GeneralRedeemedOrExpired
   * 6: CertificationRedeemedOrExpired
   * 10: GeneralReviewed
   * 11: CertificationReviewed
   * 19: GeneralCancelled
   */
  display_status?: number
  end_time_text?: string
  form_link?: string
  fun_rewards_per?: number
  id?: number
  is_vip?: boolean
  lat?: number
  lng?: number
  member_level?: number
  member_level_name?: string
  member_note?: string
  need_to_know?: string
  niceday_order_id?: string
  original_checkout?: string
  original_end_time_text?: string
  original_time?: string
  owner_id?: number
  product?: ForderProductInfos
  product_sale_region_id?: number
  promos?: ForderOrdersDetailPromo[]
  rating?: number
  redeem_code?: string
  region_id?: number
  remarks?: GithubComZoeknowFunnowGoModuleOrderDomainRemark[]
  reschedulable?: boolean
  reschedule_rules?: string[]
  /**
   * 0: Reschedulable
   * 1: UnReschedulableTime
   * 2: Rescheduled
   * 3: UnReschedulableProduct
   * 4: UnReschedulableUsed
   * 5: UnReschedulableCanceled
   * 6: UnReschedulableGroupMember
   */
  reschedule_status?: ServicesRescheduleStatus
  return_bonus?: number
  review_content?: string
  review_create_time?: string
  review_rating?: number
  review_tags?: SreviewReviewTag[]
  sale_region_id?: number
  /**
   * 0: Normal
   * 2: Certification
   * 6: CampingSales
   */
  sales_mode?: EdbSalesMode
  show_refund?: boolean
  /**
   * 0: Created
   * 1: Transaction
   * 2: Complete
   * 3: Failed
   * 4: Cancel
   * 5: Release
   * 6: FullRefund
   * 7: PartialRefund
   * 8: Transferred
   * 9: AppFullRefund
   * 10: AppPartialRefund
   * 11: PaymentTimeout
   */
  status?: EdbOrdersStatus
  symbol?: string
  symbol_code?: string
  timezone?: string
  total_amount?: number
  txn_amount?: number
  txn_info?: ForderTxnInfo
  upgrades?: ForderOrdersUpgrade[]
  used_time?: string
  vip_reward?: string
  zip?: string
}

export interface OrderEditOrderMainProduct {
  /** @example 3122936102775 */
  pid?: number
  /** @example 1 */
  quantity?: number
}

export interface OrderEditOrderReq {
  /** @example "2023-01-09 23:28:10" */
  booking_time?: string
  /**
   * edb.OrdersBookingType
   * @example 1
   */
  booking_type?: 1 | 2
  /** @example "i am a branch note" */
  branch_note?: string
  /** @example 1 */
  cust_num?: number
  /** @example false */
  is_force?: boolean
  /**
   * if true, can only edit BranchNote
   * give default value "true" for compatibility of the old API
   * @default true
   * @example true
   */
  is_from_order_detail?: boolean
  /** @example true */
  is_notify?: boolean
  /**
   * 0: email, 1: sms
   * @maxItems 2
   * @minItems 0
   * @example [0,1]
   */
  notify_methods?: EdbOrdersNotifyMethod[]
  product?: OrderEditOrderMainProduct
  remarks?: ServicesOrdersRemark[]
  /** @uniqueItems true */
  rids?: number[]
}

export interface OrderEditOrderResp {
  code?: number
  data?: ForderManagerDetail
  message?: string
}

export interface OrderEditTimeSlot {
  discount?: number
  slot?: string
}

export interface OrderGetDeferPaymentData {
  customized_amount?: number
  min_amount?: number
  notify_methods?: EdbOrdersNotifyMethod[]
  previous_notify_methods?: EdbOrdersNotifyMethod[]
  product_info?: OrderProductInfo
}

export interface OrderGetEditResponse {
  end_day?: string
  headcount?: number[]
  slots?: OrderEditTimeSlot[]
  start_day?: string
  time_mode?: EdbProductTimeMode
  timezone?: string
}

export interface OrderGetEditRulesResponse {
  edit_rules?: string[]
  editable?: boolean
}

export interface OrderGetOrdersResource {
  is_assigned?: boolean
  is_paused?: boolean
  is_reserved?: boolean
  max?: number
  min?: number
  name?: string
  rid?: number
  /** 0: normal, 1: disabled, 2: offline */
  status?: EdbResourceStatus
}

export interface OrderGetOrdersResourceData {
  resources?: OrderGetOrdersResource[]
  rg_id?: number
  rg_name?: string
  /**
   * resource group status
   * 0: normal, 1: offline
   */
  status?: EdbResourceGroupStatus
}

export interface OrderGetUnassignedOrdersData {
  booking_end_time?: string
  /** @example "2006-01-02 15:04:05" */
  booking_start_time?: string
  /** @example "+886" */
  calling_code?: string
  cust_name?: string
  cust_num?: number
  cust_title?: string
  /** @example "2006-01-02 15:04:05" */
  end?: string
  /** @example "2006-01-02 15:04:05" */
  order_created_at?: string
  order_display_status?: any
  order_id?: number
  /** @example "912345678" */
  phone?: string
  /** @example "2006-01-02 15:04:05" */
  start?: string
}

export interface OrderGroupCodeData {
  code?: string
}

export interface OrderGroupValue {
  style_id?: EdbLabelStyleID
  tag_id?: EdbLabelID
  tag_name?: string
  value?: number
}

export interface OrderModifyReq {
  booking_time?: string
  branchID?: number
  branch_note?: string
  order_id: number
  ordersID?: number
  pax?: number
}

export interface OrderMovie {
  booking_num?: string
  cinema_name?: string
  cust_num?: number
  group_id?: number
  hall?: string
  name?: string
  offline_refund_limit?: number
  online_refund_limit?: number
  ori_cust_num?: number
  ori_tickets?: OrderMovieTicket[]
  poster?: string
  seats?: string[]
  status?: OrderMovieOrderStatus
  tickets?: OrderMovieTicket[]
  version?: string
}

export interface OrderMovieForList {
  name?: string
  poster?: string
  status?: OrderMovieOrderStatus
}

export enum OrderMovieOrderStatus {
  MovieOrderStatusSucc = 0,
  MovieOrderStatusShowing = 1,
  MovieOrderStatusReviewed = 2,
  MovieOrderStatusCanceled = 3,
}

export interface OrderMovieTicket {
  description?: string
  name?: string
  qty?: number
}

export interface OrderOrder {
  booking_time?: string
  booking_type?: EdbOrdersBookingType
  branch_note?: string
  calling_code?: string
  cancel_type?: EdbProductCancelType
  channel?: DomainManagerOrderChannel
  country_code?: string
  created_at?: string
  cust_num?: number
  cust_title?: string
  /** @example "2023-10-03" */
  date?: string
  defer_payment?: ForderDeferPayment
  display_status?: number
  email?: string
  gender?: number
  group_detail?: ForderGroupDetail
  group_order_count?: number
  group_role?: EdbOrdersGroupRole
  id?: number
  is_flash?: boolean
  is_group_order?: boolean
  /** check no occupation: is_occu_resource = false or (is_occu_resource = true && resource_use = 0) */
  is_occu_resource?: boolean
  is_redeem?: boolean
  is_vip?: boolean
  member_nickname?: string
  member_note?: string
  member_reward?: string
  /** deprecated */
  nickname?: string
  order_type?: EmongoOrderType
  original_booking_time?: string
  partner_logo_img?: string
  partner_name?: string
  payment_mode?: EdbProductPaymentMode
  payment_status?: EmongoPaymentStatus
  phone?: string
  product_name?: string
  products?: ForderOrdersProduct[]
  promos?: ForderPromo[]
  remark_cust_title?: string
  remark_name?: string
  remarks?: ForderRemark[]
  resource_groups?: OrderRscGroupForOrderList[]
  /** check unassigned: resource_use > 0 && resource_groups = [] */
  resource_use?: number
  revenue_amount?: number
  sales_mode?: EdbSalesMode
  status?: number
  tags?: ForderLabel[]
  tier_discount?: number
  /** 1: Early Morning, 2: Morning, 4: Afternoon, 6: Evening */
  time_range_type?: TimeparseTimeRangeType
  /** @example "14:15" */
  time_slot?: string
  total_amount?: number
  txn_type?: EdbTxnType
  used_time?: string
  vip_reward?: string
}

export interface OrderOrderDetailResp {
  code?: number
  data?: PartnerOrderOrderData
  message?: string
}

export interface OrderOrderEatigoPromo {
  discount?: number
  has_refund?: boolean
  is_multiple?: boolean
  name?: string
  promo_code_list_id?: number
  promo_condition?: string
  promo_content?: string
  promo_id?: number
  refund_quantity?: number
  usage_quantity?: number
}

export interface OrderOrderIDData {
  link?: string
  orderId: number
  token?: string
}

export interface OrderOrderListResponse {
  code?: number
  data?: ForderListOrderInfo[]
  message?: string
  total_count?: number
}

export interface OrderOrderListV2Data {
  list?: OrderOrderListV2Info[]
  next_page_token?: string
}

export interface OrderOrderListV2Info {
  attendee_status?: SattendeeDisplayStatus
  booking_time?: string
  branch_name?: string
  cover_image?: string
  delivery?: OrderDelivery
  display_status?: number
  end_time_text?: string
  has_cert?: boolean
  id?: number
  is_rescheduled?: boolean
  movie?: OrderMovieForList
  review_bonus?: number
  reviewed?: boolean
  sales_mode?: EdbSalesMode
  status?: number
  timezone?: string
  type?: number
  used_time?: string
}

export interface OrderOrderListV2Resp {
  code?: number
  data?: OrderOrderListV2Data
  message?: string
}

export interface OrderOrderProduct {
  dscp?: string
  id?: number
  name?: string
  price?: number
  quantity?: number
  total_price?: number
}

export interface OrderOrderRemark {
  content?: string
  title?: string
}

export interface OrderOrdersCountResponse {
  code?: number
  data?: Record<string, number>
  message?: string
}

export interface OrderOrdersDetailResponse {
  code?: number
  data?: ForderManagerDetail
  message?: string
}

export interface OrderOrdersGroupDetail {
  member_rewards?: string[]
  products?: OrderOrdersProduct[]
  remarks?: string[]
  vip_rewards?: string[]
}

export interface OrderOrdersProduct {
  product_name?: string
  quantity?: number
}

export interface OrderOrdersSearchResponseData {
  orders?: OrderOrder[]
}

export interface OrderOrdersSourceData {
  domain_prefix?: string
  source?: EdbOrdersSource
}

export interface OrderOrdersSourceResp {
  code?: number
  data?: OrderOrdersSourceData
  message?: string
}

export interface OrderOverviewData {
  group_name?: string
  group_value?: OrderGroupValue[]
}

export interface OrderRedeemDetailResponse {
  code?: number
  data?: ForderManagerDetail
  message?: string
}

export interface OrderRedeemOrdersRequest {
  redeem_code?: string
}

export interface OrderReleaseOrderByTokenReq {
  token: string
}

export interface OrderRescheduleRuleResp {
  code?: number
  data?: OrderRescheduleRuleRespData
  message?: string
}

export interface OrderRescheduleRuleRespData {
  reschedulable?: boolean
  reschedule_rule?: string
}

export interface OrderRescheduleTimeData {
  end_day?: string
  start_day?: string
  time_mode?: EdbProductTimeMode
  times?: string[]
}

export interface OrderRescheduleTimeResp {
  code?: number
  data?: OrderRescheduleTimeData
  message?: string
}

export interface OrderReviewTag {
  id?: number
  name?: string
}

export interface OrderRscForOrderList {
  name?: string
  rid?: number
}

export interface OrderRscGroupForOrderList {
  resources?: OrderRscForOrderList[]
  rg_id?: number
  rg_name?: string
}

export interface OrderRschTimeResp {
  code?: number
  data?: OrderRschTimeRespData
  message?: string
}

export interface OrderSessionInfo {
  /** @example 2 */
  bookings?: number
  /** @example "00:00" */
  from?: string
  /** @example "Early Morning" */
  name?: string
  /**
   * if this field is `null`, then frontend should not show pax
   * @example 3
   */
  pax?: number
  time_range_type?: 1 | 2 | 4 | 6
  time_slots?: OrderTimeSlotInfo[]
  /** @example "05:59" */
  to?: string
}

export interface OrderSubItem {
  id?: number
  qty?: number
}

export interface OrderTag {
  id?: number
  name?: string
}

export interface OrderTimeSlotInfo {
  /** @example 2 */
  bookings?: number
  /**
   * if this field is `null`, then frontend should not show pax
   * @example 3
   */
  pax?: number
  /** @example "14:15" */
  time_slot?: string
}

export interface OrderUpdateAttendanceReq {
  attendance: 'reserved' | 'confirmed' | 'attended' | 'finished' | 'no-show'
  branchID?: number
  order_id: number
  ordersID?: number
  redeem_code?: string
}

export interface OrderUpdateOrderByTokenReq {
  email?: string
  member_note?: string
  tax_info?: ForderCompanyTax
  token: string
}

export interface OrderUpdateOrderStatusReq {
  display_status?: SostatusMgrStatus
}

export interface OrderUpdateOrdersRcsReq {
  is_force?: boolean
  rids?: number[]
}

export interface OrderProductInfo {
  amount?: number
  name?: string
  quantity?: number
  total_amount?: number
}

export interface OrderRschTimeRespData {
  end_day?: string
  headcount?: number[]
  headcount_changeable?: boolean
  selldates?: SselldateSelldates
  start_day?: string
  time_mode?: EdbProductTimeMode
  timezone?: string
}

export interface OrdersArrivalTimeRes {
  discount_tiers?: EsearchDiscountTier[]
  headcount?: number[]
  sell_time?: string
}

export interface OrdersAttendee {
  modified_email?: string
  modified_phone?: string
  note?: string
  notified_at?: string
  notified_by?: string
}

export interface OrdersCreateOrdersNotifyReq {
  attendee?: OrdersAttendee
  orders_id: number
  type: EdbOrdersNotifyType
}

export interface OrdersCreateOrdersNotifyResp {
  attendee?: OrdersAttendee
}

export interface OrdersDiscountData {
  discount?: number
}

export interface OrdersListRemarkData {
  content?: string
  id?: number
  orders_id?: number
  remark_id?: number
  title?: string
}

export interface OrdersListResponse {
  attention?: string
  auth_id?: string
  auth_type?: number
  balance?: number
  bid?: number
  bonus?: number
  booking_time?: string
  booking_type?: EdbOrdersBookingType
  branch_name?: string
  branch_platform?: EdbBranchPlatform
  brand_id?: EdbBrandID
  brand_name?: string
  cancel_at?: string
  cert_file_path?: string
  cert_id?: number
  cert_status?: number
  checkout_time?: string
  contact_phone?: string
  courier_name?: string
  courier_phone?: string
  courier_pickup_at?: string
  courier_pickup_time?: string
  courier_type?: EdbCourierType
  created_at?: string
  currency_code?: string
  currency_id?: number
  cust_num?: number
  delivery_fee?: number
  delivery_id?: string
  delivery_status?: EdbDeliveryStatus
  delivery_uuid?: string
  discount?: number
  discount_mode?: EdbProductSetDiscountMode
  discount_type?: EdbProductSetDiscountType
  discount_value?: number
  duration?: number
  duration_text?: string
  eatigo_booking_id?: number
  email?: string
  fnc_rate?: number
  funnow_refund_amount?: number
  gift_id?: number
  gift_type?: EdbGiftToMemberType
  group_code?: string
  group_role?: EdbOrdersGroupRole
  id?: number
  incapable?: number
  invoice_number?: string
  member_level?: number
  member_level_name?: string
  member_status?: EdbMemberStatus
  merchant_refund_amount?: number
  movie_booking_num?: string
  nickname?: string
  no_show_at?: string
  orders_delivery_id?: number
  os?: string
  product_id?: number
  product_name?: string
  quantity?: number
  receiver_address?: string
  receiver_name?: string
  receiver_phone?: string
  receiver_pickup_at?: string
  receiver_pickup_time?: string
  redeem_code?: string
  region_id?: number
  region_name?: string
  remark?: OrdersListRemarkData[]
  rwg_type?: EdbRwGType
  sale_region_id?: number
  sales_mode?: EdbSalesMode
  source?: EdbOrdersSource
  statement?: OrdersStatementData[]
  statement_set?: OrdersStatementData[]
  status?: EdbOrdersStatus
  total_amount?: number
  txn_acquirer?: string
  txn_allow_amount?: number
  txn_amount?: number
  txn_auth_code?: string
  txn_cap_time?: string
  txn_card_bin_code?: string
  txn_card_country?: string
  txn_card_issuer?: string
  txn_card_lastfour?: string
  txn_cost_milli_sec?: number
  txn_currency_code?: string
  txn_currency_id?: number
  txn_ex_trade_id?: string
  txn_id?: number
  txn_pay_amount?: number
  txn_status?: number
  txn_trade_time?: string
  txn_type?: number
  tz_location?: string
  uid?: number
  unhide_bid?: number
  unhide_gift_id?: number
  unhide_id?: number
  unhide_pid?: number
  unhide_uid?: number
  updated_at?: string
  used_time?: string
  vip_id?: number
}

export interface OrdersOrdersAttendeeData {
  created_at?: string
  created_by?: string
  form_created_at?: string
  form_modified_at?: string
  modified_by?: string
  modified_email?: string
  modified_phone?: string
  note?: string
  notified_at?: string
  notified_by?: string
}

export interface OrdersOrdersDeliveryData {
  courier_name?: string
  courier_note?: string
  courier_phone?: string
  courier_pickup_at?: string
  courier_pickup_time?: string
  courier_type?: EdbCourierType
  delivery_fee?: number
  delivery_id?: string
  delivery_uuid?: string
  receiver_address?: string
  receiver_area?: string
  receiver_establishment?: string
  receiver_floor_building_number?: string
  receiver_google_place_id?: string
  receiver_lat?: number
  receiver_lng?: number
  receiver_name?: string
  receiver_note?: string
  receiver_phone?: string
  receiver_pickup_at?: string
  receiver_pickup_time?: string
  receiver_pickup_type?: EdbPickupType
  status?: EdbDeliveryStatus
}

export interface OrdersOrdersDiscountData {
  created_at?: string
  discount?: number
  discount_type?: EdbProductSetDiscountType
  mode?: EdbProductSetDiscountMode
  updated_at?: string
}

export interface OrdersOrdersMovieTicketData {
  adjustment?: number
  amount?: number
  bonus?: number
  bonus_discount_diff?: number
  commission?: number
  commission_rate?: number
  created_at?: string
  discount?: number
  ez_price?: number
  ez_service_fee?: number
  ez_type?: string
  fee?: number
  fee_discount?: number
  fn_service_fee?: number
  id?: number
  is_use_seat?: EdbOrdersMovieTicketIsUseSeat
  movie_ticket_id?: number
  movie_ticket_name?: string
  orders_id?: number
  ori_amount?: number
  promo_code_id?: number
  promo_code_list_id?: number
  qty?: number
  ticket_contract_price?: number
  txn_amount?: number
  updated_at?: string
}

export interface OrdersOrdersRemarkData {
  content?: string
  created_at?: string
  id?: number
  orders_id?: number
  remark_id?: number
  remark_type?: EdbProductRemarkType
  title?: string
  updated_at?: string
}

export interface OrdersOrdersReviewData {
  created_at?: string
  description?: string
  id?: number
  orders_id?: number
  platform?: number
  rating?: number
  uid?: number
  updated_at?: string
}

export interface OrdersOrdersReviewReq {
  description?: string
  platform?: number
  rating?: number
  tags?: OrdersReviewTag[]
  uid?: number
}

export interface OrdersOrdersReviewRes {
  created_at?: string
  description?: string
  id?: number
  platform?: number
  rating?: number
  tags?: OrdersReviewTag[]
  uid?: number
  updated_at?: string
}

export interface OrdersOrdersReviewTagData {
  id?: number
  orders_id?: number
  review_id?: number
  tag_id?: number
  tag_name?: string
}

export interface OrdersOrdersRwGData {
  branch_rwg_type?: EdbRwGType
  confirmation_mode?: string
  created_at?: string
  data_source?: string
  idempotency_token?: string
  prepayment_status?: string
  updated_at?: string
}

export interface OrdersOrdersStatement {
  created_at?: string
  id?: number
  orders_id?: number
  statement_id?: number
  updated_at?: string
}

export interface OrdersOrdersSubItemData {
  free_qty?: number
  id?: number
  name?: string
  orders_product_id?: number
  price?: number
  qty?: number
  split_qty_index?: number
  sub_item_id?: number
}

export interface OrdersOrdersTaxData {
  company_name?: string
  created_at?: string
  email?: string
  receipt_company?: string
  tax_id?: string
  updated_at?: string
}

export interface OrdersOriginData {
  booking_time: string
  cust_num?: number
  discount?: number
  no_show_at?: string
  used_time?: string
}

export interface OrdersQuestionnaire {
  id?: number
  quest?: string
  type?: number
}

export interface OrdersReferListRes {
  id?: number
  name?: string
  orders_id?: number
}

export interface OrdersRefundMovieTicketData {
  amount?: number
  created_at?: string
  id?: number
  orders_id?: number
  orders_movie_ticket_id?: number
  qty?: number
  updated_at?: string
}

export interface OrdersResponse {
  attendee?: OrdersOrdersAttendeeData
  auth_id?: string
  auth_type?: number
  balance?: number
  bid?: number
  bonus?: number
  booking_time?: string
  booking_type?: EdbOrdersBookingType
  branch_name?: string
  cancel_at?: string
  cancel_by?: EdbOrdersCancelBy
  cancel_reason?: string
  checkout_time?: string
  comp_bonus?: EschemaOrdersCompBonus[]
  comp_payment?: EschemaOrdersCompPayment
  comp_promo?: EschemaOrdersCompPromo[]
  confirm_at?: string
  contact_phone?: string
  created_at?: string
  currency_code?: string
  currency_id?: number
  cust_num?: number
  delivery?: OrdersOrdersDeliveryData
  discount?: number
  eatigo_booking_id?: number
  email?: string
  finish_at?: string
  fnc_rate?: number
  funnow_refund_amount?: number
  gift_id?: number
  gift_type?: EdbGiftToMemberType
  group_code?: string
  group_role?: EdbOrdersGroupRole
  history?: EdbOrdersHistoryStatus
  id?: number
  incapable?: number
  invoice_number?: string
  lang_code?: string
  lang_id?: number
  lang_name?: string
  legacy_system_orders_id?: string
  member_level?: number
  member_level_name?: string
  member_note?: string
  merchant_refund_amount?: number
  movie_booking_num?: string
  movie_hall?: string
  movie_seat_tag?: string
  movie_ticket?: OrdersOrdersMovieTicketData[]
  nickname?: string
  no_show_at?: string
  note?: string
  orders_discount?: OrdersOrdersDiscountData
  os?: string
  product?: EschemaOrdersProduct[]
  redeem_code?: string
  refund_diff?: number
  refund_movie_ticket?: OrdersRefundMovieTicketData[]
  region_brand_name?: string
  region_id?: number
  region_name?: string
  remark?: OrdersOrdersRemarkData[]
  review?: OrdersOrdersReviewData[]
  review_tag?: OrdersOrdersReviewTagData[]
  rwg?: OrdersOrdersRwGData
  sale_region?: EschemaRegionData
  sales_mode?: EdbSalesMode
  source?: EdbOrdersSource
  statement?: OrdersOrdersStatement[]
  statement_at?: string
  status?: EdbOrdersStatus
  sub_item?: OrdersOrdersSubItemData[]
  tax?: OrdersOrdersTaxData
  tier_discount?: number
  total_amount?: number
  txn_acquirer?: string
  txn_allow_amount?: number
  txn_amount?: number
  txn_auth_code?: string
  txn_balance?: number
  txn_cap_time?: string
  txn_card_bin_code?: string
  txn_card_country?: string
  txn_card_issuer?: string
  txn_card_lastfour?: string
  txn_cost_milli_sec?: number
  txn_currency_code?: string
  txn_currency_id?: number
  txn_ex_trade_id?: string
  txn_id?: number
  txn_pay_amount?: number
  txn_status?: number
  txn_trade_time?: string
  txn_type?: number
  tz_location?: string
  uid?: number
  unhide_bid?: number
  unhide_id?: number
  unhide_uid?: number
  updated_at?: string
  upgrade?: EschemaOrdersUpgrade[]
  used_time?: string
  vip_id?: number
  vip_reward?: string
}

export interface OrdersReviewTag {
  id?: number
}

export interface OrdersStatementData {
  orders_id?: number
  statement_id?: number
}

export interface OrdersUpdateBookingTimeRes {
  new_booking_time?: string
  new_headcount?: number
  orders_id?: number
  ori_booking_time?: string
  ori_headcount?: number
  uid?: number
  updated_at?: string
  updated_occupation_time?: number
  updated_product_time?: number
}

export interface OrdersUpdateNoteOriginData {
  cancel_reason?: string
  member_note?: string
  note?: string
  remark?: OrdersUpdateRemarkData[]
}

export interface OrdersUpdateNoteReq {
  cancel_reason?: string
  member_note?: string
  note?: string
  remark?: OrdersUpdateRemarkData[]
}

export interface OrdersUpdateNoteRes {
  cancel_reason?: string
  id?: number
  member_note?: string
  note?: string
  origin?: OrdersUpdateNoteOriginData
  remark?: OrdersUpdateRemarkData[]
  updated_at?: string
}

export interface OrdersUpdateRemarkData {
  content?: string
  id?: number
  remark_type?: EdbProductRemarkType
}

export interface OrdersUpdateReq {
  booking_time: string
  cust_num?: number
  discount?: OrdersDiscountData
  no_show_at?: string
  used_time?: string
}

export interface OrdersUpdateRes {
  booking_time: string
  cust_num?: number
  discount?: OrdersDiscountData
  id?: number
  no_show_at?: string
  origin?: OrdersOriginData
  updated_at?: string
  used_time?: string
}

export interface OrdersUploadCertRes {
  cert_status?: number
  file_path?: string
  id?: number
  orders_id?: number
  updated_at?: string
}

export interface OrdersreceiptListResponse {
  auth_id?: string
  auth_type?: number
  bid?: number
  bonus?: number
  booking_time?: string
  branch_name?: string
  contact_phone?: string
  created_at?: string
  currency_code?: string
  discount?: number
  email?: string
  email_from?: string
  email_from_name?: string
  email_subject?: string
  email_to?: string
  fnc_rate?: number
  id?: number
  nickname?: string
  orders_amount?: number
  orders_created_at?: string
  orders_id?: number
  product_id?: number
  product_name?: string
  redeem_code?: string
  refund_id?: number
  region_id?: number
  region_name?: string
  status?: EdbOrdersReceiptStatus
  trade_time?: string
  txn_amount?: number
  txn_currency_code?: string
  txn_pay_amount?: number
  type?: EdbOrdersReceiptType
  tz_location?: string
  uid?: number
  unhide_bid?: number
  unhide_orders_id?: number
  unhide_pid?: number
  unhide_uid?: number
  updated_at?: string
}

export interface OrdersreceiptRequest {
  email_bcc?: string
  email_cc?: string
  email_content?: string
  email_from?: string
  email_from_name?: string
  email_subject?: string
  email_to?: string
  orders_id?: number
  refund_id?: number
  status?: EdbOrdersReceiptStatus
  type?: EdbOrdersReceiptType
}

export interface OrdersreceiptResponse {
  auth_id?: string
  auth_type?: number
  bid?: number
  bonus?: number
  booking_time?: string
  branch_name?: string
  contact_phone?: string
  created_at?: string
  currency_code?: string
  discount?: number
  email?: string
  email_bcc?: string
  email_cc?: string
  email_content?: string
  email_from?: string
  email_from_name?: string
  email_subject?: string
  email_to?: string
  fnc_rate?: number
  id?: number
  nickname?: string
  orders_amount?: number
  orders_created_at?: string
  orders_id?: number
  product_id?: number
  product_name?: string
  redeem_code?: string
  refund_id?: number
  region_id?: number
  region_name?: string
  status?: EdbOrdersReceiptStatus
  trade_time?: string
  txn_amount?: number
  txn_currency_code?: string
  txn_pay_amount?: number
  type?: EdbOrdersReceiptType
  tz_location?: string
  uid?: number
  unhide_bid?: number
  unhide_orders_id?: number
  unhide_pid?: number
  unhide_uid?: number
  updated_at?: string
}

export interface OrdersreceiptSendRequest {
  id?: number[]
}

export interface PartnerDocListResponse {
  code?: number
  data?: PartnerListResponse[]
  message?: string
  total_count?: number
}

export interface PartnerListResponse {
  id?: number
  name?: string
  product_mapping_type?: EdbPartnerProductMappingType
  status?: EdbPartnerStatus
  type?: EdbPartnerType
}

export interface PattendeeFields {
  decline_hour?: number
  sheets?: number
}

export interface PaymentConfirmGrabPayByTokenReq {
  auth_code: string
  orderid: string
  token?: string
}

export interface PaymentConfirmGrabPayReq {
  auth_code: string
  orderid: string
}

export interface PaymentCreditCardListData {
  card_list?: FpaymentCreditCardInfo[]
  limit_notice?: string
  promo_limit_notice?: string
  total_count?: number
}

export interface PaymentCreditCardListResp {
  code?: number
  data?: PaymentCreditCardListData
  message?: string
}

export interface PaymentDocListResponse {
  code?: number
  data?: PaymentListResponse[]
  message?: string
  total_count?: number
}

export interface PaymentDocResponse {
  code?: number
  data?: PaymentResponse
  message?: string
}

export interface PaymentListResponse {
  acquirer?: string
  adjustment?: number
  all_amount?: number
  allow_amount?: number
  auth_code?: string
  balance?: number
  bid?: number
  bonus?: number
  booking_time?: string
  branch_fee?: number
  branch_name?: string
  card_lastfour?: string
  category_tag_id?: number
  category_tag_name?: string
  commission?: number
  compensation?: number
  created_at?: string
  currency_code?: string
  currency_id?: number
  custom_commission?: number
  data_updated_at?: string
  delivery_fee?: number
  delivery_status?: EdbDeliveryStatus
  discount?: number
  ex_order_id?: string
  ex_trade_id?: string
  fee?: number
  final_commission?: number
  funnow_refund_amount?: number
  gift_created_at?: string
  gift_id?: number
  gift_status?: EdbGiftToMemberStatus
  gift_type?: EdbGiftToMemberType
  gmv_amount?: number
  incapable?: number
  industry_name?: string
  industry_type?: number
  invoice_amount?: number
  invoice_date?: string
  invoice_number?: string
  invoice_status?: EdbInvoiceStatus
  invoice_type?: EdbBranchInvoiceType
  is_delivery?: EdbIsDelivery
  merchant_name?: string
  merchant_refund_amount?: number
  net_price?: number
  note?: string
  orders_created_at?: string
  orders_id?: number
  orders_source?: EdbOrdersSource
  orders_status?: EdbOrdersStatus
  ori_booking_time?: string
  ori_orders_id?: number
  partner_id?: number
  partner_name?: string
  partner_order_id?: string
  payment_status?: EdbPaymentStatus
  product_fee?: number
  product_id?: number
  product_name?: string
  promo_code_id?: number
  quantity?: number
  refund_bonus?: number
  refund_diff?: number
  refund_discount?: number
  region_id?: number
  region_name?: string
  sales_mode?: EdbSalesMode
  sender_orders_id?: number
  statement_at?: string
  statement_note?: string
  total_amount?: number
  transfer_amount?: number
  txn_allow_amount?: number
  txn_amount?: number
  txn_balance?: number
  txn_currency_code?: string
  txn_currency_id?: number
  txn_pay_amount?: number
  txn_type?: EdbTxnType
  tz_location?: string
  unhide_bid?: number
  unhide_gift_id?: number
  unhide_orders_id?: number
  unhide_product_id?: number
  updated_at?: string
  upgrade_net_price?: number
  user_adj?: number
  vat_number?: string
}

export interface PaymentMovieTicketData {
  bonus_discount_diff?: number
  fee?: number
  id?: number
  orders_id?: number
  qty?: number
}

export interface PaymentMultiCurrencyInfo {
  txn_amount?: Record<string, string>
}

export interface PaymentPaymentAmountData {
  comment_reward?: number
  comment_reward_amount?: string
  currency_code?: string
  currency_symbol?: string
  fun_coin_discount?: string
  fun_rewards_per?: number
  member_level?: number
  member_level_name?: string
  multi_currency_info?: PaymentMultiCurrencyInfo
  promo_discount?: string
  remaining_fun_coin?: number
  txn_amount?: string
  used_fun_coin?: number
}

export interface PaymentRefreshErr {
  message?: string
  orders_id?: number
}

export interface PaymentRefreshRequest {
  orders_ids?: number[]
}

export interface PaymentRefreshResponse {
  code?: number
  errors?: PaymentRefreshErr[]
  message?: string
}

export interface PaymentRefundMovieTicketData {
  id?: number
  orders_id?: number
  qty?: number
}

export interface PaymentResponse {
  acquirer?: string
  adjustment?: number
  all_amount?: number
  allow_amount?: number
  auth_code?: string
  balance?: number
  bid?: number
  bonus?: number
  booking_time?: string
  branch_fee?: number
  branch_name?: string
  card_lastfour?: string
  category_tag_id?: number
  category_tag_name?: string
  commission?: number
  comp_bonus?: EschemaOrdersCompBonus[]
  comp_promo?: EschemaOrdersCompPromo[]
  compensation?: number
  created_at?: string
  currency_code?: string
  currency_id?: number
  custom_commission?: number
  data_updated_at?: string
  delivery_fee?: number
  delivery_status?: EdbDeliveryStatus
  discount?: number
  ex_order_id?: string
  ex_trade_id?: string
  fee?: number
  final_commission?: number
  funnow_refund_amount?: number
  gift_created_at?: string
  gift_id?: number
  gift_status?: EdbGiftToMemberStatus
  gift_type?: EdbGiftToMemberType
  gmv_amount?: number
  incapable?: number
  industry_name?: string
  industry_type?: number
  invoice_amount?: number
  invoice_date?: string
  invoice_number?: string
  invoice_status?: EdbInvoiceStatus
  invoice_type?: EdbBranchInvoiceType
  is_delivery?: EdbIsDelivery
  merchant_name?: string
  merchant_refund_amount?: number
  movie_ticket?: PaymentMovieTicketData[]
  net_price?: number
  note?: string
  orders_created_at?: string
  orders_id?: number
  orders_source?: EdbOrdersSource
  orders_status?: EdbOrdersStatus
  ori_booking_time?: string
  ori_orders_id?: number
  partner_id?: number
  partner_name?: string
  partner_order_id?: string
  payment_status?: EdbPaymentStatus
  product?: EschemaOrdersProduct[]
  product_fee?: number
  product_id?: number
  product_name?: string
  promo_code_id?: number
  quantity?: number
  refund_bonus?: number
  refund_diff?: number
  refund_discount?: number
  refund_movie_ticket?: PaymentRefundMovieTicketData[]
  region_id?: number
  region_name?: string
  sales_mode?: EdbSalesMode
  sender_orders_id?: number
  statement_at?: string
  statement_note?: string
  total_amount?: number
  transfer_amount?: number
  txn_allow_amount?: number
  txn_amount?: number
  txn_balance?: number
  txn_currency_code?: string
  txn_currency_id?: number
  txn_pay_amount?: number
  txn_type?: EdbTxnType
  tz_location?: string
  unhide_bid?: number
  unhide_gift_id?: number
  unhide_orders_id?: number
  unhide_product_id?: number
  updated_at?: string
  upgrade?: EschemaOrdersUpgrade[]
  upgrade_net_price?: number
  user_adj?: number
  vat_number?: string
}

export interface PaymentUpdateRequest {
  adjustment?: number
  compensation?: number
  custom_commission?: number
  fee?: number
  note?: string
  payment_status?: EdbPaymentStatus
  statement_at?: string
  statement_note?: string
  user_adj?: number
}

export interface PaymentUpdateResponse {
  adjustment?: number
  compensation?: number
  created_at?: string
  custom_commission?: number
  fee?: number
  note?: string
  orders_id?: number
  payment_status?: EdbPaymentStatus
  statement_at?: string
  statement_note?: string
  updated_at?: string
  user_adj?: number
}

export interface PaymentbrandDocListResponse {
  code?: number
  data?: PaymentbrandListResponse[]
  message?: string
  total_count?: number
}

export interface PaymentbrandDocResponse {
  code?: number
  data?: PaymentbrandResponse
  message?: string
}

export interface PaymentbrandListResponse {
  acquirer?: string
  adjustment?: number
  all_amount?: number
  allow_amount?: number
  auth_code?: string
  balance?: number
  bid?: number
  bonus?: number
  booking_time?: string
  branch_fee?: number
  branch_name?: string
  brand_id?: number
  brand_name?: string
  card_lastfour?: string
  category_tag_id?: number
  category_tag_name?: string
  commission?: number
  compensation?: number
  created_at?: string
  currency_code?: string
  currency_id?: number
  custom_commission?: number
  data_updated_at?: string
  discount?: number
  ex_order_id?: string
  ex_trade_id?: string
  fee?: number
  final_commission?: number
  funnow_refund_amount?: number
  gmv_amount?: number
  id?: number
  industry_type?: number
  invoice_amount?: number
  invoice_date?: string
  invoice_number?: string
  invoice_status?: number
  invoice_type?: number
  merchant_name?: string
  merchant_refund_amount?: number
  net_price?: number
  note?: string
  orders_created_at?: string
  orders_id?: string
  orders_status?: number
  ori_booking_time?: string
  ori_orders_id?: string
  payment_status?: EdbPaymentStatus
  product_fee?: number
  product_id?: number
  product_name?: string
  promo_code_id?: number
  quantity?: number
  refund_bonus?: number
  refund_diff?: number
  refund_discount?: number
  region_id?: number
  region_name?: string
  sales_mode?: number
  statement_note?: string
  total_amount?: number
  transfer_amount?: number
  txn_allow_amount?: number
  txn_amount?: number
  txn_balance?: number
  txn_currency_code?: string
  txn_currency_id?: number
  txn_pay_amount?: number
  txn_type?: number
  updated_at?: string
  upgrade_net_price?: number
  user_adj?: number
  vat_number?: string
}

export interface PaymentbrandRequest {
  acquirer?: string
  adjustment?: number
  all_amount?: number
  allow_amount?: number
  auth_code?: string
  balance?: number
  bid?: number
  bonus?: number
  booking_time?: string
  branch_fee?: number
  branch_name?: string
  brand_id?: number
  brand_name?: string
  card_lastfour?: string
  category_tag_id?: number
  category_tag_name?: string
  commission?: number
  compensation?: number
  currency_code?: string
  currency_id?: number
  custom_commission?: number
  data_updated_at?: string
  discount?: number
  ex_order_id?: string
  ex_trade_id?: string
  fee?: number
  final_commission?: number
  funnow_refund_amount?: number
  gmv_amount?: number
  industry_type?: number
  invoice_amount?: number
  invoice_date?: string
  invoice_number?: string
  invoice_status?: number
  invoice_type?: number
  merchant_name?: string
  merchant_refund_amount?: number
  net_price?: number
  note?: string
  orders_created_at?: string
  orders_id?: string
  orders_status?: number
  ori_booking_time?: string
  ori_orders_id?: string
  payment_status?: EdbPaymentStatus
  product_fee?: number
  product_id?: number
  product_name?: string
  promo_code_id?: number
  quantity?: number
  refund_bonus?: number
  refund_diff?: number
  refund_discount?: number
  region_id?: number
  region_name?: string
  sales_mode?: number
  statement_note?: string
  total_amount?: number
  transfer_amount?: number
  txn_allow_amount?: number
  txn_amount?: number
  txn_balance?: number
  txn_currency_code?: string
  txn_currency_id?: number
  txn_pay_amount?: number
  txn_type?: number
  upgrade_net_price?: number
  user_adj?: number
  vat_number?: string
}

export interface PaymentbrandResponse {
  acquirer?: string
  adjustment?: number
  all_amount?: number
  allow_amount?: number
  auth_code?: string
  balance?: number
  bid?: number
  bonus?: number
  booking_time?: string
  branch_fee?: number
  branch_name?: string
  brand_id?: number
  brand_name?: string
  card_lastfour?: string
  category_tag_id?: number
  category_tag_name?: string
  commission?: number
  compensation?: number
  created_at?: string
  currency_code?: string
  currency_id?: number
  custom_commission?: number
  data_updated_at?: string
  discount?: number
  ex_order_id?: string
  ex_trade_id?: string
  fee?: number
  final_commission?: number
  funnow_refund_amount?: number
  gmv_amount?: number
  id?: number
  industry_type?: number
  invoice_amount?: number
  invoice_date?: string
  invoice_number?: string
  invoice_status?: number
  invoice_type?: number
  merchant_name?: string
  merchant_refund_amount?: number
  net_price?: number
  note?: string
  orders_created_at?: string
  orders_id?: string
  orders_status?: number
  ori_booking_time?: string
  ori_orders_id?: string
  payment_status?: EdbPaymentStatus
  product_fee?: number
  product_id?: number
  product_name?: string
  promo_code_id?: number
  quantity?: number
  refund_bonus?: number
  refund_diff?: number
  refund_discount?: number
  region_id?: number
  region_name?: string
  sales_mode?: number
  statement_note?: string
  total_amount?: number
  transfer_amount?: number
  txn_allow_amount?: number
  txn_amount?: number
  txn_balance?: number
  txn_currency_code?: string
  txn_currency_id?: number
  txn_pay_amount?: number
  txn_type?: number
  updated_at?: string
  upgrade_net_price?: number
  user_adj?: number
  vat_number?: string
}

export interface PaymentsetListResponse {
  acquirer?: string
  adjustment?: number
  allow_amount?: number
  attend_status?: EdbPaymentAttendStatus
  auth_code?: string
  balance?: number
  bid?: number
  /** Discount */
  bonus?: number
  booking_time?: string
  /** Fee */
  branch_fee?: number
  branch_name?: string
  branch_pax_fee?: number
  brand_id?: number
  brand_name?: string
  cancel_at?: string
  card_lastfour?: string
  category_tag_id?: number
  category_tag_name?: string
  channel?: string
  collection_amount?: number
  commission?: number
  compensation?: number
  cooling_off_day?: number
  created_at?: string
  currency_code?: string
  currency_id?: number
  cust_num?: number
  custom_commission?: number
  data_updated_at?: string
  delivery_fee?: number
  delivery_status?: EdbDeliveryStatus
  deposit_amount?: number
  deposit_ratio?: number
  discount?: number
  ex_order_id?: string
  ex_trade_id?: string
  external_type?: EdbPaymentExternalType
  fee?: number
  final_commission?: number
  funnow_refund_amount?: number
  gift_created_at?: string
  /** Gift */
  gift_id?: number
  gift_sender_orders_id?: number
  gift_status?: EdbGiftToMemberStatus
  gift_type?: EdbGiftToMemberType
  gmv_amount?: number
  id?: number
  /** Data from other table */
  incapable?: number
  industry_name?: string
  industry_type?: number
  invoice_amount?: number
  invoice_date?: string
  invoice_number?: string
  invoice_status?: EdbInvoiceStatus
  /** Invoice */
  invoice_type?: EdbBranchInvoiceType
  is_delivery?: EdbIsDelivery
  is_free_of_charge?: EdbPaymentIsFreeOfCharge
  issue_invoice?: EdbBranchIssueInvoice
  mer_inv_amount?: number
  mer_inv_charge?: number
  merchant_name?: string
  merchant_refund_amount?: number
  net_price?: number
  /** Others */
  note?: string
  /** Amount */
  orders_amount?: number
  orders_created_at?: string
  orders_discount?: number
  orders_discount_mode?: EdbProductSetDiscountMode
  orders_discount_type?: EdbProductSetDiscountType
  /** Orders info */
  orders_id?: number
  orders_num?: string
  orders_source?: EdbOrdersSource
  orders_status?: EdbOrdersStatus
  ori_booking_time?: string
  ori_orders_id?: number
  ori_orders_num?: string
  /** Partner */
  partner_id?: number
  partner_name?: string
  partner_order_id?: string
  pax_fee?: number
  payment_status?: EdbPaymentStatus
  product_fee?: number
  product_id?: number
  product_name?: string
  product_pax_fee?: number
  promo_code_id?: number
  promo_cost_alloc?: EdbPromoCostAllocation
  quantity?: number
  /** Refund */
  refund_bonus?: number
  refund_diff?: number
  refund_diff_status?: EdbPaymentRefundDiffStatus
  refund_discount?: number
  region_id?: number
  region_name?: string
  sales_mode?: EdbSalesMode
  statement_at?: string
  statement_note?: string
  total_amount?: number
  total_pax_fee?: number
  transfer_amount?: number
  txn_allow_amount?: number
  txn_amount?: number
  txn_balance?: number
  txn_currency_code?: string
  txn_currency_id?: number
  txn_pay_amount?: number
  /** Txn info */
  txn_type?: EdbTxnType
  tz_location?: string
  unhide_bid?: number
  unhide_gift_id?: number
  unhide_orders_id?: number
  unhide_product_id?: number
  updated_at?: string
  upgrade_net_price?: number
  user_adj?: number
  vat_number?: string
}

export interface PaymentsetMovieTicketData {
  bonus_discount_diff?: number
  fee?: number
  id?: number
  orders_id?: number
  qty?: number
}

export interface PaymentsetRefreshReq {
  is_force?: boolean
}

export interface PaymentsetRefundMovieTicketData {
  id?: number
  orders_id?: number
  qty?: number
}

export interface PaymentsetResponse {
  acquirer?: string
  adjustment?: number
  allow_amount?: number
  attend_status?: EdbPaymentAttendStatus
  auth_code?: string
  balance?: number
  bid?: number
  /** Discount */
  bonus?: number
  booking_time?: string
  /** Fee */
  branch_fee?: number
  branch_name?: string
  branch_pax_fee?: number
  brand_id?: number
  brand_name?: string
  cancel_at?: string
  card_lastfour?: string
  category_tag_id?: number
  category_tag_name?: string
  channel?: string
  collection_amount?: number
  commission?: number
  comp_bonus?: EschemaOrdersCompBonus[]
  comp_promo?: EschemaOrdersCompPromo[]
  compensation?: number
  cooling_off_day?: number
  created_at?: string
  currency_code?: string
  currency_id?: number
  cust_num?: number
  custom_commission?: number
  data_updated_at?: string
  delivery_fee?: number
  delivery_status?: EdbDeliveryStatus
  deposit_amount?: number
  deposit_ratio?: number
  discount?: number
  ex_order_id?: string
  ex_trade_id?: string
  external_type?: EdbPaymentExternalType
  fee?: number
  final_commission?: number
  funnow_refund_amount?: number
  gift_created_at?: string
  /** Gift */
  gift_id?: number
  gift_sender_orders_id?: number
  gift_status?: EdbGiftToMemberStatus
  gift_type?: EdbGiftToMemberType
  gmv_amount?: number
  id?: number
  /** Data from other table */
  incapable?: number
  industry_name?: string
  industry_type?: number
  invoice_amount?: number
  invoice_date?: string
  invoice_number?: string
  invoice_status?: EdbInvoiceStatus
  /** Invoice */
  invoice_type?: EdbBranchInvoiceType
  is_delivery?: EdbIsDelivery
  is_free_of_charge?: EdbPaymentIsFreeOfCharge
  issue_invoice?: EdbBranchIssueInvoice
  mer_inv_amount?: number
  mer_inv_charge?: number
  merchant_name?: string
  merchant_refund_amount?: number
  movie_ticket?: PaymentsetMovieTicketData[]
  net_price?: number
  /** Others */
  note?: string
  /** Amount */
  orders_amount?: number
  orders_created_at?: string
  orders_discount?: number
  orders_discount_mode?: EdbProductSetDiscountMode
  orders_discount_type?: EdbProductSetDiscountType
  /** Orders info */
  orders_id?: number
  orders_num?: string
  orders_source?: EdbOrdersSource
  orders_status?: EdbOrdersStatus
  ori_booking_time?: string
  ori_orders_id?: number
  ori_orders_num?: string
  /** Partner */
  partner_id?: number
  partner_name?: string
  partner_order_id?: string
  pax_fee?: number
  payment_status?: EdbPaymentStatus
  product?: PsproductFields[]
  product_fee?: number
  product_id?: number
  product_name?: string
  product_pax_fee?: number
  promo_code_id?: number
  promo_cost_alloc?: EdbPromoCostAllocation
  quantity?: number
  /** Refund */
  refund_bonus?: number
  refund_diff?: number
  refund_diff_status?: EdbPaymentRefundDiffStatus
  refund_discount?: number
  refund_movie_ticket?: PaymentsetRefundMovieTicketData[]
  region_id?: number
  region_name?: string
  sales_mode?: EdbSalesMode
  statement_at?: string
  statement_note?: string
  total_amount?: number
  total_pax_fee?: number
  transfer_amount?: number
  txn_allow_amount?: number
  txn_amount?: number
  txn_balance?: number
  txn_currency_code?: string
  txn_currency_id?: number
  txn_pay_amount?: number
  /** Txn info */
  txn_type?: EdbTxnType
  tz_location?: string
  unhide_bid?: number
  unhide_gift_id?: number
  unhide_orders_id?: number
  unhide_product_id?: number
  updated_at?: string
  upgrade?: PsupgradeFields[]
  upgrade_net_price?: number
  user_adj?: number
  vat_number?: string
}

export interface PaymentsetUpdateExtReq {
  /** Amount */
  adjustment?: number
  allow_amount?: number
  balance?: number
  collection_amount?: number
  commission?: number
  compensation?: number
  /** Fee */
  cust_num?: number
  custom_commission?: number
  deposit_amount?: number
  /** Refund */
  deposit_ratio?: number
  /** Fee */
  fee?: number
  final_commission?: number
  funnow_refund_amount?: number
  gmv_amount?: number
  merchant_refund_amount?: number
  /** Others */
  note?: string
  pax_fee?: number
  payment_status?: EdbPaymentStatus
  statement_at?: string
  statement_note?: string
  /** Amount */
  total_amount?: number
  total_pax_fee?: number
  transfer_amount?: number
  txn_allow_amount?: number
  txn_amount?: number
  txn_balance?: number
  txn_pay_amount?: number
  user_adj?: number
}

export interface PaymentsetUpdateExtRes {
  /** Amount */
  adjustment?: number
  allow_amount?: number
  balance?: number
  collection_amount?: number
  commission?: number
  compensation?: number
  /** Fee */
  cust_num?: number
  custom_commission?: number
  deposit_amount?: number
  /** Refund */
  deposit_ratio?: number
  /** Fee */
  fee?: number
  final_commission?: number
  funnow_refund_amount?: number
  gmv_amount?: number
  id?: number
  merchant_refund_amount?: number
  /** Others */
  note?: string
  pax_fee?: number
  payment_status?: EdbPaymentStatus
  statement_at?: string
  statement_note?: string
  /** Amount */
  total_amount?: number
  total_pax_fee?: number
  transfer_amount?: number
  txn_allow_amount?: number
  txn_amount?: number
  txn_balance?: number
  txn_pay_amount?: number
  updated_at?: string
  user_adj?: number
}

export interface PaymentsetUpdateReq {
  /** Amount */
  adjustment?: number
  compensation?: number
  custom_commission?: number
  /** Fee */
  fee?: number
  /** Others */
  note?: string
  pax_fee?: number
  payment_status?: EdbPaymentStatus
  statement_at?: string
  statement_note?: string
  user_adj?: number
}

export interface PaymentsetUpdateRes {
  /** Amount */
  adjustment?: number
  compensation?: number
  custom_commission?: number
  /** Fee */
  fee?: number
  id?: number
  /** Others */
  note?: string
  orders_id?: number
  pax_fee?: number
  payment_status?: EdbPaymentStatus
  statement_at?: string
  statement_note?: string
  updated_at?: string
  user_adj?: number
}

export interface PdunqianFields {
  room_type_code?: string
}

export interface PinlineFields {
  table_tag?: string
}

export interface PpartnerCreateReq {
  partner_id?: EdbPartnerID
  partner_product_id?: string
  product_id?: number
}

export interface PpartnerCreateRes {
  id?: number
  partner_product_id?: string
  status?: EdbProductPartnerStatus
}

export interface PpartnerFields {
  branch_partner_id?: number
  id?: number
  partner_id?: number
  partner_name?: string
  partner_product_id?: string
  status?: EdbProductPartnerStatus
  upload_status?: EdbProductPartnerUploadStatus
}

export interface PpartnerUpdateReq {
  status?: EdbProductPartnerStatus
}

export interface PpartnerUpdateRes {
  id?: number
  status?: EdbProductPartnerStatus
}

export interface PpartnerUploadReq {
  product_id?: number
}

export interface PpartnerUploadRes {
  id?: number
  partner_product_id?: string
  status?: EdbProductPartnerStatus
  upload_status?: EdbProductPartnerUploadStatus
}

export interface PpaymentFields {
  def_pax_fee?: number
  fb_fee?: number
  fee?: number
  net_price?: number
  net_price_mode?: EdbProductNetPriceMode
}

export interface ProdtagDocListResponse {
  code?: number
  data?: ProdtagListResponse[]
  message?: string
  total_count?: number
}

export interface ProdtagDocResponse {
  code?: number
  data?: ProdtagResponse
  message?: string
}

export interface ProdtagDocTransListResponse {
  code?: number
  data?: ProdtagTransListResponse[]
  message?: string
  total_count?: number
}

export interface ProdtagDocTransResponse {
  code?: number
  data?: ProdtagTransResponse
  message?: string
}

export interface ProdtagListResponse {
  cover_image?: string
  group_id?: number
  group_name?: string
  id?: number
  name?: string
  type?: EdbProductTagType
}

export interface ProdtagRequest {
  cover_image?: string
  group_id?: number
  name?: string
  review_tag?: ProdtagTagData[]
  tag_credits?: ProdtagTagCreditData[]
}

export interface ProdtagResponse {
  cover_image?: string
  created_at?: string
  group_id?: number
  group_name?: string
  id?: number
  name?: string
  review_tag?: ProdtagTagData[]
  tag_credits?: ProdtagTagCreditData[]
  type?: EdbProductTagType
  updated_at?: string
}

export interface ProdtagTagCreditData {
  card_number?: string
}

export interface ProdtagTagData {
  group_id?: number
  group_name?: string
  id?: number
  name?: string
  rating?: number
}

export interface ProdtagTransListResponse {
  code?: string
  id?: number
  name?: string
  status?: EdbTransStatus
  tag_id?: number
  trans_id?: number
}

export interface ProdtagTransRequest {
  lang_id?: number
  name?: string
  name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  tag_id?: number
}

export interface ProdtagTransResponse {
  created_at?: string
  id?: number
  lang_id?: number
  name?: string
  name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  tag_id?: number
  updated_at?: string
}

export interface ProductAddUpgAdditionData {
  count?: number
  desc?: string
  discount?: number
  name?: string
  /** Based on the sales region, and is not empty if the currencyID differs from the branch region */
  origin_price?: number
  /** deprecated, but retain this for compatibility */
  productID?: number
  product_id?: number
}

export interface ProductAddUpgOriginCurrInfo {
  currency_code?: string
  currency_id?: EdbCurrencyID
  currency_symbol?: string
}

export interface ProductAdditionData {
  id?: number
  name?: string
}

export interface ProductAdditionListData {
  bid?: number
  duration?: number
  id?: number
  name?: string
}

export interface ProductAlbumData {
  id?: number
  image_path?: string
}

export interface ProductAlbumImg {
  image_path?: string
}

export interface ProductAlbumReq {
  album_images?: ProductAlbumImg[]
}

export interface ProductAlbumRes {
  album_images?: ProductAlbumData[]
  id?: number
}

export interface ProductArrivalTimeResp {
  code?: number
  data?: FunnowProductArrivalTimeInfo
  message?: string
}

export interface ProductBlockSet {
  end?: string
  name?: string
  start?: string
}

export interface ProductBlockSetData {
  end?: string
  id?: number
  name?: string
  start?: string
}

export interface ProductBranchPlace {
  address?: string
  area?: string
  google_place_id?: string
  lat?: number
  lng?: number
  location_detail?: string
  nearby_spot?: string
  transportation?: string
  zip?: string
}

export interface ProductBrand {
  name?: string
  product_link?: string
}

export interface ProductCancelRuleData {
  cancel_rule?: string
  cancel_rules?: string[]
  cancel_setting?: string
  cancel_setting_data?: ScancelruleCancelSetting
  cancel_type?: EdbProductCancelType
  cancelable?: EdbProductCancelable
  currency_code?: string
  fb_cancel_rule?: string
  fb_cancel_rules?: string[]
  fb_cancel_setting?: string
  fb_cancel_setting_data?: ScancelruleCancelSetting
  fb_cancel_type?: EdbProductFBCancelType
  fb_cancelable?: EdbProductCancelable
  is_yc?: boolean
  partner_cancel_rules?: string[]
  payment_mode?: EdbProductPaymentMode
  /** append at the end of all rules */
  supplementary_rules?: string[]
}

export interface ProductCancelRuleResp {
  fb_cancel_rules?: string[]
  fb_cancel_setting?: string
}

export interface ProductChannelData {
  channel_id?: number
  id?: number
  name?: string
  orders_referer_name?: string
  pax_fee?: number
}

export interface ProductCountRes {
  code?: number
  message?: string
  total_count?: number
}

export interface ProductCoverImgRes {
  cover_image?: string
  id?: number
  updated_at?: string
}

export interface ProductCreateBlockSetReq {
  block_sets?: ProductBlockSet[]
}

export interface ProductCreateFlashSetReq {
  /** @example "15:04:05" */
  checkout_time?: string
  /** @example "15:04:05" */
  cutoff_time?: string
  discount_tiers?: ProductSetDiscountTier[]
  /** @example "2006-01-02 15:04:05" */
  end: string
  /** @example "2006-01-02 15:04:05" */
  exposure_start?: string
  interval?: 15 | 30 | 60
  name?: string
  price?: number
  /** @uniqueItems true */
  rg_ids?: number[]
  sell_limit?: number
  /** @example "2006-01-02 15:04:05" */
  start: string
  /** 0: normal, 1: session */
  type?: 0 | 1
}

export interface ProductCreateFunbookProductResponse {
  pid?: number
}

export interface ProductCreateHolidaySetReq {
  /** @minItems 1 */
  holiday_sets: ProductHolidaySet[]
}

export interface ProductCreateOrdersNotifyReq {
  product_id: number
  type: EdbOrdersNotifyType
}

export interface ProductCreateProductRequest {
  buffer_time?: number
  duration?: number
  /** @min 0 */
  fbrrp?: number
  funbook_description?: string
  funbook_name: string
  prepare_time?: number
  resource_groups?: ResourceResourceGroup[]
  weekly_sets: Record<string, ProductSet[]>
}

export interface ProductCreateProductResponse {
  code?: number
  data?: ProductCreateProductResponseData
  message?: string
}

export interface ProductCreateProductResponseData {
  bid?: number
  buffer_time?: number
  duration?: number
  fbrrp?: number
  funbook_description?: string
  funbook_name?: string
  prepare_time?: number
  product_id?: number
  resource_groups?: ResourceResourceGroup[]
  weekly_sets?: Record<string, ProductWeekdaySetData[]>
}

export interface ProductCreateSetsWeekdayReq {
  /** @example "2006-01-02 15:04:05" */
  end?: string
  name?: string
  settings: ProductSetsWeekdaySetting[]
  /** @example "2006-01-02 15:04:05" */
  start: string
}

export interface ProductCreateWeeklySetsData {
  weekly_sets?: Record<string, ProductWeekdaySetData[]>
}

export interface ProductCreateWeeklySetsReq {
  /** @uniqueItems true */
  rg_ids?: number[]
  set_time_ranges?: ProductSetTimeRange[]
  weekly_sets: Record<string, ProductSet[]>
}

export interface ProductCrossDaysOriginCurrInfo {
  currency_code?: string
  currency_id?: EdbCurrencyID
  currency_symbol?: string
}

export interface ProductDetailData {
  /** address except city and township */
  address?: string
  /** is allowed to choose reservation quantity or not */
  allow_multi?: EdbProductAllowMulti
  /** city and township address */
  area?: string
  bid?: number
  /** branch introduction */
  branch_intro?: string
  branch_name?: string
  cancel_rules?: string[]
  /** ProductTag ID when TagType is Unique(5) */
  category_id?: number
  /** ProductTag name when TagType is Unique(5) */
  category_name?: string
  checkout?: string
  cover_image?: string
  /**
   * currency code following ISO 4217 such as TWD, USD
   * @example "TWD"
   */
  currency_code?: string
  currency_id?: number
  /**
   * currency symbol such as $, €, £
   * @example "$"
   */
  currency_symbol?: string
  /** product content */
  description?: string
  /** product sale region price */
  discount?: number
  distance?: string
  duration?: number
  duration_text?: string
  facilities?: FproductFacilitiesResponse[]
  images?: string[]
  /** is allowed to book over a day or not */
  is_crossday?: EdbProductIsCrossDay
  /** is allowed to choose the number of people or not */
  is_cust_num?: EdbProductIsCustNum
  is_favorite?: boolean
  /** latitude */
  lat?: number
  /** max reservation quantity */
  limit_max?: number
  /** min reservation quantity */
  limit_min?: number
  /** list type (0: main, 1: addition, 2: main&addition) */
  list_type?: EdbProductListType
  /** longitude */
  lng?: number
  /** nearby spot */
  nearby_spot?: string
  /** customer notice */
  need_to_know?: string
  /** notice */
  notices?: string
  onboard?: number
  /** business hours */
  opening_hours?: string
  /** origin currency price info base on branch region */
  origin_price_info?: ProductOriginPriceInfo
  /** product ID */
  pid?: number
  /** prepare time */
  prepare_time?: number
  price_list?: FproductPriceResponse[]
  /** product name */
  product_name?: string
  /** rating */
  rating?: number
  region_tag?: FproductTagsResponse
  reschedule_rules?: string[]
  /** recommended retail price */
  rrp?: number
  /**
   * sell time of product
   * @example "2023-05-04 17:15:00"
   */
  sell_time?: string
  /** special recommended retail price */
  srrp?: number
  static_link?: string
  /**
   * 0: open,
   * 1: hidden from platform and manager but display on url,
   * 2: hidden from platform,
   * 3: show on funnow but not sellable,
   * 4: hidden from platform but display on url and can edit by manager
   */
  status?: EdbProductStatus
  sub_item_type?: EdbProductSubItemType
  tags?: FproductTagsResponse[]
  /** time mode (0: 7 days, 1: 30 days, 2: 180 days, 3: 60 days, 4: 90 days, 5: 365 days) */
  time_mode?: EdbProductTimeMode
  /** transportation */
  transportation?: string
  /** VIP reward */
  vip_reward?: string
}

export interface ProductDiscountDetail {
  maximum_discount_rate?: number
  start_interval?: number
}

export interface ProductDiscountInterval {
  discount_rate?: number
  interval?: number
}

export interface ProductDiscountPlan {
  details?: ProductDiscountDetail
  intervals?: ProductDiscountInterval[]
}

export interface ProductFacilityData {
  icon?: string
  id?: number
  name?: string
}

export interface ProductFlashDetailResponseData {
  /** @example ["/web/images/funnow.svg","/web/images/niceday.svg"] */
  channel_icons: string[]
  /** @example "15:04:05" */
  checkout_time?: string
  /** @example "15:04:05" */
  cutoff_time?: string
  /** @example "2006-01-02 15:04:05" */
  end: string
  /** @example "2006-01-02 15:04:05" */
  exposure_start?: string
  /** product set id */
  id: number
  interval: 15 | 30 | 60
  name?: string
  price: number
  product_name: string
  /**
   * Deprecated: reserve for backward compatibility.
   * Please use `price`, both `price` and `ref_price` are the same value.
   * Related ticket: https://zoeknow.atlassian.net/browse/ZOEKIT-15061
   */
  ref_price: number
  resource_groups: SproductsetMonthPeriodDataResourceGroup[]
  sell_limit: number
  sold_count: number
  /** @example "2006-01-02 15:04:05" */
  start: string
}

export interface ProductFlashSetData {
  /** @example "15:04:05" */
  checkout_time?: string
  /** @example "15:04:05" */
  cutoff_time?: string
  discount_tiers?: ProductSetDiscountTier[]
  /** @example "2006-01-02 15:04:05" */
  end: string
  /** @example "2006-01-02 15:04:05" */
  exposure_start?: string
  id?: number
  interval?: 15 | 30 | 60
  name?: string
  price?: number
  /** @uniqueItems true */
  rg_ids?: number[]
  sell_limit?: number
  /** @example "2006-01-02 15:04:05" */
  start: string
  /** 0: normal, 1: session */
  type?: 0 | 1
}

export interface ProductFunbookProductRequest {
  additions?: number[]
  album_images?: string[]
  booking_mode: 0 | 1
  buffer_time: number
  buffer_time_unit: 0 | 1 | 2
  cover_image?: string
  description: string
  detail_mode: boolean
  duration: number
  duration_time_unit: 0 | 1 | 2
  fb_cancel_rule?: string
  fb_cancel_setting: string
  fb_cancelable?: boolean
  fb_reschedulable?: boolean
  fb_reschedule_setting: string
  intro?: string
  limit?: 0 | 1 | 2 | 3
  max_cust_num?: number
  name: string
  notices?: string
  payment_mode?: 0 | 1
  prepare_time: number
  prepare_time_unit: 0 | 1 | 2
  resource_use?: number
  resources?: number[]
  rwg_beauty_price?: number
  time_mode: 0 | 1 | 2
  upgrade_mode?: boolean
  upgrades?: number[]
}

export interface ProductFunbookProductResponse {
  code?: number
  data?: ProductProductResponseData
  message?: string
}

export interface ProductGatewayResp {
  code?: number
  data?: FunnowProductGatewayData
  message?: string
}

export interface ProductGetMonthRes {
  code?: number
  data?: Record<string, SproductsetMonthPeriodData[]>
  message?: string
}

export interface ProductGetProductSetRsc {
  max?: number
  min?: number
  name?: string
  rid?: number
  /** 0: normal, 1: disabled, 2: offline */
  status?: EdbResourceStatus
}

export interface ProductGetProductSetRscData {
  resources?: ProductGetProductSetRsc[]
  rg_id?: number
  rg_name?: string
  /**
   * resource group status
   * 0: normal, 1: offline
   */
  status?: EdbResourceGroupStatus
}

export interface ProductGetProductSetRscRes {
  code?: number
  data?: ProductGetProductSetRscData[]
  message?: string
}

export interface ProductGetSetListRes {
  code?: number
  data?: any
  message?: string
  total_count?: number
}

export interface ProductGiftInfoData {
  bid?: number
  cover_image?: string
  gift_cards?: ProductGiftInfoGiftCard[]
  member_reward?: string
  price?: DataconvPriceAmount
  product_name?: string
  upgrades?: ProductGiftInfoUpgrade[]
  vip_reward?: string
}

export interface ProductGiftInfoGiftCard {
  card_image?: string
  id?: number
  name?: string
  placeholder?: string
}

export interface ProductGiftInfoResp {
  code?: number
  data?: ProductGiftInfoData
  message?: string
}

export interface ProductGiftInfoUpgrade {
  description?: string
  id?: number
  name?: string
  total_price?: DataconvPriceAmount
}

export interface ProductHistoryProductData {
  branch_name?: string
  cover_image?: string
  product_id?: number
  product_name?: string
}

export interface ProductHistoryRes {
  code?: number
  data?: ProductHistoryProductData[]
  message?: string
}

export interface ProductHolidaySet {
  /** @example "15:04:05" */
  checkout_time?: string
  /** @example "15:04:05" */
  cutoff_time?: string
  discount_tiers?: ProductSetDiscountTier[]
  /** @example "2006-01-02 15:04:05" */
  end: string
  interval?: 15 | 30 | 60
  name?: string
  price?: number
  /** @uniqueItems true */
  rg_ids?: number[]
  sell_limit?: number
  /** @example "2006-01-02 15:04:05" */
  start: string
  /** 0: normal, 1: session */
  type?: 0 | 1
}

export interface ProductHolidaySetData {
  /** @example "15:04:05" */
  checkout_time?: string
  /** @example "15:04:05" */
  cutoff_time?: string
  discount_tiers?: ProductSetDiscountTier[]
  /** @example "2006-01-02 15:04:05" */
  end: string
  id?: number
  interval?: 15 | 30 | 60
  name?: string
  price?: number
  /** @uniqueItems true */
  rg_ids?: number[]
  sell_limit?: number
  /** @example "2006-01-02 15:04:05" */
  start: string
  /** 0: normal, 1: session */
  type?: 0 | 1
}

export interface ProductInlineData {
  is_matched?: boolean
  resource_data?: Record<string, EredisInlineResource>
}

export interface ProductListResponse {
  add_type?: EdbProductAddType
  allow_multi?: number
  bid?: number
  branch_name?: string
  branch_platform?: EdbBranchPlatform
  branch_pms_type?: EdbBranchPMSType
  branch_type?: EdbBranchType
  brand_id?: number
  brand_name?: string
  buffer_time?: number
  cancel_type?: EdbProductCancelType
  cancelable?: EdbProductCancelable
  checkout?: string
  cover_image?: string
  created_at?: string
  currency_code?: string
  default_name?: string
  duration?: number
  duration_text?: string
  fbrrp?: number
  id?: number
  is_cross_day?: number
  is_cust_num?: number
  limit_max?: number
  limit_min?: number
  list_type?: EdbProductListType
  name?: string
  prepare_time?: number
  ref_price?: number
  region_id?: number
  region_name?: string
  reschedulable?: number
  resource_use?: number
  rrp?: number
  sale_region?: ProductListSaleRegionData[]
  sale_start_at?: string
  sales_mode?: EdbSalesMode
  show_on?: EdbProductShowOn
  srrp?: number
  start_at?: string
  status?: EdbProductStatus
  tag?: ProductListTagData[]
  time_mode?: EdbProductTimeMode
  trans?: ProductListTransData[]
  tz_location?: string
  unhide_bid?: number
  unhide_pid?: number
  updated_at?: string
  upgrade?: ProductListUpgradeData[]
  yc_mode?: EdbProductYCMode
}

export interface ProductListSaleRegionData {
  brand_name?: string
  id?: number
  product_id?: number
  region_id?: number
  region_name?: string
  sale_brand_id?: number
}

export interface ProductListTagData {
  group_id?: number
  group_name?: string
  id?: number
  product_id?: number
  tag_id?: number
  tag_name?: string
  type?: EdbProductTagType
}

export interface ProductListTransData {
  id?: number
  lang_code?: string
  lang_id?: number
  product_id?: number
  product_name?: string
  trans_status?: EdbTransStatus
}

export interface ProductListUpgradeData {
  id?: number
  product_id?: number
  upgrade_id?: number
  upgrade_name?: string
}

export interface ProductMaxCrossDaysRes {
  code?: string
  currency_id?: number
  day_prices?: FproductDayPrice[]
  origin_curr_info?: ProductCrossDaysOriginCurrInfo
  symbol_code?: string
  timezone?: string
}

export interface ProductOpeningHour {
  /** @example "18:00" */
  end_time?: string
  /** @example "00:00" */
  start_time?: string
}

export interface ProductOriginPriceInfo {
  currency_code?: string
  currency_id?: EdbCurrencyID
  currency_symbol?: string
  price?: number
}

export type ProductOtherProductsSerializer = object

export interface ProductPlacardData {
  type?: number
  wording?: string
}

export interface ProductPlacardRes {
  code?: number
  data?: ProductPlacardData[]
  message?: string
}

export interface ProductProductInfoResponse {
  code?: number
  data?: ProductProductInfoResponseData
  message?: string
}

export interface ProductProductInfoResponseData {
  bid?: number
  /** 0: Normal, 1: Seat only */
  booking_mode?: EdbProductBookingMode
  /** Sort alphabetically case-insensitive */
  brands?: ProductBrand[]
  buffer_time?: number
  /** @example ["/web/images/funnow.svg","/web/images/niceday.svg"] */
  channel_icons?: string[]
  checkout?: string
  cover_image?: string
  description?: string
  duration?: number
  duration_text?: string
  /** 0: None, 1: Edit deny */
  edit_mode?: EdbProductEditMode
  facilities?: ProductFacilityData[]
  fbrrp?: number
  funbook_description?: string
  funbook_link?: string
  funbook_name?: string
  funnow_link?: string
  images?: string[]
  is_block?: boolean
  is_enabled_rwg?: boolean
  is_enabled_yc?: boolean
  name?: string
  notices?: string
  prepare_time?: number
  product_id?: number
  ref_price?: number
  resource_groups?: ResourceResourceGroup[]
  rwg?: ProductRwG
  srrp?: number
  time_mode?: EdbProductTimeMode
  /** 0: FunNow, 1: FunBook, 2: FunNow & FunBook */
  type?: EdbProductShowOn
  vip_reward?: string
  weekly_sets?: Record<string, ProductWeekdaySetData[]>
}

export interface ProductProductListData {
  allow_multi?: boolean
  bid?: number
  /** 0: Normal, 1: Seat only */
  booking_mode?: EdbProductBookingMode
  /** @example ["/web/images/funnow.svg","/web/images/niceday.svg"] */
  channel_icons?: string[]
  checkout?: string
  duration?: number
  duration_text?: string
  /** 0: None, 1: Edit deny */
  edit_mode?: EdbProductEditMode
  funbook_name?: string
  is_bind_resource?: boolean
  is_cust_num?: boolean
  is_enabled_yc?: boolean
  is_occu_resource?: boolean
  /** 0: None, 1: same as cust num */
  limit_max_type?: EdbProductLimitType
  /** 0: None, 1: same as cust num */
  limit_min_type?: EdbProductLimitMinType
  /** 0: Normal, 1: Addition, 2: main product and addition */
  list_type?: EdbProductListType
  name?: string
  /** 0: Online, 1: Onsite */
  payment_mode?: EdbProductPaymentMode
  pid?: number
  rank?: number
  ref_price?: number
  resource_use?: number
  /** 0: Open, 1: BranchImmutable, 2: Disabled, 3: Block, 4: Hidden */
  status?: EdbProductStatus
  /** 0: FunNow, 1: FunBook, 2: FunNow & FunBook */
  type?: EdbProductShowOn
}

export interface ProductProductListResp {
  code?: number
  data?: ProductProductListData[]
  message?: string
}

export interface ProductProductResponseData {
  additions?: number[]
  album_images?: string[]
  allow_multi?: boolean
  booking_mode?: EdbProductBookingMode
  buffer_time?: number
  buffer_time_unit?: SproductTimeUnit
  cover_image?: string
  description?: string
  detail_mode?: boolean
  duration?: number
  duration_time_unit?: SproductTimeUnit
  fb_cancel_rule?: string
  fb_cancel_rules?: string[]
  fb_cancel_setting?: string
  fb_cancel_setting_time?: ScancelruleFunbookCancelRule[]
  fb_cancel_setting_time_unit?: ScancelruleCancelSettingMode
  fb_cancelable?: boolean
  fb_reschedulable?: boolean
  fb_reschedule_rules?: string[]
  fb_reschedule_setting?: string
  fb_reschedule_setting_time?: any
  fb_reschedule_setting_time_unit?: SrescheduleruleRescheduleSettingMode
  intro?: string
  is_cust_num?: boolean
  limit?: SproductProductLimit
  max_cust_num?: number
  name?: string
  notices?: string
  payment_mode?: EdbProductPaymentMode
  prepare_time?: number
  prepare_time_unit?: SproductTimeUnit
  product_id?: number
  resource_use?: number
  resources?: number[]
  rwg_beauty_price?: number
  time_mode?: EdbProductTimeMode
  upgrade_mode?: boolean
  upgrades?: number[]
}

export interface ProductRegionData {
  brand_id?: number
  brand_name?: string
  id?: number
  name?: string
  status?: EdbRegionStatus
}

export interface ProductRemarkOption {
  id?: number
  regex?: string
  value?: string
}

export interface ProductRemarkRes {
  code?: number
  data?: ManagerProductRemarkData[]
  message?: string
}

export interface ProductRequest {
  add_type?: EdbProductAddType
  addition?: ProductAdditionData[]
  address?: string
  album_images?: ProductAlbumData[]
  allow_multi?: EdbProductAllowMulti
  area?: string
  attendee?: PattendeeFields
  attendee_remark?: AdminProductRemarkData[]
  attendee_type?: EdbProductAttendeeType
  attention?: string
  bid?: number
  buffer_time?: number
  cancel_rule?: string
  cancel_setting?: string
  cancel_type?: EdbProductCancelType
  cancelable?: EdbProductCancelable
  capacity?: number
  card_verification?: AdminProductTagData[]
  channel?: ProductChannelData[]
  checkout?: string
  contact_phone?: string
  cooling_off?: EdbProductCoolingOff
  cover_image?: string
  ctag?: AdminProductTagData[]
  description?: string
  dunqian?: PdunqianFields
  duration?: number
  duration_text?: string
  etag?: AdminProductTagData[]
  facility?: AdminProductTagData[]
  fb_cancel_rule?: string
  fb_cancel_setting?: string
  fb_cancel_type?: EdbProductFBCancelType
  fb_cancelable?: EdbProductCancelable
  fb_cooling_off?: EdbProductCoolingOff
  fb_description?: string
  fb_name?: string
  fb_remark?: AdminProductRemarkData[]
  fb_reschedulable?: EdbProductReschedulable
  fb_reschedule_setting?: string
  fbrrp?: number
  flash_sale?: AdminProductTagData[]
  giftable?: EdbProductGiftable
  google_place_id?: string
  inline?: PinlineFields
  intro?: string
  is_cross_day?: EdbProductIsCrossDay
  is_cust_num?: EdbProductIsCustNum
  is_occu_resource?: EdbProductIsOccuResource
  is_show_address?: EdbProductIsShowAddress
  lat?: number
  list_type?: EdbProductListType
  lng?: number
  location_detail?: string
  max_cust_num?: number
  member_reward?: string
  min_cust_num?: number
  name?: string
  nearby_spot?: string
  note?: string
  notices?: string
  partner?: PpartnerFields[]
  payment?: PpaymentFields
  popular?: number
  popular_mode?: EdbProductPopularMode
  prepare_time?: number
  purchase_notice?: string
  region?: ProductRegionData[]
  region_label?: AdminProductTagData[]
  remark?: AdminProductRemarkData[]
  reschedulable?: EdbProductReschedulable
  reschedule_setting?: string
  resource?: ProductResourceData[]
  resource_use?: number
  rrp?: number
  sale_start_at?: string
  sales_mode?: EdbSalesMode
  setting?: PsettingFields
  shalom?: PshalomFields
  show_on?: EdbProductShowOn
  srrp?: number
  start_at?: string
  status?: EdbProductStatus
  tag?: AdminProductTagData[]
  time_mode?: EdbProductTimeMode
  trans?: ProductTransData[]
  transportation?: string
  uniq_tag?: AdminProductTagData[]
  upgrade?: ProductUpgradeData[]
  user_rights?: AdminProductTagData[]
  vip_reward?: string
  wise?: PwiseFields
  zip?: string
}

export interface ProductRescheduleRuleData {
  fb_reschedulable?: EdbProductReschedulable
  fb_reschedule_rules?: string[]
  fb_reschedule_setting?: string
  fb_reschedule_setting_data?: SrescheduleruleRescheduleSetting
  is_fb_hc_changeable?: boolean
  is_yc?: boolean
  payment_mode?: EdbProductPaymentMode
  reschedulable?: EdbProductReschedulable
  reschedule_rules?: string[]
  reschedule_setting?: string
  reschedule_setting_data?: SrescheduleruleRescheduleSetting
}

export interface ProductRescheduleRuleResp {
  fb_reschedule_rules?: string[]
  fb_reschedule_setting?: string
}

export interface ProductResourceData {
  name?: string
  rg_name?: string
  rid?: number
}

export interface ProductResponse {
  add_type?: EdbProductAddType
  addition?: ProductAdditionData[]
  address?: string
  album_images?: ProductAlbumData[]
  allow_multi?: EdbProductAllowMulti
  area?: string
  attendee?: PattendeeFields
  attendee_preview_link?: string
  attendee_remark?: AdminProductRemarkData[]
  attendee_type?: EdbProductAttendeeType
  attention?: string
  bid?: number
  branch_funbook_ver?: EdbFunBookVer
  branch_name?: string
  branch_place?: ProductBranchPlace
  branch_platform?: EdbBranchPlatform
  branch_pms_type?: EdbBranchPMSType
  branch_rwg?: EdbRwG
  branch_rwg_type?: number
  branch_type?: EdbBranchType
  brand_id?: number
  brand_name?: string
  buffer_time?: number
  can_group?: boolean
  cancel_rule?: string
  cancel_setting?: string
  cancel_type?: EdbProductCancelType
  cancelable?: EdbProductCancelable
  capacity?: number
  card_verification?: AdminProductTagData[]
  channel?: ProductChannelData[]
  checkout?: string
  contact_phone?: string
  cooling_off?: EdbProductCoolingOff
  country_code?: string
  cover_image?: string
  created_at?: string
  ctag?: AdminProductTagData[]
  currency_code?: string
  decimal_precision?: number
  description?: string
  dunqian?: PdunqianFields
  duration?: number
  duration_text?: string
  etag?: AdminProductTagData[]
  facility?: AdminProductTagData[]
  fb_cancel_rule?: string
  fb_cancel_setting?: string
  fb_cancel_type?: EdbProductFBCancelType
  fb_cancelable?: EdbProductCancelable
  fb_cooling_off?: EdbProductCoolingOff
  fb_description?: string
  fb_name?: string
  fb_remark?: AdminProductRemarkData[]
  fb_reschedulable?: EdbProductReschedulable
  fb_reschedule_setting?: string
  fbrrp?: number
  flash_sale?: AdminProductTagData[]
  giftable?: EdbProductGiftable
  google_place_id?: string
  id?: number
  inline?: PinlineFields
  inline_company_id?: string
  inline_tag_type?: EdbInlineTagType
  intro?: string
  is_cross_day?: EdbProductIsCrossDay
  is_cust_num?: EdbProductIsCustNum
  is_manual_ungroup?: boolean
  is_occu_resource?: EdbProductIsOccuResource
  is_show_address?: EdbProductIsShowAddress
  lat?: number
  list_type?: EdbProductListType
  lng?: number
  location_detail?: string
  max_cust_num?: number
  member_reward?: string
  min_cust_num?: number
  name?: string
  nearby_spot?: string
  note?: string
  notices?: string
  partner?: PpartnerFields[]
  payment?: PpaymentFields
  popular?: number
  popular_mode?: EdbProductPopularMode
  prepare_time?: number
  purchase_notice?: string
  region?: ProductRegionData[]
  region_id?: number
  region_label?: AdminProductTagData[]
  region_name?: string
  remark?: AdminProductRemarkData[]
  reschedulable?: EdbProductReschedulable
  reschedule_setting?: string
  resource?: ProductResourceData[]
  resource_use?: number
  rrp?: number
  sale_start_at?: string
  sales_mode?: EdbSalesMode
  setting?: PsettingFields
  shalom?: PshalomFields
  show_on?: EdbProductShowOn
  srrp?: number
  start_at?: string
  status?: EdbProductStatus
  tag?: AdminProductTagData[]
  time_mode?: EdbProductTimeMode
  trans?: ProductTransData[]
  transportation?: string
  tz_location?: string
  unhide_bid?: number
  unhide_pid?: number
  uniq_tag?: AdminProductTagData[]
  updated_at?: string
  upgrade?: ProductUpgradeData[]
  user_rights?: AdminProductTagData[]
  vip_reward?: string
  wise?: PwiseFields
  zip?: string
}

export interface ProductReviewData {
  placeholder?: string
  tags?: EatigoProductTagData[]
}

export interface ProductReviewResponse {
  rating?: Record<string, ProductReviewData>
}

export interface ProductReviewTagData {
  placeholder?: FproductPlaceholders
  /**
   * vague meaning: will be replaced with Tag field
   * https://zoeknow.atlassian.net/browse/ZOEKIT-11068
   */
  rating?: FproductTags
  tag?: FproductTags
}

export interface ProductRwG {
  description?: string
  name?: string
  price?: number
  type?: EdbRwGType
}

export interface ProductSet {
  /** @example "15:04:05" */
  checkout_time?: string
  /** @example "15:04:05" */
  cutoff_time?: string
  discount_tiers?: ProductSetDiscountTier[]
  /** @example "2006-01-02 15:04:05" */
  end: string
  interval?: 15 | 30 | 60
  name?: string
  price?: number
  sell_limit?: number
  /** @example "2006-01-02 15:04:05" */
  start: string
  /** 0: normal, 1: session */
  type?: 0 | 1
}

export interface ProductSetDiscountTier {
  discount?: number
  limit?: number
}

export interface ProductSetTimeRange {
  end?: string
  start?: string
}

export enum ProductTimeSlotsType {
  TimeSlotsTypeDefault = 0,
  TimeSlotsTypeWithRange = 1,
}

export interface ProductToChannelReq {
  channel_id?: number
  product_id?: number
}

export interface ProductToChannelRes {
  channel_id?: number
  id?: number
  product_id?: number
}

export interface ProductTransData {
  address?: string
  address_status?: EdbTransFieldStatus
  area?: string
  area_status?: EdbTransFieldStatus
  attention?: string
  attention_status?: EdbTransFieldStatus
  cancel_rule?: string
  cancel_rule_status?: EdbTransFieldStatus
  description?: string
  description_status?: EdbTransFieldStatus
  duration_text?: string
  duration_text_status?: EdbTransFieldStatus
  fb_cancel_rule?: string
  fb_cancel_rule_status?: EdbTransFieldStatus
  fb_description?: string
  fb_description_status?: EdbTransFieldStatus
  fb_name?: string
  fb_name_status?: EdbTransFieldStatus
  id?: number
  intro?: string
  intro_status?: EdbTransFieldStatus
  is_for_delete?: boolean
  lang_id?: number
  location_detail?: string
  location_detail_status?: EdbTransFieldStatus
  member_reward?: string
  member_reward_status?: EdbTransFieldStatus
  name?: string
  name_status?: EdbTransFieldStatus
  nearby_spot?: string
  nearby_spot_status?: EdbTransFieldStatus
  notices?: string
  notices_status?: EdbTransFieldStatus
  purchase_notice?: string
  purchase_notice_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  transportation?: string
  transportation_status?: EdbTransFieldStatus
  vip_reward?: string
  vip_reward_status?: EdbTransFieldStatus
}

export interface ProductTransListRes {
  code?: string
  id?: number
  name?: string
  product_id?: number
  status?: EdbTransStatus
  trans_id?: number
}

export interface ProductTransReq {
  address?: string
  address_status?: EdbTransFieldStatus
  area?: string
  area_status?: EdbTransFieldStatus
  attention?: string
  attention_status?: EdbTransFieldStatus
  cancel_rule?: string
  cancel_rule_status?: EdbTransFieldStatus
  description?: string
  description_status?: EdbTransFieldStatus
  duration_text?: string
  duration_text_status?: EdbTransFieldStatus
  fb_cancel_rule?: string
  fb_cancel_rule_status?: EdbTransFieldStatus
  fb_description?: string
  fb_description_status?: EdbTransFieldStatus
  fb_name?: string
  fb_name_status?: EdbTransFieldStatus
  intro?: string
  intro_status?: EdbTransFieldStatus
  lang_id?: number
  location_detail?: string
  location_detail_status?: EdbTransFieldStatus
  member_reward?: string
  member_reward_status?: EdbTransFieldStatus
  name?: string
  name_status?: EdbTransFieldStatus
  nearby_spot?: string
  nearby_spot_status?: EdbTransFieldStatus
  notices?: string
  notices_status?: EdbTransFieldStatus
  product_id?: number
  purchase_notice?: string
  purchase_notice_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  transportation?: string
  transportation_status?: EdbTransFieldStatus
  vip_reward?: string
  vip_reward_status?: EdbTransFieldStatus
}

export interface ProductTransRes {
  address?: string
  address_status?: EdbTransFieldStatus
  area?: string
  area_status?: EdbTransFieldStatus
  attention?: string
  attention_status?: EdbTransFieldStatus
  cancel_rule?: string
  cancel_rule_status?: EdbTransFieldStatus
  created_at?: string
  description?: string
  description_status?: EdbTransFieldStatus
  duration_text?: string
  duration_text_status?: EdbTransFieldStatus
  fb_cancel_rule?: string
  fb_cancel_rule_status?: EdbTransFieldStatus
  fb_description?: string
  fb_description_status?: EdbTransFieldStatus
  fb_name?: string
  fb_name_status?: EdbTransFieldStatus
  id?: number
  intro?: string
  intro_status?: EdbTransFieldStatus
  lang_id?: number
  location_detail?: string
  location_detail_status?: EdbTransFieldStatus
  member_reward?: string
  member_reward_status?: EdbTransFieldStatus
  name?: string
  name_status?: EdbTransFieldStatus
  nearby_spot?: string
  nearby_spot_status?: EdbTransFieldStatus
  notices?: string
  notices_status?: EdbTransFieldStatus
  product_id?: number
  purchase_notice?: string
  purchase_notice_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  transportation?: string
  transportation_status?: EdbTransFieldStatus
  updated_at?: string
  vip_reward?: string
  vip_reward_status?: EdbTransFieldStatus
}

export interface ProductUpdStatusReq {
  status?: EdbProductStatus
}

export interface ProductUpdStatusRes {
  id?: number
  status?: EdbProductStatus
  updated_at?: string
}

export interface ProductUpdateBlockSetReq {
  end?: string
  id?: number
  name?: string
  start?: string
}

export interface ProductUpdateFlashSetReq {
  /** @example "15:04:05" */
  checkout_time?: string
  /** @example "15:04:05" */
  cutoff_time?: string
  discount_tiers?: ProductSetDiscountTier[]
  /** @example "2006-01-02 15:04:05" */
  end: string
  /** @example "2006-01-02 15:04:05" */
  exposure_start?: string
  id?: number
  interval?: 15 | 30 | 60
  name?: string
  price?: number
  /** @uniqueItems true */
  rg_ids?: number[]
  sell_limit?: number
  /** @example "2006-01-02 15:04:05" */
  start: string
  /** 0: normal, 1: session */
  type?: 0 | 1
}

export interface ProductUpdateHolidaySetReq {
  /** @example "15:04:05" */
  checkout_time?: string
  /** @example "15:04:05" */
  cutoff_time?: string
  discount_tiers?: ProductSetDiscountTier[]
  /** @example "2006-01-02 15:04:05" */
  end: string
  id?: number
  interval?: 15 | 30 | 60
  name?: string
  price?: number
  /** @uniqueItems true */
  rg_ids?: number[]
  sell_limit?: number
  /** @example "2006-01-02 15:04:05" */
  start: string
  /** 0: normal, 1: session */
  type?: 0 | 1
}

export interface ProductUpdateProductRequest {
  buffer_time?: number
  duration?: number
  fbrrp?: number
  funbook_description?: string
  funbook_name?: string
  pid?: number
  prepare_time?: number
}

export interface ProductUpdateProductResourceRequest {
  resource_groups?: ResourceResourceGroup[]
}

export interface ProductUpdateProductResourceResponse {
  code?: number
  data?: ProductUpdateProductResourceResponseData
  message?: string
}

export interface ProductUpdateProductResourceResponseData {
  resource_groups?: ResourceResourceGroup[]
}

export interface ProductUpdateProductResponse {
  code?: number
  data?: ProductUpdateProductResponseData
  message?: string
}

export interface ProductUpdateProductResponseData {
  buffer_time?: number
  duration?: number
  fbrrp?: number
  funbook_description?: string
  funbook_name?: string
  prepare_time?: number
  product_id?: number
}

export interface ProductUpdateRankData {
  pid?: number
  /** @min 1 */
  rank?: number
}

export interface ProductUpdateRankRequest {
  /**
   * @minItems 2
   * @uniqueItems true
   */
  products: ProductUpdateRankData[]
}

export interface ProductUpdateSetsWeekdayReq {
  /** @example "2006-01-02 15:04:05" */
  end?: string
  name?: string
  settings: ProductSetsWeekdaySetting[]
  /** @example "2006-01-02 15:04:05" */
  start: string
}

export interface ProductUpdateSetsWeekdaySettingReq {
  settings: ProductSetsWeekdaySetting[]
}

export interface ProductUpdateWeeklySetsReq {
  /** @example "15:04:05" */
  checkout_time?: string
  /** @example "15:04:05" */
  cutoff_time?: string
  discount_tiers?: ProductSetDiscountTier[]
  /** @example "2006-01-02 15:04:05" */
  end: string
  id?: number
  interval?: 15 | 30 | 60
  name?: string
  price?: number
  /** @uniqueItems true */
  rg_ids?: number[]
  sell_limit?: number
  set_time_ranges?: ProductSetTimeRange[]
  /** @example "2006-01-02 15:04:05" */
  start: string
  /** 0: normal, 1: session */
  type?: 0 | 1
}

export interface ProductUpgradeData {
  id?: number
  name?: string
  upgrade_type?: EdbProductUpgradeType
}

export interface ProductUpgradeListData {
  bid?: number
  duration?: number
  id?: number
  name?: string
  price?: number
}

export interface ProductWeekdaySetData {
  /** @example "15:04:05" */
  checkout_time?: string
  /** @example "15:04:05" */
  cutoff_time?: string
  discount_tiers?: ProductSetDiscountTier[]
  /** @example "2006-01-02 15:04:05" */
  end: string
  id?: number
  interval?: 15 | 30 | 60
  name?: string
  price?: number
  /** @uniqueItems true */
  rg_ids?: number[]
  sell_limit?: number
  set_time_ranges?: ProductSetTimeRange[]
  /** @example "2006-01-02 15:04:05" */
  start: string
  /** 0: normal, 1: session */
  type?: 0 | 1
}

export interface ProductWeekdaySetsData {
  id?: string
}

export interface ProductWeekdaySetsRes {
  code?: number
  data?: ProductWeekdaySetsData
  message?: string
}

export interface ProductArrivalTimeData {
  earliest_sell_time?: string
  sell_dates?: Record<string, ProductRangeNameSellInfo[]>
}

export interface ProductEatigoProductsData {
  max_cust_num?: number
  restaurant_id?: number
}

export interface ProductGetArrivalTimesRes {
  code?: number
  data?: ProductArrivalTimeData
  message?: string
}

export interface ProductListSetsWeekdayData {
  end?: string
  id?: string
  name?: string
  start?: string
}

export interface ProductRangeNameSellInfo {
  sell_info?: ProductSellInfo[]
  time_range_name?: string
}

export interface ProductSellInfo {
  is_block?: boolean
  remain_count?: number
  sell_time?: string
}

export interface ProductSetsWeekdaySet {
  /** @example "2006-01-02 15:04:05" */
  checkout_time?: string
  /** @example "2006-01-02 15:04:05" */
  cutoff_time?: string
  discount_tiers?: ProductSetDiscountTier[]
  /** @example "15:04:05" */
  end: string
  interval?: 15 | 30 | 60
  name?: string
  rg_ids?: number[]
  /** @example "15:04:05" */
  start: string
  type?: 0 | 1
}

export interface ProductSetsWeekdaySetting {
  sets: ProductSetsWeekdaySet[]
  weekdays: number[]
}

export interface ProductSetsWeekdayTimeSlotData {
  sets?: ProductSetsWeekdayTimeSlotDataSet[]
  time?: string
}

export interface ProductSetsWeekdayTimeSlotDataSet {
  discount_tiers?: ProductSetDiscountTier[]
  weekday?: number
}

export interface ProducttagProductTag {
  cover_image?: string
  id?: number
  name?: string
}

export interface PromoBranchData {
  bid?: number
  name?: string
}

export interface PromoBulkRequest {
  count?: number
}

export interface PromoCodeListResponse {
  code?: string
  end_at?: string
  id?: number
  name?: string
  promo_code_list_code?: string
  promo_code_list_id?: number
  promo_title?: string
  promo_type?: number
  start_at?: string
}

export interface PromoListForCampaignResponse {
  code?: string
  id?: number
  name?: string
  promo_code_id?: number
}

export interface PromoListResponse {
  code?: string
  duration?: number
  end_at?: string
  id?: number
  name?: string
  promo_title?: string
  promo_type?: number
  start_at?: string
  time_type?: number
}

export interface PromoMemberTagData {
  id?: number
  name?: string
}

export interface PromoNewUserPromoData {
  amounts?: Record<string, string>
  include_movie?: boolean
  receivable?: boolean
}

export interface PromoNewUserPromoRes {
  code?: number
  data?: PromoNewUserPromoData
  message?: string
}

export interface PromoProductPromoInfo {
  code?: EdbCurrencyCode
  condition?: string
  currency_id?: EdbCurrencyID
  discount?: string
  end_at?: string
  id?: EdbPromoCodeID
  promo_code_list_id?: number
  promo_title?: string
  status?: FpromoStatus
  symbol_code?: string
  usable?: boolean
  usage_rate?: string
}

export interface PromoPromoAmountData {
  currency_id?: number
  val_x?: number
  val_y?: number
  val_z?: number
}

export interface PromoPromoCardFundingData {
  funding?: EdbCardFunding
}

export interface PromoPromoCardLevelData {
  level?: EdbCardLevel
}

export interface PromoPromoCardTypeData {
  card_type?: EdbCardType
}

export interface PromoPromoCodeListData {
  code?: string
  id?: number
}

export interface PromoPromoCreditData {
  card_number?: string
}

export interface PromoReceiveNewUserPromoData {
  content?: string
  title?: string
}

export interface PromoReceiveNewUserPromoRes {
  code?: number
  data?: PromoReceiveNewUserPromoData
  message?: string
}

export interface PromoRequest {
  applicable_platform?: EdbPromoApplicablePlatform
  bonus?: number
  branches?: PromoBranchData[]
  brand_id?: number
  code?: string
  condition?: string
  cost_allocation?: EdbPromoCostAllocation
  duration?: number
  end_at?: string
  end_input_at?: string
  is_credit_limit?: number
  is_discount_movie_fee?: EdbPromoIsDiscountMovieFee
  is_first_purchase_limit?: number
  limit_cnt?: number
  limit_period?: number
  limit_times?: number
  member_tags?: PromoMemberTagData[]
  name?: string
  path?: string
  products?: EschemaProductData[]
  promo_amounts?: PromoPromoAmountData[]
  promo_card_fundings?: PromoPromoCardFundingData[]
  promo_card_levels?: PromoPromoCardLevelData[]
  promo_card_types?: PromoPromoCardTypeData[]
  promo_code_list?: PromoPromoCodeListData[]
  promo_content?: string
  promo_credits?: PromoPromoCreditData[]
  promo_title?: string
  promo_type?: number
  specified_txn_currency_id?: number
  start_at?: string
  start_input_at?: string
  tags?: PromoTagData[]
  time_type?: number
  total_limit_times?: number
}

export interface PromoResponse {
  applicable_platform?: EdbPromoApplicablePlatform
  bonus?: number
  branches?: PromoBranchData[]
  brand_id?: number
  code?: string
  condition?: string
  cost_allocation?: EdbPromoCostAllocation
  created_at?: string
  duration?: number
  end_at?: string
  end_input_at?: string
  id?: number
  is_credit_limit?: number
  is_discount_movie_fee?: EdbPromoIsDiscountMovieFee
  is_first_purchase_limit?: number
  limit_cnt?: number
  limit_period?: number
  limit_times?: number
  member_tags?: PromoMemberTagData[]
  name?: string
  path?: string
  products?: EschemaProductData[]
  promo_amounts?: PromoPromoAmountData[]
  promo_card_fundings?: PromoPromoCardFundingData[]
  promo_card_levels?: PromoPromoCardLevelData[]
  promo_card_types?: PromoPromoCardTypeData[]
  promo_code_list?: PromoPromoCodeListData[]
  promo_content?: string
  promo_credits?: PromoPromoCreditData[]
  promo_title?: string
  promo_type?: number
  specified_txn_currency_id?: number
  start_at?: string
  start_input_at?: string
  tags?: PromoTagData[]
  time_type?: number
  total_limit_times?: number
  updated_at?: string
}

export interface PromoTagData {
  group_name?: string
  id?: number
  name?: string
}

export interface PromoTransListResponse {
  code?: string
  id?: number
  name?: string
  promo_code_id?: number
  status?: EdbTransStatus
  trans_id?: number
}

export interface PromoTransRequest {
  condition?: string
  condition_status?: EdbTransFieldStatus
  lang_id?: number
  promo_code_id?: number
  promo_content?: string
  promo_content_status?: EdbTransFieldStatus
  promo_title?: string
  promo_title_status?: EdbTransFieldStatus
  status?: EdbTransStatus
}

export interface PromoTransResponse {
  condition?: string
  condition_status?: EdbTransFieldStatus
  created_at?: string
  id?: number
  lang_id?: number
  promo_code_id?: number
  promo_content?: string
  promo_content_status?: EdbTransFieldStatus
  promo_title?: string
  promo_title_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  updated_at?: string
}

export interface PromoCampaignCampaign {
  cover_image_url?: string
  description?: string
  id?: number
  promo_codes?: PromoCampaignCampaignCode[]
  title?: string
}

export interface PromoCampaignCampaignCode {
  code?: string
  content?: string
  end_at?: string
  status?: number
  title?: string
  usage_rate?: string
}

export interface PromoCampaignListResponse {
  cover_image?: string
  description?: string
  id?: number
  title?: string
}

export interface PromoCampaignPromoCode {
  code?: string
  id?: number
  name?: string
  promo_code_id?: number
}

export interface PromoCampaignRequest {
  cover_image?: string
  description?: string
  promo_codes?: PromoCampaignPromoCode[]
  title?: string
  trans?: PromoCampaignTransData[]
}

export interface PromoCampaignResponse {
  cover_image?: string
  created_at?: string
  description?: string
  id?: number
  promo_codes?: PromoCampaignPromoCode[]
  title?: string
  trans?: PromoCampaignTransData[]
  updated_at?: string
}

export interface PromoCampaignTransData {
  description?: string
  description_status?: EdbTransFieldStatus
  id?: number
  lang_id?: number
  status?: EdbTransStatus
  title?: string
  title_status?: EdbTransFieldStatus
}

export interface PsettingFields {
  booking_mode?: EdbProductBookingMode
  detail_mode?: EdbProductDetailMode
  edit_mode?: EdbProductEditMode
  limit_min_type?: EdbProductLimitMinType
  limit_type?: EdbProductLimitType
  new_release_mode?: EdbProductNewReleaseMode
  payment_mode?: EdbProductPaymentMode
  popular?: number
  popular_mode?: EdbProductPopularMode
  rank?: number
  rating?: number
  ref_price?: number
  ref_price_mode?: EdbProductRefPriceMode
  remain_mode?: EdbProductRemainMode
  remain_ref?: number
  rwg?: EdbRwG
  upgrade_mode?: EdbProductUpgradeMode
  yc_mode?: EdbProductYCMode
}

export interface PshalomFields {
  rate_plan_code?: string
  room_type_code?: string
}

export interface PsproductFields {
  allow_amount?: number
  collection_amount?: number
  commission?: number
  created_at?: string
  end_time?: string
  fee?: number
  id?: number
  net_price?: number
  orders_amount?: number
  orders_product_id?: number
  payment_set_id?: number
  product_id?: number
  product_name?: string
  quantity?: number
  start_time?: string
  transfer_amount?: number
  unhide_pid?: number
  unit_price?: number
  updated_at?: string
}

export interface PsupgradeFields {
  allow_amount?: number
  collection_amount?: number
  commission?: number
  created_at?: string
  fee?: number
  id?: number
  net_price?: number
  orders_amount?: number
  orders_upgrade_id?: number
  payment_set_id?: number
  quantity?: number
  transfer_amount?: number
  unit_price?: number
  updated_at?: string
  upgrade_id?: number
  upgrade_name?: string
}

export interface PwiseFields {
  room_type_code?: string
}

export interface QuestOrderQuestionnaireSerializer {
  code?: number
  data?: OrdersQuestionnaire[]
  message?: string
  total_count?: number
}

export interface RecalculateRecalculateReq {
  /** BusinessID for TABLEAPP */
  business_id?: number
  time_ranges?: RecalculateRecalculateTimeRange[]
}

export interface RecalculateRecalculateTimeRange {
  /** @example "yyyy-mm-ddThh:mm:ssZ" */
  end?: string
  /** @example "yyyy-mm-ddThh:mm:ssZ" */
  start?: string
}

export interface ReceiptApplyDocData {
  amount?: number
  created_at?: string
  date?: string
  doc_id?: number
  id?: number
  number?: string
  receipt_id?: number
  total_amount?: number
  type?: EdbSOADocumentType
  updated_at?: string
  vat_amount?: number
}

export interface ReceiptCreateApplyDocReq {
  amount: number
  id: number
}

export interface ReceiptCreateApplyDocRes {
  amount?: number
  id?: number
  total_amount?: number
  vat_amount?: number
}

export interface ReceiptCreateReq {
  bid: number
  /** @minItems 1 */
  create_apply_doc: ReceiptCreateApplyDocReq[]
  date: string
  memo?: string
  receipt?: ReceiptReceiptData
  receipt_amount?: number
  remark?: string
  subject?: string
}

export interface ReceiptCreateRes {
  amount?: number
  apply_doc?: ReceiptCreateApplyDocRes[]
  balance?: number
  bid: number
  country_code?: string
  /** @minItems 1 */
  create_apply_doc: ReceiptCreateApplyDocReq[]
  created_at?: string
  date: string
  due_date?: string
  due_date_type?: EdbSOADocumentDueDateType
  id?: number
  legal_entity_name?: string
  memo?: string
  mode?: EdbSOADocumentMode
  number?: string
  receipt?: ReceiptReceiptData
  receipt_amount?: number
  receipt_id?: number
  remark?: string
  status?: EdbSOADocumentStatus
  subject?: string
  total_amount?: number
  type?: EdbSOADocumentType
  updated_at?: string
  vat_amount?: number
  vat_apply_type?: EdbSOADocumentVATApplyType
  vat_number?: string
  vat_type?: EdbBranchVATType
}

export interface ReceiptListResponse {
  balance?: number
  bid?: number
  branch_name?: string
  brand_id?: number
  created_at?: string
  date?: string
  doc_id?: number
  invoice_doc_id?: number
  invoice_id?: number
  invoice_number?: string
  is_email_sent?: number
  number?: string
  payment_mode?: EdbSOAReceiptPaymentMode
  receipt_id?: number
  region_id?: number
  region_name?: string
  statement_id?: number
  status?: EdbSOADocumentStatus
  total_amount?: number
  type?: EdbSOADocumentType
  unhide_bid?: number
  updated_at?: string
}

export interface ReceiptReceiptData {
  effective_date: string
  payment_mode: EdbSOAReceiptPaymentMode
}

export interface ReceiptResponse {
  amount?: number
  apply_doc?: ReceiptApplyDocData[]
  attach?: DocattachFields[]
  balance?: number
  bid?: number
  branch_name?: string
  created_at?: string
  date?: string
  doc_id?: number
  due_date?: string
  due_date_type?: EdbSOADocumentDueDateType
  effective_date?: string
  invoice_doc_id?: number
  invoice_number?: string
  legal_entity_name?: string
  memo?: string
  number?: string
  payment_mode?: EdbSOAReceiptPaymentMode
  receipt_id?: number
  region_id?: number
  region_name?: string
  remark?: string
  statement_id?: number
  status?: EdbSOADocumentStatus
  subject?: string
  total_amount?: number
  type?: EdbSOADocumentType
  updated_at?: string
  vat_amount?: number
  vat_apply_type?: EdbSOADocumentVATApplyType
  vat_number?: string
  vat_type?: EdbBranchVATType
}

export interface ReceiptUpdateReq {
  memo?: string
  remark?: string
  subject?: string
}

export interface ReceiptUpdateRes {
  id?: number
  memo?: string
  remark?: string
  subject?: string
  updated_at?: string
}

export interface ReceiptBranchListRes {
  bid?: number
  currency_code?: string
  decimal_precision?: number
  default_name?: string
  legal_entity_name?: string
  name?: string
  unhide_bid?: number
}

export interface ReceiptDocListRes {
  balance?: number
  bid?: number
  date?: string
  id?: number
  number?: string
  status?: EdbSOADocumentStatus
  total_amount?: number
  type?: EdbSOADocumentType
  unhide_bid?: number
}

export interface RefundDocListResponse {
  code?: number
  data?: RefundListResponse[]
  message?: string
  total_count?: number
}

export interface RefundDocResponse {
  code?: number
  data?: RefundResponse
  message?: string
}

export interface RefundListResponse {
  action?: number
  auth_id?: string
  auth_type?: number
  bid?: number
  bonus?: number
  booking_time?: string
  branch_name?: string
  contact_phone?: string
  created_at?: string
  currency_code?: string
  discount?: number
  email?: string
  ex_order_id?: string
  ex_trade_id?: string
  fnc_rate?: number
  id?: number
  nickname?: string
  orders_amount?: number
  orders_created_at?: string
  orders_id?: number
  product_id?: number
  product_name?: string
  redeem_code?: string
  refund_type?: EdbRefundType
  region_id?: number
  region_name?: string
  rtn_code?: string
  rtn_msg?: string
  status?: EdbRefundStatus
  total_amount?: number
  trade_time?: string
  txn_amount?: number
  txn_currency_code?: string
  txn_pay_amount?: number
  txn_type?: EdbTxnType
  tz_location?: string
  uid?: number
  unhide_bid?: number
  unhide_orders_id?: number
  unhide_pid?: number
  unhide_uid?: number
  updated_at?: string
}

export interface RefundRequest {
  allow_amount: number
  fun_coin_amount?: number
  funnow_refund_amount?: number
  is_add_fun_coin?: boolean
  is_add_promo?: boolean
  is_bypass_refund?: boolean
  is_incapable?: boolean
  is_set_incapable?: boolean
  is_update_payment?: boolean
  is_update_statement_at?: boolean
  merchant_refund_amount?: number
  orders_id?: number
  payment?: SpaymentCompData
  promos?: SpromoPromoData[]
  statement_at?: string
  txn_allow_amount: number
  type?: EdbRefundType
}

export interface RefundResponse {
  action?: number
  auth_id?: string
  auth_type?: number
  bid?: number
  bonus?: number
  booking_time?: string
  branch_name?: string
  contact_phone?: string
  created_at?: string
  currency_code?: string
  discount?: number
  email?: string
  ex_order_id?: string
  ex_trade_id?: string
  fnc_rate?: number
  id?: number
  nickname?: string
  orders_amount?: number
  orders_created_at?: string
  orders_id?: number
  product_id?: number
  product_name?: string
  redeem_code?: string
  refund_type?: EdbRefundType
  region_id?: number
  region_name?: string
  rtn_code?: string
  rtn_msg?: string
  status?: EdbRefundStatus
  total_amount?: number
  trade_time?: string
  txn_amount?: number
  txn_currency_code?: string
  txn_pay_amount?: number
  txn_type?: EdbTxnType
  tz_location?: string
  uid?: number
  unhide_bid?: number
  unhide_orders_id?: number
  unhide_pid?: number
  unhide_uid?: number
  updated_at?: string
}

export interface RegionBannerData {
  end_at?: string
  id?: number
  start_at?: string
  status?: EdbBannerStatus
  title?: string
}

export interface RegionBossNowUserData {
  auth_id?: string
  display_name?: string
  email?: string
  id?: number
  status?: string
  uid?: number
}

export interface RegionBrandBannerData {
  end_at?: string
  id?: number
  start_at?: string
  status?: EdbBannerStatus
  title?: string
  type?: EdbRegionToBannerType
}

export interface RegionCuisineFilterData {
  id?: number
  name?: string
}

export interface RegionDocListResponse {
  code?: number
  data?: RegionListResponse[]
  message?: string
  total_count?: number
}

export interface RegionDocRegionSearhKeysResp {
  code?: number
  list?: SearchplaceSearch[]
  message?: string
  total_count?: number
}

export interface RegionDocResponse {
  code?: number
  data?: RegionResponse
  message?: string
}

export interface RegionDocStatusRes {
  code?: number
  data?: RegionUpdateStatusResponse
  message?: string
}

export interface RegionDocTransListResponse {
  code?: number
  data?: RegionTransListResponse[]
  message?: string
  total_count?: number
}

export interface RegionDocTransResponse {
  code?: number
  data?: RegionTransResponse
  message?: string
}

export interface RegionDocWebImageRes {
  code?: number
  data?: RegionUpdateWebImgResponse
  message?: string
}

export interface RegionEventNewsData {
  end_at?: string
  id?: number
  start_at?: string
  status?: EdbEventNewsStatus
  title?: string
}

export interface RegionFlashSaleProductTagsData {
  day_mode?: StagDayMode
  has_cus_num?: StagHasCusNum
  has_time_filter?: StagHasTimeFilter
  tags?: FregionTagResData[]
}

export interface RegionFlashSaleProductTagsRes {
  code?: number
  data?: RegionFlashSaleProductTagsData
  message?: string
}

export interface RegionGetUpcomingMoviesRes {
  code?: number
  data?: RegionUpcomingMovieData[]
  message?: string
  timezone?: string
}

export interface RegionGiftCardData {
  id?: number
  name?: string
  status?: EdbGiftCardStatus
}

export interface RegionHotSearchData {
  hot_search_type?: EdbHotSearchType
  id?: number
  keyword?: string
}

export interface RegionLangData {
  id?: number
  name?: string
}

export interface RegionLanguage {
  code?: string
  lang_id?: number
  name?: string
}

export interface RegionListResponse {
  brand_id?: number
  brand_name?: string
  cover_image?: string
  currency_id?: number
  id?: number
  name?: string
  onboard?: EdbRegionOnBoard
  rank?: number
  status?: EdbRegionStatus
  trans?: RegionListTransData[]
}

export interface RegionListTransData {
  id?: number
  lang_code?: string
  lang_id?: number
  region_id?: number
  region_name?: string
  trans_status?: EdbTransStatus
}

export interface RegionOtherRegionData {
  cover_image?: string
  id?: number
  name?: string
}

export interface RegionOtherRegionRes {
  list?: RegionOtherRegionData[]
  total_count?: number
}

export interface RegionProduct {
  bid?: number
  branch_name?: string
  category_id?: number
  category_name?: string
  cover_image?: string
  currency_code?: string
  currency_id?: number
  currency_symbol?: string
  discount?: number
  distance?: string
  is_elite?: boolean
  is_favorite?: boolean
  is_hot?: boolean
  is_limited?: boolean
  is_new_product?: boolean
  is_now?: boolean
  is_vip_tag?: boolean
  number_of_comments?: number
  onboard?: EdbProductOnBoard
  popular?: number
  product_id?: number
  product_name?: string
  rating?: number
  rrp?: number
  sell_time?: string
  sell_time_type?: SerializerSellTimeType
  tags?: RegionProductTag[]
}

export interface RegionProductTag {
  id?: number
  name?: string
}

export interface RegionRecommendedProductsRes {
  code?: number
  data?: RegionProduct[]
  message?: string
  total_count?: number
}

export interface RegionRegionDetectInfo {
  greetings?: string
  id?: number
  languages?: SregionLangInfo[]
  name?: string
  onboard?: EdbRegionOnBoard
}

export interface RegionRegionDetectRes {
  greetings?: string
  id?: number
  languages?: RegionLanguage[]
  name?: string
  onboard?: EdbRegionOnBoard
}

export interface RegionRegionInfoData {
  banner?: RegionBanner[]
  brand?: RegionCategory[]
  brand_banners?: RegionBanner[]
  category?: RegionCategory[]
  cuisine?: RegionCategory[]
  cuisine_filter?: RegionCuisineFilter
  currency_id?: EdbCurrencyID
  event_news?: RegionEventNews[]
  greetings?: string
  id?: number
  keywords?: string[]
  location?: RegionCategory[]
  meta_description?: string
  name?: string
  onboard?: EdbRegionOnBoard
  theme?: RegionCategory[]
  web_greetings?: string
  web_image?: string
  web_subtitle?: string
}

export interface RegionRegionTaxData {
  id?: number
  name?: string
  tag_group_name?: string
  tag_id?: number
  tag_name?: string
  tax_rate?: number
}

export interface RegionRequest {
  banner?: RegionBannerData[]
  bossnow_user?: RegionBossNowUserData[]
  branch_cs_channel?: string
  brand_banner?: RegionBrandBannerData[]
  brand_id?: number
  country_code?: string
  cover_image?: string
  cs_channel?: string
  cuisine_filter?: RegionCuisineFilterData[]
  currency_id?: number
  def_fee_be_borne_by?: EdbBranchFeeBeBorneBy
  event_news?: RegionEventNewsData[]
  giftcard?: RegionGiftCardData[]
  greetings?: string
  hot_search?: RegionHotSearchData[]
  is_receipt_in_stmt?: EdbRegionIsReceiptInStmt
  lang_id?: number
  lat?: number
  line_spot_sync?: EdbRegionLINESpotSync
  lng?: number
  meta_description?: string
  name?: string
  onboard?: EdbRegionOnBoard
  rank?: number
  receipt_tmpl_id?: number
  receipt_tmpl_name?: string
  region_lang?: RegionLangData[]
  region_tax?: RegionRegionTaxData[]
  search_bar_keyword?: RegionHotSearchData[]
  send_receipt?: EdbRegionSendReceipt
  soa_email_tmpl_id?: number
  soa_email_tmpl_name?: string
  soa_note_ci_tmpl_id?: number
  soa_note_ci_tmpl_name?: string
  soa_note_ecv_tmpl_id?: number
  soa_note_ecv_tmpl_name?: string
  soa_receipt_tmpl_id?: number
  soa_receipt_tmpl_name?: string
  statement_tmpl?: string
  status?: EdbRegionStatus
  stmt_email_tmpl_id?: number
  stmt_email_tmpl_name?: string
  stmt_inv_tmpl_id?: number
  stmt_inv_tmpl_name?: string
  tax_adj_rate?: number
  title?: RegionTitleData[]
  trans?: RegionTransData[]
  tz_location?: string
  web_greetings?: string
  web_image?: string
  web_subtitle?: string
}

export interface RegionResponse {
  banner?: RegionBannerData[]
  bossnow_user?: RegionBossNowUserData[]
  branch_cs_channel?: string
  brand_banner?: RegionBrandBannerData[]
  brand_id?: number
  brand_name?: string
  country_code?: string
  cover_image?: string
  created_at?: string
  cs_channel?: string
  cuisine_filter?: RegionCuisineFilterData[]
  currency_code?: string
  currency_id?: number
  decimal_precision?: number
  def_fee_be_borne_by?: EdbBranchFeeBeBorneBy
  event_news?: RegionEventNewsData[]
  giftcard?: RegionGiftCardData[]
  greetings?: string
  hot_search?: RegionHotSearchData[]
  id?: number
  is_receipt_in_stmt?: EdbRegionIsReceiptInStmt
  lang_id?: number
  lat?: number
  line_spot_sync?: EdbRegionLINESpotSync
  lng?: number
  meta_description?: string
  name?: string
  onboard?: EdbRegionOnBoard
  rank?: number
  receipt_tmpl_id?: number
  receipt_tmpl_name?: string
  region_lang?: RegionLangData[]
  region_tax?: RegionRegionTaxData[]
  search_bar_keyword?: RegionHotSearchData[]
  send_receipt?: EdbRegionSendReceipt
  soa_email_tmpl_id?: number
  soa_email_tmpl_name?: string
  soa_note_ci_tmpl_id?: number
  soa_note_ci_tmpl_name?: string
  soa_note_ecv_tmpl_id?: number
  soa_note_ecv_tmpl_name?: string
  soa_receipt_tmpl_id?: number
  soa_receipt_tmpl_name?: string
  statement_tmpl?: string
  status?: EdbRegionStatus
  stmt_email_tmpl_id?: number
  stmt_email_tmpl_name?: string
  stmt_inv_tmpl_id?: number
  stmt_inv_tmpl_name?: string
  tax_adj_rate?: number
  title?: RegionTitleData[]
  trans?: RegionTransData[]
  tz_location?: string
  updated_at?: string
  web_greetings?: string
  web_image?: string
  web_subtitle?: string
}

export interface RegionSpecificCategoryRes {
  category_id?: number
  name?: string
}

export interface RegionTitleData {
  id?: number
  name?: string
}

export interface RegionTransData {
  greetings?: string
  greetings_status?: EdbTransFieldStatus
  id?: number
  is_for_delete?: boolean
  lang_id?: number
  meta_description?: string
  meta_description_status?: EdbTransFieldStatus
  name?: string
  name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  web_greetings?: string
  web_greetings_status?: EdbTransFieldStatus
  web_subtitle?: string
  web_subtitle_status?: EdbTransFieldStatus
}

export interface RegionTransListResponse {
  code?: string
  id?: number
  name?: string
  region_id?: number
  status?: EdbTransStatus
  trans_id?: number
}

export interface RegionTransRequest {
  greetings?: string
  greetings_status?: EdbTransFieldStatus
  lang_id?: number
  meta_description?: string
  meta_description_status?: EdbTransFieldStatus
  name?: string
  name_status?: EdbTransFieldStatus
  region_id?: number
  status?: EdbTransStatus
  web_greetings?: string
  web_greetings_status?: EdbTransFieldStatus
  web_subtitle?: string
  web_subtitle_status?: EdbTransFieldStatus
}

export interface RegionTransResponse {
  created_at?: string
  greetings?: string
  greetings_status?: EdbTransFieldStatus
  id?: number
  lang_id?: number
  meta_description?: string
  meta_description_status?: EdbTransFieldStatus
  name?: string
  name_status?: EdbTransFieldStatus
  region_id?: number
  status?: EdbTransStatus
  updated_at?: string
  web_greetings?: string
  web_greetings_status?: EdbTransFieldStatus
  web_subtitle?: string
  web_subtitle_status?: EdbTransFieldStatus
}

export interface RegionUpcomingMovieData {
  bid?: number
  branch_name?: string
  duration?: number
  group_id?: number
  movie_name?: string
  offline_refund_limit?: number
  online_refund_limit?: number
  poster?: string
  session_hall?: string
  session_hall_id?: number
  session_id?: number
  session_time?: string
  session_version?: string
}

export interface RegionUpdateStatusResponse {
  id?: number
  status?: number
  updated_at?: string
}

export interface RegionUpdateWebImgResponse {
  id?: number
  updated_at?: string
  web_image?: string
}

export interface RegionBanner {
  cover_image?: string
  description?: string
  id?: number
  link_target?: string
  link_type?: EdbBannerLinkType
  title?: string
}

export interface RegionCategory {
  cover_image?: string
  icon_image?: string
  id?: EdbCategoryID
  name?: string
  onboard?: EdbCategoryOnBoard
  web_onboard?: EdbCategoryOnBoard
}

export interface RegionCuisineFilter {
  id?: number
  tags?: RegionFilterTag[]
  title?: string
}

export interface RegionEventNews {
  cover_image?: string
  id?: number
  link_url?: string
  status?: EdbEventNewsStatus
  title?: string
}

export interface RegionFilterTag {
  id?: number
  name?: string
}

export interface RemarkListResponse {
  id?: number
  name?: string
  title?: string
  type?: number
}

export interface RemarkRequest {
  content?: string
  name?: string
  placeholder?: string
  regex_type?: EdbProductRemarkRegexType
  subhead?: string
  subs?: RemarkSubData[]
  title?: string
  trans?: RemarkTransData[]
  type?: EdbProductRemarkType
}

export interface RemarkResponse {
  content?: string
  created_at?: string
  id?: number
  name?: string
  placeholder?: string
  regex_type?: EdbProductRemarkRegexType
  subhead?: string
  subs?: RemarkSubData[]
  title?: string
  trans?: RemarkTransData[]
  type?: EdbProductRemarkType
  updated_at?: string
}

export interface RemarkSubData {
  id?: number
  is_for_delete?: boolean
  rank?: number
  sub_title?: string
}

export interface RemarkTransData {
  content?: string
  content_status?: EdbTransFieldStatus
  id?: number
  is_for_delete?: boolean
  lang_id?: number
  status?: EdbTransStatus
  subs?: RemarkTransSubData[]
  title?: string
  title_status?: EdbTransFieldStatus
}

export interface RemarkTransListRes {
  code?: string
  id?: number
  name?: string
  remark_id?: number
  status?: EdbTransStatus
  trans_id?: number
}

export interface RemarkTransReq {
  content?: string
  content_status?: EdbTransFieldStatus
  lang_id?: number
  remark_id?: number
  status?: EdbTransStatus
  subs?: RemarkTransSubData[]
  title?: string
  title_status?: EdbTransFieldStatus
}

export interface RemarkTransRes {
  content?: string
  content_status?: EdbTransFieldStatus
  created_at?: string
  id?: number
  lang_id?: number
  remark_id?: number
  status?: EdbTransStatus
  subs?: RemarkTransSubData[]
  title?: string
  title_status?: EdbTransFieldStatus
  updated_at?: string
}

export interface RemarkTransSubData {
  id?: number
  sub_id?: number
  sub_title?: string
  sub_title_status?: EdbTransFieldStatus
}

export interface RemarkUploadContentImageResponse {
  content?: string
  id?: number
  updated_at?: string
}

export interface RemarkgroupListResponse {
  id?: number
  name?: string
  type?: number
}

export interface RemarkgroupRemarkData {
  id?: number
  is_required?: EdbRemarkIsRequired
  name?: string
  title?: string
  type?: EdbProductRemarkType
}

export interface RemarkgroupResponse {
  created_at?: string
  id?: number
  name?: string
  remark?: RemarkgroupRemarkData[]
  type?: EdbRemarkGroupType
  updated_at?: string
}

export interface RequestsReceiveNewUserPromoReq {
  currency_id?: number
  region_id?: number
  source: string
}

export interface ReservationDetail {
  booking_time?: string
  cust_num?: number
  email?: string
  id?: number
  nickname?: string
  phone?: string
}

export interface ReservationDocListResponse {
  code?: number
  data?: ReservationListResponse[]
  message?: string
  total_count?: number
}

export interface ReservationDocResponse {
  code?: number
  data?: ReservationResponse
  message?: string
}

export interface ReservationEditRes {
  code?: number
  data?: ReservationDetail
  message?: string
}

export interface ReservationListResponse {
  bid?: number
  booking_time?: string
  branch_name?: string
  created_at?: string
  cust_num?: number
  email?: string
  gender?: number
  id?: number
  link_status?: EdbReservationLinkStatus
  member_phone?: string
  member_status?: EdbMemberStatus
  nickname?: string
  phone?: string
  product_id?: number
  product_name?: string
  region_id?: number
  region_name?: string
  reservation_link?: string
  source?: EdbReservationSource
  status?: EdbReservationStatus
  tz_location?: string
  uid?: number
  unhide_bid?: number
  unhide_id?: number
  unhide_pid?: number
  unhide_uid?: number
  updated_at?: string
  walk_in_time?: string
}

export interface ReservationReservationRemarkData {
  content?: string
  created_at?: string
  id?: number
  remark_id?: number
  reservation_id?: number
  title?: string
  updated_at?: string
}

export interface ReservationResponse {
  bid?: number
  booking_time?: string
  branch_name?: string
  branch_note?: string
  created_at?: string
  cust_num?: number
  cust_title?: string
  email?: string
  gender?: number
  id?: number
  link_status?: EdbReservationLinkStatus
  member_note?: string
  member_phone?: string
  nickname?: string
  phone?: string
  product_id?: number
  product_name?: string
  region_id?: number
  region_name?: string
  remark?: ReservationReservationRemarkData[]
  reservation_link?: string
  source?: EdbReservationSource
  status?: EdbReservationStatus
  tz_location?: string
  uid?: number
  unhide_bid?: number
  unhide_id?: number
  unhide_pid?: number
  unhide_uid?: number
  updated_at?: string
  walk_in_time?: string
}

export interface ResourceDocListResponse {
  code?: number
  data?: ResourceListResponse[]
  message?: string
  total_count?: number
}

export interface ResourceDocResponse {
  code?: number
  data?: ResourceResponse
  message?: string
}

export interface ResourceListResponse {
  bid?: number
  branch_name?: string
  brand_id?: number
  brand_name?: string
  created_at?: string
  name?: string
  region_id?: number
  region_name?: string
  rg_id?: number
  rg_name?: string
  rid?: number
  status?: number
  unhide_bid?: number
  unhide_rgid?: number
  unhide_rid?: number
  updated_at?: string
}

export interface ResourceProductData {
  id?: number
  name?: string
}

export interface ResourceRequest {
  name?: string
  product?: ResourceProductData[]
  rg_id?: number
  status?: number
}

export interface ResourceResourceGroup {
  name?: string
  resources?: CommonResourceResource[]
  rg_id?: number
}

export interface ResourceResponse {
  bid?: number
  branch_name?: string
  created_at?: string
  name?: string
  product?: ResourceProductData[]
  rg_id?: number
  rg_name?: string
  rid?: number
  status?: number
  unhide_bid?: number
  unhide_rgid?: number
  unhide_rid?: number
  updated_at?: string
}

export interface ReviewReviewListResp {
  code?: number
  data?: ReviewReviewWrap
  message?: string
}

export interface ReviewReviewResponse {
  avg_rating?: number
  comments?: EatigoReviewReviewData[]
  is_new?: boolean
  /** @example [0,1,3,7,8] */
  score?: number[]
  total_count?: number
}

export interface ReviewReviewTag {
  id?: number
  name?: string
}

export interface ReviewReviewTagData {
  tag_id?: number
  tag_name?: string
}

export interface ReviewReviewWrap {
  avg_rating?: number
  comment?: FunnowReviewReviewData[]
  /** @example [0,1,3,7,8] */
  score?: number[]
  total_count?: number
}

export interface ReviewTagCount {
  count?: number
  id?: number
  name?: string
}

export interface ReviewTagCountData {
  tags?: SreviewTagCountInfo[]
}

export interface ReviewTagCountRes {
  code?: number
  data?: ReviewTagCountData
  message?: string
}

export interface ReviewTagsCountResponse {
  tags?: ReviewTagCount[]
  total_reviews_count?: number
}

export interface RscGroupDocListResponse {
  code?: number
  data?: RscGroupListResponse[]
  message?: string
  total_count?: number
}

export interface RscGroupDocResponse {
  code?: number
  data?: RscGroupResponse
  message?: string
}

export interface RscGroupListResponse {
  bid?: number
  branch_name?: string
  brand_id?: number
  brand_name?: string
  created_at?: string
  name?: string
  rank?: number
  region_id?: number
  region_name?: string
  rg_id?: number
  unhide_bid?: number
  unhide_rgid?: number
  updated_at?: string
}

export interface RscGroupRequest {
  bid?: number
  name?: string
  rank?: number
  resource?: RscGroupResourceData[]
}

export interface RscGroupResourceData {
  name?: string
  rid?: number
}

export interface RscGroupResponse {
  bid?: number
  branch_name?: string
  created_at?: string
  name?: string
  rank?: number
  resource?: RscGroupResourceData[]
  rg_id?: number
  unhide_bid?: number
  unhide_rgid?: number
  updated_at?: string
}

export interface RwgBatchAvailabilityLookupReq {
  merchant_id?: string
  slot_time?: RwgSlotTime[]
}

export interface RwgBatchAvailabilityLookupResp {
  slot_time_availability?: RwgSlotTimeAvailability[]
}

export interface RwgBooking {
  booking_id?: string
  /** Required for CreateBooking and UpdateBooking:modify, but not UpdateBooking:cancel */
  slot?: RwgSlot
  status?: RwgclientBookingStatus
  /** Required for CreateBooking */
  user_information?: RwgUserInformation
}

export interface RwgBookingFailure {
  cause?: RwgclientFailureCause
  description?: string
}

export interface RwgCreateBookingReq {
  additional_request?: string
  idempotency_token?: string
  payment_information?: RwgPaymentInformation
  slot?: RwgSlot
  user_information?: RwgUserInformation
}

export interface RwgCreateBookingResp {
  booking?: RwgBooking
  booking_failure?: RwgBookingFailure
}

export interface RwgPaymentInformation {
  prepayment_status?: RwgclientPrepaymentStatus
}

export interface RwgSlot {
  confirmation_mode?: string
  duration_sec?: string
  merchant_id?: string
  resources?: RwgSlotTimeResource
  service_id?: string
  /** @example "0" */
  start_sec?: string
}

export interface RwgSlotTime {
  duration_sec?: string
  resource_ids?: RwgSlotTimeResource
  service_id?: string
  /** @example "0" */
  start_sec?: string
}

export interface RwgSlotTimeAvailability {
  available?: boolean
  slot_time?: RwgSlotTime
}

export interface RwgSlotTimeResource {
  party_size?: number
}

export interface RwgUpdateBookingReq {
  booking?: RwgBooking
}

export interface RwgUpdateBookingResp {
  booking?: RwgBooking
  booking_failure?: RwgBookingFailure
}

export interface RwgUserInformation {
  email?: string
  family_name?: string
  given_name?: string
  /** Telephone example: "+886 916 123 123" */
  telephone?: string
  user_id?: string
}

export enum RwgclientBookingStatus {
  BookingStatusConfirmed = 'CONFIRMED',
  BookingStatusFailed = 'FAILED',
  BookingStatusCanceled = 'CANCELED',
}

export enum RwgclientFailureCause {
  FailureCauseSlotUnavailable = 'SLOT_UNAVAILABLE',
  FailureSlotAlreadyBookedByUser = 'SLOT_ALREADY_BOOKED_BY_USER',
  FailureCauseBookingNotCancellable = 'BOOKING_NOT_CANCELLABLE',
}

export enum RwgclientPrepaymentStatus {
  PrepaymentStatusNotProvided = 'PREPAYMENT_NOT_PROVIDED',
}

export enum SattendeeDisplayStatus {
  DisplayStatusNone = 0,
  DisplayStatusUnfinished = 1,
  DisplayStatusFinished = 2,
}

export interface ScancelruleCancelSetting {
  mode?: ScancelruleCancelSettingMode
  rules?: ScancelruleCancelSettingRule[]
}

export enum ScancelruleCancelSettingMode {
  CancelSettingModeDay = 0,
  CancelSettingModeDate = 1,
  CancelSettingModeHour = 2,
}

export interface ScancelruleCancelSettingRule {
  /** YYYY-MM-DD */
  date?: string
  day?: number
  deposit_amount?: number
  deposit_percent?: number
  hour?: number
}

export interface ScancelruleFunbookCancelRule {
  deposit_amount?: number
  deposit_percent?: number
  is_deposit_amount?: boolean
  is_deposit_percent?: boolean
  time?: any
}

export interface ScasbinRoutePath {
  method?: string
  path?: string
}

export interface ScategoryCategoryMapIcon {
  id?: number
  map_icon_color?: string
  map_icon_image?: string
}

export interface ScategoryCategoryQueryResult {
  id?: number
  region_id?: number
}

export interface ScategoryRegionCategory {
  cover_image?: string
  icon_image?: string
  id?: number
  name?: string
  /** 0: Open, 1: Close */
  onboard?: EdbCategoryOnBoard
  /** 0: Open, 1: Close */
  web_onboard?: EdbCategoryOnBoard
}

export interface ScategorySERP {
  answer?: string
  question?: string
}

export interface ScategorySubCategory {
  cover_image?: string
  icon_image?: string
  id?: number
  name?: string
  onboard?: EdbCategoryOnBoard
  type?: EdbCategoryType
  web_onboard?: number
}

export interface ScategoryTag {
  id?: number
  name?: string
}

export interface SchemaMonth {
  time?: string
  valid?: boolean
}

export interface SearchKeywordData {
  list?: string[]
  total_count?: number
}

export interface SearchplaceSearch {
  keys?: string[]
  title?: string
}

export enum SerializerSellTimeType {
  SellTimeHidden = 'hidden',
  SellTimeNow = 'now',
  SellTimeHour = 'hour',
  SellTimeDay = 'day',
}

export interface ServicesOrdersRemark {
  content?: string
  remark_id?: number
  sub_id?: number
}

export enum ServicesRescheduleStatus {
  Reschedulable = 0,
  UnReschedulableTime = 1,
  Rescheduled = 2,
  UnReschedulableProduct = 3,
  UnReschedulableUsed = 4,
  UnReschedulableCanceled = 5,
  UnReschedulableGroupMember = 6,
  UnReschedulableSoldOut = 7,
}

export interface SeventnewsRegionEventNews {
  cover_image?: string
  id?: number
  link_url?: string
  status?: EdbEventNewsStatus
  title?: string
}

export interface SfilterCategoryFilter {
  filters?: SfilterFilterInfo[]
  title?: string
}

export interface SfilterComponent {
  /** component name */
  name?: 'price' | 'date' | 'time_range' | 'headcount' | 'rating' | 'date_range'
  /** component options */
  options?: SfilterOption[]
  /** component title */
  title?: string
  /** type of component */
  type?: 'radio' | 'range_bar' | 'scroll_num_h' | 'time_range_checkbox' | 'date_range_calendar'
}

export interface SfilterFilterInfo {
  id?: number
  title?: string
}

export interface SfilterFilterTag {
  id?: number
  tags?: SfilterTag[]
  title?: string
}

export interface SfilterOption {
  components?: SfilterComponent[]
  /** start time in time range mode */
  from?: string
  max?: string
  min?: string
  /** option title */
  title?: string
  /** end time in time range mode */
  to?: string
  /** option value */
  value?: string
}

export interface SfilterTag {
  id?: number
  name?: string
}

export interface ShelpChild {
  content?: string
  hid?: number
  parent_id?: number
  title?: string
}

export interface ShelpHelp {
  children?: ShelpChild[]
  hid?: number
  parent_id?: number
  title?: string
}

export interface SinvoiceAllowRes {
  allowance_amount?: number
  allowance_notify?: EdbAllowanceNotify
  created_at?: string
  customer_name?: string
  ia_allow_no?: string
  ia_date?: string
  ia_invoice_no?: string
  ia_remain_allowance_amt?: number
  id?: number
  invalid_reason?: string
  invoice_no?: string
  item_amount?: string
  item_count?: string
  item_name?: string
  item_price?: string
  item_tax_type?: string
  item_word?: string
  merchant_id?: string
  notify_mail?: string
  notify_phone?: string
  rtn_code?: string
  rtn_msg?: string
  status?: EdbInvoiceAllowStatus
  timestamp?: number
  updated_at?: string
}

export interface SinvoiceInvalidRequest {
  reason?: string
}

export interface SinvoiceInvalidResponse {
  id?: number
  invalid_reason?: string
  invoice_number?: string
  orders_id?: number
  rtn_code?: string
  rtn_msg?: string
  status?: EdbInvoiceStatus
  updated_at?: string
}

export interface SinvoiceRequest {
  carruer_num?: string
  carruer_type?: string
  clearance_mark?: string
  customer_addr?: string
  customer_email?: string
  customer_id?: string
  customer_identifier?: string
  customer_name?: string
  customer_phone?: string
  donation?: string
  inv_create_date?: string
  inv_type?: string
  invalid_reason?: string
  invoice_date?: string
  invoice_number?: string
  invoice_remark?: string
  item_amount?: string
  item_count?: string
  item_name?: string
  item_price?: string
  item_tax_type?: string
  item_word?: string
  love_code?: string
  merchant_id?: string
  orders_id?: number
  print?: string
  random_number?: string
  relate_number?: string
  rtn_code?: string
  rtn_msg?: string
  sales_amount?: number
  status?: EdbInvoiceStatus
  tax_type?: string
  timestamp?: number
  vat?: string
}

export interface SmovieBonus {
  qty?: number
}

export interface SmovieMovieTicket {
  id?: number
  qty?: number
}

export interface SmoviePromo {
  promo_code_list_id?: number
  qty?: number
}

export interface SmovieSeat {
  name?: string
  tag?: string
  type?: number
}

export interface SmovieSeatData {
  best_area?: number[][]
  cinema_id?: string
  cinema_trans_id?: string
  default_seats?: number[][]
  hall?: string
  max_select_seat?: number
  seats?: SmovieSeat[][]
  session_id?: number
}

export enum SoccupationAction {
  ActionAvailable = 'available',
  ActionUnavailable = 'unavailable',
}

export enum SoccupationOccupationType {
  OccupationTypeUnavailable = 0,
  OccupationTypeSold = 1,
  OccupationTypeRedeemed = 2,
}

export interface SorderAdditionForCreate {
  count?: number
  pid?: number
  productID?: number
}

export enum SorderLabelKey {
  LabelKeyVIP = 'vip',
  LabelKeyGroup = 'group',
  LabelKeyFlash = 'flash',
  LabelKeyElite = 'elite',
}

export enum SorderSearchType {
  SearchTypeOrdersID = 'order_id',
  SearchTypePhone = 'phone',
}

export enum SorderUserOrderType {
  UserOrderTypeFunNow = 1,
  UserOrderTypeFunBook = 2,
  UserOrderTypeFunBookV2 = 3,
}

export enum SostatusMgrStatus {
  MgrStatusNotArrived = 0,
  MgrStatusArrived = 1,
  MgrStatusRefund = 2,
  MgrStatusSeventyPercentRefund = 3,
  MgrStatusPartialRefund = 4,
  MgrStatusCustomerCancel = 5,
  MgrStatusBranchCancel = 6,
  MgrStatusNoShow = 7,
  MgrStatusConfirmed = 8,
  MgrStatusFinished = 9,
}

export interface SpaymentCompData {
  adjustment?: number
  compensation?: number
  note?: string
  statement_note?: string
}

export interface SproductOnBoard {
  cover_image?: string
  description?: string
  duration?: number
  fbrrp?: number
  images?: string[]
  is_crossday?: EdbProductIsCrossDay
  min_price?: number
  onboard?: EdbProductOnBoard
  product_id?: number
  product_name?: string
  rank?: SproductOnBoardRank
  sales_mode?: EdbSalesMode
  sub_item_max_sum?: number
  sub_item_min_sum?: number
  symbol_code?: string
}

export enum SproductProductLimit {
  ProductLimitNone = 0,
  ProductLimitOneForEach = 1,
  ProductLimitAtLeastOne = 2,
  ProductLimitAtMostOne = 3,
}

export enum SproductTimeUnit {
  TimeUnitMinute = 0,
  TimeUnitHour = 1,
  TimeUnitDay = 2,
}

export type SproductOnBoardRank = object

export interface SproductsetMonthPeriodData {
  /** @example ["/web/images/funnow.svg","/web/images/niceday.svg"] */
  channel_icons?: string[]
  checkout_time?: string
  cutoff_time?: string
  discount_tiers?: SproductsetMonthPeriodDataDiscountTier[]
  /** @example "2024-06-01 23:45" */
  end_date_time?: string
  exposure_start?: string
  interval?: number
  /**
   * 0: NoSettingMode, 1: WeekdayMode, 2: DateMode, 3: HolidayMode, 4: BlockMode,
   * 5: FlashMode, 6: NotEligibleFlashMode, 7: DayOffMode, 8: LastMinuteMode
   */
  mode?: EdbProductSetMode
  name?: string
  /** @example "2024-06-01 23:45" */
  origin_end_date_time?: string
  /** @example "2024-06-01 00:00" */
  origin_start_date_time?: string
  overlap_times?: SproductsetMonthPeriodDataOverlap[]
  price?: number
  price_id?: number
  resource_groups?: SproductsetMonthPeriodDataResourceGroup[]
  sell_limit?: number
  set_id?: number
  set_time_ranges?: SproductsetMonthPeriodDataSetRange[]
  sold_count?: number
  /** @example "2024-06-01 00:00" */
  start_date_time?: string
  /** 0: Normal, 1: Session */
  type?: EdbProductSetPriceType
}

export interface SproductsetMonthPeriodDataDiscountTier {
  available?: number
  discount?: number
  limit?: number
  sold_count?: number
}

export interface SproductsetMonthPeriodDataOverlap {
  mode?: EdbProductSetMode
  time?: string
}

export interface SproductsetMonthPeriodDataResourceGroup {
  rg_id?: number
  rg_name?: string
}

export interface SproductsetMonthPeriodDataSetRange {
  end?: string
  start?: string
}

export interface SpromoPromo {
  discount?: number
  is_multiple?: boolean
  name?: string
  promo_id?: number
  refund?: boolean
  refund_quantity?: number
  usage_quantity?: number
}

export interface SpromoPromoData {
  promo_code_id?: number
  promo_code_list_id?: number
}

export interface SregionLangInfo {
  code?: string
  lang_id?: number
  name?: string
}

export interface SregionRegionsListInfo {
  calling_code?: string
  country_code?: string
  currency_id?: number
  en_name?: string
  greetings?: string
  lang_id?: number
  languages?: SregionLangInfo[]
  lat?: number
  lng?: number
  name?: string
  onboard?: EdbRegionOnBoard
  region_id?: number
  region_image?: string
}

export interface SregionSaleRegionsListInfo {
  region_id?: number
}

export interface SrescheduleruleRescheduleSetting {
  mode?: SrescheduleruleRescheduleSettingMode
  rule?: SrescheduleruleRescheduleSettingRule
}

export enum SrescheduleruleRescheduleSettingMode {
  RescheduleSettingModeDay = 0,
  RescheduleSettingModeDate = 1,
  RescheduleSettingModeHour = 2,
}

export interface SrescheduleruleRescheduleSettingRule {
  /** YYYY-MM-DD HH:mm */
  date?: string
  day?: number
  hour?: number
}

export enum SreviewReviewSortType {
  ReviewSortDefault = 'default',
  ReviewSortLatest = 'latest',
  ReviewSortRatingLow = 'rating_low',
  ReviewSortRatingHigh = 'rating_high',
  ReviewSortUseful = 'useful',
  ReviewSortNewScore = 'score',
}

export interface SreviewReviewTag {
  tag_id?: number
  tag_name?: string
}

export interface SreviewTagCountInfo {
  count?: number
  rating?: number
  tag_id?: number
  tag_name?: string
}

export interface SselldateSelldate {
  selltimes?: SselldateSelltime[]
  time_range_name?: string
}

export type SselldateSelldates = Record<string, SselldateSelldate[]>

export interface SselldateSelltime {
  origin_price?: number
  price?: number
  remain_count?: number
  selltime?: string
}

export interface SstatementExistsData {
  bid?: number
  branch_name?: string
}

export interface SstatementSendEmailRequest {
  id?: number[]
}

export interface SstatementStatement {
  advise_email?: string
  bank_account_name?: string
  bank_account_number?: string
  bank_branch?: string
  bank_code?: string
  bank_name?: string
  bank_vat_number?: string
  bid?: number
  branch_name?: string
  brand_id?: EdbBrandID
  charge_sum?: number
  collection_sum?: number
  created_at?: string
  email?: string
  end?: string
  fee_be_borne_by?: EdbBranchFeeBeBorneBy
  id?: number
  invoice_type?: EdbBranchInvoiceType
  legal_entity_name?: string
  oa?: EdbBranchOA
  orders_sum?: number
  region_id?: number
  region_name?: string
  sales_mode?: number
  source?: EdbStatementSource
  start?: string
  transfer_sum?: number
  updated_at?: string
  vat_number?: string
}

export interface SsyscfgCurrencyFilterAmountSetting {
  code?: string
  filter_price_range?: SsyscfgFilterPriceRange[]
  precision?: number
  range?: number[]
}

export type SsyscfgCurrencyIDtoFilterAmountSetting = Record<string, SsyscfgCurrencyFilterAmountSetting>

export interface SsyscfgFilterPriceRange {
  max?: number
  min?: number
}

export enum StagDayMode {
  DayModeWeek = 0,
  DayModeMonthCross = 1,
  DayModeMonth = 2,
}

export enum StagHasCusNum {
  HasCusNumDisable = 0,
  HasCusNumEnable = 1,
}

export enum StagHasTimeFilter {
  HasTimeFilterDisable = 0,
  HasTimeFilterEnable = 1,
}

export interface StatementBatchCreateReq {
  bids?: number[]
  branch_oa?: EdbBranchOA
  /**
   * End time of the time range
   * @example "2024-10-31T23:59:59Z"
   */
  end?: string
  /**
   * Invoice Date, format: YYYY-MM-DD
   * @example "2024-10-10"
   */
  invoice_date?: string
  /** Invoice Due Date Type, 1: Immediate, 2: Net Days */
  invoice_due_date_type?: EdbSOADocumentDueDateType
  is_by_pass_exists?: boolean
  raw_bids?: number[]
  region_ids?: number[]
  source?: EdbStatementSource
  /**
   * Start time of the time range
   * @example "2024-10-01T00:00:00Z"
   */
  start?: string
}

export interface StatementBatchCreateRes {
  created_at?: string
  id?: number
  metadata?: string
  status?: EdbBatchJobStatus
  type?: EdbBatchJobType
  updated_at?: string
}

export interface StatementBatchJobRes {
  created_at?: string
  deleted_at?: GormDeletedAt
  failed_count?: number
  finish_count?: number
  finished_at?: string
  id?: number
  inited_at?: string
  metadata?: any
  region?: StatementRegionData[]
  seconds_to_finish?: number
  status?: EdbBatchJobStatus
  stopped_at?: string
  success_count?: number
  total_count?: number
  type?: EdbBatchJobType
  updated_at?: string
}

export interface StatementFileLinkRes {
  is_processing?: boolean
  link?: string
}

export interface StatementListResponse {
  actual_trans_amount?: number
  adj_note?: string
  advise_email?: string
  bank_account_name?: string
  bank_account_number?: string
  bank_branch?: string
  bank_code?: string
  bank_name?: string
  bank_vat_number?: string
  bid?: number
  branch_name?: string
  brand_id?: number
  brand_name?: string
  charge_adj?: number
  charge_final?: number
  charge_sum?: number
  collection_adj?: number
  collection_final?: number
  collection_sum?: number
  created_at?: string
  currency_code?: EdbCurrencyCode
  currency_id?: EdbCurrencyID
  email?: string
  end?: string
  fee_be_borne_by?: EdbBranchFeeBeBorneBy
  id?: number
  invoice_date?: SchemaMonth
  invoice_sum?: number
  invoice_type?: EdbBranchInvoiceType
  is_email_sent?: number
  legal_entity_name?: string
  mer_inv_amount_adj?: number
  mer_inv_amount_final?: number
  mer_inv_amount_sum?: number
  mer_inv_charge_sum?: number
  mutual_date?: string
  no_trans_status?: number
  oa?: EdbBranchOA
  orders_sum?: number
  region_id?: number
  region_name?: string
  sales_mode?: number
  source?: EdbStatementSource
  st_invoice_status?: EdbStatementInvoiceStatus
  start?: string
  tax_adj?: number
  trans_summary?: string
  transfer_adj?: number
  transfer_date?: string
  transfer_status?: EdbStatementTransferStatus
  transfer_sum?: number
  transfer_total?: number
  tz_location?: string
  unhide_bid?: number
  updated_at?: string
  vat_number?: string
}

export interface StatementRefreshRequest {
  ids?: number[]
}

export interface StatementRegionData {
  id?: number
  name?: string
}

export interface StatementRequest {
  bid?: number
  brand_id?: EdbBrandID
  /**
   * End time of the time range
   * @example "2024-10-31T23:59:59Z"
   */
  end?: string
  /**
   * Invoice Date, format: YYYY-MM-DD
   * @example "2024-10-10"
   */
  invoice_date?: string
  /** Invoice Due Date Type, 1: Immediate, 2: Net Days */
  invoice_due_date_type?: EdbSOADocumentDueDateType
  is_create_exists?: boolean
  sales_mode?: number
  source?: EdbStatementSource
  /**
   * Start time of the time range
   * @example "2024-10-01T00:00:00Z"
   */
  start?: string
}

export interface StatementResponse {
  actual_trans_amount?: number
  adj_note?: string
  advise_email?: string
  attach?: StmtattachFields[]
  bank_account_name?: string
  bank_account_number?: string
  bank_branch?: string
  bank_code?: string
  bank_name?: string
  bank_vat_number?: string
  bid?: number
  branch_name?: string
  brand_id?: number
  brand_name?: string
  charge_adj?: number
  charge_final?: number
  charge_sum?: number
  collection_adj?: number
  collection_final?: number
  collection_sum?: number
  created_at?: string
  currency_code?: EdbCurrencyCode
  currency_id?: EdbCurrencyID
  email?: string
  end?: string
  fee_be_borne_by?: EdbBranchFeeBeBorneBy
  id?: number
  invoice_date?: SchemaMonth
  invoice_sum?: number
  invoice_type?: EdbBranchInvoiceType
  is_email_sent?: number
  item?: StmtitemFields[]
  legal_entity_name?: string
  mer_inv_amount_adj?: number
  mer_inv_amount_final?: number
  mer_inv_amount_sum?: number
  mer_inv_charge_sum?: number
  mutual_date?: string
  no_trans_status?: number
  oa?: EdbBranchOA
  orders_sum?: number
  region_id?: number
  region_name?: string
  sales_mode?: number
  source?: EdbStatementSource
  st_invoice_status?: EdbStatementInvoiceStatus
  start?: string
  tax_adj?: number
  trans_summary?: string
  transfer_adj?: number
  transfer_date?: string
  transfer_status?: EdbStatementTransferStatus
  transfer_sum?: number
  transfer_total?: number
  tz_location?: string
  unhide_bid?: number
  updated_at?: string
  vat_number?: string
}

export interface StatementStopBatchJobRes {
  created_at?: string
  deleted_at?: GormDeletedAt
  finish_count?: number
  id?: number
  status?: EdbBatchJobStatus
  stopped_at?: string
  total_count?: number
  updated_at?: string
}

export interface StatementUpdateMultiData {
  adj_note?: string
  invoice_date?: string
  is_update_adj_note?: boolean
  is_update_invoice?: boolean
  is_update_mutual_date?: boolean
  is_update_no_trans_status?: boolean
  is_update_transfer?: boolean
  mutual_date?: string
  no_trans_status?: number
  st_invoice_status?: EdbStatementInvoiceStatus
  transfer_date?: string
  transfer_status?: EdbStatementTransferStatus
}

export interface StatementUpdateMultiReq {
  data?: StatementUpdateMultiData
  ids?: any[]
}

export interface StatementUpdateReq {
  actual_trans_amount?: number
  adj_note?: string
  charge_adj?: number
  collection_adj?: number
  invoice_date?: string
  mer_inv_amount_adj?: number
  mutual_amount?: number
  mutual_date?: string
  no_trans_status?: number
  st_invoice_status?: EdbStatementInvoiceStatus
  trans_summary?: string
  transfer_adj?: number
  transfer_date?: string
  transfer_status?: EdbStatementTransferStatus
}

export interface StatementUpdateRes {
  actual_trans_amount?: number
  adj_note?: string
  charge_adj?: number
  collection_adj?: number
  id?: number
  invoice_date?: string
  mer_inv_amount_adj?: number
  mutual_amount?: number
  mutual_date?: string
  no_trans_status?: number
  st_invoice_status?: EdbStatementInvoiceStatus
  trans_summary?: string
  transfer_adj?: number
  transfer_date?: string
  transfer_status?: EdbStatementTransferStatus
  updated_at?: string
}

export interface StatementZipAttachesReq {
  attach_types?: EdbAttachType[]
  statement_ids?: number[]
}

export interface StatementemailDocListResponse {
  code?: number
  data?: StatementemailListResponse[]
  message?: string
  total_count?: number
}

export interface StatementemailDocResponse {
  code?: number
  data?: StatementemailResponse
  message?: string
}

export interface StatementemailListResponse {
  created_at?: string
  from_email?: string
  from_name?: string
  id?: number
  statement_id?: number
  status?: number
  subject?: string
  to_email?: string
  updated_at?: string
}

export interface StatementemailRequest {
  bcc?: string
  cc?: string
  content?: string
  from_email?: string
  from_name?: string
  statement_id?: number
  status?: number
  subject?: string
  to_email?: string
}

export interface StatementemailResponse {
  bcc?: string
  cc?: string
  content?: string
  created_at?: string
  from_email?: string
  from_name?: string
  id?: number
  statement_id?: number
  status?: number
  subject?: string
  to_email?: string
  updated_at?: string
}

export interface StatementordersDocListResponse {
  code?: number
  data?: StatementordersListResponse[]
  message?: string
  total_count?: number
}

export interface StatementordersDocResponse {
  code?: number
  data?: StatementordersResponse
  message?: string
}

export interface StatementordersListResponse {
  bid?: number
  booking_time?: string
  branch_name?: string
  commission?: number
  fee?: number
  id?: number
  net_price?: number
  orders_amount?: number
  orders_id?: number
  product_id?: number
  product_name?: string
  region_id?: number
  region_name?: string
  statement_id?: number
  status?: EdbOrdersStatus
  total_amount?: number
  transfer_amount?: number
  tz_location?: string
  unhide_bid?: number
  unhide_orders_id?: number
  unhide_pid?: number
  vat_number?: string
}

export interface StatementordersResponse {
  bid?: number
  booking_time?: string
  branch_name?: string
  commission?: number
  created_at?: string
  fee?: number
  id?: number
  net_price?: number
  orders_amount?: number
  orders_id?: number
  product_add_info?: string
  product_id?: number
  product_name?: string
  quantity?: number
  region_id?: number
  region_name?: string
  statement_id?: number
  status?: EdbOrdersStatus
  total_amount?: number
  transfer_amount?: number
  tz_location?: string
  unhide_bid?: number
  unhide_orders_id?: number
  unhide_pid?: number
  updated_at?: string
  vat_number?: string
}

export interface StatementordersbrandDocListResponse {
  code?: number
  data?: StatementordersbrandListResponse[]
  message?: string
  total_count?: number
}

export interface StatementordersbrandDocResponse {
  code?: number
  data?: StatementordersbrandResponse
  message?: string
}

export interface StatementordersbrandListResponse {
  bid?: number
  booking_time?: string
  branch_name?: string
  brand_id?: number
  brand_name?: string
  fee?: number
  id?: number
  net_price?: number
  orders_amount?: number
  orders_id?: string
  product_id?: number
  product_name?: string
  region_id?: number
  region_name?: string
  statement_id?: number
  status?: number
  total_amount?: number
  transfer_amount?: number
  tz_location?: string
  unhide_bid?: number
  vat_number?: string
}

export interface StatementordersbrandResponse {
  bid?: number
  booking_time?: string
  branch_name?: string
  brand_id?: number
  brand_name?: string
  commission?: number
  created_at?: string
  fee?: number
  id?: number
  net_price?: number
  orders_amount?: number
  orders_id?: string
  product_add_info?: string
  product_id?: number
  product_name?: string
  quantity?: number
  region_id?: number
  region_name?: string
  sales_mode?: number
  statement_id?: number
  status?: number
  total_amount?: number
  transfer_amount?: number
  tz_location?: string
  unhide_bid?: number
  updated_at?: string
  vat_number?: string
}

export interface StaticResponse {
  code?: number
  data?: any
  message?: string
}

export interface StmtattachFields {
  attach_type?: EdbAttachType
  created_at?: string
  file_name?: string
  file_path?: string
  id?: number
  statement_id?: number
  updated_at?: string
}

export interface StmtitemFields {
  charge_amount?: number
  collection_amount?: number
  contract_id?: number
  created_at?: string
  id?: number
  item_name?: string
  payment_amount?: number
  post_remain_qty?: number
  pre_remain_qty?: number
  product_type?: EdbItemProductType
  quantity?: number
  service_type?: EdbItemServiceType
  statement_id?: number
  tax_adj?: number
  unit_price?: number
  updated_at?: string
}

export interface StmtorderssetResponse {
  attend_status?: EdbPaymentAttendStatus
  bid?: number
  booking_time?: string
  branch_name?: string
  brand_id?: number
  brand_name?: string
  channel?: string
  collection_amount?: number
  commission?: number
  created_at?: string
  cust_num?: number
  ecv_amount?: number
  fee?: number
  id?: number
  merchant_discount?: number
  net_price?: number
  orders_amount?: number
  orders_id?: number
  orders_num?: string
  orders_source?: EdbOrdersSource
  pax_fee?: number
  pax_unit_price?: number
  payment_set_attend_status?: EdbPaymentAttendStatus
  payment_set_cust_num?: number
  payment_set_id?: number
  payment_set_pax_fee?: number
  payment_set_pax_unit_price?: number
  product_add_info?: string
  product_id?: number
  product_name?: string
  quantity?: number
  region_id?: number
  region_name?: string
  statement_id?: number
  status?: EdbOrdersStatus
  tier_discount?: number
  transfer_amount?: number
  tz_location?: string
  unhide_bid?: number
  unhide_orders_id?: number
  unhide_pid?: number
  updated_at?: string
  vat_number?: string
}

export interface SystemSetProductRecommendsSerializer {
  code?: string
  data?: any[]
  message?: string
}

export interface SystemsrvSiteMap {
  blog?: Record<string, any>
  branch?: any
  category?: ScategoryCategoryQueryResult[]
  region?: any
}

export interface TagDetail {
  id?: EdbTagID
  name?: string
}

export interface TagStyleResponse {
  background_color?: string
  foreground_color?: string
  icon_image?: string
  id?: EdbLabelStyleID
}

export interface TagTagInfoResponse {
  code?: number
  data?: ProducttagProductTag
  message?: string
}

export interface TagGroupDocListResponse {
  code?: number
  data?: TagGroupListResponse[]
  message?: string
  total_count?: number
}

export interface TagGroupDocResponse {
  code?: number
  data?: TagGroupResponse
  message?: string
}

export interface TagGroupListResponse {
  id?: number
  name?: string
  type?: number
}

export interface TagGroupRequest {
  name?: string
  type?: EdbProductTagType
}

export interface TagGroupResponse {
  created_at?: string
  id?: number
  name?: string
  type?: EdbProductTagType
  updated_at?: string
}

export interface TaskClaimRewardMsg {
  content?: string
  title?: string
}

export interface TaskClaimRewardReq {
  task_reward_history_id: number
}

export interface TaskListRegionData {
  brand_id?: number
  brand_name?: string
  region_id?: number
  region_name?: string
  status?: EdbRegionStatus
  task_id?: number
}

export interface TaskListResponse {
  brand_name?: string
  id?: number
  region?: TaskListRegionData[]
  title?: string
}

export interface TaskRequest {
  brand_id?: number
  calculation_excluded_tags?: TaskTagData[]
  calculation_included_tags?: TaskTagData[]
  completion_method?: string
  cover_image?: string
  duration?: number
  eligibility_excluded_tags?: TaskTagData[]
  eligibility_included_tags?: TaskTagData[]
  end_time?: string
  in_progress_app_link?: string
  internal_title?: string
  locked_app_link?: string
  participation_type?: EdbTaskParticipationType
  region?: EschemaRegionData[]
  required_orders_count_max?: number
  required_orders_count_min?: number
  reward_description?: string
  reward_title?: string
  start_time?: string
  subtitle?: string
  task_reward?: TaskTaskRewardField[]
  terms_of_use?: string
  time_type?: EdbTaskTimeType
  title?: string
  trans?: TaskTransData[]
}

export interface TaskResponse {
  brand_id?: number
  calculation_excluded_tags?: TaskTagData[]
  calculation_included_tags?: TaskTagData[]
  completion_method?: string
  cover_image?: string
  created_at?: string
  duration?: number
  eligibility_excluded_tags?: TaskTagData[]
  eligibility_included_tags?: TaskTagData[]
  end_time?: string
  id?: number
  in_progress_app_link?: string
  internal_title?: string
  locked_app_link?: string
  participation_type?: EdbTaskParticipationType
  region?: EschemaRegionData[]
  required_orders_count_max?: number
  required_orders_count_min?: number
  reward_description?: string
  reward_title?: string
  start_time?: string
  subtitle?: string
  task_reward?: TaskTaskRewardField[]
  terms_of_use?: string
  time_type?: EdbTaskTimeType
  title?: string
  trans?: TaskTransData[]
  updated_at?: string
}

export interface TaskTagData {
  id?: number
  name?: string
}

export interface TaskTask {
  completed_orders_count?: number
  cta_status?: number
  encourage_msg?: string
  end_time?: string
  id?: number
  inprogress_orders_count?: number
  qualification_status?: number
  required_orders_counts?: number[]
  reward_title?: string
  start_time?: string
  subtitle?: string
  time_status_msg?: string
  title?: string
}

export interface TaskTaskDetail {
  completed_orders_count?: number
  completion_method?: string
  cover_image_url?: string
  cta_link?: string
  cta_status?: number
  encourage_msg?: string
  end_time?: string
  id?: number
  inprogress_orders_count?: number
  reward_description?: string
  reward_title?: string
  start_time?: string
  subtitle?: string
  task_rewards?: TaskTaskReward[]
  terms_of_use?: string
  time_status_msg?: string
  title?: string
}

export interface TaskTaskReward {
  history_id?: number
  id?: number
  redemption_status?: number
  required_orders_count?: number
  reward_status?: number
  reward_title?: string
}

export interface TaskTaskRewardField {
  id?: number
  required_orders_count?: number
  reward_miles?: number
  reward_promo_code_code?: string
  reward_promo_code_id?: number
  reward_promo_code_list_id?: number
  reward_promo_code_name?: string
}

export interface TaskTransData {
  completion_method?: string
  completion_method_status?: EdbTransFieldStatus
  id?: number
  is_for_delete?: boolean
  lang_id?: number
  reward_description?: string
  reward_description_status?: EdbTransFieldStatus
  reward_title?: string
  reward_title_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  subtitle?: string
  subtitle_status?: EdbTransFieldStatus
  terms_of_use?: string
  terms_of_use_status?: EdbTransFieldStatus
  title?: string
  title_status?: EdbTransFieldStatus
}

export interface TemplateAlbumImage {
  image_path?: string
}

export interface TemplateBlogData {
  id?: number
  subject?: string
}

export interface TemplateDocAlbumResponse {
  code?: number
  data?: TemplateUploadAlbumRes
  message?: string
}

export interface TemplateDocListResponse {
  code?: number
  data?: TemplateListResponse[]
  message?: string
  total_count?: number
}

export interface TemplateDocResponse {
  code?: number
  data?: TemplateResponse
  message?: string
}

export interface TemplateListResponse {
  created_at?: string
  id?: number
  name?: string
  type?: EdbTemplateType
  updated_at?: string
}

export interface TemplateRequest {
  blog?: TemplateBlogData[]
  content?: string
  name?: string
  setting?: string
  type?: EdbTemplateType
}

export interface TemplateResponse {
  blog?: TemplateBlogData[]
  content?: string
  created_at?: string
  id?: number
  name?: string
  setting?: string
  type?: EdbTemplateType
  updated_at?: string
}

export interface TemplateUploadAlbumRes {
  album_images?: TemplateAlbumImage[]
  id?: number
}

export type TextprotoMIMEHeader = Record<string, string[]>

export enum TimeparseTimeRangeType {
  TimeRangeTypeMidnight = 1,
  TimeRangeTypeMorning = 2,
  TimeRangeTypeNoon = 3,
  TimeRangeTypeAfternoon = 4,
  TimeRangeTypeNight = 5,
  TimeRangeTypeEvening = 6,
}

export interface TitleDocListResponse {
  code?: number
  data?: TitleListResponse[]
  message?: string
  total_count?: number
}

export interface TitleDocResponse {
  code?: number
  data?: TitleResponse
  message?: string
}

export interface TitleListResponse {
  id?: number
  name?: string
}

export interface TitleRequest {
  name?: string
}

export interface TitleResponse {
  created_at?: string
  id?: number
  name?: string
  updated_at?: string
}

export interface TngclientPaymentAmount {
  currency?: string
  /** @example "0" */
  value?: string
}

export interface TngclientResultResp {
  resultCode?: string
  resultMessage?: string
  resultStatus?: string
}

export interface TngclientTNGWebhookRes {
  result?: TngclientResultResp
}

export interface TpclientBackendNotify {
  acquirer?: string
  amount?: number
  auth_code?: string
  bank_transaction_id?: string
  instalment_info?: TpclientInstalmentInfo
  msg?: string
  order_number?: string
  rec_trade_id: string
  status?: number
  transaction_time_millis?: number
}

export interface TpclientInstalmentInfo {
  each_payment?: number
  extra_info?: any
  first_payment?: number
  number_of_instalments?: number
}

export interface UniqtagDocListResponse {
  code?: number
  data?: UniqtagListResponse[]
  message?: string
  total_count?: number
}

export interface UniqtagDocResponse {
  code?: number
  data?: UniqtagResponse
  message?: string
}

export interface UniqtagListResponse {
  id?: number
  rating?: number
  tag_group_id?: number
  tag_group_name?: string
  tag_id?: number
  tag_name?: string
  tag_type?: EdbProductTagType
  uniq_type_tag_group_id?: number
  uniq_type_tag_group_name?: string
  uniq_type_tag_id?: number
  uniq_type_tag_name?: string
  uniq_type_tag_type?: EdbProductTagType
}

export interface UniqtagRequest {
  rating?: number
  tag_id?: number
  uniq_type_tag_id?: number
}

export interface UniqtagResponse {
  id?: number
  rating?: number
  tag_group_id?: number
  tag_group_name?: string
  tag_id?: number
  tag_name?: string
  tag_type?: EdbProductTagType
  uniq_type_tag_group_id?: number
  uniq_type_tag_group_name?: string
  uniq_type_tag_id?: number
  uniq_type_tag_name?: string
  uniq_type_tag_type?: EdbProductTagType
}

export interface UpgradeListResponse {
  bid?: number
  branch_name?: string
  brand_id?: number
  brand_name?: string
  created_at?: string
  duration?: number
  extra_resource_use?: number
  id?: number
  limit_min?: number
  name?: string
  price?: number
  region_id?: number
  region_name?: string
  trans?: UpgradeListTransData[]
  unhide_bid?: number
  updated_at?: string
}

export interface UpgradeListTransData {
  id?: number
  lang_code?: string
  lang_id?: number
  trans_status?: EdbTransStatus
  upgrade_id?: number
  upgrade_name?: string
}

export interface UpgradeProductData {
  id?: number
  name?: string
  upgrade_type?: EdbProductUpgradeType
}

export interface UpgradeRequest {
  bid?: number
  description?: string
  duration?: number
  extra_attendee_sheets?: number
  extra_resource_use?: number
  limit_min?: number
  name?: string
  net_price?: number
  price?: number
  product?: UpgradeProductData[]
  removed_time?: string
  trans?: UpgradeTransData[]
}

export interface UpgradeResponse {
  bid?: number
  branch_name?: string
  created_at?: string
  description?: string
  duration?: number
  extra_attendee_sheets?: number
  extra_resource_use?: number
  id?: number
  limit_min?: number
  name?: string
  net_price?: number
  price?: number
  product?: UpgradeProductData[]
  removed_time?: string
  trans?: UpgradeTransData[]
  tz_location?: string
  unhide_bid?: number
  updated_at?: string
}

export interface UpgradeTransData {
  description?: string
  description_status?: EdbTransFieldStatus
  id?: number
  is_for_delete?: boolean
  lang_id?: number
  name?: string
  name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
}

export interface UpgradeTransListRes {
  code?: string
  id?: number
  name?: string
  status?: EdbTransStatus
  trans_id?: number
  upgrade_id?: number
}

export interface UpgradeTransReq {
  description?: string
  description_status?: EdbTransFieldStatus
  lang_id?: number
  name?: string
  name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  upgrade_id?: number
}

export interface UpgradeTransRes {
  created_at?: string
  description?: string
  description_status?: EdbTransFieldStatus
  id?: number
  lang_id?: number
  name?: string
  name_status?: EdbTransFieldStatus
  status?: EdbTransStatus
  updated_at?: string
  upgrade_id?: number
}

export interface UsecaseMenuItem {
  discount_price?: string
  id?: number
  item?: string
  original_price?: string
}

export interface ValidatorUpdateBookingTimeReq {
  custNum?: number
  headcount?: number
  new_booking_time?: string
  new_bookingtime?: string
  ordersID?: number
  ori_booking_time?: string
  token?: string
  uid?: number
}
